import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { InternalTaskDTO, WorkTaskStatus } from '../../../api/api';
import {
  DateRangeColumnFilter,
  dateRangeFilterFn,
  SearchColumnFilter,
  SelectColumnFilter
} from '../../../blocks/table/TableFilterUtils';
import { CheckboxColumn, multiSelectFilterFn } from '../../../blocks/table/TableUtils';
import StatusTag from '../../../components/status-tag/StatusTag';
import useTableInstance from '../../../hooks/useTableInstance';
import { formatDateString } from '../../../utils/dateHandling';
import { getEnumDisplayValue } from '../../../utils/enumUtils';
import { getLocationString } from '../../../utils/location/locationHandling';

export interface InternalTaskData {
  id?: number;
  status?: string;
  location?: string;
  timeFrom?: string;
  timeTo?: string;
  description?: string;
  createdByName?: string;
  createdByEmail?: string;
  internalTaskType?: string;
  normTime?: number;
}

export const useInternalTaskList = (tasks: InternalTaskDTO[]) => {
  const data = useMemo(() => {
    if (!tasks.length) return [];

    return tasks.map((task) => {
      return {
        id: task.workTaskId,
        status: getEnumDisplayValue(task.status ?? WorkTaskStatus.Created),
        location: getLocationString(task.taskLocation) ?? '-',
        timeFrom: formatDateString(task.timeFrom ?? '-'),
        timeTo: formatDateString(task.timeTo ?? '-'),
        description: task.description ?? '-',
        createdByName: task.createdByName ?? '-',
        createdByEmail: task.createdByEmail ?? '-',
        internalTaskType: task.taskType?.name,
        normTime: task.normTimeMin
      } as InternalTaskData;
    });
  }, [tasks]);

  const columns = useMemo(() => {
    return [
      CheckboxColumn<InternalTaskData>(),
      {
        Header: 'Id',
        accessor: 'id',
        width: 120,
        minWidth: 120,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Type',
        accessor: 'internalTaskType',
        width: 120,
        minWidth: 120,
        Filter: SelectColumnFilter,
        filter: multiSelectFilterFn,
        disableSortBy: true
      },
      {
        Header: 'Addresse',
        accessor: 'location',
        width: 120,
        minWidth: 120,
        Filter: SearchColumnFilter,
        filter: 'text',
        disableSortBy: true
      },
      {
        Header: 'Tekniker',
        accessor: 'createdByName',
        width: 120,
        minWidth: 120,
        Filter: SearchColumnFilter,
        filter: 'text',
        disableSortBy: true
      },
      {
        Header: 'Bemærkning',
        accessor: 'description',
        width: 120,
        minWidth: 120,
        Filter: SearchColumnFilter,
        filter: 'text',
        disableSortBy: true
      },
      {
        Header: 'Fra',
        accessor: 'timeFrom',
        width: 120,
        minWidth: 120,
        Filter: (tableInstance, x) => DateRangeColumnFilter(tableInstance, true),
        filter: dateRangeFilterFn,
        disableSortBy: true
      },
      {
        Header: 'Til',
        accessor: 'timeTo',
        width: 120,
        minWidth: 120,
        Filter: (tableInstance, x) => DateRangeColumnFilter(tableInstance, true),
        filter: dateRangeFilterFn,
        disableSortBy: true
      },
      {
        Header: 'Varighed',
        accessor: 'normTime',
        width: 120,
        minWidth: 120,
        Filter: SearchColumnFilter,
        filter: 'text',
        Cell: (cellProps: CellProps<InternalTaskData>) => {
          const { cell } = cellProps;
          return <>{cell.value + ' min'}</>;
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 80,
        minWidth: 80,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: multiSelectFilterFn,
        Cell: (cellProps: CellProps<InternalTaskData>) => {
          const { cell } = cellProps;
          return <StatusTag statusValue={cell.value}>{cell.value}</StatusTag>;
        }
      }
    ] as Column<InternalTaskData>[];
  }, []);

  const tableInstance = useTableInstance<InternalTaskData>(data, columns, {
    initialState: {
      filters: [
        {
          id: 'status',
          value: [getEnumDisplayValue(WorkTaskStatus.Created), getEnumDisplayValue(WorkTaskStatus.Pause)]
        }
      ],
      pageSize: 50,
      pageIndex: 0
    }
  });

  return { tableInstance };
};
