import { CreateAttributeValueDTO, TaskComponentValueDTO } from '../api/api';

export const extractAttributeValuesFromComponents = (components: TaskComponentValueDTO[]): CreateAttributeValueDTO[] => {
   return components?.reduce((acc, component): CreateAttributeValueDTO[] => {
    component.attributes?.map((a) =>
      acc.push({
      attributeId: a.reusableComponentAttributeId ?? a.attributeId,
      value: a.value,
      valueType: a.inputType,
      taskComponentId: component.id
    })
    );
    return acc;
  }, [] as CreateAttributeValueDTO[]);
};
