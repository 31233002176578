import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ItemConsumptionDTO, ProjectDTO2 } from '../../../../api/api';
import { AddIcon } from '../../../../assets/icons/AddIcon';
import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';
import Button from '../../../../components/button/Button';
import LoadingOverlay from '../../../../components/loading-overlay/LoadingOverlay';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../../../components/table/Table';
import Typography from '../../../../components/typography/Typography';
import GoodsService from '../../../../services/GoodsService';
import TaskService from '../../../../services/TaskService';
import { log } from '../../../../utils/logging/log';
import GoodsTableRow from '../components/GoodsTableRow';
import NoDataTableRow from '../components/NoDataTableRow';
import ConsumeGoodsPage from './ConsumeGoodsPage';

type Page = 'standard' | 'add';

interface Props extends TabProps {
  taskId?: string;
  projectNumber: string;
}

const TaskGoodsStep = (props: Props) => {
  const { taskId, projectNumber } = props;

  const [viewState, setViewState] = useState<'loading' | 'ready' | 'error'>('loading');
  const [page, setPage] = useState<Page>('standard');
  const [itemOrders, setItemOrders] = useState<ItemConsumptionDTO[]>([]);
  const [project, setProject] = useState<ProjectDTO2>();

  useEffect(() => {
    if (taskId) {
      const consumedItemsPromise = GoodsService.getConsumedItems(parseInt(taskId)).then((res) => setItemOrders(res));

      const projectPromise = TaskService.getProjectById(projectNumber).then((res) => setProject(res));

      Promise.all([consumedItemsPromise, projectPromise])
        .then(() => setViewState('ready'))
        .catch((error) => {
          log(error);
          setViewState('error');
        });
    }
  }, [taskId, page, projectNumber]);

  if (page === 'add') {
    return (
      <ConsumeGoodsPage
        handleReturn={() => setPage('standard')}
        taskId={taskId}
        projectNumber={project?.id}
        projectLegalEntity={project?.legalEntityId}
      />
    );
  }

  return (
    <Container>
      {viewState === 'loading' && <LoadingOverlay />}
      {viewState === 'ready' && (
        <>
          <Header>
            <h3>Varer forbrugt på opgaven</h3>
            <Button onClick={() => setPage('add')}>
              <AddIcon size="14px" />
              Forbrug varer
            </Button>
          </Header>
          <TableContainer>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <TableCell sx={{ width: '20%' }}>Dato</TableCell>
                  <TableCell sx={{ width: '30%' }}>Lager</TableCell>
                  <TableCell sx={{ width: '30%' }}>Medarbejder</TableCell>
                  <TableCell sx={{ width: '10%' }}>Antal</TableCell>
                  <TableCell sx={{ width: '10%' }}></TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {itemOrders.length > 0 ? (
                  itemOrders.map((order, i) => {
                    return <GoodsTableRow key={i} itemOrder={order} />;
                  })
                ) : (
                  <NoDataTableRow text="Ingen varer forbrugt på opgaven" />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {viewState === 'error' && <Typography variant="h5"> Der kunne ikke hentes varer.</Typography>}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
`;

const StyledTableRow = styled((props) => <TableRow {...props} />)`
  && .MuiTableCell-root {
    font-weight: 800;
  }
`;

export default TaskGoodsStep;
