import styled from 'styled-components';
import TextField from '../../../components/text-field/TextField';
import { Row, SectionHeader } from '../../../styling/FormStyling';
import { ProfileSetupProps } from '../ProfileSetupDialog';

interface Props extends ProfileSetupProps {}

const TechnicianSetupForm = (props: Props) => {
  const { errors, onChangeValue } = props;

  return (
    <>
      <SectionHeader>Kontaktoplysninger</SectionHeader>
      <StyledProfileRow>
        <TextField
          onChange={(e) => onChangeValue('name', e.target.value)}
          error={errors?.name}
          fullWidth
          label="Fulde navn"
          helperText="Så dit team kan søge dig frem i WORK"
          required
        />
        <TextField
          onChange={(e) => onChangeValue('phone', e.target.value)}
          error={errors?.phone}
          fullWidth
          type="number"
          label="Telefonnummer"
          required
        />
      </StyledProfileRow>
    </>
  );
};

export const StyledProfileRow = styled(Row)`
  margin: 20px 0px;
`;

export default TechnicianSetupForm;
