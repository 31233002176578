import { Question } from '../../models/Question';
import {
  FileContainer,
  UploadedFilesContainer
} from '../../views/task-view/task-details-view2/task-files-step/TaskFilesStep';
import FilePreview from '../file-preview/FilePreview';
import Button from '../button/Button';
import styled from 'styled-components';
import { getGuid } from '../../utils/guidGenerating';
import { useCallback, useRef, useState } from 'react';
import { CameraIcon } from '../../assets/icons/CameraIcon';
import { FileAnswerDTO, FileParameter } from '../../api/api';
import QuestionHeader from '../question-header/QuestionHeader';
import TaskChecklistService from '../../services/TaskChecklistService';
import TaskService from '../../services/TaskService';
import { log } from '../../utils/logging/log';
import { DownloadInfo, ImageType } from '../../models/DownloadInfo';

interface Props {
  question: Question;
  handleSaveFile?: (file: FileParameter[], answerId: number, callback?: () => void) => void;
  taskId?: number;
  handleRemove?: (fileAnswerId: number, questionId: number, taskId: number) => void;
  required?: boolean;
  showMandatoryFieldError?: boolean;
  readOnly?: boolean;
}

const QuestionFile = (props: Props) => {
  const { question, handleSaveFile, taskId, handleRemove, required, showMandatoryFieldError, readOnly } = props;
  const [answerId, setAnswerId] = useState(question.id);
  const [uploading, setUploading] = useState(false);
  const htmlId = question.id?.toString() ?? question.sectionQuestionId + getGuid();

  const inputRef = useRef<HTMLInputElement>(null);

  const resetInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const findAvailableFilename = (files: FileAnswerDTO[], originalFilename: string) => {
    files = files || [];
    let newFilename = originalFilename;
    let index = 1;
    const dotIndex = originalFilename.lastIndexOf('.');
    const extension = dotIndex !== -1 ? originalFilename.slice(dotIndex) : '';

    while (files.some((file) => file.fileName === newFilename)) {
      newFilename = `${originalFilename.slice(0, dotIndex)} (${index})${extension}`;
      index++;
    }
    return newFilename;
  };

  const handleUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!!event.target.files) {
      const files = Array.from(event.target.files).map((obj) => {
        let availableFilename = findAvailableFilename(question?.value, obj.name);
        return {
          data: obj,
          fileName: availableFilename
        } as FileParameter;
      });

      setUploading(true);
      handleSaveFile && handleSaveFile(files, answerId ?? -1, () => setUploading(false));
    }
  };

  const handleDownload = useCallback(
    (filename: string): DownloadInfo => {
      return { imageType: ImageType.QADownloadInfo, taskId, filename, answerId };
    },
    [taskId, answerId]
  );

  const getAnswerId = () => {
    if (answerId === null || answerId === undefined) {
      TaskChecklistService.updateAnswer({ workTaskId: taskId, sectionQuestionId: question.sectionQuestionId }).then(
        (res) => {
          setAnswerId(res[0].answerId);
        }
      );
    }
  };

  const getThumbnail = useCallback(async (fileName: string): Promise<string> => {
    let filename = fileName.substring(0, fileName.lastIndexOf('.'));
    return TaskService.getThumbNail(taskId ?? -1, filename, answerId ?? -1)
      .then((res) => {
        return URL.createObjectURL(res.data);
      })
      .catch((err) => {
        log(err);
        return '';
      });
  }, []);

  return (
    <Container>
      <QuestionHeader
        text={question.text}
        required={required}
        helpText={question.helpText}
        error={showMandatoryFieldError && required && question.value.length === 0}
        readOnly={readOnly}
      />
      {!readOnly && (
        <InputContainer>
          <StyledInput
            ref={inputRef}
            accept={'image/*'}
            id={htmlId}
            multiple
            type="file"
            onChange={handleUploadFile}
            onClick={resetInput}
            data-testid="fileInput"
          />
          <Label htmlFor={htmlId}>
            <Button variant="secondary" element="span" isLoading={uploading} onClick={() => getAnswerId()}>
              <CameraIcon /> Upload
            </Button>
          </Label>
        </InputContainer>
      )}
      {question.value && (
        <UploadedFilesContainer>
          {question?.value.length > 0 &&
            taskId &&
            question?.value.map((obj: FileAnswerDTO, i: any) => {
              return (
                <FileContainer key={obj.fileName}>
                  <FilePreview
                    name={obj.fileName ?? ''}
                    date={obj.uploadedTime}
                    uploadedBy={obj.uploadedBy}
                    handleRemove={
                      readOnly
                        ? undefined
                        : () => handleRemove && question.id && handleRemove(obj.fileAnswerId ?? -1, question.id, taskId)
                    }
                    handleDownload={(fileName) => handleDownload(fileName)}
                    handleThumbnail={() => getThumbnail(obj.fileName ?? '')}
                    isImage
                  />
                </FileContainer>
              );
            })}
        </UploadedFilesContainer>
      )}
    </Container>
  );
};

const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  display: flex;
`;

export const StyledInput = styled.input`
  display: none;
`;

export default QuestionFile;
