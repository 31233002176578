import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { StockDTO } from '../../../../api/api';
import useTableInstance from '../../../../hooks/useTableInstance';
import Checkbox from '../../../../components/checkbox/Checkbox';

export interface StockData {
  name?: string;
  minMax?: string;
  itemId?: string;
  selected?: boolean;
  available?: string;
  minOnHand?: string;
}

export const useStockTable = (
  stocks: (StockDTO & { selected?: boolean })[],
  stockAmountColumnName: string,
  selectItem: (id: string, value: boolean) => void
) => {
  const data = useMemo(() => {
    if (!stocks.length) return [];

    return stocks.map((stock) => {
      const idSuffix = stock.product?.batchNumber || stock.product?.serialNumber;
      return {
        name: stock.product?.name + (idSuffix ? ` (${idSuffix})` : ''),
        minMax: `${stock.minOnHand}/${stock.maxOnHand}`,
        minOnHand: stock.minOnHand,
        itemId: stock.product?.itemId,
        selected: stock.selected,
        available: stock.available
      } as StockData;
    });
  }, [stocks]);

  const columns = useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'selected',
        width: 50,
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<StockData>) => {
          const { cell } = cellProps;
          return (
            <Checkbox
              checked={cell.row.original.selected}
              onChange={(e) => selectItem(cell.row.original.itemId ?? '', e.target.checked)}
              disabled={(cell.row.original.available ?? 0) <= (cell.row.original.minOnHand ?? 0)}
            />
          );
        }
      },
      {
        Header: 'Navn',
        accessor: 'name',
        width: 300,
        disableFilters: true
      },
      {
        Header: 'Varenummer',
        accessor: 'itemId',
        width: 120,
        minWidth: 120,
        disableFilters: true
      },
      {
        Header: stockAmountColumnName,
        accessor: 'available',
        width: 120,
        minWidth: 120,
        disableFilters: true
      },
      {
        Header: 'Min/Max',
        accessor: 'minMax',
        width: 75,
        minWidth: 75,
        disableFilters: true
      }
    ] as Column<StockData>[];
  }, [data]);

  const tableInstance = useTableInstance<StockData>(data, columns);

  return { tableInstance };
};
