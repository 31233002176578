import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { ComponentAttributeDTO, InputType, ReuseableComponentAttributeDTO, TaskComponentDTO } from '../../../../../api/api';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import useTableInstance from '../../../../../hooks/useTableInstance';

export interface ReusableComponentsData {
  id?: number;
  label?: string;
  inputType?: InputType;
  hintText?: string;
}

const useAddFieldTable = (
  allAttributes: ReuseableComponentAttributeDTO[],
  attributeUpdatedCallback: (attribute: ReuseableComponentAttributeDTO, checked: boolean) => void,
  previousAttributes: ComponentAttributeDTO[],
  activeSection?: TaskComponentDTO
) => {
  const data = useMemo(() => {
    const alreadySelectedIds = previousAttributes.map((prevAttributes) => prevAttributes.attributeId);
    return allAttributes.filter((attribute) => !alreadySelectedIds.includes(attribute.id));
  }, [allAttributes, previousAttributes]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Sendes til',
        accessor: 'sendToDepartment',
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<ReuseableComponentAttributeDTO>) => {
          const { cell } = cellProps;
          return (
            <Checkbox
              onChange={(e) => attributeUpdatedCallback(cell.row.original, e.target.checked)}
              checked={activeSection?.attributes?.some((attribute) => attribute.attributeId === cell.row.original.id)}
            />
          );
        }
      },
      {
        Header: 'Id',
        accessor: 'id',
        disableFilters: true,
        width: 50,
        minWidth: 50
      },
      {
        Header: 'Navn',
        accessor: 'label',
        width: 200,
        disableFilters: true
      },
      {
        Header: 'Type',
        accessor: 'inputType',
        disableFilters: true,
        width: 100,
        minWidth: 100
      },
      {
        Header: 'Hjælpetekst',
        accessor: 'hintText',
        width: 200,
        disableFilters: true
      }
    ] as Column<ReusableComponentsData>[];
  }, [activeSection?.attributes, attributeUpdatedCallback]);

  const tableInstance = useTableInstance<ReusableComponentsData>(data, columns);

  return { tableInstance };
};

export default useAddFieldTable;
