import styled from 'styled-components';
import { Section, SectionHeader } from '../../../../../styling/FormStyling';
import TextRow from '../../components/TextRow';
import { StyledSectionContent } from '../TaskDetailsStep';

import { ColumnFlex3 } from '../../../../../styling/Styling';
import { TaskTypeExternalResourceDTO } from '../../../../../api/api';

interface Props {
  externalLinks?: TaskTypeExternalResourceDTO[];
}

export const ExternalResources = (props: Props) => {
  const { externalLinks } = props;

  return (
    <>
      {externalLinks && externalLinks?.length > 0 && (
        <Section>
          <SectionHeader>Links</SectionHeader>
          <StyledSectionContent>
            <StyledColumnFlex>
              {externalLinks
                ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                .map((l, index) => {
                  return (
                    <TextRow
                      type="link"
                      key={l.name ?? '' + index}
                      inputMode={false}
                      disabled
                      label={l.name ?? ''}
                      value={l.url ?? ''}
                    />
                  );
                })}
            </StyledColumnFlex>
          </StyledSectionContent>
        </Section>
      )}
    </>
  );
};

const StyledColumnFlex = styled(ColumnFlex3)`
  justify-content: space-between;
  width: 50%;
`;
