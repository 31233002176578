import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReuseableComponentAttributeDTO } from '../../../api/api';
import { AddIcon } from '../../../assets/icons/AddIcon';
import Table from '../../../blocks/table/TableClient';
import Button from '../../../components/button/Button';
import DialogView from '../../../components/dialog-view/DialogView';
import Typography from '../../../components/typography/Typography';
import { FormMode } from '../../../models/FormMode';
import AdminService from '../../../services/AdminService';
import NotificationService from '../../../services/NotificationService';
import { Row } from '../../../styling/FormStyling';
import { log } from '../../../utils/logging/log';
import ReusableComponentForm from './reusable-component-form/ReusableComponentForm';
import useReusableComponentsTable, { ReusableComponentsData } from './useReusableComponentsList';

const AdminReusableComponentsView = () => {
  const [reusableComponentAttributes, setReusableComponentAttributes] = useState<ReuseableComponentAttributeDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState<ReuseableComponentAttributeDTO>();
  const [mode, setMode] = useState<FormMode>('create');

  useEffect(() => {
    setLoading(true);
    AdminService.getReusableComponents()
      .then((res) => setReusableComponentAttributes(res))
      .catch((err) => {
        NotificationService.error('Kunne ikke hente felter');
        log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleOpenForm = useCallback((attribute: ReuseableComponentAttributeDTO | undefined, mode: FormMode) => {
    setSelectedAttribute(attribute);
    setMode(mode);
    setShowDialog(true);
  }, []);

  const handleCancelCreation = useCallback(() => {
    setShowDialog(false);
  }, []);

  const handleDoubleClick = useCallback(
    (rowData: ReusableComponentsData) => {
      handleOpenForm(
        reusableComponentAttributes.find((a) => a.id === rowData.id),
        'update'
      );
    },
    [reusableComponentAttributes, handleOpenForm]
  );

  const handleSubmitAttributeCreation = useCallback(
    (dto: ReuseableComponentAttributeDTO) => {
      if (mode === 'create') {
        return Promise.resolve(
          AdminService.addReusableComponent(dto)
            .then((res) => {
              setReusableComponentAttributes((prev) => [...prev, res]);
              setShowDialog(false);
              NotificationService.success('Oprettede ny felt');
            })
            .catch((err) => {
              log(err);
              NotificationService.error('Kunne ikke oprette typefelt');
            })
        );
      }

      if (!selectedAttribute?.id) throw new Error('Trying to update field without id');

      return Promise.resolve(
        AdminService.updateReusableComponent(selectedAttribute?.id, dto)
          .then((res) => {
            setReusableComponentAttributes((prev) => prev.map((att) => (att.id === dto.id ? res : att)));
            setShowDialog(false);
            NotificationService.success('Opdaterede typefeltet');
          })
          .catch((err) => {
            log(err);
            NotificationService.error('Kunne ikke opdatere typefelt');
          })
      );
    },
    [mode, selectedAttribute?.id]
  );

  const { tableInstance } = useReusableComponentsTable(reusableComponentAttributes);

  return (
    <Container>
      <Header>
        <Typography variant="h3">Felter til opgavetyper</Typography>
        <Row>
          <Button onClick={() => handleOpenForm({}, 'create')}>
            <AddIcon size="20px" />
            Opret felt
          </Button>
        </Row>
      </Header>
      <Table
        loading={loading}
        tableInstance={tableInstance}
        onDoubleClickRow={(row) => handleDoubleClick(row)}
        showPagination
      />
      {showDialog && (
        <DialogView>
          <ReusableComponentForm
            handleCancel={() => handleCancelCreation()}
            initialBody={selectedAttribute}
            handleSubmit={handleSubmitAttributeCreation}
          />
        </DialogView>
      )}
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;

  & > *:nth-child(1) {
    padding: 30px 30px 0px 30px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default AdminReusableComponentsView;
