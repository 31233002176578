import styled from 'styled-components';
import Typography from '../../../components/typography/Typography';
import Toggle from '../../../components/toggle/Toggle';
import { Status } from '../../../api/api';
import { TechnicianStatus } from '../../../models/TechnicianStatus';

interface Props {
  technicianStatus: TechnicianStatus;
  selectStatus: (enumValue: Status) => void;
  selectedStatusEnumValue?: Status;
}

const TechnicianStatusLine = (props: Props) => {
  const { color, text, status } = props.technicianStatus;
  const { selectStatus, selectedStatusEnumValue } = props;

  return (
    <Container>
      <LeftContainer>
        <ColorTile backgroundColor={color} />
        <Typography variant="h5" fontWeight="bold">
          {text}
        </Typography>
      </LeftContainer>
      <Toggle
        id={Status[status]}
        checked={status === selectedStatusEnumValue}
        handleChange={() => selectStatus(status)}
      ></Toggle>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.palette.grey.black5};
  justify-content: space-between;
  padding: 0px 5px 0px 0px;
  flex-direction: row;
  border-radius: 4px;
  overflow: hidden;
  height: 50px;
`;

const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const ColorTile = styled.div<{ backgroundColor?: string }>`
  background: ${(props) => props.backgroundColor};
  height: 100%;
  width: 5px;
  margin-right: 5px;
`;

export default TechnicianStatusLine;
