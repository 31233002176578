import { TaskComponentAttribute } from '../../models/TaskComponentAttribute';

export const validateDynamicField = (attribute: TaskComponentAttribute) => {
  if (!attribute.value && attribute.isMandatory) return false;

  if (attribute.value && attribute.maxLength && attribute.value.length > attribute.maxLength) return false;

  if (attribute.value && attribute.minLength && attribute.value.length < attribute.minLength) return false;

  return true;
};
