import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const PlayIconOutline = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
      <path d="M320-200v-560l440 280-440 280Zm80-280Zm0 134 210-134-210-134v268Z" />
    </IconSvg>
  );
};
