import styled from 'styled-components';
import { IconProps } from '../../assets/icons/IconProps';

interface Props extends IconProps {
  children?: React.ReactNode;
  viewBox: string;
  xmlns: string;
}

const IconSvg = (props: Props) => {
  const { children, viewBox, xmlns, zIndex } = props;

  return (
    <StyledSvg {...props} viewBox={viewBox} xmlns={xmlns} zIndex={zIndex}>
      {children}
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<IconProps>`
  padding: ${(props) => props.padding ?? '0'};
  width: ${(props) => props.width ?? props.size ?? '28px'};
  height: ${(props) => props.height ?? props.size ?? '28px'};
  margin: ${(props) => props.margin ?? ''};
  min-height: ${(props) => props.minHeight ?? ''};
  min-width: ${(props) => props.minWidth ?? ''};
  z-index: ${(props) => props.zIndex ?? 1};

  polyline,
  path {
    stroke-width: ${(props) => props.strokeWidth};
  }

  fill: ${(props) =>
    props.disabled ? props.theme.palette.grey.black10 : props.color ?? props.theme.palette.main.black.primary};
  stroke: ${(props) =>
    props.disabled ? props.theme.palette.grey.black10 : props.color ?? props.theme.palette.main.black.primary};
`;

export default IconSvg;
