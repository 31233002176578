import styled from 'styled-components';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import Button from '../../../components/button/Button';
import IconButton from '../../../components/icon-button/IconButton';

interface Props<T extends { id: string }> {
  selectedItems: T[];
  children?: React.ReactNode;
  rowNameSingle: string;
  rowNameMultiple: string;
  onClose?: () => void;
}

const TableMenuBar = <T extends { id: string }>(props: Props<T>) => {
  const { selectedItems, children, rowNameSingle, rowNameMultiple, onClose } = props;
  return (
    <TaskBar data-testid="table-menu-bar">
      <span>
        {Object.keys(selectedItems).length === 1
          ? `1 ${rowNameSingle} valgt`
          : Object.keys(selectedItems).length + ` ${rowNameMultiple} valgt`}
      </span>
      {children}
      {onClose && (
        <IconButton onClick={() => onClose()} padding="8px" variant="outlined">
          <CloseIcon size="10px" />
        </IconButton>
      )}
    </TaskBar>
  );
};

const TaskBar = styled.div`
  width: fit-content;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 32px;
  z-index: ${(props) => props.theme.zIndex.main};
  display: flex;
  align-items: center;
  padding: 6px 18px;
  column-gap: 4px;

  > *:first-child {
    padding-right: 22px;
    border-right: 1px solid #ffffff;
  }
  > *:last-child {
    margin-left: 16px;
  }

  color: white;
  background: black;
  border-radius: 16px;
`;

export const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 400;
`;

export default TableMenuBar;
