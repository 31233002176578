import React from 'react';
import styled, { css } from 'styled-components';
import { ChevronRight } from '../../../../../assets/icons/ChevronRight';
import { StationComponentDTO } from '../../../../../api/api';
import Typography from '../../../../../components/typography/Typography';
import IconBadge from '../../../../../components/icon-badge/IconBadge';
import { TargetIcon } from '../../../../../assets/icons/TargetIcon';

interface Props {
  component: StationComponentDTO;
  onClick: (component: StationComponentDTO) => void;
  hasUpdates: boolean;
}

export const Field = (props: Props) => {
  const { component, onClick, hasUpdates } = props;

  return (
    <>
      <Row key={component.name} onClick={() => onClick(component)}>
        {component.voltage && <VoltageIndicator voltage={component.voltage} />}
        <RowText>{component.name}</RowText>
        {hasUpdates && <IconBadge Icon={() => <TargetIcon size="18px" color="white" />} text="Data opdateret" />}
        <RightArrow size="14px" edited={false} focused={false} as={ChevronRight} />
      </Row>

      {component.subComponents && component.subComponents?.length > 0 && (
        <SubComponents>
          <Typography variant="h6" fontStyle="italic">
            Herunder {component.name}
          </Typography>
          {component.subComponents.map((subComponent) => {
            return (
              <Row key={subComponent.name} onClick={() => onClick(subComponent)}>
                {subComponent.voltage && <VoltageIndicator voltage={subComponent.voltage} />}
                <RowText>{subComponent.name}</RowText>
                <RightArrow size="14px" edited={false} focused={false} as={ChevronRight} />
              </Row>
            );
          })}
        </SubComponents>
      )}
    </>
  );
};

const adaptiveColor = css<{ edited?: boolean; focused?: boolean; isComponent?: boolean }>`
  ${({ theme, edited, focused }) =>
    edited
      ? theme.palette.functions.success.primary
      : focused
      ? theme.palette.functions.action.primary
      : theme.palette.grey.black20};
`;

export const Row = styled.div<{ focused?: boolean; edited?: boolean }>`
  display: flex;
  height: 48px;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing(3)} ${(props) => props.theme.spacing(4)};
  margin: ${(props) => props.theme.spacing(2)} 0;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  transition: border 225ms ease-in-out;
  background-color: ${({ edited }) => edited && '#edf9f0'};
  border-color: ${adaptiveColor};
`;

const SubComponents = styled.div`
  margin: 0 0 ${(props) => props.theme.spacing(4)} ${(props) => props.theme.spacing(4)};
`;

export const RowText = styled.div`
  flex: 1;
  margin-top: 3px;
`;

export const RightArrow = styled(ChevronRight)<{ focused: boolean; edited: boolean }>`
  margin-left: ${(props) => props.theme.spacing(3)};
  transition: stroke 225ms ease-in-out;
  stroke: ${adaptiveColor};
  rotate: ${({ focused }) => (focused ? '90deg' : '0deg')};
`;

export const VoltageIndicator = styled.span<{ voltage: number }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: ${(props) => props.theme.spacing(3)};
  background-color: ${({ voltage, theme }) =>
    voltage > 400 ? theme.palette.functions.error.primary : theme.palette.functions.action.primary};
`;

export default Field;
