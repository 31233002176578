import styled from 'styled-components';

import { Link as MuiLink } from '@mui/material';

interface Props {
  children: React.ReactNode;
  fontSize?: number;
  minWidth?: string;
  onClick?: () => void;
  disabled?: boolean;
  disableUnderline?: boolean;
}

const Link = (props: Props) => {
  const { children, fontSize, minWidth, onClick, disabled, disableUnderline, ...rest } = props;

  return (
    <StyledLink
      onClick={onClick}
      fontSize={fontSize}
      minWidth={minWidth}
      disabled={disabled}
      underline={disabled || disableUnderline ? 'none' : 'always'}
      {...rest}
    >
      {children}
    </StyledLink>
  );
};

const StyledLink = styled(MuiLink)<{ disabled?: boolean }>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => props.theme.palette.functions.action.primary} !important;
`;

export default Link;
