import { useCallback } from 'react';
import styled from 'styled-components';
import DialogView from '../../../components/dialog-view/DialogView';
import OrderGoodsContent from './OrderGoodsContent';
import { isDevice } from '../../../utils/device-handling/deviceDetectionUtils';
import { useNavigate } from '../../../hooks/useNavigate';

interface Props {
  onClose?: () => void;
}

const OrderGoodsView = (props: Props) => {
  const { onClose } = props;
  const fullscreenTask = isDevice();
  const navigate = useNavigate();

  const handleCloseDialog = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleCloseTask = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      {fullscreenTask ? (
        <StyledContent>
          <OrderGoodsContent onClose={handleCloseTask} />
        </StyledContent>
      ) : (
        <DialogView handleClose={handleCloseDialog}>
          <StyledDialogContent>
            <OrderGoodsContent onClose={handleCloseDialog} />
          </StyledDialogContent>
        </DialogView>
      )}
    </>
  );
};

const StyledDialogContent = styled.div`
  padding: 0;
  height: 90vh;
  width: 85vw;
  max-width: 1400px;
  overflow: hidden;

  @media (max-width: 1200px) {
    width: 95vw;
  }
`;

const StyledContent = styled.div`
  height: 100vh;
  width: 100vw;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: start;
  overflow: hidden;
`;

export default OrderGoodsView;
