export const convertMinutesToHoursAndMinutes = (_minutes: number) => {
  if (isNaN(_minutes)) return { hours: 0, minutes: 0 };
  var hours = _minutes / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return { hours: rhours, minutes: rminutes };
};

export const convertHoursAndMinutesToMinutes = (hours: number, minutes: number) => {
  if (isNaN(minutes) && isNaN(hours)) return 0;

  if(isNaN(minutes)) return hours * 60;
  
  if(isNaN(hours)) return minutes;

  return hours * 60 + minutes;
};
