import styled, { css } from 'styled-components';
import { CircularProgress } from '@mui/material';

export const StyledCircularProgress = styled((props) => <CircularProgress {...props} />)`
  vertical-align: middle;
`;

const fullWidth = css`
  width: 100%;
`;
export const ColumnFlex = styled.div<{ fullWidth?: boolean; rowGap?: string }>`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.rowGap ?? ''};
  ${(props) => props.fullWidth && fullWidth};
`;
export const ColumnFlex1 = styled((props) => <ColumnFlex {...props} />)`
  flex: 1;
`;
export const ColumnFlex2 = styled((props) => <ColumnFlex {...props} />)`
  flex: 2;
`;
export const ColumnFlex3 = styled((props) => <ColumnFlex {...props} />)`
  flex: 3;
`;
export const ColumnFlex4 = styled((props) => <ColumnFlex {...props} />)`
  flex: 4;
`;
export const ColumnFlex5 = styled((props) => <ColumnFlex {...props} />)`
  flex: 5;
`;
