import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ChecklistEditedLockDTO, ChecklistLockDTO, EditedChecklists, TaskLockDTO } from '../../models/TaskLockDTO';

interface SignalRState {
  connection: any;
  lockedWorkTasks: TaskLockDTO[];
  lockedChecklists: ChecklistLockDTO[];
  activeChecklistLockId: number;
  editedChecklists: EditedChecklists;
}

const initialState: SignalRState = {
  connection: null,
  lockedWorkTasks: [],
  lockedChecklists: [],
  activeChecklistLockId: -1,
  editedChecklists: { activeWorkTask: undefined, editedChecklists: [] }
};

const signalRSlice = createSlice({
  name: 'signalR',
  initialState,
  reducers: {
    setSignalRConnection(state, action: PayloadAction<any>) {
      state.connection = action.payload;
    },
    setLockedWorkTasks: (state, action: PayloadAction<TaskLockDTO[]>) => {
      state.lockedWorkTasks = action.payload;
    },
    setLockedChecklists: (state, action: PayloadAction<ChecklistLockDTO[]>) => {
      state.lockedChecklists = action.payload;
    },
    setActiveChecklistLockId: (state, action: PayloadAction<number>) => {
      state.activeChecklistLockId = action.payload;
    },
    setEditingChecklistOnWorktaskId: (state, action: PayloadAction<number>) => {
      const editingChecklistOnWorktaskId = action.payload;
      state.editedChecklists.activeWorkTask = editingChecklistOnWorktaskId;
      state.editedChecklists.editedChecklists = [];
    },
    setEditedChecklist: (state, action: PayloadAction<ChecklistEditedLockDTO>) => {
      const inputEditedChecklists = action.payload;
      const isOpen = current(state).editedChecklists.activeWorkTask?.toString() === inputEditedChecklists.workTaskId;

      if (isOpen && inputEditedChecklists.checklistId) {
        const editedChecklists = current(state).editedChecklists.editedChecklists;
        state.editedChecklists.editedChecklists = editedChecklists.concat(inputEditedChecklists.checklistId);
      }
    }
  }
});

export const {
  setSignalRConnection,
  setLockedWorkTasks,
  setLockedChecklists,
  setActiveChecklistLockId,
  setEditingChecklistOnWorktaskId,
  setEditedChecklist
} = signalRSlice.actions;
export const selectConnection = (state: RootState) => state.signalRReducer.connection;
export const selectLockedWorkTasks = (state: RootState) => state.signalRReducer.lockedWorkTasks;
export const selectLockedChecklist = (state: RootState) => state.signalRReducer.lockedChecklists;
export const selectActiveChecklistLockId = (state: RootState) => state.signalRReducer.activeChecklistLockId;
export const selectEditedChecklistIds = (state: RootState) => state.signalRReducer.editedChecklists.editedChecklists;

export default signalRSlice.reducer;
