import styled from 'styled-components';

import { TimeRegistrationDTO } from '../../api/api';
import { getUserEmail } from '../../utils/authProvider/authProvider';
import { formatDateString } from '../../utils/dateHandling';
import { renderApproved } from './TimeRegTableUser';
import { TimeRegistrationSO } from '../../stateManagement/reducers/timeRegistrationReducer';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../components/table/Table';
import NoDataTableRow from '../../views/task-view/task-details-view2/components/NoDataTableRow';
import TimeRegMenu from './TimeRegMenu';

interface Props {
  timeRegistrations?: TimeRegistrationDTO[];
  handleDelete: (regId: number) => void;
  handleEdit: (timeReg: TimeRegistrationSO) => void;
}

const TimeRegTableTask = (props: Props) => {
  const { timeRegistrations, handleDelete, handleEdit } = props;

  const userEmail = getUserEmail();

  const renderMyApproved = (row: TimeRegistrationDTO) => {
    if (row.employee === userEmail) {
      return renderApproved(row);
    } else {
      return <></>;
    }
  };

  return (
    <TableContainer>
      <Table cellSpacing={'1px'}>
        <TableHead>
          <TableRow>
            <TableCell>Dato</TableCell>
            <TableCell>Medarbejder</TableCell>
            <TableCell>Bemærkning</TableCell>
            <TableCell>Timeart</TableCell>
            <TableCell>Antal</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timeRegistrations && timeRegistrations?.filter((row) => row.hours !== 0)?.length > 0 ? (
            timeRegistrations
              .filter((row) => row.hours !== 0)
              .map((row, i) => (
                <TableRow key={i}>
                  <TableCell>{row.date ? formatDateString(row.date) : '-'}</TableCell>
                  <TableCell>{row.employee}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.category?.name}</TableCell>
                  <TableCell>{row.hours === 1 ? `${row.hours} time` : `${row.hours} timer`}</TableCell>
                  <TableCell>{renderMyApproved(row)}</TableCell>
                  <TableCell sx={{ padding: '4px' }} align="right">
                    {row?.id && (
                      <TimeRegMenu
                        registration={row}
                        handleDelete={(id: number) => handleDelete(id)}
                        handleEdit={handleEdit}
                        overrideDisable={row.employee !== userEmail}
                        isNegativeTime={row?.hours !== undefined ? row.hours < 0 : false}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <NoDataTableRow text="Ingen timer registreret på opgaven" />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const StyledApprovedSpan = styled(({ approved, ...props }) => <span {...props} />)<{
  approved: boolean;
}>`
  display: flex;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-align: center;
  width: fit-content;
  padding: 0.25rem 1em;

  border-radius: 5px;
  background-color: ${(props) =>
    props.approved ? props.theme.palette.functions.success.primary : props.theme.palette.grey.black40};
`;

export default TimeRegTableTask;
