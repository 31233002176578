import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import TextField from '../../../../../components/text-field/TextField';
import { SectionProps } from '../../../../../models/CommonProps/SectionProps';
import { TaskCreationTask } from '../../../../../models/TaskCreationTask';
import { updateTask } from '../../../../../stateManagement/reducers/taskCreationReducer';
import { Row } from '../../../../../styling/FormStyling';
import { MassCreationTask } from '../../../../../models/MassCreationTask';
import { updateMassCreationTask } from '../../../../../stateManagement/reducers/massCreationReducer';

interface Props extends SectionProps {
  task: TaskCreationTask | MassCreationTask;
  isMassCreation?: boolean;
}

const NotesSection = (props: Props) => {
  const { task, readonlyMode = false, isMassCreation } = props;
  const { taskCreationId } = task as TaskCreationTask;
  const { notesForPlanning } = task;

  const [localNotesForPlanning, setLocalNotesForPlanning] = useState(notesForPlanning || '');

  const dispatch = useDispatch();

  const handleBlurNotesForPlanning = useCallback(() => {
    if (isMassCreation) {
      dispatch(updateMassCreationTask({ notesForPlanning: localNotesForPlanning }));
    } else {
      dispatch(updateTask({ id: taskCreationId ?? '', task: { notesForPlanning: localNotesForPlanning } }));
    }
  }, [dispatch, isMassCreation, localNotesForPlanning, taskCreationId]);

  return (
    <Row>
      <TextField
        label="Tilføj bemærkning"
        placeholder="Skriv bemærkning til planlægning"
        value={localNotesForPlanning}
        fullWidth
        variant="outlined"
        onBlur={handleBlurNotesForPlanning}
        onChange={(e) => setLocalNotesForPlanning(e.target.value)}
        disabled={readonlyMode}
        multiline
        maxRows={2}
        minRows={2}
      />
    </Row>
  );
};

export default NotesSection;
