import { useState } from 'react';
import styled from 'styled-components';
import { AddIcon } from '../../assets/icons/AddIcon';
import Table from '../table/TableClient';
import Button from '../../components/button/Button';
import Typography from '../../components/typography/Typography';
import { Row } from '../../styling/FormStyling';
import useLinksList from './useLinksList';
import CreateEditLinkForm from '../../views/admin/admin-portal-links-view/CreateEditLinkForm';
import { LinkDTO, CreateLinkDTO } from '../../models/LinkModel';

interface Props {
  title?: string;
  links: LinkDTO[];
  loading?: boolean;
  createLinkHandler: (newLink: CreateLinkDTO) => void;
  deleteLinkHandler: (id: number) => void;
  editLinkHandler: (link: LinkDTO) => void;
  updateOrderHandler: (links: LinkDTO[]) => void;
}

const LinksView = (props: Props) => {
  const { title, links, loading, deleteLinkHandler, createLinkHandler, editLinkHandler, updateOrderHandler } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [editingLink, setEditingLink] = useState<LinkDTO>();

  const editPortalLinkHandler = (editingPortalLink: LinkDTO) => {
    setEditingLink(editingPortalLink);
    setShowDialog(true);
  };

  const { tableInstance } = useLinksList(
    links,
    (_links) => updateOrder(_links),
    (editingLink) => editPortalLinkHandler(editingLink),
    (id) => id && deleteLinkHandler(id)
  );

  const handleEditLink = (link: LinkDTO) => {
    editLinkHandler(link);
    closeDialog();
  };

  const handleCreateLink = (link: CreateLinkDTO) => {
    const order = links.length + 1;
    createLinkHandler({ ...link, order: order });
    closeDialog();
  };

  const closeDialog = () => {
    setShowDialog(false);
    setEditingLink(undefined);
  };

  const updateOrder = (_links: LinkDTO[]) => {
    const updateOrderNumbers = _links.map((link, index) => ({ ...link, order: index + 1 }));
    updateOrderHandler(updateOrderNumbers);
  };

  return (
    <Container>
      <Header>
        {title && <Typography variant="h3">{title}</Typography>}
        <Row>
          <Button onClick={() => setShowDialog(true)}>
            <AddIcon size="20px" /> Opret link
          </Button>
        </Row>
      </Header>
      <Table
        loading={loading ?? false}
        tableInstance={tableInstance}
        onDoubleClickRow={(rowData) => editPortalLinkHandler(rowData)}
        showPagination
      />
      {showDialog && (
        <CreateEditLinkForm
          editingLink={editingLink}
          editLinkHandler={handleEditLink}
          allPortalLinks={links}
          createLinkHandler={handleCreateLink}
          closeDialog={() => closeDialog()}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.spacing(5)} ${(props) => props.theme.spacing(10)};
`;

export default LinksView;
