import { SelectChangeEvent } from '@mui/material/Select';
import { TimeOfDay } from '../../api/api';
import { getEnumDisplayValue } from '../../utils/enumUtils';
import { MenuItem, Select } from '../select/Select';

interface Props {
  value?: TimeOfDay;
  onChange?: (event: SelectChangeEvent<any>) => void;
  error?: boolean;
  required?: boolean;
  label: string;
}

const TimeOfDayDropdown = (props: Props) => {
  const { value, onChange, error, required, label } = props;
  return (
    <Select
      required={required}
      label={label}
      value={value || ''}
      onChange={(event) => onChange && onChange(event)}
      error={error}
    >
      <MenuItem value={TimeOfDay.Morning}>{getEnumDisplayValue(TimeOfDay.Morning)}</MenuItem>
      <MenuItem value={TimeOfDay.Noon}>{getEnumDisplayValue(TimeOfDay.Noon)}</MenuItem>
      <MenuItem value={TimeOfDay.Midday}>{getEnumDisplayValue(TimeOfDay.Midday)}</MenuItem>
      <MenuItem value={TimeOfDay.Afternoon}>{getEnumDisplayValue(TimeOfDay.Afternoon)}</MenuItem>
    </Select>
  );
};

export default TimeOfDayDropdown;
