import styled from 'styled-components';
import { TabProps } from '../../../blocks/tabs-vertical/TabsVertical';
import Button from '../../../components/button/Button';
import AssetTypeDropdown from '../../../components/asset-type-dropdown/AssetTypeDropdown';
import {
  AssetType,
  GisRegion2,
  MassCreationRequestItemDTO,
  MassCreationStretchRequestItemDTO,
  MassLookupResponseDTO,
  Region
} from '../../../api/api';
import { useCallback, useEffect, useState } from 'react';
import Papa from 'papaparse';
import AssetService from '../../../services/AssetService';
import { log } from '../../../utils/logging/log';
import { useDispatch, useSelector } from 'react-redux';
import Select, { MenuItem } from '../../../components/select/Select';
import { getEnumDisplayValue, mapEnum } from '../../../utils/enumUtils';
import { selectUserProfile } from '../../../stateManagement/reducers/userProfileReducer';
import NotificationService from '../../../services/NotificationService';
import { MassCreationTask } from '../../../models/MassCreationTask';
import {
  MassCreationTypes,
  STATUS,
  massCreation,
  setFileName,
  setRequestItems,
  setStatus,
  setStretchItems,
  updateMassCreationTask,
  setMassCreationType
} from '../../../stateManagement/reducers/massCreationReducer';
import { getNearestAddressByLatLng } from '../../../utils/location/locationHandling';
import { MassCreationRequestItem } from '../../../models/MassCreationRequestItem';
import { Divider, Grid, LinearProgress, Typography } from '@mui/material';
import { UploadIcon } from '../../../assets/icons/UploadIcon';
import ResultLabel from './ResultLabel';
import FileStepResults from './FileStepResults';
import RadioButtons, { RadioButtonOptions } from '../../../components/radio-buttons/RadioButtons';
import { LocationIcon } from '../../../assets/icons/LocationIcon';
import Hyperlink from '../../../components/hyperlink/HyperLink';
import { RelationIcon } from '../../../assets/icons/RelationIcon';

interface Props extends TabProps {
  task: MassCreationTask;
  disableNextButtonHandler: (disable: boolean) => void;
}

const checkboxItems: RadioButtonOptions[] = [
  {
    label: 'Asset',
    subtextElement: <Typography>Opgaverne udføres hver på et asset</Typography>,
    icon: <LocationIcon size="34px" />,
    value: MassCreationTypes.ASSET
  },
  {
    label: 'Strækning',
    subtextElement: <Typography>'Opgaverne udføres hver på strækningen mellem to assets'</Typography>,
    icon: <RelationIcon size="34px" />,
    value: MassCreationTypes.STRETCH
  }
];

const FileStep = (props: Props) => {
  const { disableNextButtonHandler } = props;
  const dispatch = useDispatch();
  const { showResultsTable, fileName, status, task, requestItems, massCreationType } = useSelector(massCreation);
  const userDepartment = useSelector(selectUserProfile).userProfile.department;
  const [numberOfErrors, setNumberOfErrors] = useState(0);

  const updateMassCreationTaskField = useCallback(
    (key: keyof MassCreationTask, value: any) => {
      dispatch(
        updateMassCreationTask({
          [key]: value
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (Number(massCreationType) === MassCreationTypes.STRETCH) {
      updateMassCreationTaskField('assetType', undefined);
    }
  }, [massCreationType, updateMassCreationTaskField]);

  useEffect(() => {
    if (userDepartment?.departmentId !== task.createdByDepartmentId) {
      updateMassCreationTaskField('createdByDepartmentId', userDepartment?.departmentId);
    }
  }, [task.createdByDepartmentId, updateMassCreationTaskField, userDepartment?.departmentId]);

  useEffect(() => {
    if (requestItems.length === 0) {
      disableNextButtonHandler(true);
      return;
    }
    const _numberOfErrors = requestItems?.filter((obj: MassCreationRequestItem) => !obj.exist).length;

    if (_numberOfErrors > 0) {
      disableNextButtonHandler(true);
      setNumberOfErrors(_numberOfErrors);
    } else {
      disableNextButtonHandler(false);
      setNumberOfErrors(0);
    }
  }, [disableNextButtonHandler, requestItems, massCreationType]);

  const handleFileSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const blob = new Blob(event.target.files as any, { type: 'text/csv' }) as any;
      const _fileName = (event.target.files as any)[0].name;
      dispatch(setFileName(_fileName));
      dispatch(setStatus(STATUS.UPLOADING));
      Papa.parse(blob, {
        header: true,
        skipEmptyLines: true,
        delimiter: ';',
        encoding: 'ISO-8859-1', // Making it possible to read Æ, Ø, Å

        complete: ({ data: csvData }) => {
          if (!csvData) return;

          let promises: Array<Promise<MassLookupResponseDTO>> = [];

          if (Number(massCreationType) === MassCreationTypes.ASSET) {
            promises.push(
              AssetService.massLookup({
                assetIds: csvData.map((row: any) => {
                  return row.assetId;
                }),
                assetType: task.assetType as unknown as AssetType,
                region: task.gisRegion as unknown as Region
              })
            );
          } else if (Number(massCreationType) === MassCreationTypes.STRETCH) {
            const myMap = new Map();

            csvData.forEach((row: any) => {
              if (myMap.get(row.fromType)) {
                !myMap.get(row.fromType).includes(row.fromAssetId) &&
                  myMap.set(row.fromType, [...myMap.get(row.fromType), row.fromAssetId]);
              } else {
                myMap.set(row.fromType, [row.fromAssetId]);
              }
              if (myMap.get(row.toType)) {
                !myMap.get(row.toType).includes(row.toAssetId) &&
                  myMap.set(row.toType, [...myMap.get(row.toType), row.toAssetId]);
              } else {
                myMap.set(row.toType, [row.toAssetId]);
              }
            });

            if (myMap.get(undefined)) {
              NotificationService.error(
                'Kunne ikke søge assets. toType eller fromType er ikke sat korrekt i den uploadede fil'
              );
              dispatch(setStatus(STATUS.READY));
              return;
            }

            myMap.forEach((assets, assetType) => {
              promises.push(
                AssetService.massLookup({
                  assetIds: assets,
                  assetType: assetType as unknown as AssetType,
                  region: task.gisRegion as unknown as Region
                })
              );
            });
          }

          Promise.all(promises)
            .then((response: MassLookupResponseDTO[]) => {
              const gisLookupResponse = response.map((obj) => obj.assets).flat();
              gisLookupResponse.length > 0 &&
                getNearestAddressByLatLng(
                  gisLookupResponse
                    .filter((a) => !!a?.coordinates)
                    .map((a) => {
                      return { lat: a?.coordinates?.yCoord ?? 0, lng: a?.coordinates?.xCoord ?? 0 };
                    })
                ).then((addressResponse) => {
                  const gisResult: MassCreationRequestItemDTO[] = gisLookupResponse.map((gisResponse: any) => {
                    const taskLocation = addressResponse.find(
                      (a) =>
                        a.latitude === gisResponse?.coordinates?.yCoord && a.longitude === gisResponse?.coordinates?.xCoord
                    );

                    const description = (
                      csvData.find((obj: any) => obj.assetId === gisResponse.assetId) as MassCreationRequestItem
                    )?.description;

                    const descriptionStretch = (
                      csvData.find((obj: any) => obj.fromAssetId === gisResponse.assetId) as MassCreationRequestItem
                    )?.description;

                    return {
                      assetId: gisResponse.assetId,
                      description: description ?? descriptionStretch,
                      assetPrimaryVoltage: gisResponse.primaerSpaendingsNiveauKode,
                      exist: gisResponse.exist,
                      taskLocation
                    } as MassCreationRequestItem;
                  });

                  if (Number(massCreationType) === MassCreationTypes.ASSET) {
                    dispatch(updateMassCreationTask({ requestItems: gisResult }));
                  } else if (Number(massCreationType) === MassCreationTypes.STRETCH) {
                    const stretchData = csvData.map((obj: any) => {
                      const gisData = gisResult.find((result: any) => result.assetId === obj.fromAssetId) as any;

                      return {
                        assetFromId: obj.fromAssetId,
                        assetToId: obj.toAssetId,
                        assetTypeFrom: obj.fromType,
                        assetTypeTo: obj.toType,
                        description: obj.description,
                        assetPrimaryVoltage: gisData?.assetPrimaryVoltage,
                        taskLocation: gisData?.taskLocation
                      } as MassCreationStretchRequestItemDTO;
                    });
                    dispatch(setStretchItems(stretchData));
                  } else {
                    log('Could not determine Masscreationtype');
                  }
                  dispatch(setRequestItems(gisResult));
                });
            })
            .catch((e) => {
              NotificationService.error('Der skete en fejl. Kunne ikke slå assets op.');
              log(e);
              setNumberOfErrors((err) => err + 1);
              dispatch(setRequestItems([]));
            })
            .finally(() => {
              event.target.value = '';
              dispatch(setStatus(STATUS.DONE));
            });
        }
      });
    },
    [dispatch, massCreationType, task.assetType, task.gisRegion]
  );

  const regionSelectedHandler = (value: GisRegion2) => {
    updateMassCreationTaskField('gisRegion', value);
  };

  const onDeleteHandler = () => {
    dispatch(setRequestItems([]));
    dispatch(setStatus(STATUS.READY));
  };

  const enableUploadButton = () => {
    if (Number(massCreationType) === MassCreationTypes.ASSET) {
      return task.gisRegion && task.assetType;
    } else if (Number(massCreationType) === MassCreationTypes.STRETCH) {
      return task.gisRegion;
    }
  };

  return showResultsTable ? (
    <FileStepResults requestItems={requestItems} />
  ) : (
    <>
      <Row>
        <RadioButtons
          handleValueChange={(value) => dispatch(setMassCreationType(value))}
          options={checkboxItems}
          value={massCreationType}
          row
          labelPlacement="start"
          showDivider
          disabled={requestItems.length > 0}
        />
      </Row>
      <Row>
        <InformationBox>
          <LeftContainer>
            <QuestionMark>?</QuestionMark>
          </LeftContainer>
          <RightContainer>
            {Number(massCreationType) === MassCreationTypes.ASSET && (
              <>
                <Typography variant="body2">
                  CSV-filen der uploades skal være semikolon-separeret, og den skal være i formatet: ”assetId, description”.
                </Typography>
                <Typography variant="body2">”assetID” angiver det asset opgaven skal udføres på</Typography>
                <Typography variant="body2">
                  ”description” angiver hvad der skrives på arbejdskortets opgavebeskrivelse
                </Typography>
              </>
            )}
            {Number(massCreationType) === MassCreationTypes.STRETCH && (
              <>
                <Typography variant="body2">
                  CSV-filen der uploades skal være semikolon-separeret, og den skal være i formatet: "fromAssetId, fromType,
                  toAssetId, toType, description"
                </Typography>
                <Typography variant="body2">"fromAssetId" angiver det asset opgaven skal udføres fra</Typography>
                <Typography variant="body2">"fromType" angiver asset-typen af fromAssetId</Typography>
                <Typography variant="body2">"toAssetId" angiver det asset opgaven skal udføres til</Typography>
                <Typography variant="body2">"toType" angiver asset-typen af toAssetId</Typography>
                <Typography variant="body2">
                  ”description” angiver hvad der skrives på arbejdskortets opgavebeskrivelse
                </Typography>
              </>
            )}
          </RightContainer>
        </InformationBox>
      </Row>
      <Row>
        <StyledDivider />
      </Row>
      {status === STATUS.READY && (
        <>
          <Row>
            <Column>
              <StyledSectionLabel>Region</StyledSectionLabel>
              <Select
                data-testid="region-dropdown"
                id="region"
                fullWidth
                value={task.gisRegion}
                onChange={(e) => regionSelectedHandler(e.target.value as GisRegion2)}
                variant="outlined"
                displayEmpty
              >
                <MenuItem value={undefined}>Intet valgt</MenuItem>
                {mapEnum(GisRegion2).map((region, i) => {
                  return (
                    <MenuItem key={i} value={GisRegion2[region]}>
                      {getEnumDisplayValue(GisRegion2[region])}
                    </MenuItem>
                  );
                })}
              </Select>
            </Column>
            <Column>
              <StyledSectionLabel>Type</StyledSectionLabel>
              <AssetTypeDropdown
                assetTypeChange={(e) => updateMassCreationTaskField('assetType', e.target.value)}
                value={task.assetType}
                showNothingSelectedValue
                disabled={Number(massCreationType) === MassCreationTypes.STRETCH}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <StyledSectionLabel>Upload CSV fil</StyledSectionLabel>
              <ButtonContainer>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  id="image-upload"
                  accept={'.csv'}
                  onChange={(e) => handleFileSelect(e)}
                  disabled={!enableUploadButton()}
                />
                <label htmlFor="image-upload">
                  <StyledUploadButton variant="secondary" element="span" disabled={!enableUploadButton()}>
                    <UploadIcon size="20px" /> Upload
                  </StyledUploadButton>
                </label>
                <StyledButtonHelpText>Select a .csv file to upload</StyledButtonHelpText>
              </ButtonContainer>
            </Column>
          </Row>
        </>
      )}

      {status === STATUS.UPLOADING && (
        <>
          <LinearProgress />
        </>
      )}

      {status === STATUS.DONE && (
        <Row>
          <StyledContainer>
            <FullWidthRow>
              <SubContainer>
                {task.gisRegion && (
                  <StyledTextContainer>
                    <StyledLabel>Region:</StyledLabel>
                    {`${getEnumDisplayValue(task.gisRegion)}`}
                  </StyledTextContainer>
                )}
                {task.assetType && (
                  <StyledTextContainer>
                    <StyledLabel>Type:</StyledLabel>
                    {`${getEnumDisplayValue(task.assetType)}`}
                  </StyledTextContainer>
                )}
                <Hyperlink label={fileName} />
              </SubContainer>
              <SubContainer>
                <Button onClick={onDeleteHandler} variant="secondary">
                  Slet
                </Button>
              </SubContainer>
            </FullWidthRow>

            <Grid lg={12} paddingBottom={1}>
              <ResultLabel numberOfErrors={numberOfErrors} />
            </Grid>
          </StyledContainer>
        </Row>
      )}
    </>
  );
};

const Row = styled.div`
  display: flex;
  column-gap: 24px;
  width: 100%;
  margin-top: 20px;
`;

const FullWidthRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InformationBox = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff4ec;
  padding: 12px 5px;
  border-radius: 10px;
  font-weight: 500;
  width: 100%;
`;

const LeftContainer = styled.div`
  padding: 10px;
`;

const SubContainer = styled.div``;

const RightContainer = styled.div``;

const QuestionMark = styled.div`
  color: white;
  background-color: ${(props) => props.theme.palette.functions.warning.primary};
  padding: 1px 7px;
  border-radius: 50%;
  font-size: 13px;
`;

const StyledDivider = styled(Divider)`
  width: 100%;
`;

const StyledUploadButton = styled(Button)`
  padding: 12px 18px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSectionLabel = styled.label`
  margin-bottom: 10px;
`;

const StyledButtonHelpText = styled.label`
  margin: 0px 15px;
  font-size: 13px;
`;

const StyledTextContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const StyledLabel = styled.label`
  font-weight: 600;
  width: 75px;
`;

export default FileStep;
