import { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import calendar, {
  isDate,
  isSameDay,
  isSameMonth,
  getNextMonth,
  getPreviousMonth,
  WEEK_DAYS,
  THIS_MONTH,
  THIS_YEAR
} from './helpers';
import DatePickerHeader from './components/DatePickerHeader';
import { CalendarIcon } from '../../assets/icons/CalendarIcon';
import Grow from '../grow';
import TextField from '../text-field/TextField';
import { formatDate } from '../../utils/dateHandling';
import { InputAdornment, TextFieldProps } from '@mui/material';
import n1LightTheme from '../../theme/light-theme';
import { isSameWeek, getWeek } from 'date-fns';

export type DateState = { current?: Date; month: number; year: number };

export type Props = {
  defaultDate?: Date;
  onDateChanged: (date: Date) => void;
  disableWeekends?: boolean;
  earliestStartDate?: Date;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  className?: string;
  compact?: boolean;
  centerX?: boolean;
  centerY?: boolean;
  selectEntireWeek?: boolean;
  allowedDays?: Date[];
} & TextFieldProps;

const DatePicker = (props: Props) => {
  const {
    defaultDate,
    onDateChanged,
    top,
    right,
    bottom,
    left,
    className,
    disableWeekends,
    earliestStartDate,
    compact,
    centerX,
    centerY,
    required,
    selectEntireWeek = false,
    allowedDays,
    ...textFieldProps
  } = props;

  const [open, setOpen] = useState(false);

  const [dateState, setDateState] = useState<DateState>({ month: THIS_MONTH(), year: THIS_YEAR() });
  const [today] = useState(new Date());

  const getCalendarDates = () => {
    const { current, month, year } = dateState;
    const calendarMonth = month || (current ? +current.getMonth() + 1 : 0);
    const calendarYear = year || (current ? current.getFullYear() : 0);
    return calendar(calendarMonth, calendarYear);
  };

  const isDateDisabled = (date: Date) => {
    let disabled = false;

    if (allowedDays) {
      disabled = !allowedDays.find((allowedDate) => isSameDay(allowedDate, date));
    }
    if (!disabled && disableWeekends) {
      disabled = date.getDay() === 6 || date.getDay() === 0;
    }
    if (!disabled && earliestStartDate) {
      disabled = date < new Date(earliestStartDate.toDateString());
    }

    return disabled;
  };

  const renderCalendarDate = (date: any, index: number) => {
    const { current, month, year } = dateState;
    const _date = new Date(date.join('-'));
    const isToday = isSameDay(_date, today);
    const isCurrent = current && isSameDay(_date, current);
    const isCurrentWeek = selectEntireWeek && current && isSameWeek(_date, current, { weekStartsOn: 1 });
    const inMonth = month && year ? isSameMonth(_date, new Date([year, month, 1].join('-'))) : false;
    const onClick = gotoDate(_date);
    const props = { index, inMonth, onClick, title: _date.toDateString() };
    let disabled = isDateDisabled(_date);

    let DateComponent = CalendarDate;
    if (selectEntireWeek && isCurrentWeek) {
      DateComponent = isToday ? HighlightedTodayCalendarDate : HighlightedCalendarWeekDate;
    } else {
      DateComponent = isCurrent ? HighlightedCalendarDate : isToday ? TodayCalendarDate : CalendarDate;
    }

    return (
      <DateComponent disabled={disabled} key={`date-component-${index}`} {...props} index={index} inMonth={inMonth}>
        {_date.getDate()}
      </DateComponent>
    );
  };

  const weekNumbers = (): number[] => {
    const weekNumbers: number[] = [];
    getCalendarDates().forEach((date) => {
      const _date = new Date(date.join('-'));
      const weekNumber = getWeek(_date, { firstWeekContainsDate: 4, weekStartsOn: 1 });
      if (!weekNumbers.includes(weekNumber)) {
        weekNumbers.push(weekNumber);
      }
    });
    return weekNumbers;
  };

  const addDateToState = useCallback((date: Date) => {
    const isDateObject = isDate(date);
    const _date = isDateObject ? date : new Date();
    setDateState({
      current: isDateObject ? date : _date,
      month: +_date.getMonth() + 1,
      year: _date.getFullYear()
    });
  }, []);

  const gotoDate = useCallback(
    (date: Date) => (event: any) => {
      event && event.preventDefault();
      const { current } = dateState;
      !(current && isSameDay(date, current)) && addDateToState(date);
      onDateChanged(date);
      setOpen(false);
    },
    [dateState, addDateToState, onDateChanged]
  );

  const gotoPreviousMonth = useCallback(() => {
    const { month, year } = dateState;
    const previousMonth = getPreviousMonth(month, year);
    setDateState({
      month: previousMonth.month,
      year: previousMonth.year,
      current: dateState.current
    });
  }, [dateState]);

  const gotoNextMonth = useCallback(() => {
    const { month, year } = dateState;
    const nextMonth = getNextMonth(month, year);
    setDateState({
      month: nextMonth.month,
      year: nextMonth.year,
      current: dateState.current
    });
  }, [dateState]);

  const gotoPreviousYear = useCallback(() => {
    const { year } = dateState;
    setDateState({
      month: dateState.month,
      year: year - 1,
      current: dateState.current
    });
  }, [dateState]);

  const gotoNextYear = useCallback(() => {
    const { year } = dateState;
    setDateState({
      month: dateState.month,
      year: year + 1,
      current: dateState.current
    });
  }, [dateState]);

  useEffect(() => {
    defaultDate && addDateToState(defaultDate);
  }, [defaultDate, addDateToState]);

  const dateValue = useMemo(() => {
    if (dateState.current) {
      return formatDate(dateState.current);
    } else {
      return '';
    }
  }, [dateState]);

  return (
    <Root className={className}>
      <TextField
        onClick={() => !props.disabled && setOpen((open) => !open)}
        title={dateValue}
        value={dateValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarIcon color={n1LightTheme.palette.grey.black60} size={compact ? '14px' : '18px'} />
            </InputAdornment>
          ),
          readOnly: true
        }}
        autoComplete={'off'}
        compact={compact}
        fullWidth
        required={required}
        {...textFieldProps}
      />
      <Grow in={open}>
        <CalendarContainer
          top={top}
          right={right}
          bottom={bottom}
          left={left}
          centerX={centerX ?? false}
          centerY={centerY ?? false}
        >
          <DatePickerHeader
            dateState={dateState}
            gotoPreviousMonth={gotoPreviousMonth}
            gotoNextMonth={gotoNextMonth}
            gotoPreviousYear={gotoPreviousYear}
            gotoNextYear={gotoNextYear}
          />
          <CalendarGrid>
            {Object.keys(WEEK_DAYS).map((day, index) => {
              return (
                <CalendarDay key={`calendar-day-${index}`} index={index}>
                  {WEEK_DAYS[day as keyof typeof WEEK_DAYS]}
                </CalendarDay>
              );
            })}

            {weekNumbers().map((weekNumber, index) => {
              return <WeekNumberCell key={`week-number-${index}`}>{weekNumber}</WeekNumberCell>;
            })}

            <>{getCalendarDates().map(renderCalendarDate)}</>
          </CalendarGrid>
        </CalendarContainer>
      </Grow>
    </Root>
  );
};

export const Root = styled.div`
  position: relative;
  width: 100%;
`;

export const CalendarContainer = styled.div<{
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  centerX?: boolean;
  centerY?: boolean;
}>`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  overflow: hidden;
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.dialogTools};
  padding: 4px 12px 12px 12px;

  top: ${(props) => props.top ?? '50px'};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  left: ${(props) => !props.left && !props.right && '0'};

  ${({ centerX, centerY }) =>
    centerX && centerY
      ? css`
          top: 50%;
          bottom: auto;
          left: 50%;
          right: auto;
          transform: translate(-50%, -50%) !important;
        `
      : centerX && !centerY
      ? css`
          left: 50%;
          right: auto;
          transform: translateX(-50%) !important;
        `
      : !centerX && centerY
      ? css`
          top: 50%;
          bottom: auto;
          transform: translateY(-50%) !important;
        `
      : css``};

  background: #ffffff;
  border: 1px solid ${(props) => props.theme.palette.grey.black20};
  border-radius: 16px;
`;

export const CalendarGrid = styled.div<{ borderBottom?: boolean }>`
  display: grid;
  grid-template: repeat(7, 36px) / repeat(8, 36px);
  justify-items: center;
  justify-content: space-between;
  grid-row-gap: 5px;
  padding: 0 12px;
  margin: 0 -12px;
  border-top: 1px solid ${(props) => props.theme.palette.grey.black10};
  border-bottom: ${(props) => props.borderBottom && `1px solid ${props.theme.palette.grey.black10}`};
`;

export const CalendarCell = styled.div<{ index: number; inMonth?: boolean }>`
  width: 36px;
  text-align: center;
  align-self: center;
  border: 1px solid transparent;
  padding: 6px;
  user-select: none;
  grid-column: ${(props) => (props.index % 7) + 2} / span 1;
  content: ${(props) => `"${(props.index % 7) + 2}"`};
`;

export const WeekNumberCell = styled.div`
  width: 36px;
  text-align: center;
  align-self: center;
  border: 1px solid transparent;
  padding: 6px;
  user-select: none;
  grid-column: 1;
  content: 1;
  font-weight: 600;
`;

export const CalendarDay = styled(CalendarCell)`
  ${(props) => ({ ...props.theme.typography.p })};
  font-weight: 600;
`;

export const CalendarDate = styled(CalendarCell)<{ hoverActive?: boolean; disabled?: boolean }>`
  ${(props) => ({ ...props.theme.typography.p })};
  font-weight: ${(props) => (props.inMonth ? 400 : 300)};
  background: ${(props) => (props.hoverActive ? props.theme.palette.grey.black5 : 'transparent')};
  cursor: pointer;
  border-radius: 14px;
  box-sizing: border-box;
  color: ${(props) => !props.inMonth && props.theme.palette.grey.black40};
  grid-row: ${(props) => Math.floor(props.index / 7) + 2} / span 1;
  transition: all 0.4s ease-out;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.palette.grey.black10} !important;
      pointer-events: none !important;
    `}

  :hover {
    color: #333;
    background: ${(props) => props.theme.palette.grey.black10};
  }
`;

export const HighlightedCalendarDate = styled(CalendarDate)`
  color: #fff !important;
  background: ${(props) => props.theme.palette.grey.black60} !important;
`;

export const HighlightedCalendarWeekDate = styled(CalendarDate)`
  background: rgba(255, 212, 0, 0.5) !important;
`;

export const HighlightedTodayCalendarDate = styled(HighlightedCalendarWeekDate)`
  border: 1px solid ${(props) => props.theme.palette.grey.black60};
`;

export const TodayCalendarDate = styled(HighlightedCalendarDate)`
  color: #000 !important;
  border: 1px solid ${(props) => props.theme.palette.grey.black60};
  background: ${(props) => (props.hoverActive ? props.theme.palette.grey.black5 : 'transparent')} !important;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.palette.grey.black10} !important;
      pointer-events: none !important;
    `}

  :hover {
    background: ${(props) => props.theme.palette.grey.black10} !important;
  }
`;

export default DatePicker;
