import {
  Column,
  TableOptions,
  useColumnOrder,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useRowSelect,
  useRowState,
  useSortBy,
  useTable
} from 'react-table';

const useTableInstance = <T extends object>(data: T[], columns: Column<T>[], options?: Partial<TableOptions<T>>) => {
  const useTableBody = {
    plugins: [
      useGlobalFilter,
      useFilters,
      useColumnOrder,
      useGroupBy,
      useSortBy,
      useExpanded,
      useFlexLayout,
      usePagination,
      useRowSelect,
      useRowState
    ]
  };
  return useTable<T>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: Number.MAX_SAFE_INTEGER
      },
      autoResetGlobalFilter: false,
      ...options
    },
    ...useTableBody.plugins
  );
};

export default useTableInstance;
