import { useCallback, useState } from 'react';

const useFormIsDirty = () => {
  const [fields, setFields] = useState<Record<string, boolean>>({});
  const [isDirty, setIsDirty] = useState(false);

  const toggleFieldIsDirty = useCallback(
    (key: string, value: boolean) => {
      const obj = fields;
      obj[key] = value;
      setFields(obj);
      setIsDirty(Object.values(obj).some((dirty) => dirty));
    },
    [fields]
  );

  const resetFields = () => {
    setFields({});
    setIsDirty(false);
  };

  return { isDirty, toggleFieldIsDirty, resetFields };
};

export default useFormIsDirty;
