import { InputAdornment } from '@mui/material';
import { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { DepartmentDTO, ReasonCodeDTO, UpdateReasonCodeStatusDTO2 } from '../../../../../api/api';
import { SearchIcon } from '../../../../../assets/icons/SearchIcon';
import { TabProps } from '../../../../../blocks/tabs-vertical/TabsVertical';
import TextField from '../../../../../components/text-field/TextField';
import useAddReasonTable from './useAddReasonTable';
import { StyledTable } from '../../AdminTaskTypesView';
import AdminService from '../../../../../services/AdminService';
import Typography from '../../../../../components/typography/Typography';
import { StyledBackButtonIcon } from '../../AdminTaskTypesDialog';
import { ChevronLeft } from '../../../../../assets/icons/ChevronLeft';

export const CREATING_DEPARTMENT = 'CREATING_DEPARTMENT';
export const PLANNING_DEPARTMENT_NAME = 'Planlægning';

interface Props extends TabProps {
  allReasonCodes: Array<ReasonCodeDTO>;
  selectedReasonCodes: UpdateReasonCodeStatusDTO2[];
  newReasonCodes: UpdateReasonCodeStatusDTO2[];
  setNewReasonCodes: (reasonCode: UpdateReasonCodeStatusDTO2[]) => void;
  onBack: () => void;
}

const AddReason = (props: Props) => {
  const { allReasonCodes, onBack, newReasonCodes, setNewReasonCodes, selectedReasonCodes } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [departments, setDepartments] = useState<DepartmentDTO[]>([]);

  useEffect(() => {
    AdminService.getDepartments().then((res) => setDepartments(res ?? []));
  }, []);

  const reasonSelectedHandler = useCallback(
    (reasonCodeId: string, checked: boolean) => {
      const obj: UpdateReasonCodeStatusDTO2 = {
        reasonCodeId: parseInt(reasonCodeId)
      };

      if (checked) {
        const newList = [...newReasonCodes, obj];
        setNewReasonCodes(newList);
      } else {
        const filteredList = [...newReasonCodes].filter((elm) => elm.reasonCodeId !== parseInt(reasonCodeId));
        setNewReasonCodes(filteredList);
      }
    },
    [newReasonCodes, setNewReasonCodes]
  );

  const departmentChangedHandler = useCallback(
    (reasonCodeId: string, newDepartment: string) => {
      const updated = newReasonCodes.map((reason) => {
        if (parseInt(reasonCodeId) === reason.reasonCodeId) {
          return {
            ...reason,
            sendToDepartmentId:
              newDepartment === CREATING_DEPARTMENT
                ? departments.find((d) => d.name == PLANNING_DEPARTMENT_NAME)?.departmentId
                : departments.find((d) => d.departmentId === parseInt(newDepartment))?.departmentId,
            sendToCreatingDepartment: newDepartment === CREATING_DEPARTMENT
          };
        } else {
          return reason;
        }
      });

      setNewReasonCodes(updated);
    },
    [departments, newReasonCodes, setNewReasonCodes]
  );

  const { tableInstance } = useAddReasonTable(
    newReasonCodes,
    selectedReasonCodes,
    allReasonCodes,
    departments,
    departmentChangedHandler,
    reasonSelectedHandler,
    searchTerm
  );

  return (
    <>
      <StyledContainer>
        <Header>
          <StyledBackButtonIcon onClick={() => onBack()}>
            <ChevronLeft size="22px" />
          </StyledBackButtonIcon>
          <Typography variant="h4">Tilføj årsagsforklaring</Typography>
        </Header>
        <StyledTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon size="18px" />
              </InputAdornment>
            )
          }}
          label="Søg på statusårsag"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
        <StyledTable
          showPagination
          noDataText="Alle årsager er allerede valgt"
          noPadding
          loading={false}
          tableInstance={tableInstance}
        />
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  margin: 20px 0px;
  height: 80%;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

export default AddReason;
