import { InputAdornment } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { updateTask } from '../../../stateManagement/reducers/taskCreationReducer';

import TaskTypeService from '../../../services/TaskTypeService';

import { TaskTypeCategoryTypeEnum, TaskTypeDetailedDTO2 } from '../../../api/api';

import { TabProps } from '../../../blocks/tabs-vertical/TabsVertical';

import { CheckmarkIcon } from '../../../assets/icons/CheckmarkIcon';
import { SearchIcon } from '../../../assets/icons/SearchIcon';

import { TaskCreationTask } from '../../../models/TaskCreationTask';

import CircularProgress from '../../../components/circular-progress/CircularProgress';
import TextField from '../../../components/text-field/TextField';

import Button from '../../../components/button/Button';
import Select, { MenuItem } from '../../../components/select/Select';
import { MassCreationTask } from '../../../models/MassCreationTask';
import { TaskComponentAttribute } from '../../../models/TaskComponentAttribute';
import { TaskComponent } from '../../../models/TaskTypeComponent';
import {
  MassCreationTypes,
  massCreation,
  updateMassCreationTask
} from '../../../stateManagement/reducers/massCreationReducer';
import { Container, Section, SectionHeader } from '../../../styling/FormStyling';
import { getEnumDisplayValue, Voltage } from '../../../utils/enumUtils';
import { log } from '../../../utils/logging/log';
import { InfoIcon } from '../../../assets/icons/InfoIcon';
import ToolTip from '../../../components/tool-tip/ToolTip';
import Typography from '../../../components/typography/Typography';

interface Props extends TabProps {
  task: TaskCreationTask | MassCreationTask;
  isMassCreation?: boolean;
  setShouldCreateRelationsTask?: (shouldCreate: boolean) => void;
}

const TaskTypeStepDetails = (props: Props) => {
  const { task, isMassCreation, setShouldCreateRelationsTask } = props;
  const { taskCreationId = '' } = task as TaskCreationTask;
  const { taskTypeCategories = [], specificAssetTypes = [], taskTypeDTOs = [] } = task.taskTypePickerInfo ?? {};

  const dispatch = useDispatch();

  const [filteredWorkTypes, setFilteredWorkTypes] = useState<TaskTypeDetailedDTO2[]>(
    task.taskTypePickerInfo?.taskTypeDTOs ?? []
  );
  const [assetTypeFilter, setAssetTypeFilter] = useState<string[]>(task.assetTypeFilter ?? []);
  const [taskCategoryFilter, setTaskCategoryFilter] = useState<number[]>(task.taskCategoryFilter ?? []);
  const [voltageLevelFilter, setVoltageLevelFilter] = useState<Voltage[]>(task.voltageLevelFilter ?? []);
  const [emergencyErrorFilter, setEmergencyErrorFilter] = useState<string[]>(task.emergencyErrorFilter ?? []);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState(task.showFilters);
  const massCreationType = useSelector(massCreation).massCreationType;

  const fetchWorkTypes = useCallback(async () => {
    if (!isSearching) {
      setIsSearching(true);
      try {
        const res = await TaskTypeService.getActiveTaskTypes();
        if (isMassCreation) {
          dispatch(updateMassCreationTask({ taskTypePickerInfo: res }));
        } else {
          dispatch(updateTask({ id: taskCreationId, task: { taskTypePickerInfo: res } }));
        }
      } catch (error) {
        log(error);
      } finally {
        setIsSearching(false);
      }
    }
  }, [dispatch, isMassCreation, isSearching, taskCreationId]);

  useEffect(() => {
    if (!task.taskTypePickerInfo) fetchWorkTypes();
  }, [fetchWorkTypes, task.taskTypePickerInfo]);

  const handleTaskTypeSelected = (taskType: TaskTypeDetailedDTO2) => {
    const newTask: TaskCreationTask = {
      taskTypeId: taskType.id,
      taskType,
      normTimeMin: taskType.normTimeMin,
      projectNumber: taskType.projectNumber_OneERP,
      assignedToDepartmentId: taskType?.defaultDepartment?.departmentId,
      ignoreDrivingTimeWhenPlanned: taskType.ignoreDrivingTimeWhenPlanned,
      earliestStartDate: undefined,
      deadline: undefined,
      normTimeValidated: false,
      components: taskType?.taskComponents?.map((c) => {
        return {
          ...c,
          attributes: c.attributes?.map((a) => {
            let attribute = { ...a } as TaskComponentAttribute;
            const attributeFromParent = task.componentsFromParent?.find((cfp) => cfp.attributeId === a.attributeId);
            if (attributeFromParent?.value) attribute.value = attributeFromParent.value;

            return attribute;
          })
        } as TaskComponent;
      })
    };
    if (isMassCreation) {
      dispatch(updateMassCreationTask(newTask));
    } else {
      setShouldCreateRelationsTask && setShouldCreateRelationsTask(!!newTask.taskType?.requiresPreparationTask);
      dispatch(updateTask({ id: taskCreationId, task: newTask }));
    }
  };

  useEffect(() => {
    if (!taskTypeDTOs) return;

    if (isSearching) return;

    let filtered = taskTypeDTOs.filter(
      (t) =>
        (assetTypeFilter.length === 0 || !!assetTypeFilter.find((f) => f === t.specificAssetType)) &&
        (taskCategoryFilter.length === 0 ||
          !!taskCategoryFilter.some((f) => !!t.taskTypeCategories?.find((c) => c === f))) &&
        (voltageLevelFilter.length === 0 ||
          (voltageLevelFilter.includes(Voltage.Low) && t.voltageLevelLow) ||
          (voltageLevelFilter.includes(Voltage.Medium) && t.voltageLevelMedium) ||
          (voltageLevelFilter.includes(Voltage.High) && t.voltageLevelHigh)) &&
        (emergencyErrorFilter.length === 0 ||
          !!emergencyErrorFilter.find((f) => f === t.emergencyOperationalFailure?.toString())) &&
        (!searchTerm || t.name?.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()))
    );

    let sortedList = filtered.sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));

    if (isMassCreation && Number(massCreationType) === MassCreationTypes.STRETCH)
      sortedList = sortedList.filter((taskType) => taskType.enableStretches);

    setFilteredWorkTypes(sortedList);

    const newTask = {
      assetTypeFilter,
      taskCategoryFilter: taskCategoryFilter,
      emergencyErrorFilter,
      voltageLevelFilter
    };

    if (isMassCreation) {
      dispatch(updateMassCreationTask(newTask));
    } else {
      dispatch(
        updateTask({
          id: taskCreationId,
          task: newTask
        })
      );
    }
  }, [
    assetTypeFilter,
    taskCategoryFilter,
    voltageLevelFilter,
    emergencyErrorFilter,
    taskTypeDTOs,
    dispatch,
    taskCreationId,
    searchTerm,
    isMassCreation,
    isSearching,
    massCreationType
  ]);

  const toggleShowFilters = () => {
    const newValue = !showFilters;
    setShowFilters(newValue);
    if (!newValue) {
      setAssetTypeFilter([]);
      setVoltageLevelFilter([]);
      setTaskCategoryFilter([]);
      setEmergencyErrorFilter([]);
    }

    if (isMassCreation) {
      dispatch(updateMassCreationTask({ showFilters: newValue }));
    } else {
      dispatch(updateTask({ id: taskCreationId, task: { showFilters: newValue } }));
    }
  };

  return (
    <Container>
      <ToolsSection noBorder>
        <SectionHeader>Filtrer arbejdstype</SectionHeader>
        <SearchSection>
          <TextField
            dataTestId="tasktype-search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            label="Søg på arbejdstype"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon size="18px" />
                </InputAdornment>
              )
            }}
            fullWidth
          />
          <Button fixedWidth="128px" variant="secondary" onClick={() => toggleShowFilters()} square>
            {showFilters ? 'Skjul ' : 'Vis '}filtre
          </Button>
        </SearchSection>
        {showFilters && (
          <FilterSection>
            <Select
              onChange={(e) => setAssetTypeFilter(Array.isArray(e.target.value) ? e.target.value : [])}
              fullWidth
              label="Komponent"
              value={assetTypeFilter}
              multiple
              compact
            >
              {specificAssetTypes &&
                specificAssetTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
            </Select>
            <Select
              onChange={(e) => setTaskCategoryFilter(Array.isArray(e.target.value) ? e.target.value : [])}
              fullWidth
              label="Kategori"
              value={taskCategoryFilter}
              multiple
              compact
            >
              {taskTypeCategories &&
                taskTypeCategories.map((category) => {
                  return category.taskTypeCategoryType === TaskTypeCategoryTypeEnum.WorkTask ? (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ) : (
                    <></>
                  );
                })}
            </Select>
            <Select
              onChange={(e) => setVoltageLevelFilter(Array.isArray(e.target.value) ? e.target.value : [])}
              fullWidth
              label="Spændingsniveau"
              value={voltageLevelFilter}
              multiple
              compact
            >
              {Object.keys(Voltage).map((k) => (
                <MenuItem key={k} value={k}>
                  {getEnumDisplayValue(k as Voltage)}
                </MenuItem>
              ))}
            </Select>
            <Select
              onChange={(e) => setEmergencyErrorFilter(Array.isArray(e.target.value) ? e.target.value : [])}
              value={emergencyErrorFilter}
              fullWidth
              label="Akut driftsfejl"
              multiple
              compact
            >
              <MenuItem key="Ja" value={'true'}>
                Ja
              </MenuItem>
              <MenuItem key="Nej" value={'false'}>
                Nej
              </MenuItem>
            </Select>
          </FilterSection>
        )}
      </ToolsSection>
      <Section>
        <SectionHeader>Vælg arbejdstype</SectionHeader>

        {isSearching && <CircularProgress />}
        {!isSearching && !!filteredWorkTypes.length && (
          <List data-testid={`work-type-list`}>
            {filteredWorkTypes.map((worktype, i) => (
              <ListItem
                key={i}
                onClick={() => handleTaskTypeSelected(worktype)}
                selected={task.taskTypeId === worktype.id}
                data-testid={`task-type-list-${i}`}
              >
                <LeftListItem>
                  <Typography fontWeight={task.taskTypeId === worktype.id ? 'bold' : 'regular'}>{worktype.name}</Typography>
                  {worktype.description && (
                    <ToolTip title={worktype.description}>
                      <div>
                        <StyledInfoIcon size="20px" />
                      </div>
                    </ToolTip>
                  )}
                </LeftListItem>
                {task.taskTypeId === worktype.id && <CheckmarkIcon size="20px" data-testid="checkmark-icon" />}
              </ListItem>
            ))}
          </List>
        )}
        {!isSearching && !filteredWorkTypes.length && 'Der var ingen arbejdstyper at finde'}
      </Section>
    </Container>
  );
};

const List = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing(2)};
  user-select: none;
`;

const ToolsSection = styled(Section)`
  padding-bottom: ${(props) => props.theme.spacing(3)};
`;

const ListItem = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 0 ${(props) => props.theme.spacing(4)};
  border: 1px solid ${({ theme }) => theme.palette.grey.black10};
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  max-width: 100%;

  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${theme.palette.grey.black10};
      border-color: ${theme.palette.grey.black10};
    `}
`;

const LeftListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const SearchSection = styled.div`
  display: flex;
  column-gap: ${(props) => props.theme.spacing(6)};
  align-items: center;
`;

const FilterSection = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing(4)} 0 ${(props) => props.theme.spacing(1)} 0;
  column-gap: ${(props) => props.theme.spacing(6)};
`;

export default TaskTypeStepDetails;
