import styled from 'styled-components';
import { AccessConditionsDTO, AssetType2 } from '../../../../../api/api';
import Button from '../../../../../components/button/Button';
import { Section, SectionHeader } from '../../../../../styling/FormStyling';
import TextRow from '../../components/TextRow';
import { WorkTaskDTOExtended } from '../../../../../models/TaskType';

interface Props {
  accessConditions?: AccessConditionsDTO;
  isAccessConditionsLoading: boolean;
  setShowAccessConditionsDialog: (value: boolean) => void;
  task?: WorkTaskDTOExtended;
}
export const AccessConditions = (props: Props) => {
  const { accessConditions, isAccessConditionsLoading, setShowAccessConditionsDialog, task } = props;
  return (
    <Section>
      <SectionHeader>Adgangsforhold</SectionHeader>
      <AccessConditionsSection>
        <TextRow label={'Nøgleboks'} value={accessConditions?.keyBoxInfo ?? ''} isLoading={isAccessConditionsLoading} />
        <TextRow label={'Adgang'} value={accessConditions?.accessInfo ?? ''} isLoading={isAccessConditionsLoading} />
        <TextRow
          label={'Teknikergodkendelse'}
          value={accessConditions?.technicianApproval ?? ''}
          isLoading={isAccessConditionsLoading}
        />
        <Button
          variant="secondary"
          onClick={() => setShowAccessConditionsDialog(true)}
          disabled={!task?.assetId || (task?.assetType !== AssetType2.Netstation && task.assetType !== AssetType2.CabelBox)}
        >
          Rediger
        </Button>
      </AccessConditionsSection>
    </Section>
  );
};

const AccessConditionsSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;
