import { useRef, useState } from 'react';
import styled from 'styled-components';
import { CheckmarkIcon } from '../../assets/icons/CheckmarkIcon';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';
import TextField from '../text-field/TextField';
import IconButton from '../icon-button/IconButton';

interface Props {
  createTitle: (title: string) => void;
  resetTitle: () => void;
  label: string;
}

const AddTitle = (props: Props) => {
  const { createTitle, resetTitle, label } = props;
  const [newTitleName, setNewTitleName] = useState('');
  const myRef = useRef<null | HTMLFormElement>(null);

  const createNewSection = (e: any) => {
    e.preventDefault();

    createTitle(newTitleName);
    resetNewSection();
  };

  const resetNewSection = () => {
    setNewTitleName('');
    resetTitle();
  };

  return (
    <Form ref={myRef} onSubmit={(e) => createNewSection(e)}>
      <StyledTextField
        label={label}
        required
        autoFocus
        value={newTitleName}
        onChange={(e) => setNewTitleName(e.target.value)}
      />
      <ButtonContainer>
        <StyledIconButton disabled={!newTitleName} onClick={(e) => createNewSection(e)}>
          <CheckmarkIcon size="20px" />
        </StyledIconButton>
        <StyledIconButton onClick={resetNewSection}>
          <DeleteIcon size="20px" />
        </StyledIconButton>
      </ButtonContainer>
    </Form>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  padding: 10px;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const StyledTextField = styled(TextField)`
  width: 50%;
`;
const StyledIconButton = styled(IconButton)`
  padding: 10px 10px;
`;

export default AddTitle;
