import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody
} from '@mui/material';
import styled from 'styled-components';

const OuterContainer = styled.div`
  border-radius: 16px;
  height: 100%;
`;

const InnerContainer = styled.div<{ maxHeight?: string }>`
  box-sizing: border-box;
  max-height: ${(props) => props.maxHeight};
  overflow-y: ${(props) => (props.maxHeight ? 'auto' : 'inherit')};
  border: 1px solid ${(props) => props.theme.palette.grey.black10};
  border-radius: 16px;
`;

interface Props {
  children?: React.ReactNode;
  maxHeight?: string;
}
export const TableContainer = (props: Props) => {
  const { children, maxHeight } = props;
  return (
    <OuterContainer>
      <InnerContainer maxHeight={maxHeight}>{children}</InnerContainer>
    </OuterContainer>
  );
};

export const Table = styled(MuiTable)``;

export const TableHead = styled((props) => <MuiTableHead {...props} />)`
  & .MuiTableCell-root {
    color: ${(props) => props.theme.palette.grey.black60};
    background: ${(props) => props.theme.palette.grey.black5};
    font-weight: 600;

    :first-child {
      border-radius: 16px 0 0 0;
    }
    :last-child {
      border-radius: 0 16px 0 0;
    }
  }
`;

export const TableRow = styled(MuiTableRow)``;

export const TableCell = styled(MuiTableCell)`
  && {
    padding: 16px 6px;

    :first-child {
      padding-left: 16px;
    }
    :last-child {
      padding-right: 16px;
    }
  }
`;

export const TableBody = styled(MuiTableBody)``;
