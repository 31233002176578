import styled, { css } from 'styled-components';
import Button from '../components/button/Button';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
`;

export const MenuTrigger = styled(Button)`
  margin-top: auto;
  & {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const ExpandedToolMenu = styled.div<{ pointerOnItems?: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.palette.grey.black90};
  width: max-content;
  padding: 18px 8px;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;

  > * {
    padding: 12px 16px;
    border-radius: 8px;
    ${({ pointerOnItems }) =>
      pointerOnItems &&
      css`
        :hover {
          background: #f2f4fe;
          cursor: pointer;
        }
      `}
  }

  position: absolute;
  bottom: 64px;
  left: 0;
  z-index: ${(props) => props.theme.zIndex.dialogTools};

  background-color: white;
  border-radius: 24px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.07);
  border: 1px solid #dbdbdb;
`;
