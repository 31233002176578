import WorkClient from '../api/workClient';
import { TechnicianStatusDTO } from '../api/api';

const getTechnicians = () => {
  return WorkClient.techniciansAll();
};

const updateStatus = (status: TechnicianStatusDTO) => {
  return WorkClient.technicians(status);
};

const TechnicianDutyService = {
  getTechnicians,
  updateStatus
};

export default TechnicianDutyService;
