import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const CloseIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg" data-testid="dialog-close-icon">
      <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
    </IconSvg>
  );
};
