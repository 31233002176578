import styled from 'styled-components';
import LinkTile from '../../components/link-tile/LinkTile';
import Typography from '../../components/typography/Typography';
import { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import NotificationService from '../../services/NotificationService';
import { log } from '../../utils/logging/log';
import { PortalLinkDto } from '../../api/api';
import LoadingOverlay from '../../components/loading-overlay/LoadingOverlay';

const PortalView = () => {
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState<PortalLinkDto[]>([]);

  useEffect(() => {
    setLoading(true);
    UserService.getAllPortalLinks()
      .then((response) => {
        setLinks(response.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)));
      })
      .catch((e) => {
        log(e);
        NotificationService.error('Kunne ikke hente links');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      {loading && <LoadingOverlay />}
      <Typography variant="h2">Portalen</Typography>
      <Typography variant="h5">
        Her er et udvalg af nyttige links til eksterne værktøjer, der ofte benyttes i forbindelse med arbejde i systemet
      </Typography>
      <LinkRow>
        {links.map((link) => (
          <LinkTile key={link.id} linkTo={link.link ?? ''} headline={link.linkText ?? ''} />
        ))}
      </LinkRow>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: 32px;
`;

const LinkRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  margin-top: 12px;
  flex-wrap: wrap;
  row-gap: 12px;
`;

export default PortalView;
