import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ContractorAgreementDTO2 } from '../../../../api/api';
import Table from '../../../../blocks/table/TableClient';
import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';
import Button from '../../../../components/button/Button';
import IconButton from '../../../../components/icon-button/IconButton';
import NotificationService from '../../../../services/NotificationService';
import WorkInstructionsService from '../../../../services/WorkInstructionsService';
import { log } from '../../../../utils/logging/log';
import useInstructionTable, { InstructionData } from './useWorkInstructionTable';
import WorkInstruction from './WorkInstruction';
import { ChevronLeft } from '../../../../assets/icons/ChevronLeft';
interface Props extends TabProps {
  taskId: string;
  onPDFCreatedCallback: () => void;
}

export type PageType = 'table' | 'edit' | 'sign';

const WorkInstructionsStep = (props: Props) => {
  const { taskId, onPDFCreatedCallback } = props;

  const [instructions, setInstructions] = useState<ContractorAgreementDTO2[]>([]);
  const [selectedWorkTaskInstruction, setSelectedWorkTaskInstruction] = useState<ContractorAgreementDTO2>();
  const [loading, setLoading] = useState(false);
  const [creatingDefaultInstruction, setCreatingDefaultInstruction] = useState(false);
  const [page, setPage] = useState<PageType>('table');

  const fetchInstructions = useCallback(() => {
    setLoading(true);
    WorkInstructionsService.getWorkInstructions(parseInt(taskId))
      .then((res) => setInstructions(res))
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke hente arbejdsinstruktioner');
      })
      .finally(() => setLoading(false));
  }, [taskId]);

  const handleDoubleClick = useCallback(
    (rowData: InstructionData) => {
      if (!rowData.id) return;

      setSelectedWorkTaskInstruction(instructions.find((i) => i.id === rowData.id));
      setPage('edit');
    },
    [instructions]
  );

  const handleDeleteWorkInstruction = useCallback(() => {
    fetchInstructions();
  }, [fetchInstructions]);

  useEffect(() => {
    fetchInstructions();
  }, [fetchInstructions]);

  const { tableInstance } = useInstructionTable(instructions, handleDeleteWorkInstruction);

  const handleReturn = useCallback(() => {
    setPage('table');
    fetchInstructions();
  }, [fetchInstructions]);

  const handleCreateInstruction = useCallback(() => {
    setCreatingDefaultInstruction(true);
    WorkInstructionsService.createWorkInstruction(parseInt(taskId))
      .then((res) => {
        setSelectedWorkTaskInstruction(res);
        setPage('edit');
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke oprette en arbejdsinstruktion');
      })
      .finally(() => setCreatingDefaultInstruction(false));
  }, [taskId]);

  if (page === 'table') {
    return (
      <Container>
        <StyledHeader>
          <h3>Arbejdsinstruktioner</h3>
          <Button onClick={handleCreateInstruction} isLoading={creatingDefaultInstruction}>
            Opret instruks
          </Button>
        </StyledHeader>
        <Table
          loading={loading}
          tableInstance={tableInstance}
          noPadding
          alwaysShowSort
          onDoubleClickRow={handleDoubleClick}
        />
      </Container>
    );
  }

  if (selectedWorkTaskInstruction) {
    return (
      <Container>
        {page === 'edit' && (
          <HeaderContainer>
            <IconButton onClick={handleReturn} padding={'8px'}>
              <ChevronLeft size="18px" />
            </IconButton>
            <h3 style={{ display: 'inline' }}>Arbejdsinstruktion</h3>
          </HeaderContainer>
        )}
        <WorkInstruction
          taskId={taskId}
          instruction={selectedWorkTaskInstruction}
          onPDFCreatedCallback={onPDFCreatedCallback}
          page={page}
          setPage={(page: PageType) => setPage(page)}
          addNewInstruction={(newInstruction) =>
            setInstructions((prevInstructions) => [...prevInstructions, newInstruction])
          }
        />
      </Container>
    );
  }

  return <></>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 18px;
  margin-bottom: 24px;
  padding: 32px 0px;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.black10};
`;

export default WorkInstructionsStep;
