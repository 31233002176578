import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { WhiteAreaHorizontalPadding, WhiteAreaTopPadding } from '../../styling/StylingConstants';
import Typography from '../typography/Typography';

export interface HorizontalTabProps {
  tabKey: string;
  tabDisplayName?: string;
  tabIcon?: React.ReactNode;
}

interface Props {
  children?: React.ReactNode;
  defaultActiveTabKey: string;
  minWidth?: string;
  height?: string;
}

const TabsHorizontal = (props: Props) => {
  const { children, defaultActiveTabKey, minWidth, height } = props;
  const arrayChildren = React.Children.toArray(children);

  const [activeTabKey, setActiveTabKey] = useState(defaultActiveTabKey);

  const isActiveChild = (child: React.ReactChild | React.ReactFragment | React.ReactPortal): boolean => {
    return (child as any).props?.tabKey === activeTabKey;
  };

  useEffect(() => {
    setActiveTabKey(defaultActiveTabKey);
  }, [defaultActiveTabKey]);

  const renderMenuTabs = () => {
    return arrayChildren.map((child, i) => {
      const displayName = (child as any).props?.tabDisplayName ?? 'Item ' + i;
      const key = (child as any).props?.tabKey;
      const tabIcon = (child as any).props?.tabIcon;

      return (
        <Tab
          title={displayName}
          active={isActiveChild(child)}
          onClick={() => key !== activeTabKey && setActiveTabKey(key)}
          key={`horizontal-tab-${i}`}
        >
          <TabContent>
            {tabIcon}
            <Typography variant="h6" fontWeight="bold">
              {displayName}
            </Typography>
          </TabContent>
        </Tab>
      );
    });
  };

  const renderContent = () => {
    const child = arrayChildren.find((child) => isActiveChild(child));

    if (child) {
      return <>{child}</>;
    }
  };

  return (
    <Root minWidth={minWidth} height={height}>
      <Tabs>{renderMenuTabs()}</Tabs>

      <Container>{renderContent()}</Container>
    </Root>
  );
};

const TabsHeight = '40px';

const Root = styled.div<{ minWidth?: string; height?: string }>`
  height: ${(props) => props.height ?? '100%'};
  min-width: ${(props) => props.minWidth};
`;

const Container = styled.div`
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  height: calc(100% - ${TabsHeight});
  margin-top: -1px;
  padding-top: 1px;
  padding: ${WhiteAreaTopPadding}px ${WhiteAreaHorizontalPadding}px 0px;

  border-radius: 0 12px 12px 12px;
  background-color: #ffffff;
`;

const Tabs = styled.div`
  display: flex;
  height: ${TabsHeight};
`;

const Tab = styled.div<{ active?: boolean; disabled?: boolean }>`
  position: relative;
  overflow: hidden;
  padding: 10px 42px 8px 24px;
  min-width: ${(props) => props.active && 'fit-content'};

  border-radius: 16px 8px 0 0;
  color: ${(props) => (props.active ? '#000000' : props.theme.palette.grey.black60)};
  cursor: pointer;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${(props) => (props.active ? '#ffffff' : props.theme.palette.grey.black5)};
    transform: skewX(30deg);
    z-index: ${(props) => props.theme.zIndex.negative};
    transform-origin: 100% 100%;
    border-radius: 16px 8px 0 0;
  }

  :first-child {
    padding-left: 32px;
  }

  :last-child {
    margin-right: 12px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${(props) => props.theme.palette.grey.black40};
      cursor: not-allowed;
      font-weight: 400;
    `}
`;

const TabContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 20px;

  & svg {
    min-width: 16px;
  }
`;

export default TabsHorizontal;
