import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { StockDTO } from '../../../../api/api';
import Table from '../../../../blocks/table/TableClient';
import { HorizontalTabProps } from '../../../../components/tabs-horizontal/TabsHorizontal';
import Typography from '../../../../components/typography/Typography';
import GoodsService from '../../../../services/GoodsService';
import NotificationService from '../../../../services/NotificationService';
import { selectUserProfile } from '../../../../stateManagement/reducers/userProfileReducer';
import { log } from '../../../../utils/logging/log';
import { useStockTable } from './useStockTable';
import TableMenuBar from '../../../../blocks/table/table-menu-bar/TableMenuBar';
import { SendIcon } from '../../../../assets/icons/SendIcon';
import { MenuTrigger } from '../../../../styling/ListToolsStyling';
import { setSelectedGoods } from '../../../../stateManagement/reducers/returnGoodsReducer';

interface Props extends HorizontalTabProps {
  setShowReturnGoods: (show: boolean) => void;
}

const CarStorageTab = (props: Props) => {
  const { setShowReturnGoods } = props;
  const vehicle = useSelector(selectUserProfile).userProfile.vehicle;
  const [stock, setStock] = useState<(StockDTO & { selected?: boolean })[]>([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (vehicle?.legalEntityId && vehicle.vehicleId) {
      setLoading(true);
      GoodsService.getItemsOnHand3(vehicle?.legalEntityId, vehicle?.vehicleId, vehicle?.defaultIssueLocationId)
        .then((res) => {
          setStock(res);
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Bilens lager kunne ikke hentes');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [vehicle?.legalEntityId, vehicle?.vehicleId, vehicle?.defaultIssueLocationId]);

  const selectedRowIds = useMemo(() => {
    return stock.filter((obj) => obj.selected).map((obj) => obj.product?.itemId) as string[];
  }, [stock]);

  useEffect(() => {
    const result = stock.filter((stock) => selectedRowIds.includes(stock.product?.itemId ?? ''));
    dispatch(setSelectedGoods(result));
  }, [dispatch, selectedRowIds, stock]);

  const selectItem = (id: string, value: boolean) => {
    const updatedStock = stock.map((s) => {
      if (s.product?.itemId === id) {
        return {
          ...s,
          selected: value
        };
      } else {
        return s;
      }
    });
    setStock(updatedStock);
  };

  const { tableInstance } = useStockTable(stock, 'På bilens lager', selectItem);

  const handleOpenMoveGoods = () => {
    setShowReturnGoods(true);
  };

  const toggleAllRows = (value: boolean) => {
    setStock((prevStock) =>
      prevStock.map((stock) => {
        return {
          ...stock,
          selected: value
        };
      })
    );
  };

  if (!(vehicle?.legalEntityId && vehicle.vehicleId)) {
    return (
      <Container>
        <Typography variant="h4">Du er ikke koblet til en bils lager</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Table loading={loading} tableInstance={tableInstance} alwaysShowSort noPadding showPagination></Table>
      {selectedRowIds.length > 0 && (
        <TableMenuBar
          selectedItems={selectedRowIds.map((x) => ({ id: x }))}
          rowNameSingle="vare"
          rowNameMultiple="varer"
          onClose={() => toggleAllRows(false)}
        >
          <MenuTrigger onClick={handleOpenMoveGoods} variant="tertiary" square>
            <SendIcon size="20px" />
            Returner varer
          </MenuTrigger>
        </TableMenuBar>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  .table-footer {
    justify-content: end;
  }
`;

export default CarStorageTab;
