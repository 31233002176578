import { Badge } from '@mui/material';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { ShopIcon } from '../../../assets/icons/ShopIcon';

interface Props {
  show: boolean;
  count?: number;
}

const SelectedGoodsBadge = (props: Props) => {
  const { show, count } = props;

  const nodeRef = useRef(null);

  return (
    <Root>
      <CSSTransition nodeRef={nodeRef} in={show} timeout={250} classNames={'fade'}>
        <Container ref={nodeRef}>
          <Badge overlap="circular" badgeContent={count} color="error">
            <ShopIcon size="24px" padding="10px" />
          </Badge>
        </Container>
      </CSSTransition>
    </Root>
  );
};

const Root = styled.div`
  .fade-enter {
    opacity: 1;
    transition: opacity 250ms;
  }
  .fade-enter-active {
    opacity: 0;
    transition: opacity 250ms;
  }
  .fade-enter-done {
    opacity: 1;
    transition: opacity 250ms;
  }
  .fade-exit {
    opacity: 1;
    transition: opacity 250ms;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 250ms;
  }
  .fade-exit-done {
    opacity: 1;
    transition: opacity 250ms;
  }
`;

const Container = styled.div`
  margin-right: 6px;
`;

export default SelectedGoodsBadge;
