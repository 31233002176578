import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BoxIcon } from '../../assets/icons/BoxIcon';
import { ShopIcon } from '../../assets/icons/ShopIcon';
import { VehicleIcon } from '../../assets/icons/VehicleIcon';
import Button from '../../components/button/Button';
import TabsHorizontal from '../../components/tabs-horizontal/TabsHorizontal';
import Typography from '../../components/typography/Typography';
import EmergencyStorageTab from './goods-view-tabs/emergency-storage-tab/EmergencyStorageTab';
import CarStorageTab from './goods-view-tabs/car-storage-tab/CarStorageTab';
import OrdersTab from './goods-view-tabs/orders-tab/OrdersTab';
import OrderGoodsView from './order-goods-dialog/OrderGoodsView';
import { GreyAreaHorizontalPadding, GreyAreaTopPadding } from '../../styling/StylingConstants';
import { useNavigate } from '../../hooks/useNavigate';
import CountGoodsView from './count-goods-dialog/CountGoodsView';
import { isDevice } from '../../utils/device-handling/deviceDetectionUtils';
import {
  MOVE_GOODS_ROUTE,
  ORDER_GOODS_ROUTE,
  COUNT_GOODS_ROUTE,
  RETURN_GOODS_ROUTE,
  RECEIPT_ROUTE
} from '../../utils/constants';
import MoveGoodsView from './move-goods-dialog/MoveGoodsView';
import ReturnGoodsTab from './goods-view-tabs/return-goods-tab/ReturnGoodsTab';
import ReturnGoodsView from './return-goods-dialog/ReturnGoodsView';
import ReceiptView from './goods-view-tabs/return-goods-tab/ReceiptView';
import useQuery from '../../hooks/useQuery';
import { QueryParams } from '../../models/QueryParams';
import CriticalOrderPopover from '../../blocks/critical-order-popover/CriticalOrderPopOver';
import { UpdateIcon } from '../../assets/icons/UpdateIcon';

const GoodsView = () => {
  const [visibleModal, setVisibleModal] = useState<'count' | 'move' | 'order' | 'return' | 'receipt' | 'none'>('none');
  const navigate = useNavigate();
  const fullscreen = isDevice();
  const [activeTab, setActiveTab] = useState('01');

  const { id } = useQuery<QueryParams>();

  useEffect(() => {
    id && setActiveTab(id);
  }, [id]);

  const handleOpenOrderGoods = useCallback(() => {
    if (fullscreen) {
      navigate('/' + ORDER_GOODS_ROUTE);
    } else {
      setVisibleModal('order');
    }
  }, [fullscreen, navigate]);

  const handleOpenOrderCount = useCallback(() => {
    if (fullscreen) {
      navigate('/' + COUNT_GOODS_ROUTE);
    } else {
      setVisibleModal('count');
    }
  }, [fullscreen, navigate]);

  const handleOpenMoveGoods = useCallback(() => {
    if (fullscreen) {
      navigate('/' + MOVE_GOODS_ROUTE);
    } else {
      setVisibleModal('move');
    }
  }, [fullscreen, navigate]);

  const handleOpenReturnGoods = useCallback(() => {
    if (fullscreen) {
      navigate('/' + RETURN_GOODS_ROUTE);
    } else {
      setVisibleModal('return');
    }
  }, [fullscreen, navigate]);

  const handleOpenReceipt = useCallback(() => {
    if (fullscreen) {
      navigate('/' + RECEIPT_ROUTE);
    } else {
      setVisibleModal('receipt');
    }
  }, [fullscreen, navigate]);

  const closeReceiptHandler = () => {
    setVisibleModal('none');
    setActiveTab('03');
  };

  return (
    <FullscreenContainer>
      <Header>
        <Typography variant="h2">Varer</Typography>
        <ButtonContainer>
          <CriticalOrderPopover
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          />
          {window._env_.COUNT_GOODS_DISABLED === 'false' && (
            <Button onClick={handleOpenOrderCount} variant="secondary">
              <ShopIcon size="16px" />
              Lav optælling
            </Button>
          )}
          <Button onClick={handleOpenMoveGoods} variant="secondary">
            <ShopIcon size="16px" />
            Flyt varer
          </Button>
          <Button onClick={handleOpenOrderGoods}>
            <ShopIcon size="16px" />
            Bestil varer
          </Button>
        </ButtonContainer>
      </Header>
      <TabsHorizontal defaultActiveTabKey={activeTab} height="calc(100% - 72px)">
        <OrdersTab tabKey="01" tabDisplayName="Bestillinger" tabIcon={<BoxIcon size="18px" />} />
        <CarStorageTab
          setShowReturnGoods={handleOpenReturnGoods}
          tabKey="02"
          tabDisplayName="Bilens lager"
          tabIcon={<VehicleIcon size="18px" />}
        />
        <ReturnGoodsTab
          handleOpenReceipt={handleOpenReceipt}
          tabKey="03"
          tabDisplayName="Returvarer"
          tabIcon={<UpdateIcon size="18px" />}
        />
        {/*
                <OrderHistoryTab tabKey="04" tabDisplayName="Ordre historik" tabIcon={<HistoryIcon size="16px" />}/>
                <RequisitionsTab tabKey="05" tabDisplayName="Rekvisitioner" tabIcon={<HistoryIcon size="16px" />}/> */}
        <EmergencyStorageTab tabKey="06" tabDisplayName="Vareopslag" tabIcon={<BoxIcon size="18px" />} />
      </TabsHorizontal>

      {visibleModal === 'count' && <CountGoodsView onClose={() => setVisibleModal('none')} />}
      {visibleModal === 'move' && <MoveGoodsView onClose={() => setVisibleModal('none')} />}
      {visibleModal === 'order' && <OrderGoodsView onClose={() => setVisibleModal('none')} />}
      {visibleModal === 'return' && (
        <ReturnGoodsView showReceipt={handleOpenReceipt} onClose={() => setVisibleModal('none')} />
      )}
      {visibleModal === 'receipt' && <ReceiptView onClose={closeReceiptHandler} />}
    </FullscreenContainer>
  );
};

const FullscreenContainer = styled.div`
  padding: ${GreyAreaTopPadding}px ${GreyAreaHorizontalPadding}px;
  height: 100%;
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
`;

export default GoodsView;
