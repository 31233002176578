import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import useTableInstance from '../../../hooks/useTableInstance';
import KebabMenu from '../../../components/kebab-menu/KebabMenu';
import { MenuItem } from '../../../components/select/Select';
import { DepartmentDTO } from '../../../api/api';
import { formatTimeAndDateString } from '../../../utils/dateHandling';

const useDepartmentsList = (departments: DepartmentDTO[], editDepartment: (editingDepartment: DepartmentDTO) => void) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Navn',
        accessor: 'name',
        minWidth: 90,
        disableFilters: true
      },
      {
        Header: 'Sidst opdateret',
        accessor: 'updated',
        minWidth: 100,
        disableFilters: true,
        Cell: (cellProps: CellProps<DepartmentDTO>) => {
          const { cell } = cellProps;
          return formatTimeAndDateString(cell.row.original.updated ?? '');
        }
      },
      {
        Header: '',
        accessor: 'button',
        minWidth: 90,
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<DepartmentDTO>) => {
          const { cell } = cellProps;
          const department = cell.row.original;
          return (
            <KebabMenu>
              <MenuItem onClick={() => editDepartment(department)}>Rediger</MenuItem>
            </KebabMenu>
          );
        }
      }
    ] as Column<DepartmentDTO>[];
  }, [departments]);

  const tableInstance = useTableInstance<any>(departments, columns);

  return { tableInstance };
};

export default useDepartmentsList;
