import styled from 'styled-components';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import Typography from '../typography/Typography';

export interface RadioButtonOptions {
  label: string;
  subtextElement?: JSX.Element;
  icon?: React.ReactNode;
  value: any;
}

interface Props {
  options: RadioButtonOptions[];
  label?: string;
  value: any;
  handleValueChange: (value: any) => void;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  row?: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  showDivider?: boolean;
}

const RadioButtons = (props: Props) => {
  const {
    options,
    label,
    value,
    handleValueChange,
    required,
    error,
    disabled,
    row = false,
    labelPlacement,
    showDivider: showRowDivider = false
  } = props;

  const renderFormLabel = (option: RadioButtonOptions) => {
    if (labelPlacement === 'end') return <>{option.label}</>;

    return (
      <LabelWrapper tall={option.icon !== undefined}>
        {option.icon}
        <LabelText>
          <Typography fontWeight="bold" variant="h5">
            {option.label}
          </Typography>
          {option.subtextElement}
        </LabelText>
      </LabelWrapper>
    );
  };

  return (
    <Control error={error}>
      {label && (
        <FormLabel>
          <Typography variant="h5" fontWeight="bold">{`${label}${required ? '*' : ''}`}</Typography>
        </FormLabel>
      )}
      <RadioGroup row={row} value={value}>
        {options.map((o, i) => (
          <StyledFormControlLabel
            control={<StyledRadio />}
            onChange={(e: any) => handleValueChange(e.target.value)}
            value={o.value}
            label={renderFormLabel(o)}
            key={i}
            disabled={disabled}
            labelPlacement={labelPlacement}
            row={row}
            showDivider={showRowDivider}
            isLastElement={i === options.length - 1}
            data-testid={`radio-button-value-${i}`}
          />
        ))}
      </RadioGroup>
    </Control>
  );
};

const StyledRadio = styled(Radio)`
  &.MuiRadio-colorSecondary.Mui-checked {
    color: ${(props) => props.theme.palette.grey.black90};
  }
`;

const Control = styled(FormControl)<{ error?: boolean }>`
  & label {
    text-align: left;
    color: ${(props) => (props.error ? 'red' : props.theme.palette.grey.black90)} !important;
    padding-right: 25px;
  }
  & label:focused {
    color: ${(props) => props.theme.palette.grey.black90} !important;
  }
  & .MuiIconButton-colorSecondary:hover {
    background-color: white;
  }
  & .MuiRadio-colorSecondary.Mui-checked:hover {
    background-color: white;
  }
`;

const StyledFormControlLabel = styled(({ isLastElement, row, showDivider, ...props }) => <FormControlLabel {...props} />)<{
  row?: boolean;
  showDivider?: boolean;
  isLastElement?: boolean;
}>`
  justify-content: ${(props) => (props.labelPlacement === 'start' ? 'space-between' : '')};
  border-right: ${(props) => (!props.isLastElement && props.row && props.showDivider ? '1px solid rgb(219, 219, 219)' : '')};
  border-bottom: ${(props) =>
    !props.isLastElement && !props.row && props.showDivider ? '1px solid rgb(219, 219, 219)' : ''};
`;

const LabelWrapper = styled.div<{ tall?: boolean }>`
  padding: 12px;
  display: flex;
  width: 300px;
  min-height: ${(props) => (props.tall ? '70px' : '0px')};
`;

const LabelText = styled.div`
  margin-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default RadioButtons;
