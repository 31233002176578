import { forwardRef, useEffect, useRef } from 'react';
import { CellProps, Column, HeaderProps, Row } from 'react-table';
import CheckboxInput from '../../components/checkbox/Checkbox';
import { DepartmentDTO } from '../../api/api';

export interface TableToolsProps<T extends { id: string }> {
  selectedRows: T[];
  fetchTasksCallback: () => void;
  setIsLoading: (isLoading: boolean) => void;
}

export interface TableTab {
  id: number;
  header: string;
  filter: (department: DepartmentDTO) => void;
  disabledColumns?: string[];
}

export enum ColumnAccessors {
  Id = 'id',
  TaskType = 'taskType',
  Location = 'location',
  AssetId = 'assetId',
  AssetType = 'assetType',
  NotesForTechnician = 'notesForTechnician',
  NotesForPlanning = 'notesForPlanning',
  EarliestStartDate = 'earliestStartDate',
  Deadline = 'deadline',
  Status = 'status',
  Department = 'department',
  ProjectNumber = 'projectNumber',
  CreatedBy = 'createdBy',
  CreationDate = 'creationDate',
  CompletedAt = 'completedAt',
  PlannedDuration = 'plannedDuration',
  AssignedTo = 'assignedTo',
  CaseWorker = 'caseWorker',
  PlannedArrival = 'plannedArrival'
}

export enum CaseWorkerCustomSearch {
  HasValue = 'Tildelt',
  NoValue = 'Ikke tildelt'
}

export interface TaskData {
  checkbox?: string;
  id: string;
  taskType: string;
  location: string;
  assetId: string;
  assetType: string;
  status: string;
  department: string;
  notesForPlanning: string;
  notesForTechnician: string;
  earliestStartDate: string;
  deadline: string;
  projectNumber: string;
  createdBy: string;
  creationDate: string;
  completedAt: string;
  plannedDuration: string;
  assignedTo: string;
  caseWorker: string;
  hasParent: boolean;
  appointment?: string;
  plannedArrival: string;
}

export const defaultColumn = {
  minWidth: 125,
  width: 175
};

export const caseWorkerSearchFn = (rows: Row<any>[], id: any, filterValue: string = '') => {
  if (!filterValue) return rows;
  if (filterValue === CaseWorkerCustomSearch.HasValue) {
    return rows.filter((row) => row.values[id] !== '-');
  }
  if (filterValue === CaseWorkerCustomSearch.NoValue) {
    return rows.filter((row) => row.values[id] === '-');
  }
  return rows.filter((row) => row.values[id].toLowerCase().includes(filterValue?.toLowerCase()));
};

export const multiSelectFilterFn = (rows: Row<TaskData>[], id: any, filterValues: string[]) => {
  if (!filterValues.length) return rows;
  return rows.filter((row) => filterValues.includes(row.values[id]));
};

export const CheckboxColumn = <T extends object>(
  width?: number,
  minWidth?: number,
  maxWidth?: number,
  disabled?: boolean
) => {
  const Checkbox = forwardRef(({ indeterminate, ...rest }: { indeterminate?: boolean }, ref) => {
    const defaultRef = useRef();
    const resolvedRef: any = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <CheckboxInput disabled={disabled} ref={resolvedRef} {...rest} />
      </>
    );
  });

  return {
    Header: (header: HeaderProps<T>) => {
      const { getToggleAllRowsSelectedProps } = header;
      return <Checkbox {...getToggleAllRowsSelectedProps()} />;
    },
    Cell: (cellProps: CellProps<T>) => {
      const { row, toggleRowSelected, setRowState, state, flatRows } = cellProps;

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const prevSelectedRow = state.rowState?.prevSelectedRow;

        if (event.target.checked) {
          setRowState(['prevSelectedRow'], row.id);
          toggleRowSelected(row.id, true);
        } else {
          setRowState(['prevSelectedRow'], undefined);
          toggleRowSelected(row.id, false);
        }
        if ((event.nativeEvent as PointerEvent)?.shiftKey && prevSelectedRow) {
          const currentIndex = flatRows.findIndex((element) => element.index === parseInt(row.id));
          const previousIndex = flatRows.findIndex((element) => element.index === parseInt(prevSelectedRow.toString()));

          if (previousIndex < currentIndex) {
            for (let i = previousIndex + 1; i < currentIndex; i++) {
              toggleRowSelected(flatRows[i].id, true);
            }
          } else {
            for (let i = currentIndex; i < previousIndex; i++) {
              toggleRowSelected(flatRows[i].id, true);
            }
          }
        }
      };
      return <CheckboxInput disabled={disabled} checked={state.selectedRowIds[row.id] ?? false} onChange={handleChange} />;
    },
    accessor: 'checkbox',
    width: width ?? 60,
    minWidth: minWidth ?? 60,
    maxWidth: maxWidth ?? 60,
    disableFilters: true,
    disableSortBy: true
  } as Column<T>;
};
