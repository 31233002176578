import Popover, { PopoverOrigin } from '@mui/material/Popover';
import { useState } from 'react';
import styled from 'styled-components';

interface Props {
  buttonElement: JSX.Element;
  popoverElement: JSX.Element;
  activeByClick?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  disablePopover?: boolean;
}

export default function BasicPopover(props: Props) {
  const {
    buttonElement,
    popoverElement,
    activeByClick = false,
    anchorOrigin = {
      vertical: 'top',
      horizontal: 'center'
    },
    transformOrigin = {
      vertical: 'bottom',
      horizontal: 'center'
    },
    disablePopover = false
  } = props;

  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);

  const handleSetActive = (anchor: Element) => {
    if (anchorEl === undefined) {
      setAnchorEl(anchor);
    } else {
      setAnchorEl(undefined);
    }
  };

  return (
    <div>
      {activeByClick ? (
        disablePopover ? (
          <div onClick={(event) => handleSetActive(event.currentTarget)}>{buttonElement}</div>
        ) : (
          <Clickable onClick={(event) => handleSetActive(event.currentTarget)}>{buttonElement}</Clickable>
        )
      ) : (
        <div onMouseEnter={(event) => setAnchorEl(event.currentTarget)} onMouseLeave={() => setAnchorEl(undefined)}>
          {buttonElement}
        </div>
      )}
      {!disablePopover && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: activeByClick ? 'auto' : 'none'
          }}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(undefined)}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          {popoverElement}
        </Popover>
      )}
    </div>
  );
}

const Clickable = styled.div`
  cursor: pointer;
`;
