import { TaskCreationTask } from '../../../models/TaskCreationTask';
import { MassCreationTask } from '../../../models/MassCreationTask';
import { TabProps } from '../../../blocks/tabs-vertical/TabsVertical';
import FileUpload from '../../../components/file-upload/FileUpload';
import styled from 'styled-components';

import { Container, Section, SectionHeader } from '../../../styling/FormStyling';

import AssetDataSection from './sections/asset-data/AssetDataSection';
import DetailsSection from './sections/details/DetailsSection';
import NotesSection from './sections/notes/NotesSection';
import CustomerAppointmentSection from './sections/customer-appointment/CustomerAppointmentSection';
import TaskTypeTemplateComponent from '../../../blocks/tasktype-template-component/TaskTypeTemplateComponent';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateMassCreationTask } from '../../../stateManagement/reducers/massCreationReducer';
import { updateTask } from '../../../stateManagement/reducers/taskCreationReducer';
import { extractAttributeValuesFromComponents } from '../../../utils/componentHandling';
import StretchesSection, { StretchContainer } from './sections/stretches/StretchesSection';
import { StretchDTO } from '../../../api/api';
import Checkbox from '../../../components/checkbox/Checkbox';
import useUserAccess from '../../../hooks/useUserAccess';
import Typography from '../../../components/typography/Typography';
import { allowedTestingEnviroments } from '../../../services/TestingService';
export interface CreationStatus {
  show: boolean;
  text: string;
}

interface Props extends TabProps {
  task: TaskCreationTask | MassCreationTask;
  onFileError?: (errors: Map<number, string>) => void;
  handleFileChanges?: (files: File[]) => void;
  files?: File[];
  isMassCreation?: boolean;
  handleGoToAsset?: () => void;
  simulatePlanning?: boolean;
  setSimulatePlanning?: (newState: boolean) => void;
}

const DetailsStep = (props: Props) => {
  const {
    task,
    onFileError,
    handleFileChanges,
    files,
    isMassCreation,
    handleGoToAsset,
    simulatePlanning,
    setSimulatePlanning
  } = props;
  const { technicianUserGroupAllowed } = useUserAccess();

  const dispatch = useDispatch();
  const handleComponentChange = useCallback(
    (componentId: number, fieldId: number, value: any) => {
      if (!task.components) return;

      const newComponents = [...task.components];
      const compIndex = newComponents.findIndex((c) => c.id === componentId);
      const comp = newComponents[compIndex];
      let attributeIndex = comp?.attributes?.findIndex((a) => a.attributeId === fieldId);
      if (comp?.attributes && attributeIndex != null && comp?.attributes[attributeIndex]) {
        let attribute = comp?.attributes[attributeIndex] ?? undefined;
        let newAttributes = [...comp.attributes];
        newAttributes[attributeIndex] = { ...attribute, value: value };

        newComponents[compIndex] = { ...newComponents[compIndex], attributes: newAttributes };
        if (isMassCreation) {
          dispatch(
            updateMassCreationTask({
              components: newComponents,
              componentValues: extractAttributeValuesFromComponents(newComponents)
            })
          );
        } else {
          dispatch(updateTask({ id: (task as TaskCreationTask).taskCreationId ?? '', task: { components: newComponents } }));
        }
      }
    },
    [dispatch, isMassCreation, task]
  );

  const onStretchChangeHandler = (stretches: StretchDTO[]) => {
    dispatch(
      updateTask({
        id: (task as TaskCreationTask).taskCreationId ?? '',
        task: { stretches }
      })
    );
  };

  return (
    <Container>
      <Section noBorder>
        <SectionHeader>Detaljer</SectionHeader>
        <DetailsSection task={task} isMassCreation={isMassCreation} />
      </Section>
      {!isMassCreation && (
        <Section>
          <SectionHeader>Er der ønske om at få lavet en tidsaftale?</SectionHeader>
          <CustomerAppointmentSection task={task} />
        </Section>
      )}
      {!isMassCreation && (
        <Section>
          <SectionHeader>Vedhæft filer</SectionHeader>
          <FileUpload
            files={files ?? []}
            onError={onFileError}
            onFileSelection={handleFileChanges}
            onRemoveSelectedFile={handleFileChanges}
          />
        </Section>
      )}
      <Section>
        <SectionHeader>Bemærkninger til planlægning</SectionHeader>
        <NotesSection task={task} isMassCreation={isMassCreation} />
      </Section>
      {!isMassCreation && (
        <Section>
          <SectionHeader>Asset data</SectionHeader>
          <AssetDataSection task={task} />
        </Section>
      )}
      {task.components &&
        task.components.map((c) => (
          <TaskTypeTemplateComponent
            component={c}
            onBlur={handleComponentChange}
            mode={'create'}
            showError={task.dynamicFieldsErrors}
            key={c.id}
          />
        ))}

      {task?.taskType?.enableStretches && !isMassCreation && (
        <Section>
          <SectionHeader>Strækninger</SectionHeader>
          <StretchContainer>
            <StretchesSection
              onStretchChange={(value) => onStretchChangeHandler(value)}
              value={(task as TaskCreationTask).stretches}
              mode={'create'}
              isEditable={true}
              readonlyMode={false}
              handleGoToAsset={handleGoToAsset}
            />
          </StretchContainer>
        </Section>
      )}

      {allowedTestingEnviroments && technicianUserGroupAllowed() && setSimulatePlanning && (
        <Section>
          <ObsBox>
            <SectionHeader>Test værktøj</SectionHeader>
            <Checkbox
              label="Simulér planlægning på dig selv"
              checked={simulatePlanning}
              onChange={() => setSimulatePlanning(!simulatePlanning)}
              data-testid="schdule-on-self"
            />
            <Typography variant="p" fontWeight="bold">
              Dette værktøj fungerer kun i udviklings- og testmiljøet.
            </Typography>
            <Typography variant="p">
              Det vil ikke blive tilgængeligt i produktionsmiljøet. Har du problemer med opgaver der er planlagt ved hjælp af
              dette værktøj, så skal fejlen reproduceres efter planlægning igennem FLS, før den indrapporteres.
            </Typography>
          </ObsBox>
        </Section>
      )}
    </Container>
  );
};

export default DetailsStep;

const ObsBox = styled.div`
  margin: ${(props) => props.theme.spacing(5)};
  padding: 0 ${(props) => props.theme.spacing(5)} ${(props) => props.theme.spacing(5)};
  background-color: ${(props) => props.theme.palette.background.secondary};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.grey.black20};
`;
