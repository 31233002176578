import { CircularProgress as MuiCircularProgress } from '@mui/material';
import styled from 'styled-components';

interface Props {
  color?: string;
  width?: string;
  margin?: string;
  position?: string;
  top?: string;
  size?: string;
}

const CircularProgress = (props: Props) => {
  return (
    <StyledContainer {...props}>
      <MuiCircularProgress size={props.size} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div<Props>`
  display: flex;
  justify-content: center;
  margin: ${(props) => props.margin ?? '2rem auto'};
  width: ${(props) => props.width ?? '100%'};
  position: ${(props) => props.position ?? ''};
  top: ${(props) => props.top ?? ''};
  left: 0;
  right: 0;

  & .MuiCircularProgress-root {
    color: ${(props) => props.color ?? props.theme.palette.functions.action.primary};
  }
`;

export default CircularProgress;
