import { memo, useRef } from 'react';
import styled, { css } from 'styled-components';
import useIsTruncated from '../../../hooks/useIsTruncated';

import { TableData as StyledTableData } from './Cell';

interface Props {
  getCellProps: any;
  render: any;
  value: any;
}

const TruncatableCell = (props: Props) => {
  const { getCellProps, render, value } = props;

  const ref = useRef(null);
  const isTruncated = useIsTruncated(ref);

  return (
    <TableData ref={ref} {...getCellProps()} title={isTruncated ? value : ''} isTruncated={isTruncated}>
      {render('Cell')}
    </TableData>
  );
};

const TableData = styled(StyledTableData)<{ isTruncated?: boolean }>`
  ${({ isTruncated }) =>
    isTruncated &&
    css`
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `}
`;

export default memo(TruncatableCell);
