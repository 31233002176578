import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const RemoveIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
      <path d="M200-440v-80h560v80H200Z" />
    </IconSvg>
  );
};
