import { DepartmentDTO } from '../../../api/api';
import DepartmentDropdown from '../../../components/department-dropdown/DepartmentDropdown';
import TextField from '../../../components/text-field/TextField';
import { SectionHeader } from '../../../styling/FormStyling';
import { ProfileSetupProps } from '../ProfileSetupDialog';
import { StyledProfileRow } from './TechnicianSetupForm';

interface Props extends ProfileSetupProps {
  onDepartmentsLoaded: React.Dispatch<React.SetStateAction<DepartmentDTO[]>>;
}

const OfficeSetupForm = (props: Props) => {
  const { departmentId, errors, onChangeValue, onDepartmentsLoaded } = props;

  return (
    <>
      <SectionHeader>Kontaktoplysninger</SectionHeader>
      <StyledProfileRow>
        <TextField
          onChange={(e) => onChangeValue('name', e.target.value)}
          error={errors?.name}
          fullWidth
          label="Fulde navn"
          helperText="Så dit team kan søge dig frem i WORK"
          required
        />
        <TextField
          onChange={(e) => onChangeValue('phone', e.target.value)}
          error={errors?.phone}
          type="number"
          fullWidth
          label="Telefonnummer"
          required
        />
      </StyledProfileRow>
      <StyledProfileRow>
        <DepartmentDropdown
          error={errors?.departmentId}
          fullWidth
          value={departmentId !== undefined ? departmentId : ''}
          onDepartmentsLoaded={onDepartmentsLoaded}
          selectDepartment={(department) => {
            department?.departmentId !== undefined && onChangeValue('departmentId', department.departmentId);
          }}
        />
      </StyledProfileRow>
    </>
  );
};

export default OfficeSetupForm;
