import { useLocation } from 'react-router-dom';

type UseQuery = { [key: string]: string };

export const useQuery = <T>(): T | UseQuery => {
  const urlSearchParams = new URLSearchParams(useLocation().search);

  const result: T | UseQuery = {};

  urlSearchParams.forEach((value, key) => {
    result[key] = value;
  });

  return result;
};

export default useQuery;
