import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import TimeRegForm from '../timereg-form/TimeRegForm';
import DialogView from '../../components/dialog-view/DialogView';
import Typography from '../../components/typography/Typography';
import IconButton from '../../components/icon-button/IconButton';
import { CloseIcon } from '../../assets/icons/CloseIcon';

const TimeRegDialog = () => {
  const navigate = useNavigate();

  return (
    <DialogView>
      <Container>
        <Header>
          <Typography variant="h3">Tilføj timer</Typography>
          <IconButton variant="outlined" onClick={() => navigate(-1)}>
            <CloseIcon size="18px" />
          </IconButton>
        </Header>
        <TimeRegForm handleReturn={() => navigate(-1)} />
      </Container>
    </DialogView>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px;
  background-color: white;
  width: 700px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default TimeRegDialog;
