import { useCallback } from 'react';
import styled from 'styled-components';

interface Props {
  linkTo: string;
  headline: string;
}

const LinkTile = (props: Props) => {
  const { linkTo, headline } = props;

  //www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
  const handleOnClick = useCallback(() => {
    window.open(linkTo, '_blank', 'noopener,noreferrer');
  }, [linkTo]);

  return <Container onClick={handleOnClick}>{headline}</Container>;
};

const Container = styled.div`
  height: 128px;
  width: 128px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.grey.black5};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  font-size: 1.5rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

export default LinkTile;
