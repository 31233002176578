import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ChevronLeft = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-left-icon">
      <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
    </IconSvg>
  );
};
