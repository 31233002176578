import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { StockDTO, TransferOrderDTO } from '../../api/api';

interface OrderGoodsState {
  selectedGoods: StockDTO[];
  selectedTransferOrder?: TransferOrderDTO;
}

const initialState: OrderGoodsState = {
  selectedGoods: [],
  selectedTransferOrder: undefined
};

const returnGoodsSlice = createSlice({
  name: 'returnGoods',
  initialState: initialState,
  reducers: {
    setSelectedGoods: (state: OrderGoodsState, action: PayloadAction<StockDTO[]>) => {
      state.selectedGoods = action.payload;
    },
    setSelectedOrder: (state: OrderGoodsState, action: PayloadAction<TransferOrderDTO>) => {
      state.selectedTransferOrder = action.payload;
    }
  }
});

export const { setSelectedGoods, setSelectedOrder } = returnGoodsSlice.actions;
export const selectedGoods = (state: RootState) => state.returnGoodsReducer.selectedGoods;
export const selectedTransferOrder = (state: RootState) => state.returnGoodsReducer.selectedTransferOrder;

export default returnGoodsSlice.reducer;
