import { memo, useEffect, useState } from 'react';
import { DepartmentDTO } from '../../api/api';
import Select, { MenuItem, SelectProps } from '../select/Select';
import DepartmentService from '../../services/DepartmentService';
import { SelectChangeEvent } from '@mui/material';

interface Props<T> extends SelectProps<T> {
  selectDepartment: (d: DepartmentDTO) => void;
  placeholder?: string;
  disabled?: boolean;
  onDepartmentsLoaded?: React.Dispatch<React.SetStateAction<DepartmentDTO[]>>;
}

const DepartmentDropdown = <T extends unknown>(props: Props<T>) => {
  const { selectDepartment, disabled, value, placeholder, onDepartmentsLoaded, ...rest } = props;
  const [departments, setDepartments] = useState<DepartmentDTO[]>([]);

  useEffect(() => {
    DepartmentService.GetDepartments().then((res) => {
      setDepartments(res);
      onDepartmentsLoaded && onDepartmentsLoaded(res);
    });
  }, [onDepartmentsLoaded]);

  const renderOptions = () => {
    return departments.map((d) => {
      return (
        <MenuItem key={d.departmentId} style={{ whiteSpace: 'pre-wrap' }} value={d.departmentId}>
          {d.name}
        </MenuItem>
      );
    });
  };

  const handleSelectDepartment = (e: SelectChangeEvent<T>) => {
    const selectedDepartment = departments.find((d) => d.departmentId === e.target.value);
    selectedDepartment && selectDepartment(selectedDepartment);
  };

  return (
    <Select
      onChange={(e) => handleSelectDepartment(e)}
      label="Afdeling"
      required
      value={value}
      disabled={disabled}
      {...rest}
    >
      {renderOptions()}
    </Select>
  );
};

export default memo(DepartmentDropdown);
