import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Select from '../../../../../components/select/Select';
import TextField from '../../../../../components/text-field/TextField';
import { SectionProps } from '../../../../../models/CommonProps/SectionProps';
import { TaskCreationTask } from '../../../../../models/TaskCreationTask';
import { updateTask } from '../../../../../stateManagement/reducers/taskCreationReducer';
import { SectionContent } from '../../../../../styling/FormStyling';

import { Row } from '../../../../../styling/FormStyling';

interface Props extends SectionProps {
  task: TaskCreationTask;
}

const AssetDataSection = (props: Props) => {
  const { task, readonlyMode } = props;
  const { taskCreationId = '', assetPrimaryVoltage = 400, assetId, assetType } = task;

  const [voltage, setVoltage] = useState(assetPrimaryVoltage);

  const dispatch = useDispatch();

  const handleVoltageChange = (event: SelectChangeEvent<number>) => {
    setVoltage(event.target.value as number);
    dispatch(
      updateTask({
        id: taskCreationId,
        task: { assetPrimaryVoltage: event.target.value as number }
      })
    );
  };

  return (
    <SectionContent>
      <Row>
        <TextField
          disabled
          label="Asset ID"
          value={assetId ? `${assetId} ${assetType ? ` - ${assetType}` : ''}` : '-'}
          variant="outlined"
          fullWidth
        />
        <Select
          labelId="voltage-type"
          label="Spændingstype"
          value={voltage}
          variant="outlined"
          onChange={handleVoltageChange}
          disabled={readonlyMode}
          fullWidth
        >
          <MenuItem value={400}>0,4 kV</MenuItem>
          <MenuItem value={10000}>10 kV</MenuItem>
          <MenuItem value={15000}>15 kV</MenuItem>
          <MenuItem value={20000}>20 kV</MenuItem>
          <MenuItem value={60000}>60 kV</MenuItem>
        </Select>
      </Row>
    </SectionContent>
  );
};

export default AssetDataSection;
