import styled from 'styled-components';
import BasicPopover from '../popover/Popover';

interface Props {
  children?: React.ReactNode;
  menuItems: JSX.Element;
}

const ContextMenuTab = (props: Props) => {
  const { children, menuItems } = props;

  return (
    <BasicPopover
      buttonElement={<>{children}</>}
      popoverElement={<TabsVerticalToolsMenu>{menuItems}</TabsVerticalToolsMenu>}
      activeByClick
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    />
  );
};

const TabsVerticalToolsMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(2)};

  > * {
    padding: 12px 16px;
    border-radius: 8px;
  }

  z-index: ${(props) => props.theme.zIndex.dialogTools};
`;

export const ContextMenuItem = styled.span`
  :hover {
    background: #f2f4fe;
    cursor: pointer;
  }
`;

export default ContextMenuTab;
