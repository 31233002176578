import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const PauseIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg" data-testid="pause-icon">
      <path d="M520-200v-560h240v560H520Zm-320 0v-560h240v560H200Zm400-80h80v-400h-80v400Zm-320 0h80v-400h-80v400Zm0-400v400-400Zm320 0v400-400Z" />
    </IconSvg>
  );
};
