import WorkClient from '../api/workClient';

const cancelSubWorkTask = (id: number) => {
  return WorkClient.cancelSubWorkTask(id);
};

const createSubTask = (workTaskId: number, taskTypeId: number) => {
  return WorkClient.createSubWorkTask(workTaskId, taskTypeId);
};

const getSubTask = (id: number) => {
  return WorkClient.getSubWorkTask3(id);
};

const SubWorkTaskService = {
  cancelSubWorkTask,
  createSubTask,
  getSubTask
};

export default SubWorkTaskService;
