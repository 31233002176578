import styled from 'styled-components';
import { ComponentCommentResponseDTO, ListViewCommentResponseDTO } from '../../../../../api/api';
import { DeleteIcon } from '../../../../../assets/icons/DeleteIcon';
import { TabProps } from '../../../../../blocks/tabs-vertical/TabsVertical';
import IconButton from '../../../../../components/icon-button/IconButton';
import IconBadge from '../../../../../components/icon-badge/IconBadge';
import Typography from '../../../../../components/typography/Typography';
import { formatDateWithTime } from '../../../../../utils/dateHandling';
import { CommentIcon } from '../../../../../assets/icons/CommentIcon';

interface Props extends TabProps {
  comments: ListViewCommentResponseDTO[] | ComponentCommentResponseDTO[];
  description?: string;
  onDeleteComment: (listViewCommentId: number) => void;
}

const CommentsSection = (props: Props) => {
  const { children, comments, description, onDeleteComment } = props;

  return (
    <Section>
      <SectionHeader variant="h6">
        Kommentarer
        <IconBadge Icon={() => <CommentIcon color="white" size="12px" />} text={String(comments.length)} />
      </SectionHeader>
      <SectionText>{description}</SectionText>
      {comments.map((comment) => (
        <Comment key={comment.id}>
          <CommentHeader>
            <Typography>{formatDateWithTime(comment.createdDateTime)}</Typography>
            <IconButton onClick={() => onDeleteComment(comment?.id ?? -1)}>
              <DeleteIcon size="18px" />
            </IconButton>
          </CommentHeader>
          <StyledCommentsText>{comment.value}</StyledCommentsText>
        </Comment>
      ))}
      {children}
    </Section>
  );
};

const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Comment = styled.div`
  background-color: ${(props) => props.theme.palette.grey.black5};
  border-radius: ${(props) => props.theme.spacing(2)};
  padding: ${(props) => props.theme.spacing(3)} ${(props) => props.theme.spacing(5)} ${(props) => props.theme.spacing(5)}
    ${(props) => props.theme.spacing(5)};
  margin-bottom: ${(props) => props.theme.spacing(5)};
  border: 1px solid ${(props) => props.theme.palette.grey.black10};
`;

const SectionHeader = styled(Typography)`
  display: flex;
  font-size: ${(props) => props.theme.spacing(4)};
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const StyledCommentsText = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(2)};
  font-weight: 600;
`;

const Section = styled.div`
  border: 1px solid ${(props) => props.theme.palette.grey.black20};
  padding: ${(props) => props.theme.spacing(5)};
  border-radius: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(7)};
`;

const SectionText = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(3)};
  color: ${(props) => props.theme.palette.grey.black60};
  font-size: ${(props) => props.theme.spacing(4)};
`;

export default CommentsSection;
