import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
    overflow: hidden;
  } 
  
  body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin: 0;
    font-family: 'Overpass', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-style: normal;
    background-color: #DBDBDB;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  
  #root {
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6, p, span, a {
    margin: 0;
    padding: 0;
  }

  h2 {
    font-weight: 800;
    font-size: 22px;
    line-height: 28px;
  }

  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
  }

  h4 {
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
  }
`;

export default GlobalStyles;
