import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastModel } from '../../models/Toast';
import { RootState } from '../store';

interface NotificationState {
  toast?: ToastModel;
}

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {} as NotificationState,
  reducers: {
    addToast: (state: NotificationState, action: PayloadAction<{ toast: ToastModel }>) => {
      state.toast = action.payload.toast;
    }
  }
});

export const { addToast } = notificationSlice.actions;

export const selectToast = (state: RootState) => state.notifications.toast;

export default notificationSlice.reducer;
