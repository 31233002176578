import { useRef, useState, memo, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';

import Grow from '../../../../components/grow';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { DepartmentDTO, TagDTO, WorkTaskDepartmentDTO2 } from '../../../../api/api';
import { SendIcon } from '../../../../assets/icons/SendIcon';
import { DialogBody } from '../../../../stateManagement/reducers/confirmDialogReducer';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import TaskService from '../../../../services/TaskService';
import NotificationService from '../../../../services/NotificationService';
import { Container, ExpandedToolMenu, MenuTrigger } from '../../../../styling/ListToolsStyling';
import { TableToolsProps } from '../../../../blocks/table/TableUtils';
import AdminService from '../../../../services/AdminService';
import { selectTags, setListShouldUpdate } from '../../../../stateManagement/reducers/taskListReducer';
import { useDispatch, useSelector } from 'react-redux';

interface Props<T extends { id: string }> extends TableToolsProps<T> {
  userDepartment?: DepartmentDTO;
  worktaskHasDifferentDepartment: boolean;
}

const ChangeDepartmentMenu = <T extends { id: string }>(props: Props<T>) => {
  const { selectedRows, fetchTasksCallback, setIsLoading, userDepartment, worktaskHasDifferentDepartment } = props;

  const [open, setOpen] = useState(false);
  const [departments, setDepartments] = useState<DepartmentDTO[]>();

  const tags = useSelector(selectTags);
  const dispatch = useDispatch();

  useEffect(() => {
    AdminService.getDepartments().then((res) => setDepartments(res ?? []));
  }, []);

  const rootRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(rootRef, () => setOpen(false));

  const { getConfirmation } = useConfirmationDialog();

  const setTags = useCallback(
    (tag: TagDTO, workTaskIds: number[]) => {
      setIsLoading(true);
      const numberOfTasks = selectedRows.length;
      TaskService.updateWorkTaskTag(tag.id, workTaskIds)
        .then(() => {
          tag.id &&
            NotificationService.success(
              `${numberOfTasks} ${numberOfTasks > 1 ? 'opgaver' : 'opgave'} sendt til ${tag.tagText}`
            );
          fetchTasksCallback();
        })
        .catch(() => {
          NotificationService.warning(`${numberOfTasks > 1 ? 'Opgaverne' : 'Opgaven'} er ikke lagt på den korrekte liste`);
          setIsLoading(false);
        });
      setOpen(false);
    },
    [fetchTasksCallback, selectedRows.length, setIsLoading]
  );

  const handleChangeDepartment = useCallback(
    async (newDepartment: DepartmentDTO) => {
      const body: WorkTaskDepartmentDTO2[] = selectedRows.map((row) => ({
        workTaskId: parseInt(row.id),
        newDepartmentId: newDepartment.departmentId
      }));

      let workTaskIds: number[] = [];
      selectedRows.forEach((row) => {
        workTaskIds.push(parseInt(row.id));
      });

      if (worktaskHasDifferentDepartment || body.length >= 10) {
        const differentAppartments =
          'Du er ved at ændre på en opgave der ligger i en anden afdeling end din egen. Vil du fortsætte?';
        const numberOfTasks = `Vil du ændre afdeling på ${body.length} opgaver til "${newDepartment.name}"?`;

        const dialogBody: DialogBody = {
          headerText: worktaskHasDifferentDepartment ? differentAppartments : numberOfTasks,
          bodyText: worktaskHasDifferentDepartment && body.length >= 10 ? numberOfTasks : '',
          declineButtonText: 'Fortryd',
          confirmButtonText: 'Bekræft'
        };
        const confirmation = await getConfirmation(dialogBody);
        if (confirmation !== 'confirm') return;
      }

      setIsLoading(true);
      TaskService.updateDepartments(body)
        .then(() => {
          dispatch(setListShouldUpdate(true));
          setTags({ id: undefined, tagText: '' } as TagDTO, workTaskIds);
          NotificationService.success(body.length + ' opgaver sendt til ' + newDepartment.name);
        })
        .catch((error) => {
          NotificationService.warning('Kunne ikke sende opgaver: ' + error);
          setIsLoading(false);
        });

      setOpen(false);
    },
    [selectedRows, setIsLoading, getConfirmation, dispatch, setTags, worktaskHasDifferentDepartment]
  );

  const handleChangeTag = useCallback(
    async (tag: TagDTO) => {
      if (worktaskHasDifferentDepartment || selectedRows.length >= 10) {
        const differentAppartments =
          'Du er ved at ændre på en opgave der ligger i en anden afdeling end din egen. Vil du fortsætte?';
        const numberOfTasks = `Vil du sende ${selectedRows.length} opgaver til ${tag.tagText}?`;

        const dialogBody: DialogBody = {
          headerText: worktaskHasDifferentDepartment ? differentAppartments : numberOfTasks,
          bodyText: worktaskHasDifferentDepartment && selectedRows.length >= 10 ? numberOfTasks : '',
          declineButtonText: 'Fortryd',
          confirmButtonText: 'Bekræft'
        };
        const confirmation = await getConfirmation(dialogBody);
        if (confirmation !== 'confirm') return;
      }

      setIsLoading(true);
      let workTaskIds: number[] = [];
      selectedRows.forEach((row) => {
        workTaskIds.push(parseInt(row.id));
      });

      setTags(tag, workTaskIds);
    },
    [selectedRows, setIsLoading, setTags, getConfirmation, worktaskHasDifferentDepartment]
  );

  return (
    <Container ref={rootRef}>
      <MenuTrigger onClick={() => setOpen(!open)} variant="tertiary" square data-testid="change-department-button">
        <SendIcon size="20px" />
        Send til
      </MenuTrigger>

      <Grow in={open}>
        <ExpandedToolMenu>
          <h4>Egen afdeling</h4>
          <MenuItem disabled={!userDepartment} onClick={() => userDepartment && handleChangeDepartment(userDepartment)}>
            Drift
          </MenuItem>
          {tags.map((tag, i) => {
            return (
              <MenuItem key={i} disabled={!userDepartment} onClick={() => handleChangeTag(tag)}>
                {tag.tagText}
              </MenuItem>
            );
          })}
          <h4>Øvrige afdelinger</h4>
          {departments?.map((d) => (
            <MenuItem onClick={() => handleChangeDepartment(d)} data-testid={`${d.name}-department-item`}>
              {d.name}
            </MenuItem>
          ))}
        </ExpandedToolMenu>
      </Grow>
    </Container>
  );
};

const MenuItem = styled.span<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          color: ${(props) => props.theme.palette.grey.black20};
        `
      : css`
          :hover {
            background: #f2f4fe;
            cursor: pointer;
          }
        `}
`;

export default memo(ChangeDepartmentMenu);
