import styled, { css } from 'styled-components';
import Typography from '../typography/Typography';

interface Props {
  text?: string;
  children?: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'error';
}

const InformationBox = (props: Props) => {
  const { text, variant = 'primary', children } = props;

  return (
    <Information variant={variant}>
      {text && <Typography>{text}</Typography>}
      {children}
    </Information>
  );
};

const primaryStyles = css`
  background: ${(props) => props.theme.palette.main.black.primary};
  color: ${(props) => props.theme.palette.text.secondary};
`;

const secondaryStyles = css`
  background: ${(props) => props.theme.palette.main.yellow.tertiary};
  color: ${(props) => props.theme.palette.text.primary};
`;

const tertiaryStyles = css`
  background: ${(props) => props.theme.palette.functions.action.tertiary};
  color: ${(props) => props.theme.palette.text.primary};
`;

const errorStyles = css`
  background: ${(props) => props.theme.palette.functions.error.primary};
  color: ${(props) => props.theme.palette.text.secondary};
`;

const Information = styled.div<Props>`
  margin: 0 ${(props) => props.theme.spacing(4)};
  padding: 0 ${(props) => props.theme.spacing(3)};
  border-radius: 8px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  flex-direction: row;
  height: fit-content;

  ${(props) => props.variant === 'primary' && primaryStyles};
  ${(props) => props.variant === 'secondary' && secondaryStyles};
  ${(props) => props.variant === 'tertiary' && tertiaryStyles};
  ${(props) => props.variant === 'error' && errorStyles};
`;

export default InformationBox;
