import WorkClient from '../api/workClient';

const postAttachment = (id: number, attachment: File) => {
  return WorkClient.attachmentsPOST(id, { data: attachment, fileName: attachment.name });
};

const deleteAttachment = (id: number, fileName: string) => {
  return WorkClient.attachmentsDELETE(id, fileName);
};

const getAttachments = (id: number) => {
  return WorkClient.attachmentsAll(id);
};

const getThumbnail = (id: number, fileName: string) => {
  return WorkClient.thumbnail(id, fileName);
};

const getFile = (id: number, fileName: string) => {
  return WorkClient.attachmentsGET(id, fileName);
};

const getNumberOfFiles = (id: number) => {
  return WorkClient.numberOfFiles(id);
};

const AttachmentService = {
  postAttachment,
  deleteAttachment,
  getAttachments,
  getThumbnail,
  getFile,
  getNumberOfFiles
};

export default AttachmentService;
