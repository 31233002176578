import React from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import mergeRefs from '../../utils/react/merge-refs';

const getScale = (value: number) => {
  return `scale(${value}, ${value ** 2})`;
};

interface Props {
  /**
   * Perform the enter transition when it first mounts if `in` is also `true`.
   * @default true
   */
  appear?: boolean;
  /**
   * A single child content element.
   */
  children: React.ReactElement;
  /**
   * If `true`, the component will transition in.
   */
  in: boolean;
  /**
   * The duration for the transition in ms
   * @default 225
   */
  timeout?: number;
  /**
   * A callback that is fired, when the animation is in entering state
   */
  onEntering?: (node: HTMLElement, appearing: boolean) => void;
  /**
   * A callback that is fired, when the animation is in enter state
   */
  onEnter?: (node: HTMLElement, appearing: boolean) => void;
  /**
   * A callback that is fired, when the animation is in entered state
   */
  onEntered?: (node: HTMLElement, appearing: boolean) => void;
  /**
   * A callback that is fired, when the animation is in exiting state
   */
  onExiting?: (node: HTMLElement) => void;
  /**
   * A callback that is fired, when the animation is in exit state
   */
  onExit?: (node: HTMLElement) => void;
  /**
   * A callback that is fired, when the animation is in exited state
   */
  onExited?: (node: HTMLElement) => void;
  /**
   * If true, the node will be removed from the dom, once the animation has finished
   */
  unmountOnExit?: boolean;
}

/**
 * The Grow component is used to apply a grow transition to the inner element using React Transition Group
 */
export const Grow = React.forwardRef<HTMLDivElement, Props>(function Grow(props, ref) {
  const {
    onExit,
    onEnter,
    children,
    onExited,
    onEntered,
    onExiting,
    onEntering,
    in: inProp,
    appear = true,
    timeout = 225,
    unmountOnExit
  } = props;

  const child = React.Children.only(children);

  const nodeRef = React.useRef<HTMLElement>(null);

  const handleEnter = React.useCallback(
    (appearing: boolean) => {
      const node = nodeRef.current;

      if (node) {
        // So the animation always start from the start - it is an issue with react-transition-group
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        node.scrollTop = 0;

        node.style.transition = `opacity ${timeout}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform ${
          timeout * 0.666
        }ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`;

        onEnter?.(node, appearing);
      }
    },
    [onEnter, timeout]
  );

  const handleEntering = React.useCallback(
    (appearing: boolean) => {
      const node = nodeRef.current;

      if (node) {
        node.style.opacity = '1';
        node.style.transform = getScale(1);

        onEntering?.(node, appearing);
      }
    },
    [onEntering]
  );

  const handleEntered = React.useCallback(
    (appearing: boolean) => {
      const node = nodeRef.current;

      if (node) {
        node.style.opacity = '1';
        node.style.transform = 'none';

        onEntered?.(node, appearing);
      }
    },
    [onEntered]
  );

  const handleExit = React.useCallback(() => {
    const node = nodeRef.current;

    if (node) {
      node.style.transition = `opacity ${timeout}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform ${
        timeout * 0.666
      }ms cubic-bezier(0.4, 0, 0.2, 1) 0ms ${timeout * 0.333}`;

      node.style.opacity = '0';
      node.style.transform = getScale(0.75);

      onExit?.(node);
    }
  }, [onExit, timeout]);

  const handleExiting = React.useCallback(() => {
    const node = nodeRef.current;

    if (node) {
      onExiting?.(node);
    }
  }, [onExiting]);

  const handleExited = React.useCallback(() => {
    const node = nodeRef.current;

    if (node) {
      onExited?.(node);
    }
  }, [onExited]);

  return (
    <Transition
      in={inProp}
      appear={appear}
      timeout={timeout}
      nodeRef={nodeRef}
      onExit={handleExit}
      onEnter={handleEnter}
      onExited={handleExited}
      onEntered={handleEntered}
      onExiting={handleExiting}
      onEntering={handleEntering}
      unmountOnExit={unmountOnExit}
    >
      {(status: TransitionStatus) => {
        return React.cloneElement(child, {
          ...child.props,
          ref: mergeRefs((child as any).ref, nodeRef, ref),
          style: {
            opacity: 0,
            transform: getScale(0.75),
            visibility: status === 'exited' && !inProp ? 'hidden' : undefined,
            ...child.props.style
          }
        });
      }}
    </Transition>
  );
});

export default Grow;
