import { TabProps } from '../../../../../blocks/tabs-vertical/TabsVertical';
import { DepartmentDTO, ReasonCodeDTO, UpdateReasonCodeStatusDTO2 } from '../../../../../api/api';
import useSelectedFlowTable from './useSelectedFlowTable';
import { StyledTable } from '../../AdminTaskTypesView';
import styled from 'styled-components';

interface Props extends TabProps {
  allReasonCodes: Array<ReasonCodeDTO>;
  allDepartments: Array<DepartmentDTO>;
  selectedReasonCodes: UpdateReasonCodeStatusDTO2[];
  setSelectedReasonCodes: (selectedReasonCodes: UpdateReasonCodeStatusDTO2[]) => void;
}

const ReasonsTable = (props: Props) => {
  const { allReasonCodes, allDepartments, selectedReasonCodes, setSelectedReasonCodes } = props;
  const { tableInstance } = useSelectedFlowTable(
    selectedReasonCodes,
    allReasonCodes,
    allDepartments,
    setSelectedReasonCodes
  );

  return (
    <StyledContainer>
      <StyledTable
        noPadding
        showPagination
        noDataText="Ingen årsager"
        loading={false}
        tableInstance={tableInstance}
        alwaysShowSort
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  height: 80%;
`;

export default ReasonsTable;
