import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProductDetailDTO, WarehouseDTO } from '../../../../api/api';
import Table from '../../../../blocks/table/TableClient';
import CircularProgress from '../../../../components/circular-progress/CircularProgress';
import { HorizontalTabProps } from '../../../../components/tabs-horizontal/TabsHorizontal';
import GoodsService from '../../../../services/GoodsService';
import NotificationService from '../../../../services/NotificationService';
import { log } from '../../../../utils/logging/log';
import AutoComplete from '../../../../components/auto-complete/AutoComplete';
import TextField from '../../../../components/text-field/TextField';
import { useEmergencyStorageTable } from './useEmergencyStorageTable';
import { D365_LEGALENTITYID_FOR_PRODUCTS } from '../../../../utils/constants';
import { Stock } from '../../../../models/Goods';

const EmergencyStorageTab = (props: HorizontalTabProps) => {
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [loadingStock, setLoadingStock] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseDTO>();
  const [selectedProduct, setSelectedProduct] = useState<ProductDetailDTO>();
  const [stock, setStock] = useState<Stock[]>([]);
  const [products, setProducts] = useState<ProductDetailDTO[]>([]);
  const [warehouses, setWarehouses] = useState<WarehouseDTO[]>([]);
  const { tableInstance } = useEmergencyStorageTable(stock, selectedWarehouse?.warehouseId, selectedProduct?.itemId);

  const getStock = useCallback(
    (warehouseId: string | undefined, itemId: string | undefined) => {
      setLoadingStock(true);
      GoodsService.getItemsOnHand3(D365_LEGALENTITYID_FOR_PRODUCTS, warehouseId, undefined, itemId)
        .then((res) => {
          const stock = res.map((good) => {
            return {
              ...good,
              warehouseName: warehouses.find((warehouse) => warehouse.warehouseId === good.warehouseId)?.name ?? ''
            };
          }) as Stock[];
          setStock(stock);
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Kunne ikke hente lagerbeholdning. Prøv at genindlæse denne tab.');
        })
        .finally(() => {
          setLoadingStock(false);
        });
    },
    [warehouses]
  );

  const getEmergencyWarehouses = async () => {
    try {
      return await GoodsService.getEmergencyWarehouses();
    } catch (err) {
      log(err);
      throw new Error('Kunne ikke hente beredskabslagre. Prøv at genindlæse denne tab.');
    }
  };

  const getActiveProducts = async () => {
    try {
      return await GoodsService.getActiveProducts();
    } catch (err) {
      log(err);
      throw new Error('Kunne ikke hente varer. Prøv at genindlæse denne tab.');
    }
  };

  useEffect(() => {
    setLoadingComponent(true);
    Promise.all([getEmergencyWarehouses(), getActiveProducts()])
      .then(([emergencyWarehousesRes, activeProductsRes]) => {
        setWarehouses(emergencyWarehousesRes);
        setProducts(activeProductsRes);
      })
      .catch((error) => {
        log(error);
        NotificationService.error(error.message);
      })
      .finally(() => setLoadingComponent(false));
  }, []);

  useEffect(() => {
    if (!selectedProduct && !selectedWarehouse) {
      setStock([]);
    } else {
      getStock(selectedWarehouse?.warehouseId, selectedProduct?.itemId);
    }
  }, [selectedWarehouse, selectedProduct, getStock]);

  const handleSelectWarehouse = (warehouseId: string, reason?: string) => {
    if (reason === 'clear') {
      setSelectedWarehouse(undefined);
    } else {
      setSelectedWarehouse(warehouses.find((warehouse) => warehouse.warehouseId === warehouseId));
    }
  };

  const handleSelectProduct = (itemId: string, reason?: string) => {
    if (reason === 'clear') {
      setSelectedProduct(undefined);
    } else {
      setSelectedProduct(products.find((product) => product.itemId === itemId));
    }
  };

  if (loadingComponent) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <SelectWarehouseContainer>
          <AutoComplete
            fullWidth
            onChange={(_, value, reason) => handleSelectWarehouse(value?.warehouseId ?? '', reason)}
            getOptionLabel={(warehouseId) => `${warehouseId.name} (${warehouseId.warehouseId})`}
            options={warehouses ?? []}
            value={selectedWarehouse ?? null}
            isOptionEqualToValue={(option, value) => option.warehouseId === value?.warehouseId}
            renderInput={(params) => <TextField {...params} label="Søg blandt beredskabslagre" />}
            noOptionsText="Ingen beredskabslagre"
          />
        </SelectWarehouseContainer>
        <SelectProductContainer>
          <AutoComplete
            fullWidth
            onChange={(_, value, reason) => handleSelectProduct(value?.itemId ?? '', reason)}
            getOptionLabel={(product) => `(${product.itemId}) ${product.name}`}
            options={products ?? []}
            value={selectedProduct ?? null}
            isOptionEqualToValue={(option, value) => option.itemId === value?.itemId}
            renderInput={(params) => <TextField {...params} label="Søg blandt varer" />}
            noOptionsText="Ingen varer"
          />
        </SelectProductContainer>
      </Row>
      <TableContainer>
        <Table loading={loadingStock} tableInstance={tableInstance} alwaysShowSort noPadding showPagination />
      </TableContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  column-gap: 24px;
`;

const SelectWarehouseContainer = styled.div`
  width: 450px;
  padding-bottom: 24px;
`;

const SelectProductContainer = styled.div`
  width: 550px;
`;

const TableContainer = styled.div`
  height: calc(100% - 79px);

  .table-footer {
    justify-content: end;
  }
`;

export default EmergencyStorageTab;
