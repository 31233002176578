import styled from 'styled-components';
import { CheckmarkIcon } from '../../assets/icons/CheckmarkIcon';

export enum STATUS {
  GREEN,
  GREY
}

interface Props {
  status: STATUS;
  interactive?: boolean;
  onClick?: (e: any) => void;
}

const StatusIndicator = (props: Props) => {
  const { status, interactive = false, onClick } = props;
  return (
    <StyledCheckmarkContainer status={status} onClick={onClick} interactive={interactive}>
      <CheckmarkIcon strokeWidth={'50'} size="16px" color="white" />
    </StyledCheckmarkContainer>
  );
};

const StyledCheckmarkContainer = styled.div<{ status: STATUS; interactive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => (props.interactive ? '5px' : '50%')};
  padding: 5px;
  background-color: ${(props) =>
    props.status === STATUS.GREEN ? props.theme.palette.functions.success.primary : props.theme.palette.grey.black20};
  margin-right: ${(props) => props.theme.spacing(4)};
  height: 20px;
  width: 20px;
`;

export default StatusIndicator;
