import { ProfileDTO2Role, Role, SimulatePlanningDTO } from '../api/api';
import WorkClient from '../api/workClient';
import { getUserEmail, getUserName } from '../utils/authProvider/authProvider';
import NotificationService from './NotificationService';
import { log } from '../utils/logging/log';
import { getEnumDisplayValue } from '../utils/enumUtils';

export const allowedTestingEnviroments =
  window._env_.ENVIRONMENT === 'Local' || window._env_.ENVIRONMENT === 'Dev' || window._env_.ENVIRONMENT === 'Test';

// Should ONLY be called in dev and test
const simulatePlanning = (workTaskId: number) => {
  if (!allowedTestingEnviroments) return;

  const userEmail = getUserEmail();
  const userName = getUserName();

  const body: SimulatePlanningDTO = {
    assignedToEmail: userEmail,
    assignedToName: userName,
    plannedArrival: new Date().toISOString(),
    plannedDuration: 10,
    isFixed: true,
    numberOfDays: 1
  };

  return WorkClient.simulatePlanning(workTaskId, body)
    .then(() => {
      NotificationService.success(`Opgave: ${workTaskId} er blevet planlagt på dig selv`);
    })
    .catch((err) => {
      log(err);
      NotificationService.error(`Opgave: ${workTaskId} er ikke blevet planlagt. Fejlkode: ${err}`);
    });
};

// Should ONLY be called in dev and test
const changeRole = (role: Role) => {
  if (!allowedTestingEnviroments) return;
  return WorkClient.changeRole(role)
    .then(() => {
      window.location.href = window._env_.REACT_APP_FRONTEND_URL;
    })
    .catch((err) => {
      log(err);
      NotificationService.error('Det lykkedes ikke at skifte rolle.');
    });
};

const TestingService = {
  simulatePlanning,
  changeRole
};

export default TestingService;
