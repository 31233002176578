import { Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { displayConfirmDialog, selectConfirmDialogState } from '../../stateManagement/reducers/confirmDialogReducer';

import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';
import { CloseIcon } from '../../assets/icons/CloseIcon';
import Button from '../button/Button';
import styled from 'styled-components';
import IconButton from '../icon-button/IconButton';

const ConfirmDialog = () => {
  const { onConfirm, onCancel, onDecline } = useConfirmationDialog();
  const dialogState = useSelector(selectConfirmDialogState);
  const dispatch = useDispatch();

  const handleClose = (reason: 'backdropClick' | 'escapeKeyDown') => {
    if (dialogState.locked) {
      if (reason !== 'backdropClick') {
        onCancel();
      }
    } else {
      onCancel();
    }
  };

  return (
    <Dialog
      open={dialogState.display ?? false}
      onClose={(_, reason) => handleClose(reason)}
      keepMounted={dialogState.locked}
    >
      <Container>
        {dialogState.locked && <CloseButton onClick={() => dispatch(displayConfirmDialog(false))} />}
        <TextContainer>
          <h2>{dialogState?.content?.headerText}</h2>
          <span>{dialogState?.content?.bodyText}</span>
        </TextContainer>
        <ButtonContainer>
          <Button variant="secondary" onClick={() => onDecline()}>
            {dialogState?.content?.declineButtonText}
          </Button>
          <Button onClick={() => onConfirm()} data-testid="comfirm-dialog-confirmation-button">
            {dialogState?.content?.confirmButtonText}
          </Button>
        </ButtonContainer>
      </Container>
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 40px 80px;
`;

const CloseButton = styled((props) => (
  <IconButton {...props}>
    <CloseIcon size="16px" />
  </IconButton>
))`
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 16px;
`;

export default ConfirmDialog;
