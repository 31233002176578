import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  DepartmentDTO,
  OrderByColumnEnum,
  OrderByEnum,
  TagDTO,
  TaskListConfigurationDTO2,
  TaskListCustomFilterDTO2,
  TaskListRequestDTO,
  TaskTypeBaseDTO,
  WorkTaskStatus
} from '../../api/api';

interface TaskListState {
  listShouldUpdate: boolean;
  shouldUpdateNumberOfInternalTasksCreated: boolean;
  tags: TagDTO[];
  departments: DepartmentDTO[];
  taskTypes: TaskTypeBaseDTO[];
  columnConfigs: TaskListConfigurationDTO2[];
  filterValues: TaskListRequestDTO;
  customFilters: TaskListCustomFilterDTO2[];
  selectedCustomFilters?: {
    operation?: TaskListCustomFilterDTO2;
    all?: TaskListCustomFilterDTO2;
  };
  selectedCustomFilter?: TaskListCustomFilterDTO2;
  operationBaseFilters: TaskListRequestDTO;
  technicanFilterValues?: TaskListRequestDTO;
  showAddFilterButton: boolean;
}

const initialState: TaskListState = {
  listShouldUpdate: false,
  shouldUpdateNumberOfInternalTasksCreated: false,
  tags: [],
  departments: [],
  taskTypes: [],
  columnConfigs: [],
  operationBaseFilters: {
    page: 1,
    pageSize: 25,
    tagId: -1,
    sortOrder: OrderByEnum.Desc,
    orderByColumn: OrderByColumnEnum.WorkTaskId
  } as TaskListRequestDTO,
  filterValues: {
    page: 1,
    pageSize: 25,
    tagId: -1,
    sortOrder: OrderByEnum.Desc,
    orderByColumn: OrderByColumnEnum.WorkTaskId
  } as TaskListRequestDTO,
  technicanFilterValues: undefined,
  customFilters: [],
  showAddFilterButton: false
};

const taskListSlice = createSlice({
  name: 'taskList',
  initialState: initialState,
  reducers: {
    setListShouldUpdate: (state: TaskListState, action: PayloadAction<boolean>) => {
      state.listShouldUpdate = action.payload;
      state.shouldUpdateNumberOfInternalTasksCreated = true;
    },
    setShouldUpdateNumberOfInternalTasksCreated: (state: TaskListState, action: PayloadAction<boolean>) => {
      state.shouldUpdateNumberOfInternalTasksCreated = action.payload;
    },
    setColumConfig: (state: TaskListState, action: PayloadAction<TaskListConfigurationDTO2[]>) => {
      state.columnConfigs = action.payload;
    },
    setTags: (state: TaskListState, action: PayloadAction<TagDTO[]>) => {
      state.tags = action.payload;
    },
    setDepartments: (state: TaskListState, action: PayloadAction<DepartmentDTO[]>) => {
      state.departments = action.payload;
    },
    setTaskTypes: (state: TaskListState, action: PayloadAction<TaskTypeBaseDTO[]>) => {
      state.taskTypes = action.payload;
    },
    setOperationBaseFilter: (state: TaskListState, action: PayloadAction<TaskListRequestDTO>) => {
      state.operationBaseFilters = action.payload;
    },
    setFilterValues: (state: TaskListState, action: PayloadAction<TaskListRequestDTO>) => {
      state.filterValues = action.payload;
    },
    setTechnicianFilterValues: (state: TaskListState, action: PayloadAction<TaskListRequestDTO>) => {
      state.technicanFilterValues = action.payload;
    },
    setCustomFilters: (state: TaskListState, action: PayloadAction<TaskListCustomFilterDTO2[]>) => {
      state.customFilters = action.payload;
    },
    setSelectedCustomFilter: (state: TaskListState, action: PayloadAction<TaskListCustomFilterDTO2 | undefined>) => {
      state.selectedCustomFilter = action.payload;
      state.selectedCustomFilters = { ...state.selectedCustomFilters, operation: action.payload };
      state.showAddFilterButton = false;
    },
    setSelectedAllCustomFilter: (state: TaskListState, action: PayloadAction<TaskListCustomFilterDTO2 | undefined>) => {
      state.selectedCustomFilter = action.payload;
      state.selectedCustomFilters = { ...state.selectedCustomFilters, all: action.payload };
      state.showAddFilterButton = false;
    },
    setShowFilterButton: (state: TaskListState, action: PayloadAction<boolean>) => {
      state.showAddFilterButton = action.payload;
    }
  }
});

export interface OperationListFilter {
  statuses: WorkTaskStatus[];
  departmentId: number;
}

export const {
  setListShouldUpdate,
  setShouldUpdateNumberOfInternalTasksCreated,
  setTags,
  setDepartments,
  setTaskTypes,
  setColumConfig,
  setFilterValues,
  setCustomFilters,
  setSelectedCustomFilter,
  setSelectedAllCustomFilter,
  setOperationBaseFilter,
  setTechnicianFilterValues,
  setShowFilterButton
} = taskListSlice.actions;
export const selectListShouldUpdate = (state: RootState) => state.taskList.listShouldUpdate;
export const selectShouldUpdateNumberOfInternalTasksCreated = (state: RootState) =>
  state.taskList.shouldUpdateNumberOfInternalTasksCreated;
export const selectTags = (state: RootState) => state.taskList.tags;
export const selectDepartments = (state: RootState) => state.taskList.departments;
export const selectTaskTypes = (state: RootState) => state.taskList.taskTypes;
export const selectColumnConfigs = (state: RootState) => state.taskList.columnConfigs;
export const selectFilterValues = (state: RootState) => state.taskList.filterValues;
export const selectTechnicianFilterValues = (state: RootState) => state.taskList.technicanFilterValues;
export const selectCustomFilters = (state: RootState) => state.taskList.customFilters;
export const selectSelectedCustomFilter = (state: RootState) => state.taskList.selectedCustomFilter;
export const selectSelectedCustomFilters = (state: RootState) => state.taskList.selectedCustomFilters;
export const selectOperationBaseFilter = (state: RootState) => state.taskList.operationBaseFilters;
export const selectShowAddFilterButton = (state: RootState) => state.taskList.showAddFilterButton;

export default taskListSlice.reducer;
