import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const CheckmarkIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg" data-testid="checkmark-icon">
      <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
    </IconSvg>
  );
};
