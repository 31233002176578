import styled from 'styled-components';
import Typography from '../../components/typography/Typography';
import { UnreadDot } from '../../assets/icons/UnreadDot';
import { formatTimeAndDateString } from '../../utils/dateHandling';
import { getBackgroundColor, getTextColor } from './color-utils';
import { NewsDTO } from '../../api/api';

interface Props {
  news: NewsDTO;
  onClick?: (message: NewsDTO) => void;
  isSelected?: boolean;
}

const News = (props: Props) => {
  const { news, onClick, isSelected } = props;
  const { lastUpdated, newsType, isRead, title } = props.news;

  return (
    <Container
      onClick={() => onClick && onClick(news)}
      backgroundColor={getBackgroundColor(newsType)}
      textColor={getTextColor(newsType)}
      isSelected={isSelected}
    >
      <LeftContainer>
        <Typography fontWeight={isRead ? 'regular' : 'bold'}>{title}</Typography>
      </LeftContainer>
      <RightContainer>
        <StyledTypography fontSize={12} color="greyedOut">
          {formatTimeAndDateString(lastUpdated ?? '')}
        </StyledTypography>
        {!isRead && <UnreadDot />}
      </RightContainer>
    </Container>
  );
};

const Container = styled.div<{ backgroundColor?: string; textColor?: string; isSelected?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  padding: 5px 20px;
  margin: 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 6px;
  border-radius: 8px;
  border: 2px solid ${(props) => (props.isSelected ? props.textColor : 'transparent')};

  &:hover {
    cursor: pointer;
  }
`;

const StyledTypography = styled(Typography)`
  margin: 0px 5px;
`;

const LeftContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 145px;
`;

export default News;
