import { useDispatch } from 'react-redux';
import { DialogBody, displayConfirmDialog, getConfirmationDialog } from '../stateManagement/reducers/confirmDialogReducer';

export type ConfirmResult = 'confirm' | 'decline' | 'cancel';

let resolveCallback: (value: ConfirmResult | PromiseLike<ConfirmResult>) => void;
export const useConfirmationDialog = () => {
  const dispatch = useDispatch();

  const onConfirm = () => {
    closeConfirm();
    resolveCallback('confirm');
  };

  const onDecline = () => {
    closeConfirm();
    resolveCallback('decline');
  };

  const onCancel = () => {
    closeConfirm();
    resolveCallback('cancel');
  };

  const getConfirmation = (dialogBody: DialogBody, locked = false) => {
    dispatch(getConfirmationDialog({ dialogBody, locked }));

    return new Promise<ConfirmResult>((res, rej) => {
      resolveCallback = res;
    });
  };

  const closeConfirm = () => {
    dispatch(displayConfirmDialog(false));
  };

  return { getConfirmation, onConfirm, onDecline, onCancel };
};
