import { TabProps } from '../../../../../blocks/tabs-vertical/TabsVertical';
import AddQuestion from './AddQuestion';
import Preview from './Preview';
import { useCallback, useEffect, useState } from 'react';
import {
  ChecklistDTO,
  QuestionDTO,
  SectionQuestionTextDTO,
  TaskTypeCheckListTextDTO,
  TaskTypeSectionDTO,
  TaskTypeSectionTextDTO
} from '../../../../../api/api';
import Button from '../../../../../components/button/Button';
import { log } from '../../../../../utils/logging/log';
import styled from 'styled-components';
import AddTitle from '../../../../../components/add-title/AddTitle';
import Checklist from './TaskTypeChecklist';
import { ChevronLeft } from '../../../../../assets/icons/ChevronLeft';

export enum FormState {
  MAIN,
  ADD,
  PREVIEW
}
interface Props extends TabProps {
  checklists: TaskTypeCheckListTextDTO[];
  setChecklists: (checklists: TaskTypeCheckListTextDTO[], newChecklists?: number, newSection?: number) => void;
  setFooter: (left?: React.SetStateAction<JSX.Element>, right?: React.SetStateAction<JSX.Element>) => void;
  allQuestions: QuestionDTO[];
}

const TaskTypeChecklists = (props: Props) => {
  const { checklists, setChecklists, setFooter, allQuestions } = props;
  const [checklistState, setChecklistState] = useState<FormState>(FormState.MAIN);
  const [activeSection, setActiveSection] = useState<TaskTypeSectionTextDTO>();
  const [activeChecklist, setActiveChecklist] = useState<TaskTypeCheckListTextDTO>();
  const [addingChecklist, setAddingCheklist] = useState<boolean>();

  const setAddQuestionToSection = useCallback((section: SectionQuestionTextDTO, checklist: ChecklistDTO) => {
    setActiveChecklist(checklist as TaskTypeCheckListTextDTO);
    setActiveSection(section);
    handleSetChecklistState(FormState.ADD);
  }, []);

  const handleSetSections = useCallback(
    (checklistId: number | undefined, sections: SectionQuestionTextDTO[], newSection?: number) => {
      if (!checklistId) return;
      const updateChecklist = checklists.map((checklist) => {
        if (checklist.taskTypeCheckListId === checklistId) {
          return { ...checklist, taskTypeSections: sections };
        }
        return checklist;
      });
      setChecklists(updateChecklist, undefined, newSection);
    },
    [checklists, setChecklists]
  );

  const handleSetChecklist = useCallback(
    (checklist: TaskTypeCheckListTextDTO) => {
      const updateChecklist = checklists.map((c) =>
        c.taskTypeCheckListId === checklist.taskTypeCheckListId ? checklist : c
      );
      setChecklists(updateChecklist);
    },
    [checklists, setChecklists]
  );

  const handleSetFooter = useCallback(
    (state: FormState) => {
      switch (state) {
        case FormState.MAIN:
          setFooter(
            <>
              <StyledButton
                variant="secondary"
                onClick={() => {
                  setAddingCheklist(true);
                }}
              >
                Tilføj tjekliste
              </StyledButton>
            </>
          );
          break;
        case FormState.ADD:
          setFooter(undefined, undefined);
          break;
        case FormState.PREVIEW:
          setFooter(
            <StyledButton
              variant="secondary"
              onClick={() => {
                setChecklistState(FormState.MAIN);
                handleSetFooter(FormState.MAIN);
              }}
            >
              <ChevronLeft size="22px" />
              Tilbage
            </StyledButton>,
            <></>
          );
          break;
        default:
          log('Could not render footer.');
      }
    },
    [setFooter]
  );

  const handleSetChecklistState = useCallback(
    (state: FormState) => {
      setChecklistState(state);
      handleSetFooter(state);
    },
    [handleSetFooter]
  );

  const addQuestionToSection = useCallback(
    (questions: SectionQuestionTextDTO[], section?: TaskTypeSectionDTO) => {
      const _section = section ?? activeSection;
      const updatedSections = activeChecklist?.taskTypeSections?.map((s) => {
        if (s.sectionId === _section?.sectionId) {
          return { ...s, questions: s.questions?.concat(questions) ?? questions };
        }
        return s;
      });
      handleSetSections(activeChecklist?.taskTypeCheckListId, updatedSections ?? []);
      handleSetChecklistState(FormState.MAIN);
    },
    [
      activeChecklist?.taskTypeCheckListId,
      activeChecklist?.taskTypeSections,
      activeSection,
      handleSetChecklistState,
      handleSetSections
    ]
  );

  useEffect(() => {
    handleSetChecklistState(FormState.MAIN);
    // Only first time
  }, []);

  const createChecklist = (title: string) => {
    const id = Math.random() * 1_000_000; // Temporary ID. The section will get a new ID when it is saved and added to the DB.
    setChecklists(
      [
        ...checklists,
        {
          taskTypeCheckListId: id,
          checkListName: title,
          taskTypeSections: [],
          order: checklists.length,
          isRepeatable: false
        } as TaskTypeCheckListTextDTO
      ],
      id
    );
    setAddingCheklist(false);
  };

  const deleteChecklist = (checklist: TaskTypeCheckListTextDTO) => {
    const _checklist = checklists.filter((c) => c.taskTypeCheckListId !== checklist.taskTypeCheckListId);
    setChecklists(_checklist);
  };

  const swapChecklists = (index1: number, index2: number) => {
    if (index1 === checklists.length || index2 === checklists.length || index1 === -1 || index2 === -1) return;
    const array = [...checklists];
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
    setChecklists(array);
  };

  return (
    <>
      {checklistState === FormState.MAIN && (
        <>
          {checklists.map((checklist, index) => (
            <Checklist
              index={index}
              checklist={checklist}
              setSections={(sections, newSection) => handleSetSections(checklist.taskTypeCheckListId, sections, newSection)}
              allQuestions={allQuestions}
              setAddQuestionToSection={(section) => setAddQuestionToSection(section, checklist as ChecklistDTO)}
              setChecklist={(checklist) => handleSetChecklist(checklist)}
              setPreview={() => {
                handleSetChecklistState(FormState.PREVIEW);
                setActiveChecklist(checklist);
              }}
              deleteChecklist={() => deleteChecklist(checklist)}
              swapChecklists={(index1, index2) => swapChecklists(index1, index2)}
            />
          ))}
          {addingChecklist && (
            <AddTitle
              createTitle={(title) => createChecklist(title)}
              resetTitle={() => setAddingCheklist(false)}
              label={'Tjekliste'}
            />
          )}
        </>
      )}
      {checklistState === FormState.ADD && (
        <AddQuestion
          activeSection={activeSection}
          onBack={() => handleSetChecklistState(FormState.MAIN)}
          addQuestions={(questions: SectionQuestionTextDTO[]) => addQuestionToSection(questions)}
          allQuestions={allQuestions}
        />
      )}
      {checklistState === FormState.PREVIEW && (
        <Preview
          onBack={() => handleSetChecklistState(FormState.MAIN)}
          sections={activeChecklist?.taskTypeSections ?? []}
          questions={allQuestions}
          title={activeChecklist?.checkListName ?? ''}
          isRepeatable={activeChecklist?.isRepeatable}
        />
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  margin-right: ${(props) => props.theme.spacing(2)};
`;

export default TaskTypeChecklists;
