import styled, { css } from 'styled-components';
import Typography from '../typography/Typography';

interface Props {
  variant?: 'primary' | 'secondary';
  handleChange: () => void;
  id: string;
  checked: boolean;
  label?: string;
}

const Toggle = (props: Props) => {
  const { handleChange, id, checked, variant = 'primary', label } = props;

  const handleOnChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    handleChange();
  };

  return (
    <>
      <Label variant={variant}>
        <Input type="checkbox" id={id} onChange={handleOnChange} checked={checked} data-testid={'togglecheckbox-' + id} />
        <Span variant={variant} />
      </Label>
      {label && <Typography variant="h5">{label}</Typography>}
    </>
  );
};

const Label = styled.label<{ variant: 'primary' | 'secondary' }>`
  position: relative;
  right: 0;
  display: inline-block;
  width: ${(props) => (props.variant === 'primary' ? '62px' : '52px')};
  height: ${(props) => (props.variant === 'primary' ? '36px' : '24px')};
`;

const Span = styled.span<{ variant: 'primary' | 'secondary' }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.palette.grey.black10};

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: 0.1s;
    transition: 0.1s;
  }

  input:checked + &:before {
    transform: translateX(18px);
  }

  -webkit-tap-highlight-color: transparent;

  ${(props) => props.variant === 'primary' && primaryStyles};
  ${(props) => props.variant === 'secondary' && secondaryStyles};
`;

const Input = styled.input`
  opacity: 0;
  width: 0px;
  height: 0px;

  &:checked {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

const primaryStyles = css`
  width: 52px;
  border-radius: 34px;

  border: 1px solid ${(props) => props.theme.palette.grey.black40};

  &:before {
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
  }

  input:checked + & {
    background-color: ${(props) => props.theme.palette.main.yellow.primary};
  }
`;

const secondaryStyles = css`
  width: 40px;
  border-radius: 34px;

  border: 1px solid ${(props) => props.theme.palette.grey.black20};

  &:before {
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
  }

  input:checked + & {
    background-color: ${(props) => props.theme.palette.functions.action.primary};
  }
`;

export default Toggle;
