import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CategoryDTO2, ProjectDTO2 } from '../../api/api';

interface TimeRegistrationState {
  numberOfNotApprovedRegistrations: number;
  timeRegistration?: TimeRegistrationSO;
}

export interface TimeRegistrationSO {
  id?: number;
  date?: string;
  legalEntityId?: string;
  project?: ProjectDTO2;
  category?: CategoryDTO2;
  description?: string;
  hours?: number;
  workTaskId?: number;
  email?: string;
  categoryGroupId?: string;
  timeRegAction?: TimeRegAction;
}

export type TimeRegAction = 'add' | 'edit';

const initialState: TimeRegistrationState = {
  numberOfNotApprovedRegistrations: 0
};

const timeRegistrationSlice = createSlice({
  name: 'timeRegistration',
  initialState: initialState,
  reducers: {
    setNumberOfNotApprovedRegistrations: (state: TimeRegistrationState, action: PayloadAction<number>) => {
      state.numberOfNotApprovedRegistrations = action.payload;
    },
    addTimeRegistration: (state: TimeRegistrationState, action: PayloadAction<TimeRegistrationSO>) => {
      state.timeRegistration = { timeRegAction: 'add', ...action.payload };
    },
    editTimeRegistration: (state: TimeRegistrationState, action: PayloadAction<TimeRegistrationSO>) => {
      state.timeRegistration = { timeRegAction: 'edit', ...action.payload };
    },
    clearTimeRegistration: (state: TimeRegistrationState) => {
      state.timeRegistration = undefined;
    }
  }
});

export const { setNumberOfNotApprovedRegistrations, addTimeRegistration, editTimeRegistration, clearTimeRegistration } =
  timeRegistrationSlice.actions;

export const getNumberOfNotApprovedRegistrations = (state: RootState) =>
  state.timeRegistration.numberOfNotApprovedRegistrations;
export const selectTimeRegistration = (state: RootState) => state.timeRegistration.timeRegistration;

export default timeRegistrationSlice.reducer;
