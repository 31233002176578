import styled from 'styled-components';

import { ChevronLeft } from '../../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../../assets/icons/ChevronRight';
import IconButton from '../../icon-button/IconButton';
import { DateState } from '../DatePicker';
import { DateState as DateRangeState } from '../../date-range-picker/DateRangePicker';
import { CALENDAR_MONTHS } from '../helpers';
import { useCallback } from 'react';

let pressureTimer: any;
let pressureTimeout: any;

interface Props {
  dateState: DateState | DateRangeState;
  gotoPreviousYear: () => void;
  gotoNextYear: () => void;
  gotoPreviousMonth: () => void;
  gotoNextMonth: () => void;
}

const DatePickerHeader = (props: Props) => {
  const { month, year } = props.dateState;
  const { gotoPreviousYear, gotoNextYear, gotoPreviousMonth, gotoNextMonth } = props;

  const handlePressure = useCallback((fn: any) => {
    if (typeof fn === 'function') {
      fn();
      pressureTimeout = setTimeout(() => {
        pressureTimer = setInterval(fn, 100);
      }, 500);
    }
  }, []);

  const clearPressureTimer = useCallback(() => {
    pressureTimer && clearInterval(pressureTimer);
    pressureTimeout && clearTimeout(pressureTimeout);
  }, []);

  const handlePrevious = useCallback(
    (evt: any) => {
      evt && evt.preventDefault();
      const fn = evt.shiftKey ? gotoPreviousYear : gotoPreviousMonth;
      handlePressure(fn);
    },
    [gotoPreviousYear, gotoPreviousMonth, handlePressure]
  );

  const handleNext = useCallback(
    (evt: any) => {
      evt && evt.preventDefault();
      const fn = evt.shiftKey ? gotoNextYear : gotoNextMonth;
      handlePressure(fn);
    },
    [gotoNextYear, gotoNextMonth, handlePressure]
  );

  const monthname = Object.values(CALENDAR_MONTHS)[Math.max(0, Math.min((month ?? 0) - 1, 11))];

  return (
    <CalendarHeader>
      <IconButton onMouseDown={handlePrevious} onMouseUp={clearPressureTimer}>
        <ChevronLeft size="16px" />
      </IconButton>
      <CalendarMonth>
        {monthname} {year}
      </CalendarMonth>
      <IconButton onMouseDown={handleNext} onMouseUp={clearPressureTimer}>
        <ChevronRight size="16px" />
      </IconButton>
    </CalendarHeader>
  );
};

const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
`;

const CalendarMonth = styled.div`
  ${(props) => ({ ...props.theme.typography.h5 })};
  font-weight: 700;
  text-align: center;
  user-select: none;
`;

export default DatePickerHeader;
