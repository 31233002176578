import { ChecklistDTO, IsRepeatableEnum, QandADTO, QuestionOptionDTO, QuestionType, SectionDTO } from '../../../../api/api';
import { TaskChecklist } from '../../../../models/TaskChecklist';
import { Question } from '../../../../models/Question';
import { Section } from '../../../../models/Section';

export const ConvertChecklistDTOToTaskChecklist = (dto: ChecklistDTO): TaskChecklist => {
  let dependableSections = new Map<number, { questionType: QuestionType; sections: number[] }>();
  let checklist: TaskChecklist = {
    ...dto,
    workTaskCheckListSections:
      dto.workTaskCheckListSections !== undefined
        ? dto.workTaskCheckListSections
            .sort((a, b) => {
              if ((a.order || a.order === 0) && (b.order || b.order === 0)) {
                if (a.order !== b.order) {
                  return a.order - b.order;
                } else if (a.taskSectionId && b.taskSectionId) {
                  return a.taskSectionId - b.taskSectionId;
                }
              }
              return 0;
            })
            .map((sectionDTO, index) => {
              let shouldShowSection = true;
              if (sectionDTO.dependsOnQuestionAnswerId != undefined && dto.workTaskCheckListSections !== undefined) {
                dto.workTaskCheckListSections.forEach((x) => {
                  const dependantOnAnswer = x.answerDTOList?.find(
                    (a) => a.answerId === sectionDTO.dependsOnQuestionAnswerId
                  );
                  if (dependantOnAnswer !== undefined) {
                    //Add section to Map containing answerId as key and an array of sectionIds as value containing all sections depending on the answer.

                    const value = dependableSections.get(sectionDTO.dependsOnQuestionAnswerId ?? 0);

                    //Add if already an entry...
                    if (value !== undefined) {
                      dependableSections.set(sectionDTO.dependsOnQuestionAnswerId ?? 0, {
                        ...value,
                        sections: [...value.sections, sectionDTO.taskSectionId ?? 0]
                      });
                    }
                    //...or add a new entry
                    else {
                      dependableSections.set(sectionDTO.dependsOnQuestionAnswerId ?? 0, {
                        questionType: dependantOnAnswer.questionType as QuestionType,
                        sections: [sectionDTO.taskSectionId ?? 0]
                      });
                    }

                    //Decide if section should be shown initially
                    shouldShowSection = getShouldShowSection(
                      dependantOnAnswer.questionType as QuestionType,
                      sectionDTO.dependsOnQuestionOptionInvert ?? false,
                      dependantOnAnswer.answerIntValue,
                      sectionDTO.dependsOnQuestionOptionId
                    );
                  }
                });
              }

              let section = convertSectionDTOToSection(sectionDTO);
              section.preconditionFilled = shouldShowSection;

              section.questions = !sectionDTO.answerDTOList ? [] : convertAnswerDTOToQuestions(sectionDTO.answerDTOList);
              return section;
            })
        : []
  };

  checklist.dependableSections = dependableSections;

  return checklist;
};

export const convertAnswerDTOToQuestions = (dtos: QandADTO[]): Question[] => {
  return dtos.map((q: QandADTO): Question => {
    return {
      id: q.answerId,
      sectionQuestionId: q.sectionQuestionId,
      mandatory: q.isMandatory ?? false,
      text: q.questionText ?? '',
      helpText: q.questionHelpText,
      dataType: (q.questionType as QuestionType) ?? 'Text',
      value:
        q.questionType === QuestionType.Image
          ? getImageValue(q)
          : q.answerTextValue || q.answerIntValue || q.answerDoubleValue,
      options: q.questionOptionsDTOs?.map((o: QuestionOptionDTO) => {
        return {
          displayValue: o.questionOptionText ?? '',
          value: o.id ?? 0
        };
      }),
      link: q.link,
      linkText: q.linkText,
      updatedByEmail: q.answerUpdatedByEmail,
      timeStampValue: q.answerTimeStampValue
    };
  });
};

export const convertSectionDTOToSection = (dto: SectionDTO): Section => {
  return {
    header: dto.headerText ?? '',
    sectionId: dto.sectionId ?? 0,
    taskSectionId: dto.taskSectionId,
    isRepeatable: dto.isRepeatable ?? false,
    isRepeatable2: dto.isRepeatable2 ?? IsRepeatableEnum.NotRepeatable,
    dependsOnAnswerId: dto.dependsOnQuestionAnswerId,
    dependsOnSectionQuestionId: dto.dependsOnSectionQuestionId,
    dependsOnQuestionOptionId: dto.dependsOnQuestionOptionId,
    dependsOnQuestionOptionInvert: dto.dependsOnQuestionOptionInvert,
    order: dto.order,
    isCompleted: dto.isCompleted
  } as Section;
};

const getImageValue = (question: QandADTO) => {
  if (question.fileAnswerDTOs?.length !== 0) {
    return question.fileAnswerDTOs;
  } else if (question.answerFilenameValue) {
    return [
      {
        fileName: question.answerFilenameValue,
        uploadedTime: question.answerTimeStampValue
      }
    ];
  } else {
    return [];
  }
};

export const getShouldShowSection = (
  type: QuestionType,
  sectionDependsOnQuestionOptionInvert: boolean,
  value?: number,
  sectionDependsOnQuestionOptionId?: number
): boolean => {
  if (type === QuestionType.Confirm) {
    return sectionDependsOnQuestionOptionInvert ? value !== 1 : value === 1;
  }
  if (type === QuestionType.SingleChoice) {
    return sectionDependsOnQuestionOptionInvert
      ? value !== sectionDependsOnQuestionOptionId
      : value === sectionDependsOnQuestionOptionId;
  }

  return true;
};
