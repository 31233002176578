import { useMemo } from 'react';
import { Column } from 'react-table';
import useTableInstance from '../../../hooks/useTableInstance';
import { TransferItemDTO } from '../../../api/api';

const useTransferOrderList = (items: TransferItemDTO[]) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Varenummer',
        accessor: 'itemId',
        width: 80,
        disableFilters: true
      },
      {
        Header: 'Varenavn',
        accessor: 'name',
        width: 200,
        disableFilters: true
      },
      {
        Header: 'Antal',
        accessor: 'qty',
        width: 100,
        disableFilters: true
      }
    ] as Column<TransferItemDTO>[];
  }, [items]);

  const tableInstance = useTableInstance<TransferItemDTO>(items, columns);
  return { tableInstance };
};

export default useTransferOrderList;
