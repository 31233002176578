import {
  CreatePortalLinkDto,
  PortalLinkDto,
  SaveProfileDTO2,
  TaskListConfigurationDTO2,
  TaskListCustomFilterDTO,
  TaskListCustomFilterDTO2,
  TaskListCustomFilterDTO2Type,
  UpdateProfileDTO2
} from '../api/api';
import WorkClient from '../api/workClient';

const getAllKnownUsers = () => {
  return WorkClient.getUsersAllKnown();
};

const getProfile = () => {
  return WorkClient.getProfile3();
};

const saveProfile = (body: SaveProfileDTO2 | undefined) => {
  return WorkClient.saveProfile2(body);
};

const updateProfile = (body: UpdateProfileDTO2 | undefined) => {
  return WorkClient.updateProfile(body);
};

const getTaskListColumConfiguration = () => {
  return WorkClient.getTaskListColumnConfigurations();
};

const setTaskListColumnConfiguration = (body: TaskListConfigurationDTO2 | undefined) => {
  return WorkClient.saveTaskListColumnConfigurations(body);
};

const getTaskListCustomFilters = () => {
  return WorkClient.getTaskListFilters2();
};

const addTaskListCustomFilter = (filter: TaskListCustomFilterDTO2, departmentId?: number) => {
  if (
    filter.type === TaskListCustomFilterDTO2Type.Department ||
    filter.type === TaskListCustomFilterDTO2Type.DepartmentAllTasks
  ) {
    return WorkClient.departmentFilter2(departmentId, filter);
  }
  return WorkClient.filter3(filter);
};

const deleteTaskListCustomFilter = (filter: string, isDepartmentFilter: boolean, departmentId?: number) => {
  if (isDepartmentFilter) {
    return WorkClient.taskListDepartmentFilter(filter, departmentId);
  }
  return WorkClient.filter(filter);
};

const updateTaskListCustomFilter = (filter: TaskListCustomFilterDTO, isDepartmentFilter: boolean, departmentId?: number) => {
  if (isDepartmentFilter) {
    return WorkClient.updateTaskListCustomDepartmentFilter(departmentId, filter);
  }
  return WorkClient.updateTaskListCustomUserFilter(filter);
};

const selectTaskListCustomFilter = (filter?: string, departmentId?: number) => {
  return WorkClient.setSelectedFilter(filter, departmentId);
};

const selectTaskListAllCustomFilter = (filter?: string, departmentId?: number) => {
  return WorkClient.setSelectedAllTasksFilter(filter, departmentId);
};

const getAllPortalLinks = () => {
  return WorkClient.getAllPortalLinks();
};

const createPortalLink = (portalLink: CreatePortalLinkDto) => {
  return WorkClient.createPortalLink(portalLink);
};

const editPortalLink = (id: number, portalLink: CreatePortalLinkDto) => {
  return WorkClient.updatePortalLink(id, portalLink);
};

const updateOrderNumbers = (portalLinks: PortalLinkDto[]) => {
  return WorkClient.updateOrderNumbers(portalLinks);
};

const deletePortalLink = (id: number) => {
  return WorkClient.deletePortalLink(id);
};

const getUser = (email: string) => {
  return WorkClient.getProfile(email);
};

const UserService = {
  getAllKnownUsers,
  getProfile,
  saveProfile,
  updateProfile,
  getTaskListColumConfiguration,
  setTaskListColumnConfiguration,
  getTaskListCustomFilters,
  addTaskListCustomFilter,
  deleteTaskListCustomFilter,
  updateTaskListCustomFilter,
  selectTaskListCustomFilter,
  selectTaskListAllCustomFilter,
  getAllPortalLinks,
  createPortalLink,
  editPortalLink,
  deletePortalLink,
  updateOrderNumbers,
  getUser
};

export default UserService;
