import { WorkInvoiceDTO, UpdateWorkInvoiceDTO } from '../../../../api/api';

export const workInvoiceInfoPopulated = (workInvoiceInfo?: UpdateWorkInvoiceDTO, unknownDebtor?: boolean) => {
  if (unknownDebtor) return true;
  if (!workInvoiceInfo) return false;
  if (
    !workInvoiceInfo.cvrNo &&
    !workInvoiceInfo.vehicleRegNo &&
    !workInvoiceInfo.policeCaseNo &&
    !workInvoiceInfo.contactPersonName &&
    !workInvoiceInfo.contactPersonPhoneNumber &&
    !workInvoiceInfo.contactPersonAddress
  )
    return false;

  return true;
};

export const workInvoiceResponseDTOPopulated = (workInvoiceDTO?: WorkInvoiceDTO) => {
  if (!workInvoiceDTO) return false;

  const workInvoice = {
    cvrNo: workInvoiceDTO.cvrNo,
    vehicleRegNo: workInvoiceDTO.vehicleRegNo,
    policeCaseNo: workInvoiceDTO.policeCaseNo,
    contactPersonName: workInvoiceDTO.contactPersonName,
    contactPersonPhoneNumber: workInvoiceDTO.contactPersonPhoneNumber,
    contactPersonAddress: workInvoiceDTO.contactPersonAddress
  } as UpdateWorkInvoiceDTO;

  const unknownDebtor = workInvoiceDTO.unknownDebtor;

  return workInvoiceInfoPopulated(workInvoice, unknownDebtor);
};
