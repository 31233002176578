import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ComponentAttributeLabelDTO, ReuseableComponentAttributeDTO, TaskComponentDTO } from '../../../../../api/api';
import Table from '../../../../../blocks/table/TableClient';

import useAddFieldTable from './useAddFieldTable';
import { StyledBackButtonIcon } from '../../AdminTaskTypesDialog';
import Typography from '../../../../../components/typography/Typography';
import Button from '../../../../../components/button/Button';
import { ChevronLeft } from '../../../../../assets/icons/ChevronLeft';
interface Props {
  sections: TaskComponentDTO[];
  setSection: (section: TaskComponentDTO) => void;
  activeSection?: TaskComponentDTO;
  allAttributes: ReuseableComponentAttributeDTO[];
  onBack: () => void;
}
const AddField = (props: Props) => {
  const { sections, setSection, activeSection, allAttributes, onBack } = props;

  const [updatedSection, setUpdatedSection] = useState(activeSection);

  const attributeClickedHandler = useCallback(
    (attribute: ReuseableComponentAttributeDTO, checked: boolean) => {
      if (!attribute || !attribute.id) return;

      if (checked) {
        const newAttribute: ComponentAttributeLabelDTO = {
          attributeId: attribute.id,
          order: 0,
          isEditable: false,
          isMandatory: false,
          hintText: attribute.hintText,
          label: attribute.label
        };

        updatedSection?.attributes?.push(newAttribute);
        setUpdatedSection({ ...updatedSection });
      } else {
        const _updatedSection = {
          ...updatedSection,
          attributes: updatedSection?.attributes?.filter((a) => a.attributeId !== attribute.id) ?? []
        };
        setUpdatedSection(_updatedSection);
      }
    },
    [updatedSection]
  );

  // The attributes which are about to be updated
  const previousAttributes = useMemo(() => {
    if (!activeSection?.id) return [];
    return sections.find((section) => section.id === activeSection.id)?.attributes ?? [];
  }, [sections, activeSection?.id]);

  const { tableInstance } = useAddFieldTable(allAttributes, attributeClickedHandler, previousAttributes, updatedSection);

  return (
    <>
      <Header>
        <StyledBackButtonIcon onClick={() => onBack()}>
          <ChevronLeft size="22px" />
        </StyledBackButtonIcon>
        <Typography variant="h4">Tilføj typefelt til {activeSection?.title}</Typography>
      </Header>
      <StyledTable showPagination noPadding loading={false} tableInstance={tableInstance} alwaysShowSort />
      <StyledButton variant="primary" onClick={() => setSection(updatedSection ?? {})}>
        Tilføj typefelter
      </StyledButton>
    </>
  );
};
const Header = styled.div`
  display: flex;
`;

const StyledTable = styled(Table)`
  position: relative;
  top: 20px;
  height: 80%;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-self: flex-end;
`;

export default AddField;
