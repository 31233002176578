import { memo } from 'react';
import styled from 'styled-components';

interface Props {
  getCellProps: any;
  render: any;
}

const Cell = (props: Props) => {
  const { getCellProps, render } = props;

  return <TableData {...getCellProps()}>{render('Cell')}</TableData>;
};

export const TableData = styled.td`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export default memo(Cell);
