import { useDispatch } from 'react-redux';
import { BaseWorkTaskTypeEnum, WorkTaskStatus } from '../api/api';
import { closeTaskStatusDialog, openTaskStatusDialog } from '../stateManagement/reducers/taskStatusDialogReducer';

let resolveCallback: (value: PromiseLike<WorkTaskStatus> | undefined | WorkTaskStatus) => void;
export const useUpdateTaskStateDialog = () => {
  const dispatch = useDispatch();

  const updateTaskStatus = (newStatus: WorkTaskStatus) => {
    resolveCallback(newStatus);
    dispatch(closeTaskStatusDialog());
  };

  const cancelUpdateTaskStatus = () => {
    resolveCallback(undefined);
    dispatch(closeTaskStatusDialog());
  };

  const getTaskStatusUpdateDialog = (
    taskId: number,
    baseWorkTaskType: BaseWorkTaskTypeEnum,
    taskTypeId: number,
    taskFlsId: number,
    showMultiDay: boolean,
    taskStatus: WorkTaskStatus
  ) => {
    dispatch(
      openTaskStatusDialog({
        taskId,
        baseWorkTaskType,
        taskTypeId,
        taskFlsId,
        hasFuturePartialTask: showMultiDay,
        taskStatus
      })
    );

    return new Promise<WorkTaskStatus | undefined>((res, rej) => {
      resolveCallback = res;
    });
  };

  return { getTaskStatusUpdateDialog, updateTaskStatus, cancelUpdateTaskStatus };
};
