import styled from 'styled-components';
import DialogView from '../../../components/dialog-view/DialogView';
import TextField from '../../../components/text-field/TextField';
import { useEffect, useState } from 'react';
import Select, { MenuItem } from '../../../components/select/Select';
import { DialogContent } from '@mui/material';
import Button from '../../../components/button/Button';
import { AddIcon } from '../../../assets/icons/AddIcon';
import NavigationHeader from '../../goods-view/components/NavigationHeader';
import IconButton from '../../../components/icon-button/IconButton';
import { DeleteIcon } from '../../../assets/icons/DeleteIcon';
import NotificationService from '../../../services/NotificationService';
import { getEnumDisplayValue } from '../../../utils/enumUtils';
import {
  CreateNewsDTO,
  CreateNewsDTONewsType,
  NewsDTO,
  NewsDTONewsTarget,
  NewsDTONewsType,
  UpdateNewsDTO,
  UpdateNewsDTONewsTarget,
  CreateNewsDTONewsTarget,
  UpdateNewsDTONewsType
} from '../../../api/api';
import NewsService from '../../../services/NewsService';
import { log } from '../../../utils/logging/log';
import Checkbox from '../../../components/checkbox/Checkbox';
import RichText from '../../../components/rich-text/RichText';

interface Props {
  showDialog: boolean;
  setShowDialog: (value: boolean) => void;
  createNewsObjectCallback: (newstListObject: NewsDTO) => void;
  editNewsObjectCallback: (newstListObject: NewsDTO) => void;
  editingNews?: NewsDTO;
  resetEditingNews: () => void;
}

const AdminCreateNewsDialog = (props: Props) => {
  const { showDialog, setShowDialog, createNewsObjectCallback, editingNews, editNewsObjectCallback, resetEditingNews } =
    props;
  const [header, setHeader] = useState('');
  const [text, setText] = useState('');
  const [type, setType] = useState<NewsDTONewsType | CreateNewsDTONewsType | UpdateNewsDTONewsType>(
    NewsDTONewsType.Standard
  );
  const [target, setTarget] = useState<NewsDTONewsTarget | CreateNewsDTONewsTarget | UpdateNewsDTONewsTarget>(
    NewsDTONewsTarget.All
  );

  const [link, setLink] = useState('');
  const [showLink, setShowLink] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [notifyUsers, setNotifyUsers] = useState(false);

  useEffect(() => {
    editingNews?.title && setHeader(editingNews.title);
    editingNews?.description && setText(editingNews.description);
    editingNews?.newsType && setType(editingNews.newsType);
    editingNews?.newsTarget && setTarget(editingNews.newsTarget);
    editingNews?.link && setShowLink(!!editingNews.link);
    editingNews?.link && setLink(editingNews.link ?? '');
  }, [editingNews]);

  const createNews = () => {
    const body: CreateNewsDTO = {
      title: header,
      description: text,
      newsType: type as CreateNewsDTONewsType,
      newsTarget: target as CreateNewsDTONewsTarget,
      link,
      notifyUsers
    };

    NewsService.createNews(body)
      .then((response) => {
        createNewsObjectCallback(response);
        onClose();
      })
      .catch((e) => {
        log(e);
        NotificationService.error('Kunne ikke oprette nyhed');
      });
  };

  const editNews = () => {
    if (!editingNews?.id) return;

    const body: UpdateNewsDTO = {
      id: editingNews?.id,
      title: header,
      description: text,
      link,
      newsType: type as UpdateNewsDTONewsType,
      newsTarget: target as UpdateNewsDTONewsTarget
    };

    NewsService.updateNews(body)
      .then(() => {
        NotificationService.success('Nyheden blev opdateret');
        editNewsObjectCallback({
          id: editingNews.id,
          title: body.title,
          description: body.description,
          link: body.link,
          newsType: type as NewsDTONewsType,
          newsTarget: target as NewsDTONewsTarget,
          lastUpdated: editingNews.lastUpdated
        });
        onClose();
      })
      .catch((e) => {
        log(e);
        NotificationService.error('Kunne ikke opdatere nyhed');
      });
  };

  const removeLink = () => {
    setLink('');
    setShowLink(false);
  };

  useEffect(() => {
    if (link?.match(/^http(s)?:\/\//) || !link) {
      setErrorText('');
    } else {
      setErrorText('Links skal starte med http:// eller https://');
    }
  }, [link]);

  const onClose = () => {
    setHeader('');
    setText('');
    setType(NewsDTONewsType.Standard);
    setTarget(NewsDTONewsTarget.All);
    setShowLink(false);
    setLink('');
    setNotifyUsers(false);
    setShowDialog(false);
    resetEditingNews();
  };

  return (
    <DialogView open={showDialog}>
      <NavigationHeader headerTitle={editingNews ? 'Rediger nyhed' : 'Ny nyhed'} onClose={onClose} />
      <StyledDialogContent>
        <Row>
          <TextField fullWidth label="Skriv overskrift" value={header} onChange={(e) => setHeader(e.target.value)} />
        </Row>
        <Row>
          <RichTextContainer>
            <RichText value={text} onChange={(value) => setText(value)} />
          </RichTextContainer>
        </Row>
        <Row>
          <Select onChange={(e) => setType(e.target.value as NewsDTONewsType)} value={type} label="Nyhedstype" required>
            {Object.keys(NewsDTONewsType).map((type) => (
              <MenuItem key={type} value={type}>
                {getEnumDisplayValue(type as NewsDTONewsType)}
              </MenuItem>
            ))}
          </Select>
        </Row>
        <Row>
          <Select onChange={(e) => setTarget(e.target.value as NewsDTONewsTarget)} value={target} label="Modtagere" required>
            {Object.keys(NewsDTONewsTarget).map((target) => (
              <MenuItem key={target} value={target}>
                {getEnumDisplayValue(target as NewsDTONewsTarget)}
              </MenuItem>
            ))}
          </Select>
          <StyledCheckbox
            checked={notifyUsers}
            onChange={(e) => setNotifyUsers(e.target.checked)}
            label="Notificer bruger"
          />
        </Row>
        <Row>
          {showLink ? (
            <LinkContainer>
              <TextField
                helperText={errorText}
                error={!!errorText}
                label="Link til beskeden"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                fullWidth
              />
              <IconButton onClick={removeLink}>
                <DeleteIcon size="20px" />
              </IconButton>
            </LinkContainer>
          ) : (
            <Button onClick={() => setShowLink(true)} variant="secondary">
              <AddIcon size="20px" />
              Tilføj link
            </Button>
          )}
        </Row>
        <Row>
          <Button onClick={editingNews ? editNews : createNews}>{editingNews ? 'Rediger besked' : 'Send besked'}</Button>
        </Row>
      </StyledDialogContent>
    </DialogView>
  );
};

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  width: 50vw;
  max-height: 70vh;
  overflow-y: scroll;
`;

const Row = styled.div`
  display: flex;
  margin: 15px 20px;
`;
const LinkContainer = styled.div`
  display: flex;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 20px;
`;

const RichTextContainer = styled.div`
  height: 200px;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing(10)};
`;

export default AdminCreateNewsDialog;
