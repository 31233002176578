import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { TransferOrderDTO, WorkTaskStatus } from '../../../../api/api';
import useTableInstance from '../../../../hooks/useTableInstance';
import { formatDateString } from '../../../../utils/dateHandling';
import Button from '../../../../components/button/Button';
import { useDispatch } from 'react-redux';
import { setSelectedOrder } from '../../../../stateManagement/reducers/returnGoodsReducer';

interface TransferOrderItem {
  status: WorkTaskStatus;
  createdAt: string;
  orderId: string;
  numberOfGoods: number;
}

export const useReturnOrdersTable = (tansferOrders: TransferOrderDTO[], handleOpenReceipt: () => void) => {
  const dispatch = useDispatch();
  const data = useMemo(() => {
    return tansferOrders.map((order) => {
      return {
        status: order.transferStatus,
        createdAt: formatDateString(order.receiveDate ?? ''),
        numberOfGoods: order.items?.length,
        orderId: order.transferId
      } as TransferOrderItem;
    });
  }, [tansferOrders]);

  const setSelectedTransferOrder = (id: string) => {
    const order = tansferOrders.find((order) => order.transferId === id);
    if (order) {
      dispatch(setSelectedOrder(order));
      handleOpenReceipt();
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Status',
        accessor: 'status',
        width: 120,
        disableFilters: true
      },
      {
        Header: 'Oprettet',
        accessor: 'createdAt',
        width: 120,
        disableFilters: true
      },
      {
        Header: 'Ordre ID',
        accessor: 'orderId',
        width: 120,
        disableFilters: true
      },
      {
        Header: 'Antal varer',
        accessor: 'numberOfGoods',
        width: 120,
        disableFilters: true
      },
      {
        Header: '',
        accessor: 'button',
        width: 120,
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<TransferOrderItem>) => {
          const { cell } = cellProps;
          return (
            <Button variant="secondary" onClick={() => setSelectedTransferOrder(cell.row.original.orderId)}>
              Se returordre
            </Button>
          );
        }
      }
    ] as Column<TransferOrderItem>[];
  }, [data]);

  const tableInstance = useTableInstance<TransferOrderItem>(data, columns);

  return { tableInstance };
};
