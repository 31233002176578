import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { BaseWorkTaskTypeEnum, TechnicianScheduleDTO, WorkTaskStatus } from '../../api/api';
import DatePicker from '../../components/date-picker/DatePicker';
import { useNavigateToTask } from '../../hooks/useNavigateToTask';
import TaskService from '../../services/TaskService';
import { formatDateString } from '../../utils/dateHandling';

const ScheduleView = () => {
  const [width, setWidth] = useState(100);
  const [data, setData] = useState<TechnicianScheduleDTO[]>();

  const stageRef = useRef<HTMLInputElement>(null);
  const navigateToTask = useNavigateToTask();

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  // start day at 06:00, thus time part is thrown away and 6 hours added.
  const startOfDayMs = new Date(selectedDate.toDateString()).getTime() + 21600000;
  // sets the "wideness" of the day and blocks
  const dayInMs = 86400000 / 2.0;

  useEffect(() => {
    if (stageRef.current) {
      setWidth(stageRef.current.offsetWidth);
    }
    TaskService.getSchedule(selectedDate.toISOString()).then((response: TechnicianScheduleDTO[]) => {
      setData(response);
    });
  }, [stageRef]);

  const handleDateChange = useCallback(
    (date: Date) => {
      setSelectedDate(date);
      TaskService.getSchedule(date.toISOString()).then((response: TechnicianScheduleDTO[]) => {
        const events = response as TechnicianScheduleDTO[];
        setData(events);
      });
    },
    [setSelectedDate]
  );

  const handleOnClickTask = useCallback(
    (workTaskId: number | undefined, baseWorkTaskType: BaseWorkTaskTypeEnum | undefined) => {
      if (workTaskId === undefined || baseWorkTaskType === undefined) return;
      navigateToTask(workTaskId, baseWorkTaskType);
    },
    [navigateToTask]
  );

  const getColorFromStats = (status: WorkTaskStatus | undefined) => {
    let color = '#a3a3a3';
    switch (status) {
      case WorkTaskStatus.Created:
        color = '#8266E6';
        break;
      case WorkTaskStatus.ReadyForPlanning:
        color = '#557FFE';
        break;
      case WorkTaskStatus.Planned:
        color = '#FFBB89';
        break;
      case WorkTaskStatus.PlannedAppointment:
        color = '#FF7A19';
        break;
      case WorkTaskStatus.OnRoute:
        color = '#FFEE52';
        break;
      case WorkTaskStatus.Ongoing:
        color = '#79C240';
        break;
      case WorkTaskStatus.Pause:
        color = '#DC4040';
        break;
      case WorkTaskStatus.Completed:
        color = '#60935D';
        break;
      case WorkTaskStatus.Processed:
        color = '#C7C7C7';
        break;
      }
    return color;
  }

  return (
    <div>
    <DatePicker value={formatDateString(selectedDate.toISOString())} label="Dato" onDateChanged={(date) => handleDateChange(date)} disableWeekends />
    <div id="div1" ref={stageRef} style={{ overflow: 'scroll' }}>
      {data &&
        data.map((t) => {
          return (
            <StyledContainer>
              <NameText>{t.name}</NameText>
              <Timeline>
                {t.events &&
                  t.events.map((tt) => {
                    var start = tt.taskStart ? new Date(tt.taskStart) : new Date();
                    var end = tt.taskEnd ? new Date(tt.taskEnd) : new Date();
                    var plannedArrival = tt.plannedArrival ? new Date(tt.plannedArrival) : new Date();
                    var workTaskId = tt.workTaskId ?? '-';

                    // Warning: Spicy math below!
                    return (
                      <div>
                        <TimeSlot
                          style={{
                            background: '#aaaaaa',
                            left: ((width - 400) / dayInMs) * (start.getTime() - startOfDayMs),
                            width: ((width - 400) / dayInMs) * (plannedArrival.getTime() - start.getTime())
                          }}
                        ></TimeSlot>
                        <TimeSlot
                          onClick={() => handleOnClickTask(tt.workTaskId, tt.baseWorkTaskType)}
                          style={{
                            background: (getColorFromStats(tt.status)),
                            left: ((width - 400) / dayInMs) * (plannedArrival.getTime() - startOfDayMs),
                            width: ((width - 400) / dayInMs) * (end.getTime() - plannedArrival.getTime())
                          }}
                        >
                          {workTaskId}
                        </TimeSlot>
                      </div>
                    );
                  })}
              </Timeline>
            </StyledContainer>
          );
        })}
    </div>
    </div>
  );
};

const StyledContainer = styled.div`
  height: 40px;
  display: flex;
  text-align: left;
  border-bottom: 1px solid #aaaaaa;
`;

const NameText = styled.div`
  width: 200px;
  margin: 10px 0;
`;

const Timeline = styled.div`
  position: relative;
  width: 100%;
`;

const TimeSlot = styled.div`
  position: absolute;
  background-color: #398587;
  height: 32px;
  margin: 4px 0;
`;

export default ScheduleView;
