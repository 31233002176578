import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SelectedGoods } from '../../models/Goods';
import { WarehouseExtendedDTO } from '../../api/api';

interface MoveGoodsState {
  fromWarehouse: WarehouseExtendedDTO | null;
  fromLocation: string;
  selectedGoods: SelectedGoods[];
  toWarehouse: string;
  toLocation: string;
}

const initialState: MoveGoodsState = {
  fromWarehouse: null,
  fromLocation: '',
  selectedGoods: [],
  toWarehouse: '',
  toLocation: ''
};

const moveGoodsSlice = createSlice({
  name: 'moveGoods',
  initialState: initialState,
  reducers: {
    setFromWarehouse: (state: MoveGoodsState, action: PayloadAction<WarehouseExtendedDTO>) => {
      state.fromWarehouse = action.payload;
    },
    setFromLocation: (state: MoveGoodsState, action: PayloadAction<string>) => {
      state.fromLocation = action.payload;
    },
    setSelectedGoods: (state: MoveGoodsState, action: PayloadAction<SelectedGoods[]>) => {
      state.selectedGoods = action.payload;
    },
    setToWarehouse: (state: MoveGoodsState, action: PayloadAction<string>) => {
      state.toWarehouse = action.payload;
    },
    setToLocation: (state: MoveGoodsState, action: PayloadAction<string>) => {
      state.toLocation = action.payload;
    }
  }
});

export const { setFromWarehouse, setFromLocation, setSelectedGoods, setToWarehouse, setToLocation } = moveGoodsSlice.actions;
export const selectFromWarehouse = (state: RootState) => state.moveGoodsReducer.fromWarehouse;
export const selectFromLocation = (state: RootState) => state.moveGoodsReducer.fromLocation;
export const selectSelectedGoods = (state: RootState) => state.moveGoodsReducer.selectedGoods;
export const selectToWarehouse = (state: RootState) => state.moveGoodsReducer.toWarehouse;
export const selectToLocation = (state: RootState) => state.moveGoodsReducer.toLocation;

export default moveGoodsSlice.reducer;
