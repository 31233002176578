import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ArrowDownRightIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
      <path d="m560-120-57-57 144-143H200v-480h80v400h367L503-544l56-57 241 241-240 240Z" />
    </IconSvg>
  );
};
