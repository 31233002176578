import { Children, cloneElement, forwardRef, useCallback, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import mergeRefs from '../../utils/react/merge-refs';

interface Props {
  /**
   * Perform the enter transition when it first mounts if `in` is also `true`.
   * @default true
   */
  appear?: boolean;
  /**
   * A single child content element.
   */
  children: React.ReactElement;
  /**
   * If `true`, the component will transition in.
   */
  in: boolean;
  /**
   * The duration for the transition in ms
   * @default 225
   */
  timeout?: number;
  /**
   * A callback that is fired, when the animation is in entering state
   */
  onEntering?: (node: HTMLElement, appearing: boolean) => void;
  /**
   * A callback that is fired, when the animation is in enter state
   */
  onEnter?: (node: HTMLElement, appearing: boolean) => void;
  /**
   * A callback that is fired, when the animation is in entered state
   */
  onEntered?: (node: HTMLElement, appearing: boolean) => void;
  /**
   * A callback that is fired, when the animation is in exiting state
   */
  onExiting?: (node: HTMLElement) => void;
  /**
   * A callback that is fired, when the animation is in exit state
   */
  onExit?: (node: HTMLElement) => void;
  /**
   * A callback that is fired, when the animation is in exited state
   */
  onExited?: (node: HTMLElement) => void;
  /**
   * If true, the node will be removed from the dom, once the animation has finished
   */
  unmountOnExit?: boolean;
}

/**
 * The Fade component is used to apply a Fade transition to the inner element using React Transition Group
 */
export const Fade = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    onExit,
    onEnter,
    children,
    onExited,
    onEntered,
    onExiting,
    onEntering,
    in: inProp,
    appear = true,
    timeout = 225,
    unmountOnExit
  } = props;

  const child = Children.only(children);

  const nodeRef = useRef<HTMLElement>(null);

  const handleEnter = useCallback(
    (appearing: boolean) => {
      const node = nodeRef.current;

      if (node) {
        node.style.opacity = '1';

        onEnter?.(node, appearing);
      }
    },
    [onEnter]
  );

  const handleEntering = useCallback(
    (appearing: boolean) => {
      const node = nodeRef.current;

      if (node) {
        node.style.opacity = '1';

        onEntering?.(node, appearing);
      }
    },
    [onEntering]
  );

  const handleEntered = useCallback(
    (appearing: boolean) => {
      const node = nodeRef.current;

      if (node) {
        node.style.opacity = '1';

        onEntered?.(node, appearing);
      }
    },
    [onEntered]
  );

  const handleExit = useCallback(() => {
    const node = nodeRef.current;

    if (node) {
      node.style.opacity = '0';

      onExit?.(node);
    }
  }, [onExit]);

  const handleExiting = useCallback(() => {
    const node = nodeRef.current;

    if (node) {
      onExiting?.(node);
    }
  }, [onExiting]);

  const handleExited = useCallback(() => {
    const node = nodeRef.current;

    if (node) {
      onExited?.(node);
    }
  }, [onExited]);

  return (
    <Transition
      in={inProp}
      appear={appear}
      timeout={timeout}
      nodeRef={nodeRef}
      onExit={handleExit}
      onEnter={handleEnter}
      onExited={handleExited}
      onEntered={handleEntered}
      onExiting={handleExiting}
      onEntering={handleEntering}
      unmountOnExit={unmountOnExit}
    >
      {(status: TransitionStatus) => {
        return cloneElement(child, {
          ...child.props,
          ref: mergeRefs((child as any).ref, nodeRef, ref),
          style: {
            opacity: 0,
            transition: `opacity ${timeout}ms ease-in-out`,
            ...child.props.style,
            visibility: status === 'exited' && !inProp ? 'hidden' : undefined
          }
        });
      }}
    </Transition>
  );
});

export default Fade;
