import WorkClient from '../api/workClient';

const getProjectsByCategory = (categoryId: string | undefined) => {
  return WorkClient.getAllProjectsByCategory(categoryId);
};

const ProjectsService = {
  getProjectsByCategory
};

export default ProjectsService;
