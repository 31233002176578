import React, { useRef } from 'react';
import styled from 'styled-components';
import { DoneIcon } from '../../assets/icons/DoneIcon';
import Button from '../../components/button/Button';
import { EraseIcon } from '../../assets/icons/EraseIcon';
import { PauseIcon } from '../../assets/icons/PauseIcon';
import SignatureCanvas from 'react-signature-canvas';

const canvasWidth = 800;
const canvasHeight = 400;

interface Props {
  handleConfirm: (dataURL: string) => void;
  handleClose: () => void;
}

const SignDialog = (props: Props) => {
  const { handleConfirm, handleClose } = props;

  const canvasRef = useRef<SignatureCanvas>(null);

  const handleSave = () => {
    const dataURL = canvasRef?.current?.toDataURL('image/png');
    dataURL && handleConfirm(dataURL);
  };

  return (
    <Container>
      <SignatureCanvas
        ref={canvasRef}
        penColor="black"
        canvasProps={{ width: canvasWidth, height: canvasHeight, style: { border: '1px solid lightgrey' } }}
      />
      <ButtonContainer>
        <Button variant="secondary" onClick={handleClose}>
          <PauseIcon size="18px" />
          Annuller
        </Button>
        <Button variant="secondary" onClick={() => canvasRef.current?.clear()}>
          <EraseIcon size="18px" />
          Ryd
        </Button>
        <Button variant="success" onClick={handleSave}>
          <DoneIcon size="18px" />
          Gem
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 16px;
  padding: 24px;
`;

export default SignDialog;
