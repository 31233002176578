import styled from 'styled-components';
import Typography from '../typography/Typography';

interface Props {
  Icon: React.ElementType;
  text: string;
}

const IconBadge = (props: Props) => {
  const { Icon, text } = props;

  return (
    <StyledIcon>
      <Icon />
      <Text variant="span">{text}</Text>
    </StyledIcon>
  );
};

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${(props) => props.theme.spacing(2)};
  background-color: ${(props) => props.theme.palette.main.black.primary};
  height: ${(props) => props.theme.spacing(5)};
  gap: ${(props) => props.theme.spacing(2)};
  padding: ${(props) => props.theme.spacing(0.5)} ${(props) => props.theme.spacing(2)};
  margin: 0px ${(props) => props.theme.spacing(2)};
`;

const Text = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: ${(props) => props.theme.spacing(3)};
`;

export default IconBadge;
