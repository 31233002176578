import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const NavigateIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
      <path d="m200-120-40-40 320-720 320 720-40 40-280-120-280 120Zm84-124 196-84 196 84-196-440-196 440Zm196-84Z" />
    </IconSvg>
  );
};
