import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface WorkPlanViewState {
  selectedTaskId?: number;
  selectedDate?: Date;
  workPlanViewShouldUpdate: boolean;
}

const initialState: WorkPlanViewState = {
  selectedDate: new Date(),
  workPlanViewShouldUpdate: false
};

const workPlanView = createSlice({
  name: 'workPlan',
  initialState: initialState,
  reducers: {
    setSelectedTaskId: (state: WorkPlanViewState, action: PayloadAction<number | undefined>) => {
      state.selectedTaskId = action.payload;
    },
    setSelectedDate: (state: WorkPlanViewState, action: PayloadAction<Date | undefined>) => {
      state.selectedDate = action.payload;
    },
    setWorkPlanShouldUpdate: (state: WorkPlanViewState, action: PayloadAction<boolean>) => {
      state.workPlanViewShouldUpdate = action.payload;
    }
  }
});

export const { setSelectedTaskId, setSelectedDate, setWorkPlanShouldUpdate } = workPlanView.actions;
export const getSelectedTaskId = (state: RootState) => state.workPlanView.selectedTaskId;
export const getSelectedDate = (state: RootState) => state.workPlanView.selectedDate;
export const getWorkPlanViewShouldUpdate = (state: RootState) => state.workPlanView.workPlanViewShouldUpdate;
export default workPlanView.reducer;
