export const removeQueryParams = (params: string | string[], subpath?: string): string => {
  const { pathname, search } = window.location;

  const urlSearchParams = new URLSearchParams(search);

  // If a subpath is given this part is removed from the string.
  const path = subpath ? pathname.substring(0, pathname.lastIndexOf(subpath)) : pathname;

  if (typeof params === 'string') {
    urlSearchParams.delete(params);
  }

  if (Array.isArray(params)) {
    params.forEach((param) => {
      urlSearchParams.delete(param);
    });
  }

  return `${path}${urlSearchParams.toString() !== '' ? '?' : ''}${urlSearchParams}`;
};

export default removeQueryParams;
