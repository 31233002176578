import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';

import { CategoryDTO2, CreateTimeRegistrationDTO, ProjectDTO2, UpdateTimeRegistrationDTO } from '../../api/api';
import { clearTimeRegistration, selectTimeRegistration } from '../../stateManagement/reducers/timeRegistrationReducer';
import { formatDateStringISO } from '../../utils/dateHandling';
import TimeRegistrationService from '../../services/TimeRegistrationService';
import NotificationService from '../../services/NotificationService';
import TextField from '../../components/text-field/TextField';
import Button from '../../components/button/Button';
import { log } from '../../utils/logging/log';
import DatePicker from '../../components/date-picker/DatePicker';
import TimePicker from '../../components/time-picker/TimePicker';
import AutoComplete from '../../components/auto-complete/AutoComplete';
import CircularProgress from '../../components/circular-progress/CircularProgress';
import RadioButtons, { RadioButtonOptions } from '../../components/radio-buttons/RadioButtons';

interface MappedProjectNumber {
  label: string;
  value: ProjectDTO2;
}

const groupOptions = [
  { label: 'Afdelingsregistrering', value: 'Afdeling' },
  { label: 'Fravær', value: 'Fravær' },
  { label: 'Øvrige', value: 'Øvrige' },
  { label: 'PPM', value: 'PPM' }
] as RadioButtonOptions[];

interface Props {
  handleReturn?: () => void;
}

const TimeRegForm = (props: Props) => {
  const { handleReturn } = props;

  const dispatch = useDispatch();
  const timeRegistration = useSelector(selectTimeRegistration);
  const timeRegAction = timeRegistration?.timeRegAction ?? 'add';

  const [registrationDate, setRegistrationDate] = useState<string | null>(
    timeRegistration?.date ?? formatDateStringISO(new Date().toISOString())
  );
  const [hours, setHours] = useState<number>(Math.trunc(timeRegistration?.hours ?? 0));
  const [minutes, setMinutes] = useState<number>(((timeRegistration?.hours ?? 0.25) % 1) * 60);
  const [description, setDescription] = useState(timeRegistration?.description ?? '');
  const [timeCategoryOptions, setTimeCategoryOptions] = useState<CategoryDTO2[]>([]);
  const [selectedGroup, setSelectedGroup] = useState(timeRegistration?.categoryGroupId ?? groupOptions[0].value);
  const [selectedCategoryId, setSelectedCategoryId] = useState(timeRegistration?.category?.id ?? '');
  const [selectedDate] = useState(timeRegistration?.date ? new Date(timeRegistration?.date) : new Date());
  const [projectNumberOptions, setProjectNumberOptions] = useState<ProjectDTO2[]>([]);
  const [selectedProject, setSelectedProject] = useState<MappedProjectNumber | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(timeRegAction === 'edit');

  useEffect(() => {
    if ((selectedProject?.value.id || timeRegistration?.project?.id) && timeRegAction !== 'edit') {
      TimeRegistrationService.getCategories(selectedProject?.value.id ?? timeRegistration?.project?.id)
        .then((res) => setTimeCategoryOptions(res))
        .catch((err) => log(err));
    } else {
      TimeRegistrationService.getCategoryAndProjectFromGroup(selectedGroup)
        .then((res) => {
          if (res.categories !== undefined) setTimeCategoryOptions(res.categories);
          if (res.projects !== undefined) setProjectNumberOptions(res.projects);
          if (timeRegistration?.workTaskId && timeRegistration?.project) setIsLoading(false);
        })
        .catch((err) => log(err));
    }
  }, [
    selectedCategoryId,
    selectedGroup,
    selectedProject?.value.id,
    timeRegAction,
    timeRegistration?.project,
    timeRegistration?.project?.id,
    timeRegistration?.workTaskId
  ]);

  useEffect(() => {
    if (timeCategoryOptions.length > 0 && !selectedCategoryId) {
      timeRegistration?.category?.id && setSelectedCategoryId(timeRegistration?.category.id);
    }
  }, [selectedCategoryId, timeCategoryOptions, timeRegistration?.category]);

  useEffect(() => {
    if (
      timeRegAction === 'edit' &&
      isLoading &&
      (timeRegistration?.workTaskId || timeRegistration?.project) &&
      projectNumberOptions.length > 0
    ) {
      const project =
        projectNumberOptions
          .map((p) => projectNumberToOption(p))
          .find((i) => i.value.id === timeRegistration?.project?.id) ?? null;
      setSelectedProject(project);
      project && setIsLoading(false);

      timeRegistration?.category && setSelectedCategoryId(timeRegistration?.category.id);
    }
  }, [
    projectNumberOptions,
    isLoading,
    timeRegAction,
    timeRegistration?.category,
    timeRegistration?.project,
    timeRegistration?.workTaskId
  ]);

  const handleSave = () => {
    let project = selectedProject?.value;
    if (!project && timeRegistration?.project) {
      project = timeRegistration?.project;
    }
    let category = timeCategoryOptions.find((c) => c.id === selectedCategoryId);
    if (project && selectedCategoryId && registrationDate) {
      setSubmitting(true);
      const { legalEntityId: projectLegalEntityId, id: projectId } = project;
      const body: CreateTimeRegistrationDTO = {
        date: formatDateStringISO(registrationDate) ?? '',
        categoryId: selectedCategoryId,
        hours: getTotalHours(),
        projectLegalEntityId: projectLegalEntityId,
        legalEntityId: category?.legalEntityId,
        description,
        projectId,
        categoryGroupId: selectedGroup,
        workTaskId: timeRegistration?.workTaskId
      };
      TimeRegistrationService.addTimeRegistration(body)
        .then(() => {
          NotificationService.success('Tiden blev registreret');
          setSubmitting(false);
          handleReturn && handleReturn();
          dispatch(clearTimeRegistration());
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Der opstod en fejl i registreringen af tid');
          setSubmitting(false);
        });
    } else {
      NotificationService.error('Udfyld venligst alle nødvendige felter');
    }
  };

  const handleEdit = () => {
    let project = selectedProject?.value;

    if (!project && timeRegistration?.project) {
      project = timeRegistration?.project;
    }
    let category = timeCategoryOptions.find((c) => c.id === selectedCategoryId);
    if (project && selectedCategoryId && registrationDate && timeRegistration?.id) {
      setSubmitting(true);
      const { legalEntityId: projectLegalEntityId, id: projectId } = project;
      const body: UpdateTimeRegistrationDTO = {
        date: formatDateStringISO(registrationDate) ?? '',
        categoryId: selectedCategoryId,
        hours: getTotalHours(),
        projectLegalEntityId: projectLegalEntityId,
        legalEntityId: category?.legalEntityId,
        description,
        projectId
      };
      TimeRegistrationService.editTimeRegistration(timeRegistration?.id, body)
        .then((res) => {
          NotificationService.success('Registreringen blev opdateret');
          setSubmitting(false);
          handleReturn && handleReturn();
          dispatch(clearTimeRegistration());
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Der opstod en fejl under opdatering af tidsregistreringen');
          setSubmitting(false);
        });
    } else {
      NotificationService.error('Udfyld venligst alle nødvendige felter');
    }
  };

  const handleConfirm = () => {
    if (timeRegAction === 'add') {
      handleSave();
    } else if (timeRegAction === 'edit') {
      handleEdit();
    }
  };

  const handleHoursChange = useCallback((hours: number, minutes: number) => {
    const totalHours = hours + minutes / 60.0;
    if (totalHours > -0.25 && totalHours < 0.25) {
      NotificationService.warning('Tiden skal mindst være et kvarter');
    } else {
      setHours(hours);
      setMinutes(minutes);
    }
  }, []);

  const getTotalHours = useCallback(() => {
    return hours + minutes / 60.0;
  }, [hours, minutes]);

  const handleSelectCategory = useCallback(
    (value: string | undefined | null, reason) => {
      if (reason === 'clear') {
        setSelectedCategoryId('');
      }

      const category = timeCategoryOptions.find((x) => x.id === value);
      setSelectedCategoryId(category?.id ?? '');
    },
    [timeCategoryOptions]
  );

  const projectNumberToOption = (p: ProjectDTO2): MappedProjectNumber => {
    return {
      label: `${p.id} - ${p.name} (${p.legalEntityId})`,
      value: p
    };
  };

  const mappedProjectNumbers = useMemo(() => {
    return projectNumberOptions.map((p) => projectNumberToOption(p));
  }, [projectNumberOptions]);

  const handleSelectProjectNumber = useCallback(
    (value: string | undefined, reason) => {
      if (reason === 'clear') {
        setSelectedProject(null);
      }
      const project = mappedProjectNumbers.find((project) => project.value.id + project.value.legalEntityId === value);
      project && setSelectedProject(project);
    },
    [mappedProjectNumbers]
  );

  const handleSelectGroup = useCallback(
    (value: string) => {
      if (value === selectedGroup) return;
      setSelectedGroup(value);
      setTimeCategoryOptions([]);
      setProjectNumberOptions([]);
      handleSelectProjectNumber('', 'clear');
      handleSelectCategory('', 'clear');
      TimeRegistrationService.getCategoryAndProjectFromGroup(value)
        .then((res) => {
          if (res.categories !== undefined) setTimeCategoryOptions(res.categories);

          if (res.projects !== undefined) setProjectNumberOptions(res.projects);
        })
        .catch((err) => log(err));
    },
    [handleSelectCategory, handleSelectProjectNumber, selectedGroup]
  );

  return (
    <Container>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Fragment>
          <Row>
            <Column>
              <DatePicker
                label="Registreringsdato *"
                onDateChanged={(date) => setRegistrationDate(date.toISOString())}
                defaultDate={selectedDate}
              />
            </Column>
            <Column>
              <TimePicker initialHours={hours} initialMinutes={minutes} onTimeChange={handleHoursChange} />
            </Column>
          </Row>

          {!timeRegistration?.workTaskId && (
            <Row>
              <RadioButtons
                disabled={timeRegistration?.project !== undefined}
                options={groupOptions}
                label={''}
                value={selectedGroup}
                handleValueChange={(value: string) => handleSelectGroup(value)}
                labelPlacement="end"
                row
              />
            </Row>
          )}

          <Row>
            {timeRegistration?.workTaskId && timeRegistration?.project ? (
              <TextField
                disabled
                fullWidth
                defaultValue={`${timeRegistration?.project?.id} - ${timeRegistration?.project?.name} (${timeRegistration?.project?.legalEntityId})`}
              />
            ) : (
              <AutoComplete
                onChange={(_, value, reason) =>
                  handleSelectProjectNumber((value?.value.id ?? '') + value?.value.legalEntityId, reason)
                }
                getOptionLabel={(option) => option.label ?? ''}
                options={mappedProjectNumbers}
                value={selectedProject}
                renderInput={(params) => <TextField {...params} label={'Projektnummer'} fullWidth />}
                isOptionEqualToValue={(option, value) => option.value.id === value.value.id}
                noOptionsText={'Intet projektnummer' ?? ''}
                loading={true}
                fullWidth
                data-testid="external-project-numbers-dropdown"
              />
            )}

            <AutoComplete
              onChange={(event, value, reason) => handleSelectCategory(value, reason)}
              getOptionLabel={(option) =>
                timeCategoryOptions
                  .filter((category) => option === category.id)
                  .map((category) => {
                    return `${category.id} - ${category.name} (${category.legalEntityId})`;
                  })[0] ?? ''
              }
              options={timeCategoryOptions.map((category) => {
                return category.id;
              })}
              value={selectedCategoryId}
              renderInput={(params) => <TextField {...params} label={'Timetype/Art'} fullWidth />}
              placeholder="Vælg timetypen"
              fullWidth
              data-testid="timereg-category-dropdown"
            />

            {!timeRegistration?.workTaskId && !timeRegistration?.project && (
              <Button
                variant="secondary"
                onClick={(e) => {
                  handleSelectProjectNumber('', 'clear');
                  handleSelectCategory('', 'clear');
                }}
              >
                Ryd
              </Button>
            )}
          </Row>
          <Row>
            <TextField
              fullWidth
              value={description}
              multiline
              maxRows={4}
              minRows={4}
              onChange={(e) => setDescription(e.target.value)}
              label="Bemærkning"
              placeholder="Skriv bemærkning"
              dataTestId="timereg-description-textfield"
            />
          </Row>
          <StyledButton isLoading={submitting} onClick={() => handleConfirm()} data-testid="timereg-save-button">
            Gem
          </StyledButton>
        </Fragment>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 420px;
  height: auto;
  row-gap: 32px;
`;

const Row = styled.div`
  display: flex;
  column-gap: 24px;
`;

const Column = styled.div`
  flex: 1;
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
`;

export default TimeRegForm;
