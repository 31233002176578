import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import { msalConfig } from '../authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

export const getUserInitials = (): string => {
  const account = msalInstance.getActiveAccount();

  if (!!account) {
    const names = account.name?.split(' ').filter((name) => name.charAt(0) !== '(');

    if (!!names?.length) {
      const firstLetter = names[0].charAt(0);
      const secondLetter = names[names.length - 1].charAt(0);

      return firstLetter + secondLetter;
    }
  }

  return 'NN';
};

export const getUserEmail = (): string => {
  const account = msalInstance.getActiveAccount();

  return account?.username ?? '';
};

export const getUserName = (): string => {
  return msalInstance.getActiveAccount()?.name ?? '';
};
