import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { QuestionDTO, QuestionType } from '../../../api/api';
import { AddIcon } from '../../../assets/icons/AddIcon';
import Table from '../../../blocks/table/TableClient';
import Button from '../../../components/button/Button';
import DialogView from '../../../components/dialog-view/DialogView';
import Typography from '../../../components/typography/Typography';
import { FormMode } from '../../../models/FormMode';
import AdminService from '../../../services/AdminService';
import NotificationService from '../../../services/NotificationService';
import { Row } from '../../../styling/FormStyling';
import { log } from '../../../utils/logging/log';
import ChecklistQuestionForm from './checklist-question-form/ChecklistQuestionForm';
import useChecklistQuestionList, { ChecklistQuestionData } from './useChecklistQuestionList';

const AdminChecklistQuestionView = () => {
  const [questions, setQuestions] = useState<QuestionDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState<QuestionDTO>();
  const [mode, setMode] = useState<FormMode>('create');

  useEffect(() => {
    setLoading(true);
    AdminService.getChecklistQuestions()
      .then((res) => setQuestions(res))
      .catch((err) => {
        NotificationService.error('Kunne ikke hente spørgsmål');
        log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleOpenForm = useCallback((question: QuestionDTO | undefined, mode: FormMode) => {
    setSelectedAttribute(question);
    setMode(mode);
    setShowDialog(true);
  }, []);

  const handleCancelCreation = useCallback(() => {
    setShowDialog(false);
  }, []);

  const handleDoubleClick = useCallback(
    (rowData: ChecklistQuestionData) => {
      handleOpenForm(
        questions.find((a) => a.id === rowData.id),
        'update'
      );
    },
    [questions, handleOpenForm]
  );

  const handleSubmitQuestionCreation = useCallback(
    (dto: QuestionDTO) => {
      let request = {
        ...dto,
        options: dto.options?.map((option) => {
          return { ...option, order: option.questionOptionOrder };
        })
      };
      if (mode === 'create') {
        return Promise.resolve(
          AdminService.createChecklistQuestion(request)
            .then((res) => {
              setQuestions((prev) => [...prev, res]);
              setShowDialog(false);
              NotificationService.success('Oprettede nyt spørgsmål');
            })
            .catch((err) => {
              log(err);
              NotificationService.error('Kunne ikke oprette spørgsmål');
            })
        );
      }

      if (!selectedAttribute?.id) throw new Error('Trying to update field without id');

      return Promise.resolve(
        AdminService.updateChecklistQuestion(selectedAttribute?.id, request)
          .then((res) => {
            setQuestions((prev) => prev.map((q) => (q.id === res.id ? res : q)));
            setShowDialog(false);
            NotificationService.success('Opdaterede spørgsmålet');
          })
          .catch((err) => {
            log(err);
            NotificationService.error('Kunne ikke opdatere typefelt');
          })
      );
    },
    [mode, selectedAttribute?.id]
  );

  const { tableInstance } = useChecklistQuestionList(questions);

  return (
    <Container>
      <Header>
        <Typography variant="h3">Tjeklistespørgsmål</Typography>
        <Row>
          <Button onClick={() => handleOpenForm({ questionType: QuestionType.Text }, 'create')}>
            <AddIcon size="20px" /> Opret spørgsmål
          </Button>
        </Row>
      </Header>
      <Table
        loading={loading}
        tableInstance={tableInstance}
        onDoubleClickRow={(row) => handleDoubleClick(row)}
        showPagination
      />
      {showDialog && (
        <DialogView>
          <ChecklistQuestionForm
            handleCancel={() => handleCancelCreation()}
            initialBody={selectedAttribute ?? { questionType: QuestionType.Text }}
            handleSubmit={handleSubmitQuestionCreation}
          />
        </DialogView>
      )}
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;

  & > *:nth-child(1) {
    padding: 30px 30px 0px 30px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default AdminChecklistQuestionView;
