import styled from 'styled-components';
import Typography from '../../components/typography/Typography';
import { Divider } from '@mui/material';
import { formatDateHourString, formatDateString } from '../../utils/dateHandling';
import { getTextColor } from './color-utils';
import Hyperlink from '../../components/hyperlink/HyperLink';
import { NewsDTO } from '../../api/api';
import BasicPopover from '../../components/popover/Popover';
import Person from '../../components/person/Person';
import ContactCard from '../../components/contact-card/ContactCard';

interface Props {
  news: NewsDTO;
}

const NewsPreview = (props: Props) => {
  const { title, description, lastUpdated, newsType, link, createdByEmail, createdByName } = props.news;

  return (
    <Container>
      <Document>
        <DocumentHeader>
          <DateRow>
            <StyledDate variant="span" color="greyedOut">{`Kl. ${formatDateHourString(
              lastUpdated ?? ''
            )} - ${formatDateString(lastUpdated ?? '')}`}</StyledDate>
          </DateRow>
          <HeaderRow>
            <StyledTitle fontWeight="bold" variant="h5" textColor={getTextColor(newsType)}>
              {title}
            </StyledTitle>
            <BasicPopover
              buttonElement={<StyledPerson name={createdByName} />}
              popoverElement={<ContactCard email={createdByEmail} />}
            />
          </HeaderRow>
        </DocumentHeader>
        <StyledDivider borderColor={getTextColor(newsType)} />
        <div dangerouslySetInnerHTML={{ __html: description ?? '' }}></div>
        {link && <Hyperlink label="Link" link={link} />}
      </Document>
    </Container>
  );
};

const DateRow = styled.div`
  display: flex;
  justify-content: end;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  margin: 25px;
  border-radius: 8px;
  position: relative;
`;
const Document = styled.div`
  height: calc(100% - 60px);
  width: calc(100% - 60px);
  padding: 30px 30px;
  overflow-y: auto;
`;

const DocumentHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTitle = styled(Typography)<{ textColor?: string }>`
  color: ${(props) => props.textColor};
`;

const StyledPerson = styled(Person)`
  min-width: 100px;
`;

const StyledDate = styled(Typography)<{ textColor?: string }>`
  position: relative;
`;

const StyledDivider = styled(Divider)<{ borderColor?: string }>`
  border-width: 1px !important;
  border-color: ${(props) => props.borderColor} !important;
  margin: 10px 0px !important;
`;

export default NewsPreview;
