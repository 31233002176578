import { memo, useCallback } from 'react';
import { HeaderGroup } from 'react-table';
import styled, { css } from 'styled-components';
import { ArrowDownIcon } from '../../../assets/icons/ArrowDownIcon';
import { ArrowUpIcon } from '../../../assets/icons/ArrowUpIcon';
import IconButton from '../../../components/icon-button/IconButton';
import { OrderByColumnEnum, OrderByEnum } from '../../../api/api';
import { getEnumDisplayValue } from '../../../utils/enumUtils';

interface Props<T extends object> {
  column: HeaderGroup<T>;
  activeTab?: string;
  isActive: boolean;
  filterValue?: string;
  disabledColumns?: string[];
  sortingColumn?: string;
  isSorted?: boolean;
  isSortedDesc?: boolean;
  onSortingOrder?: (orderByColumn: string, sortOrder: OrderByEnum) => void;
}

const Column = <T extends object>(props: Props<T>) => {
  const { column, activeTab, isActive, disabledColumns, isSorted, isSortedDesc, sortingColumn, onSortingOrder } = props;

  const handleOnClick = useCallback(() => {
    onSortingOrder &&
      onSortingOrder(column.id ?? '', isSorted ? (isSortedDesc ? OrderByEnum.Asc : OrderByEnum.Desc) : OrderByEnum.Desc);
  }, [column.id, isSorted, isSortedDesc, onSortingOrder]);

  const columnIsSorted = () => {
    return (
      (isSorted && (!sortingColumn || getEnumDisplayValue(sortingColumn as OrderByColumnEnum) === column.Header)) ||
      sortingColumn === column.id
    );
  };

  return (
    <ColumnContainer
      disabled={disabledColumns && disabledColumns.some((x) => x === column.id) && activeTab !== 'Alle opgaver'}
      data-testid={`table-column-header-${column.Header}`}
    >
      {column.canFilter ? column.render('Filter') : column.render('Header')}
      {column.canSort && (isActive || column.isSorted) && (
        <IconButton padding="0" onClick={handleOnClick} {...(!onSortingOrder ? column.getSortByToggleProps() : {})}>
          {columnIsSorted() ? (
            isSortedDesc ? (
              <ArrowUpIcon size="14px" />
            ) : (
              <ArrowDownIcon size="14px" />
            )
          ) : (
            <ArrowDownIcon size="14px" color="grey" />
          )}
        </IconButton>
      )}
    </ColumnContainer>
  );
};

const ColumnContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: ${(props) => props.theme.spacing(2)};
  margin-right: ${(props) => props.theme.spacing(2)};

  :hover {
    .MuiInputAdornment-root {
      display: none;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      && .MuiOutlinedInput-root {
        pointer-events: none;
      }
      && .MuiOutlinedInput-input,
      .MuiInputLabel-root {
        color: ${(props) => props.theme.palette.grey.black20};
      }
    `}
`;

export default memo(Column);
