import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
  onChange: (text: string) => void;
  value: string;
}

const RichText = (props: Props) => {
  const { onChange, value } = props;

  return (
    <ReactQuill
      modules={{
        toolbar: [
          ['bold', 'italic', 'underline'], // toggled buttons
          [{ list: 'ordered' }, { list: 'bullet' }]
        ]
      }}
      style={{ height: '100%' }}
      theme="snow"
      value={value}
      onChange={onChange}
    />
  );
};

export default RichText;
