export enum ImageType {
  QADownloadInfo = 'QADownloadInfo',
  GisComponentDownloadInfo = 'GisComponentDownloadInfo',
  GisListViewFileDownloadInfo = 'GisListViewFileDownloadInfo',
  AttachmentDownloadInfo = 'AttachmentDownloadInfo'
}

export interface QADownloadInfo {
  imageType: ImageType.QADownloadInfo;
  taskId?: number;
  filename?: string;
  answerId?: number;
}

export interface GisComponentDownloadInfo {
  imageType: ImageType.GisComponentDownloadInfo;
  taskId?: number;
  filename?: string;
  assetId?: string;
  objectId?: number;
}

export interface GisListViewFileDownloadInfo {
  imageType: ImageType.GisListViewFileDownloadInfo;
  taskId?: number;
  filename?: string;
  assetId?: string;
}

export interface AttachmentDownloadInfo {
  imageType: ImageType.AttachmentDownloadInfo;
  taskId?: number;
  filename?: string;
}

export type DownloadInfo = QADownloadInfo | GisComponentDownloadInfo | GisListViewFileDownloadInfo | AttachmentDownloadInfo;
