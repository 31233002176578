import { useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/button/Button';
import GoodsService from '../../../../services/GoodsService';
import NotificationService from '../../../../services/NotificationService';
import { log } from '../../../../utils/logging/log';

interface MoveToIssueLocationProps {
  consignorId: string;
  moveToIssueLocationStatus: string;
  transferStatus: string;
  fetchTransferOrdersCallback: () => void;
}

const MoveToIssueLocation = (props: MoveToIssueLocationProps) => {
  const { consignorId, moveToIssueLocationStatus, transferStatus, fetchTransferOrdersCallback } = props;

  const [loading, setLoading] = useState(false);
  const [successfullyClicked, setSuccessfullyClicked] = useState(false);

  const handleReceive = (consignorId: string) => {
    setLoading(true);
    GoodsService.createItemMove(consignorId)
      .then(() => {
        fetchTransferOrdersCallback();
        setSuccessfullyClicked(true);
        NotificationService.success('Ordre modtaget');
      })
      .catch((err) => {
        log(err);
        setSuccessfullyClicked(false);
        NotificationService.error('Kunne ikke modtage ordre');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (transferStatus === 'Received' && moveToIssueLocationStatus === 'Open' && consignorId !== '0' && !successfullyClicked)
    return (
      <StyledButton isLoading={loading} onClick={() => handleReceive(consignorId)} variant="secondary">
        Flyt til bil
      </StyledButton>
    );

  return <></>;
};

const StyledButton = styled(Button)`
  height: 32px;
`;

export default MoveToIssueLocation;
