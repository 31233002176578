import styled from 'styled-components';
import { ColumnFlex2 } from '../../styling/Styling';
import { getFullDateString } from '../../utils/dateHandling';
import TextRow from '../../views/task-view/task-details-view2/components/TextRow';

interface Props {
  dataURL: string;
  signedBy: string;
  signedDate: string;
}

const SignaturePreview = (props: Props) => {
  const { dataURL, signedBy, signedDate } = props;

  return (
    <Container>
      <ColumnFlex2>
        <ImgContainer>
          <StyledImg src={dataURL} />
        </ImgContainer>
      </ColumnFlex2>
      <ColumnFlex2>
        <TextRow label={'Navn'} value={signedBy}></TextRow>
        <TextRow label={'Dato'} value={getFullDateString(new Date(signedDate))}></TextRow>
      </ColumnFlex2>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const ImgContainer = styled.div`
  display: flex;
  width: 180px;
  height: 90px;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

export default SignaturePreview;
