import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  TaskListRequestDTO,
  WorkTaskListItemDTO3,
  TaskListResponseDTO,
  OrderByEnum,
  OrderByColumnEnum
} from '../../../api/api';
import Table from '../../../blocks/table/TableServer';
import Button from '../../../components/button/Button';
import LoadingOverlay from '../../../components/loading-overlay/LoadingOverlay';
import Typography from '../../../components/typography/Typography';
import NotificationService from '../../../services/NotificationService';
import TaskService from '../../../services/TaskService';
import {
  selectFilterValues,
  selectListShouldUpdate,
  selectTechnicianFilterValues,
  setFilterValues,
  setListShouldUpdate,
  setTechnicianFilterValues
} from '../../../stateManagement/reducers/taskListReducer';
import { selectUserProfile } from '../../../stateManagement/reducers/userProfileReducer';
import { GreyAreaHorizontalPadding } from '../../../styling/StylingConstants';
import { columnAccessorsToColumnEnum } from '../../../utils/enumUtils';
import { log } from '../../../utils/logging/log';
import { ColumnAccessors, TaskData, useTechnicianTaskList } from './useTechnicianTaskList';
import { useNavigateToTask } from '../../../hooks/useNavigateToTask';
import IconButton from '../../../components/icon-button/IconButton';
import { UpdateIcon } from '../../../assets/icons/UpdateIcon';
import { RemoveFilterIcon } from '../../../assets/icons/RemoveFilterIcon';
import { getUserEmail } from '../../../utils/authProvider/authProvider';

const technicianBaseFilter = {
  page: 1,
  pageSize: 25,
  sortOrder: OrderByEnum.Desc,
  orderByColumn: OrderByColumnEnum.WorkTaskId
} as TaskListRequestDTO;

const TechnicianTaskList = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigateToTask = useNavigateToTask();

  const userProfile = useSelector(selectUserProfile);
  const userEmail = getUserEmail();
  const shouldUpdate = useSelector(selectListShouldUpdate);

  const [tasks, setTasks] = useState<WorkTaskListItemDTO3[]>([]);
  const [firstFetch, setFirstFetch] = useState(true);
  const { tableInstance } = useTechnicianTaskList(tasks);
  const [tableConfig, setTableConfig] = useState<TaskListResponseDTO>({} as TaskListResponseDTO);

  const filterValues = useSelector(selectFilterValues);
  const technicianFilterValues = useSelector(selectTechnicianFilterValues);

  const controllerRef = useRef<AbortController | null>(null);

  const fetchTasks = useCallback(
    (body?: TaskListRequestDTO) => {
      setLoading(true);

      body && dispatch(setFilterValues(body));
      dispatch(setTechnicianFilterValues(body ?? filterValues));

      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      controllerRef.current = new AbortController();
      TaskService.getWorkTaskList(body ?? filterValues, controllerRef.current)
        .then((res) => {
          setTasks(res.tasks ?? ([] as WorkTaskListItemDTO3[]));
          setTableConfig({ ...res, page: res?.page && res?.page - 1 });
          dispatch(setListShouldUpdate(false));
          setLoading(false);
        })
        .catch((err) => {
          if (err.name === 'AbortError') {
            log('Request aborted');
          } else {
            log(err);
            NotificationService.error('Kunne ikke indlæse opgavelisten.');
            setLoading(false);
          }
        });

      return () => {
        // Abort the request when the component unmounts or when a dependency changes
        controllerRef.current?.abort();
      };
    },
    [dispatch, filterValues]
  );
  const fetchTechnicianBaseTasks = useCallback(() => {
    dispatch(setFilterValues({ ...technicianBaseFilter, assignedToTechnicianEmailFilter: userEmail }));
    fetchTasks({
      ...technicianBaseFilter,
      assignedToTechnicianEmailFilter: userEmail
    } as TaskListRequestDTO);
  }, [dispatch, fetchTasks, userEmail]);

  const handleUpdateTasks = useCallback(() => {
    fetchTasks(filterValues);
  }, [fetchTasks, filterValues]);

  const handleDoubleClick = useCallback(
    (rowData: TaskData) => {
      if (!rowData.baseWorkTaskType) return;
      navigateToTask(parseInt(rowData.id), rowData.baseWorkTaskType);
    },
    [navigateToTask]
  );

  useEffect(() => {
    if (firstFetch) {
      if (technicianFilterValues) {
        dispatch(setFilterValues(technicianFilterValues));
        fetchTasks(technicianFilterValues);
      } else {
        fetchTechnicianBaseTasks();
      }
      setFirstFetch(false);
    } else {
      fetchTasks();
    }
  }, [dispatch, fetchTasks, fetchTechnicianBaseTasks, firstFetch, technicianFilterValues]);

  useEffect(() => {
    if (shouldUpdate) {
      fetchTasks();
    }
  }, [fetchTasks, shouldUpdate]);

  return (
    <>
      <HeaderContainer>
        <Typography variant="h2">Opgaver der er tildelt mig</Typography>
        <Row>
          <StyledButton variant="secondary" compact onClick={fetchTechnicianBaseTasks}>
            <RemoveFilterIcon size="20px" /> <Typography fontWeight="bold">Fjern filter</Typography>
          </StyledButton>
          <IconButton variant="outlined" onClick={handleUpdateTasks}>
            <UpdateIcon size="20px" />
          </IconButton>
        </Row>
      </HeaderContainer>
      {userProfile.isLoaded ? (
        <Table
          loading={loading}
          tableInstance={tableInstance}
          noDataText="Fandt ingen opgaver"
          multipleTypeName="opgaver"
          onDoubleClickRow={handleDoubleClick}
          showPagination
          showCount
          alwaysShowSort
          sortingOrder={filterValues.sortOrder}
          sortingColumn={filterValues.orderByColumn?.toString()}
          onSortingOrder={(orderByColumn: string, sortOrder: OrderByEnum) => {
            fetchTasks({
              ...filterValues,
              orderByColumn: columnAccessorsToColumnEnum(orderByColumn as ColumnAccessors) as OrderByColumnEnum,
              sortOrder: sortOrder
            });
          }}
          tableConfig={tableConfig}
        />
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${GreyAreaHorizontalPadding}px ${GreyAreaHorizontalPadding}px 0px;
  line-height: 40px;
`;

const Row = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-right: ${(props) => props.theme.spacing(8)};
`;

export default TechnicianTaskList;
