import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import localforage from 'localforage';
import { AssetType2, AssetResultDTO, LocationDTO } from '../../api/api';
import { FileInfo, TaskCreationTask } from '../../models/TaskCreationTask';
import { RootState } from '../store';

export type AssetSearchStatus = {
  taskCreationId?: string;
  category?: AssetType2;
  locations?: AssetResultDTO[];
  assetSearchTerm?: string;
  selectedLocation?: LocationDTO;
  showSelectTaskLocation?: boolean;
};

interface TaskCreationState {
  tasks: TaskCreationTask[];
  searchStatuses: AssetSearchStatus[];
}

const initialState: TaskCreationState = {
  tasks: [],
  searchStatuses: []
};

const getFromLocalStorage = (): TaskCreationState | undefined => {
  const taskState = localStorage.getItem('taskState');

  if (taskState) {
    return JSON.parse(taskState) as TaskCreationState;
  }
};

const saveToLocalStorage = (state: TaskCreationState) => {
  localStorage.setItem('taskState', JSON.stringify(state));
};

const taskCreationSlice = createSlice({
  name: 'taskCreation',
  initialState: getFromLocalStorage() || initialState,
  reducers: {
    newTask: (state: TaskCreationState, action: PayloadAction<TaskCreationTask>) => {
      const newTask = action.payload;
      newTask.files = [];
      state.tasks = [...state.tasks, newTask];
      state.searchStatuses = [
        ...state.searchStatuses,
        {
          taskCreationId: newTask.taskCreationId,
          locations: [],
          showSelectTaskLocation: action.payload.assetId ? false : action.payload.assetLocation ? true : false,
          selectedLocation: action.payload.assetLocation
        }
      ];

      saveToLocalStorage(state);
    },
    updateTask: (state: TaskCreationState, action: PayloadAction<{ id: string; task: TaskCreationTask }>) => {
      const { id, task } = action.payload;

      let index = state.tasks.findIndex((task) => task.taskCreationId === id);

      if (index === -1) return;

      state.tasks[index] = { ...state.tasks[index], ...task };

      saveToLocalStorage(state);
    },
    deleteTask: (state: TaskCreationState, action: PayloadAction<string>) => {
      const index = state.tasks.findIndex((task) => task.taskCreationId === action.payload);

      if (index === -1) return;

      localforage.removeItem(action.payload);

      state.tasks.splice(index, 1);

      let newStatuses = state.searchStatuses;
      let statusIndex = newStatuses.findIndex((status) => status.taskCreationId === action.payload);

      newStatuses.splice(statusIndex, 1);

      saveToLocalStorage(state);
    },
    clearTasks: (state: TaskCreationState) => {
      if (state.tasks.find((t) => t.taskCreationId !== undefined)) return;
      localforage.removeItem('taskState');
      localStorage.removeItem('taskState');

      state.tasks = [];
      state.searchStatuses = [];
    },
    setAssetSearchStatus: (state: TaskCreationState, action: PayloadAction<AssetSearchStatus>) => {
      if (action.payload.taskCreationId === undefined) return;

      let newStatuses = state.searchStatuses;

      let index = newStatuses.findIndex((status) => status.taskCreationId === action.payload.taskCreationId);
      newStatuses[index] = { ...newStatuses[index], ...action.payload };
      state.searchStatuses = newStatuses;

      saveToLocalStorage(state);
    },
    setFiles: (state: TaskCreationState, action: PayloadAction<{ id: string; files: FileInfo[] }>) => {
      const { id, files } = action.payload;

      let index = state.tasks.findIndex((task) => task.taskCreationId === id);

      state.tasks[index].files = files;

      saveToLocalStorage(state);
    }
  }
});

export const { newTask, updateTask, deleteTask, setAssetSearchStatus, setFiles, clearTasks } = taskCreationSlice.actions;

export const selectTasks = (state: RootState) => state.taskCreation.tasks;
export const selectSearchStatuses = (state: RootState) => state.taskCreation.searchStatuses;

export default taskCreationSlice.reducer;
