import { Fragment } from 'react';
import styled, { css } from 'styled-components';

interface HeaderProps {
  activeStep: number;
  handleStepChange: (id: string) => void;
}

const CreateTaskDialogHeader = (props: HeaderProps) => {
  const { activeStep, handleStepChange } = props;

  const steps = [1, 2, 3];

  return (
    <Fragment>
      <div>
        Trin {activeStep} af {steps.length}
      </div>
      <StepContainer>
        {steps.map((step) => (
          <Step key={step} active={step <= activeStep} onClick={() => handleStepChange(step.toString())} />
        ))}
      </StepContainer>
    </Fragment>
  );
};

const StepContainer = styled.div`
  display: flex;
  column-gap: 8px;
  margin-top: 8px;
`;

const Step = styled.div<{ active: boolean }>`
  width: 50px;
  height: 10px;
  background-color: ${({ theme }) => theme.palette.grey.black20};
  border-radius: 5px;
  cursor: pointer;

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.palette.functions.success.primary};
    `}
`;

export default CreateTaskDialogHeader;
