import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { InputAdornment } from '@mui/material';

import calendar, {
  isDate,
  isSameDay,
  isSameMonth,
  getNextMonth,
  getPreviousMonth,
  WEEK_DAYS,
  THIS_MONTH,
  THIS_YEAR
} from '../date-picker/helpers';
import DatePickerHeader from '../date-picker/components/DatePickerHeader';
import Grow from '../grow';
import TextField from '../text-field/TextField';
import { CalendarIcon } from '../../assets/icons/CalendarIcon';
import { formatDate } from '../../utils/dateHandling';
import {
  CalendarContainer,
  CalendarDate,
  CalendarDay,
  CalendarGrid,
  HighlightedCalendarDate,
  Root,
  TodayCalendarDate,
  WeekNumberCell,
  Props as DatePickerProps
} from '../date-picker/DatePicker';
import styled from 'styled-components';
import Button from '../button/Button';
import { addDays, getWeek } from 'date-fns';
import n1LightTheme from '../../theme/light-theme';

export type DateState = { from?: Date; to?: Date; month: number; year: number };

type Props = {
  onDateChanged: (from?: Date, to?: Date) => void;
  hasDeadlineDate?: boolean;
  filterValue?: any;
  marked?: boolean;
} & DatePickerProps;

const DateRangePicker = (props: Props) => {
  const {
    defaultDate,
    onDateChanged,
    top,
    right,
    bottom,
    left,
    className,
    disableWeekends,
    earliestStartDate,
    compact,
    centerX,
    centerY,
    hasDeadlineDate,
    disabled,
    filterValue,
    marked,
    ...textFieldProps
  } = props;

  const [open, setOpen] = useState(false);

  const [dateState, setDateState] = useState<DateState>({ month: THIS_MONTH(), year: THIS_YEAR() });
  const [hoverDate, setHoverDate] = useState<Date>();
  const [today] = useState(new Date());

  const [isDisabled, setIsDisabled] = useState(disabled);

  const getCalendarDates = useCallback(() => {
    const { from, month, year } = dateState;
    const date = from ?? new Date();
    const calendarMonth = month || +date.getMonth() + 1;
    const calendarYear = year || date.getFullYear();
    return calendar(calendarMonth, calendarYear);
  }, [dateState]);

  const renderCalendarDate = (date: any, index: number) => {
    const { from, to, month, year } = dateState;
    const _date = new Date(date.join('-'));
    const isToday = isSameDay(_date, today);
    const isCurrent = (from && isSameDay(_date, from)) || (to && isSameDay(_date, to));
    const inMonth =
      month && year
        ? isSameMonth(_date, new Date([year, month, 1].join('-')))
        : isSameMonth(_date, new Date([THIS_YEAR, THIS_MONTH, 1].join('-')));
    const onClick = gotoDate(_date);
    const props = { index, inMonth, onClick, title: _date.toDateString() };

    if (disableWeekends) {
      setIsDisabled(_date.getDay() === 6 || _date.getDay() === 0);
    }
    if (!isDisabled && earliestStartDate) {
      setIsDisabled(_date < earliestStartDate);
    }

    let hoverActive = false;
    if (from && to) {
      hoverActive = _date > from && _date < to;
    } else if (hoverDate && from) {
      hoverActive = (_date < hoverDate && _date > from) || (_date > hoverDate && _date < from);
    }

    const DateComponent = isCurrent ? HighlightedCalendarDate : isToday ? TodayCalendarDate : CalendarDate;

    return (
      <DateComponent
        {...props}
        disabled={isDisabled}
        hoverActive={hoverActive}
        onMouseEnter={() => setHoverDate(_date)}
        inMonth={inMonth}
        key={`date-component-${index}`}
        index={index}
      >
        {_date.getDate()}
      </DateComponent>
    );
  };

  const weekNumbers = (): number[] => {
    const weekNumbers: number[] = [];
    getCalendarDates().forEach((date) => {
      const _date = new Date(date.join('-'));
      const weekNumber = getWeek(_date, { firstWeekContainsDate: 4, weekStartsOn: 1 });
      if (!weekNumbers.includes(weekNumber)) {
        weekNumbers.push(weekNumber);
      }
    });
    return weekNumbers;
  };

  const addDateToState = useCallback((date: Date) => {
    const isDateObject = isDate(date);
    const _date = isDateObject ? date : new Date();
    setDateState({
      from: isDateObject ? date : _date,
      month: +_date.getMonth() + 1,
      year: _date.getFullYear()
    });
  }, []);

  const gotoDate = useCallback(
    (date: Date) => (event: any) => {
      event && event.preventDefault();
      const { from, to } = dateState;

      if (!from && !to) {
        addDateToState(date);
      } else if (from && !to) {
        const first = [from, date].reduce((a, b) => {
          return a < b ? a : b;
        });
        const last = [from, date].reduce((a, b) => {
          return a > b ? a : b;
        });
        setDateState((prev) => ({ ...prev, from: first, to: last }));
      } else if (from && to) {
        setDateState((prev) => ({ ...prev, to: undefined }));
        addDateToState(date);
      }
    },
    [dateState, addDateToState]
  );

  const gotoPreviousMonth = useCallback(() => {
    const { month, year } = dateState;
    const previousMonth = getPreviousMonth(month, year);
    setDateState({
      month: previousMonth.month,
      year: previousMonth.year,
      from: dateState.from
    });
  }, [dateState]);

  const gotoNextMonth = useCallback(() => {
    const { month, year } = dateState;
    const nextMonth = getNextMonth(month, year);
    setDateState({
      month: nextMonth.month,
      year: nextMonth.year,
      from: dateState.from
    });
  }, [dateState]);

  const gotoPreviousYear = useCallback(() => {
    const { year } = dateState;
    setDateState({
      month: dateState.month,
      year: year - 1,
      from: dateState.from
    });
  }, [dateState]);

  const gotoNextYear = useCallback(() => {
    const { year } = dateState;
    setDateState({
      month: dateState.month,
      year: year + 1,
      from: dateState.from
    });
  }, [dateState]);

  const selectDaysFromDate = useCallback(
    (days: number) => {
      const todayMorning = today;
      todayMorning.setHours(0, 0, 0, 0);
      const todayMidnight = today;
      todayMidnight.setHours(23, 59, 59, 59);
      if (days === 0) {
        setDateState((prev) => ({ ...prev, from: todayMorning, to: todayMidnight }));
        onDateChanged(todayMorning, todayMidnight);
      } else if (days > 0) {
        const from = addDays(todayMorning, -Math.abs(days));
        setDateState((prev) => ({ ...prev, from, to: todayMidnight }));
        onDateChanged(from, todayMidnight);
      } else if (days < 0) {
        const to = addDays(todayMidnight, Math.abs(days));
        setDateState((prev) => ({ ...prev, from: todayMorning, to: to }));
        onDateChanged(todayMorning, to);
      }
      setOpen(false);
    },
    [onDateChanged, today]
  );

  const selectAllDaysUntilToday = useCallback(() => {
    const from = new Date('1970-01-01');
    const todayMidnight = today;
    todayMidnight.setHours(23, 59, 59, 59);
    setDateState((prev) => ({ ...prev, from, to: todayMidnight }));
    onDateChanged(from, todayMidnight);
    setOpen(false);
  }, [onDateChanged, today]);

  const applyAndClose = useCallback(() => {
    const { from, to } = dateState;
    if (from && !to) {
      setDateState((prev) => ({ ...prev, from, to: from }));
      onDateChanged(from, from);
    } else {
      onDateChanged(dateState.from, dateState.to);
    }
    setOpen(false);
  }, [onDateChanged, dateState]);

  const resetDates = useCallback(() => {
    setDateState((prev) => ({ ...prev, from: undefined, to: undefined }));
    onDateChanged(undefined, undefined);
    setOpen(false);
  }, [onDateChanged]);

  useEffect(() => {
    defaultDate && addDateToState(defaultDate);
  }, [defaultDate, addDateToState]);

  useEffect(() => {
    if (filterValue === undefined) {
      resetDates();
    }
  }, [filterValue, resetDates]);

  const dateValue = useMemo(() => {
    if (dateState.from && dateState.to) {
      return dateState.from === dateState.to
        ? formatDate(dateState.from)
        : `${formatDate(dateState.from)} - ${formatDate(dateState.to)}`;
    } else {
      return '';
    }
  }, [dateState.from, dateState.to]);

  return (
    <Root className={className}>
      <TextField
        inputProps={{ disabled: isDisabled }}
        {...textFieldProps}
        onClick={(e) => {
          !props.disabled && setOpen((open) => !open);
        }}
        title={dateValue}
        value={dateValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarIcon color={n1LightTheme.palette.grey.black60} size={compact ? '14px' : '18px'} />
            </InputAdornment>
          )
        }}
        autoComplete={'off'}
        compact={compact}
        marked={marked}
        fullWidth
      />
      <Grow in={open}>
        <CalendarContainer
          top={top}
          right={right}
          bottom={bottom}
          left={left}
          centerX={centerX ?? false}
          centerY={centerY ?? false}
        >
          <DatePickerHeader
            dateState={dateState}
            gotoPreviousMonth={gotoPreviousMonth}
            gotoNextMonth={gotoNextMonth}
            gotoPreviousYear={gotoPreviousYear}
            gotoNextYear={gotoNextYear}
          />
          <DefaultButtonsContainer>
            <DefaultButton onClick={() => selectDaysFromDate(0)} variant="secondary">
              I dag
            </DefaultButton>
            {hasDeadlineDate ? (
              <>
                <DefaultButton onClick={() => selectDaysFromDate(-7)} variant="secondary">
                  Næste 7 dage
                </DefaultButton>
                <DefaultButton onClick={() => selectAllDaysUntilToday()} variant="secondary">
                  Før i dag
                </DefaultButton>
              </>
            ) : (
              <>
                <DefaultButton onClick={() => selectDaysFromDate(7)} variant="secondary">
                  Sidste 7 dage
                </DefaultButton>
                <DefaultButton onClick={() => selectDaysFromDate(30)} variant="secondary">
                  Sidste 30 dage
                </DefaultButton>
              </>
            )}
          </DefaultButtonsContainer>
          <CalendarGrid borderBottom>
            {Object.keys(WEEK_DAYS).map((day, index) => {
              return (
                <CalendarDay key={`calendar-day-${index}`} index={index}>
                  {WEEK_DAYS[day as keyof typeof WEEK_DAYS]}
                </CalendarDay>
              );
            })}

            {weekNumbers().map((weekNumber, index) => {
              return <WeekNumberCell key={`week-number-${index}`}>{weekNumber}</WeekNumberCell>;
            })}

            <>{getCalendarDates().map(renderCalendarDate)}</>
          </CalendarGrid>
          <CalendarFooter>
            <CloseButton onClick={resetDates} variant="secondary">
              Nulstil
            </CloseButton>
            <CloseButton variant="action" onClick={applyAndClose}>
              Anvend
            </CloseButton>
          </CalendarFooter>
        </CalendarContainer>
      </Grow>
    </Root>
  );
};

const DefaultButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
`;

const CalendarFooter = styled.div`
  display: flex;
  justify-content: end;
  column-gap: 8px;
  font-size: 16px;
`;

const DefaultButton = styled(Button)`
  border-radius: 8px;
  padding: 6px 8px;
  font-weight: 400;
  height: 28px;
`;

const CloseButton = styled(Button)`
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 400;
  height: fit-content;
`;

export default memo(DateRangePicker, (prev: Props, props: Props) => {
  return prev.onDateChanged === props.onDateChanged && prev.error === props.error && prev.filterValue === props.value;
});
