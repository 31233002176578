import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextFieldProps } from '@mui/material';
import TextField from '../text-field/TextField';

type Props = {
  initialHours: number;
  initialMinutes: number;
  onTimeChange?: (hours: number, minutes: number) => void;
} & TextFieldProps;

const SimpleTime = (props: Props) => {
  const { initialHours, initialMinutes, onTimeChange, disabled, ...rest } = props;

  const [hours, setHours] = useState<number>(initialHours ?? 0);
  const [minutes, setMinutes] = useState<number>(initialMinutes ?? 0);

  useEffect(() => {
    onTimeChange && onTimeChange(hours, minutes);
  }, [hours, minutes]);

  return (
    <Container>
      {props.label && <StyledLabel>{props.label}</StyledLabel>}
      <LabelContainer>
        <StyledTextField
          {...rest}
          label=""
          onChange={(e) => setHours(parseInt(e.target.value))}
          InputProps={{ inputProps: { min: 0 } }}
          type="number"
          value={String(hours)}
          sx={{ input: { textAlign: 'center' } }}
          width="20px"
          disabled={disabled}
        />
        <StyledHelpText>Timer</StyledHelpText>
      </LabelContainer>

      <LabelContainer>
        <StyledTextField
          {...rest}
          label=""
          onChange={(e) => setMinutes(parseInt(e.target.value))}
          InputProps={{ inputProps: { min: 0, max: 59 } }}
          onFocus={(e) => e.target.select()}
          type="number"
          value={String(minutes)}
          sx={{
            input: { textAlign: 'center' }
          }}
          width="20px"
          disabled={disabled}
        />
        <StyledHelpText>Minutter</StyledHelpText>
      </LabelContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0 !important;
  align-items: center;
`;

const StyledLabel = styled.label`
  color: ${(props) => props.theme.palette.grey.black40};
  margin-right: 10px;
  min-width: 100px;
`;

const StyledHelpText = styled.label`
  color: ${(props) => props.theme.palette.grey.black40};
  margin-right: 10px;
  font-size: 10px;
`;

export const StyledTextField = styled(TextField)`
  margin: 5px !important;

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: 100%;
    left: 45%;
    transform: translateX(-50%);
    margin: 3px 0;
  }

  .MuiOutlinedInput-input {
    padding: 8px 16px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export default SimpleTime;
