import styled from 'styled-components';
import { WorkTaskStatus } from '../../api/api';

interface Props {
  status?: WorkTaskStatus;
  statusValue?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const StatusTag = (props: Props) => {
  const { children, status, statusValue, onClick, ...rest } = props;

  let color = '#a3a3a3';
  switch (status) {
    case WorkTaskStatus.Created:
      color = '#8266E6';
      break;
    case WorkTaskStatus.ReadyForPlanning:
      color = '#557FFE';
      break;
    case WorkTaskStatus.Planned:
      color = '#FFBB89';
      break;
    case WorkTaskStatus.PlannedAppointment:
      color = '#FF7A19';
      break;
    case WorkTaskStatus.OnRoute:
      color = '#FFEE52';
      break;
    case WorkTaskStatus.Ongoing:
      color = '#79C240';
      break;
    case WorkTaskStatus.Pause:
      color = '#DC4040';
      break;
    case WorkTaskStatus.Completed:
      color = '#60935D';
      break;
    case WorkTaskStatus.Processed:
      color = '#C7C7C7';
      break;
  }
  switch (statusValue?.toLowerCase()) {
    case undefined:
      break;
    case 'oprettet':
      color = '#8266E6';
      break;
    case 'klar til planlægning':
      color = '#557FFE';
      break;
    case 'planlagt':
      color = '#FFBB89';
      break;
    case 'planlagt (aftale)':
      color = '#FF7A19';
      break;
    case 'på rute':
      color = '#FFEE52';
      break;
    case 'igangværende':
      color = '#79C240';
      break;
    case 'pause':
      color = '#DC4040';
      break;
    case 'udført':
      color = '#60935D';
      break;
    case 'afsluttet':
      color = '#C7C7C7';
      break;
  }

  return (
    <Container onClick={onClick} {...rest}>
      <ColorTile color={color} />
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  column-gap: 8px;
`;

const ColorTile = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background: ${(props) => props.color};
`;

export default StatusTag;
