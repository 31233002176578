import { useCallback } from 'react';
import styled from 'styled-components';
import { InputType } from '../../api/api';
import { TaskComponent } from '../../models/TaskTypeComponent';
import { Section, SectionContent, SectionHeader } from '../../styling/FormStyling';
import { validateDynamicField } from '../../utils/validation/taskComponentAttributeValidation';
import TextRow, { InputTypes } from '../../views/task-view/task-details-view2/components/TextRow';
import { TaskComponentAttribute } from '../../models/TaskComponentAttribute';
import YesNoRadioButtons from '../../components/yes-no-radio-buttons/YesNoRadioButtons';

interface Props {
  component: TaskComponent;
  onChange?: (componentId: number, fieldId: number, value: any) => void;
  onBlur?: (componentId: number, fieldId: number, value: any) => void;
  mode: 'create' | 'read' | 'write';
  showError?: boolean;
}

const TaskTypeTemplateComponent = (props: Props) => {
  const { onChange, onBlur, component, mode, showError } = props;
  const { attributes, title } = props.component;

  const handleChange = useCallback(
    (value: string, attributeId: number) => {
      onChange && component.id && onChange(component.id, attributeId, value);
    },
    [component.id, onChange]
  );

  const handleBlur = useCallback(
    (value: string, attributeId: number) => {
      onBlur && component.id && onBlur(component.id, attributeId, value);
    },
    [component.id, onBlur]
  );

  const getTextField = (a: TaskComponentAttribute, type: InputTypes) => {
    return (
      <TextRow
        label={a.label ?? ''}
        value={a.value ?? ''}
        required={a.isMandatory}
        onBlur={(e) => a.attributeId && handleBlur(e, a.attributeId)}
        onChange={(e) => a.attributeId && handleChange(e, a.attributeId)}
        key={a.attributeId}
        inputMode={mode === 'write' || mode === 'create'}
        disabled={mode === 'write' && !a.isEditable}
        error={showError ? !validateDynamicField(a) : false}
        helperText={a.hintText}
        type={type}
        dataTestId={`${a.attributeId}-textfield`}
      />
    );
  };

  const getValueText = (value: string) => {
    switch (value) {
      case '1':
        return 'Ja';
      case '0':
        return 'Nej';
      default:
        return '';
    }
  };

  return (
    <Section>
      <SectionHeader>{title}</SectionHeader>
      <AttributesContainer direction="row">
        {attributes?.map((a) => {
          if (a.inputType === InputType.Text) {
            return <FieldContainer key={a.attributeId}>{getTextField(a, 'text')}</FieldContainer>;
          }

          if (a.inputType === InputType.Number) {
            return <FieldContainer key={a.attributeId}>{getTextField(a, 'number')}</FieldContainer>;
          }

          if (a.inputType === InputType.Email) {
            return <FieldContainer key={a.attributeId}>{getTextField(a, 'email')}</FieldContainer>;
          }

          if (a.inputType === InputType.Link) {
            return <FieldContainer key={a.attributeId}>{getTextField(a, 'link')}</FieldContainer>;
          }

          if (a.inputType === InputType.YesNo) {
            if (mode === 'write' || mode === 'create') {
              return (
                <FieldContainer key={a.attributeId}>
                  <YesNoRadioButtons
                    value={a.value}
                    handleValueChange={(value: number) =>
                      a.attributeId &&
                      (onChange
                        ? handleChange(value.toString(), a.attributeId)
                        : handleBlur(value.toString(), a.attributeId))
                    }
                    text={a.label ?? ''}
                    required={a.isMandatory}
                    error={showError ? !validateDynamicField(a) : false}
                    disabled={mode === 'write' && !a.isEditable}
                  />
                </FieldContainer>
              );
            }

            return (
              <FieldContainer key={a.attributeId}>
                <TextRow label={a.label ?? ''} value={getValueText(a.value)} />
              </FieldContainer>
            );
          }
          return <></>;
        })}
      </AttributesContainer>
    </Section>
  );
};

const FieldContainer = styled.div`
  min-width: calc(50% - 12px);
  box-sizing: border-box;
`;

const AttributesContainer = styled(SectionContent)`
  flex-wrap: wrap;
  column-gap: 24px;
`;

export default TaskTypeTemplateComponent;
