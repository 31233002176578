import { DepartmentDTO, ProjectDTO2 } from '../../../../../api/api';
import { SectionProps } from '../../../../../models/CommonProps/SectionProps';
import { useCallback, useEffect, useState } from 'react';
import ProjectsService from '../../../../../services/ProjectsService';
import styled from 'styled-components';
import TextField from '../../../../../components/text-field/TextField';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import { useDispatch } from 'react-redux';
import { updateTask } from '../../../../../stateManagement/reducers/taskCreationReducer';
import { TaskCreationTask } from '../../../../../models/TaskCreationTask';
import { addBusinessAndHolidays, formatDateString } from '../../../../../utils/dateHandling';
import DepartmentDropdown from '../../../../../components/department-dropdown/DepartmentDropdown';
import { log } from '../../../../../utils/logging/log';
import DatePicker from '../../../../../components/date-picker/DatePicker';
import { SectionContent, Row } from '../../../../../styling/FormStyling';
import AutoComplete from '../../../../../components/auto-complete/AutoComplete';
import { updateMassCreationTask } from '../../../../../stateManagement/reducers/massCreationReducer';
import { MassCreationTask } from '../../../../../models/MassCreationTask';
import { convertHoursAndMinutesToMinutes, convertMinutesToHoursAndMinutes } from '../../../../../utils/timeHandling';
import SimpleTime from '../../../../../components/simple-time/SimpleTime';
import { PLANNING_DEPARTMENT_ID } from '../../../../../utils/constants';

interface MappedProjectNumber {
  label: string;
  value: ProjectDTO2;
}

interface Props extends SectionProps {
  task: TaskCreationTask | MassCreationTask;
  isMassCreation?: boolean;
}

const DetailsSection = (props: Props) => {
  const { task, readonlyMode, isMassCreation } = props;

  const {
    assignedToDepartmentId = PLANNING_DEPARTMENT_ID,
    deadline = new Date().toISOString(),
    earliestStartDate,
    projectNumber,
    normTimeMin = 0,
    baseErrors
  } = task;

  const { taskCreationId = '', normTimeValidated = false, description } = task as TaskCreationTask;

  const [localNormTime, setLocalNormTime] = useState(normTimeMin);
  const [projectNumbers, setProjectNumbers] = useState<ProjectDTO2[]>([]);
  const [selectedProjectNumber, setSelectedProjectNumber] = useState<MappedProjectNumber | null>(null);
  const [descriptionValue, setDescriptionValue] = useState(description);
  const [isLoadingProjectNumbers, setIsLoadingProjectNumbers] = useState(true);

  const dispatch = useDispatch();

  const projectNumberToOption = useCallback((p: ProjectDTO2): MappedProjectNumber => {
    return {
      label: `${p.id} - ${p.name} (${p.legalEntityId})`,
      value: p
    };
  }, []);

  const mappedProjectNumbers = projectNumbers.map((p) => projectNumberToOption(p));

  const handleStartDateChange = useCallback(
    (date: string | null | undefined) => {
      if (date) {
        const newDeadline = addBusinessAndHolidays(
          new Date(date),
          task?.taskType?.slaDays && task?.taskType.slaDays > 0 ? task?.taskType?.slaDays : 0
        ).toISOString();
        const newStartDate = new Date(date).toISOString();

        const updatedTask = { earliestStartDate: newStartDate, deadline: newDeadline };

        if (isMassCreation) {
          dispatch(updateMassCreationTask(updatedTask));
        } else {
          dispatch(
            updateTask({
              id: taskCreationId,
              task: updatedTask
            })
          );
        }
      }
    },
    [dispatch, isMassCreation, task?.taskType?.slaDays, taskCreationId]
  );

  const handleDeadlineDateChange = useCallback(
    (date: string | null | undefined) => {
      if (date) {
        const newDeadline = new Date(date).toISOString();
        if (isMassCreation) {
          dispatch(updateMassCreationTask({ deadline: newDeadline }));
        } else {
          dispatch(updateTask({ id: taskCreationId, task: { deadline: newDeadline } }));
        }
      }
    },
    [dispatch, isMassCreation, taskCreationId]
  );

  const updateProjectNumber = useCallback(
    (project: { label: string; value: ProjectDTO2 }) => {
      setSelectedProjectNumber(project);

      const updatedTask = {
        projectNumber: project?.value.id,
        projectLegalEntityId: project?.value.legalEntityId,
        region: task.gisRegion
      };

      if (isMassCreation) {
        dispatch(updateMassCreationTask(updatedTask));
      } else {
        dispatch(
          updateTask({
            id: taskCreationId,
            task: updatedTask
          })
        );
      }
    },
    [dispatch, isMassCreation, task, taskCreationId]
  );

  const handleProjectNumberChange = useCallback(
    (_, value: string | undefined, reason) => {
      if (reason === 'clear') {
        setSelectedProjectNumber(null);
      }
      const project = mappedProjectNumbers.find((project) => project.value.id + project.value.legalEntityId === value);
      project && updateProjectNumber(project);
    },
    [mappedProjectNumbers, updateProjectNumber]
  );

  const handleOnChangeSendTo = (assignedToDepartment: DepartmentDTO) => {
    if (isMassCreation) {
      dispatch(updateMassCreationTask({ assignedToDepartmentId: assignedToDepartment.departmentId }));
    } else {
      dispatch(updateTask({ id: taskCreationId, task: { assignedToDepartmentId: assignedToDepartment.departmentId } }));
    }
  };

  const handleNormTimeValidatedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isMassCreation) {
      dispatch(updateMassCreationTask({ normTimeValidated: e.target.checked }));
    } else {
      dispatch(updateTask({ id: taskCreationId, task: { normTimeValidated: e.target.checked } }));
    }
  };

  const handleNormTimeBlur = useCallback(
    (normTime: number) => {
      if (isMassCreation) {
        dispatch(updateMassCreationTask({ normTimeMin: normTime }));
      } else {
        dispatch(updateTask({ id: taskCreationId, task: { normTimeMin: normTime } }));
      }
    },
    [dispatch, isMassCreation, taskCreationId]
  );

  const handleDescriptionBlur = useCallback(() => {
    dispatch(updateTask({ id: taskCreationId, task: { description: descriptionValue } }));
  }, [descriptionValue, dispatch, taskCreationId]);

  useEffect(() => {
    if (earliestStartDate === undefined) {
      handleStartDateChange && handleStartDateChange(new Date().toISOString());
    }
  }, [earliestStartDate, handleStartDateChange]);

  useEffect(() => {
    if (projectNumbers.length === 0) {
      ProjectsService.getProjectsByCategory(undefined)
        .then((res) => {
          if (task.projectNumber) {
            const selected = res.find((p) => p.id === projectNumber);
            if (selected) {
              updateProjectNumber(projectNumberToOption(selected));
            }
          }
          setProjectNumbers(res);
        })
        .catch((err) => {
          log(err);
        })
        .finally(() => {
          setIsLoadingProjectNumbers(false);
        });
    }
  }, [projectNumber, projectNumberToOption, projectNumbers.length, task?.projectNumber, updateProjectNumber]);

  const selectDepartment = (d: DepartmentDTO) => {
    handleOnChangeSendTo(d);
  };

  const timeChangeHandler = useCallback((hours, minutes) => {
    setLocalNormTime(convertHoursAndMinutesToMinutes(hours, minutes));
  }, []);

  return (
    <SectionContent>
      <Row>
        <RowItemContainer>
          <SimpleTime
            initialHours={convertMinutesToHoursAndMinutes(normTimeMin).hours}
            initialMinutes={convertMinutesToHoursAndMinutes(normTimeMin).minutes}
            onTimeChange={(hours, minutes) => timeChangeHandler(hours, minutes)}
            onBlur={() => handleNormTimeBlur(localNormTime)}
          />
        </RowItemContainer>
        <RowItemContainer>
          {!readonlyMode && (
            <Checkbox
              label="Normtid bekræftet"
              checked={normTimeValidated}
              onChange={handleNormTimeValidatedChange}
              data-testid="normtid-checkbox"
              error={baseErrors?.normTimeValidated}
            />
          )}
        </RowItemContainer>
      </Row>

      <Row>
        <DatePicker
          onDateChanged={(date) => handleStartDateChange(date.toISOString())}
          value={earliestStartDate ? formatDateString(earliestStartDate) : ''}
          label="Startdato"
          disabled={readonlyMode}
          required
          error={baseErrors?.earliestStartDate}
        />
        <DatePicker
          onDateChanged={(date) => handleDeadlineDateChange(date.toISOString())}
          value={formatDateString(deadline)}
          label="Slutdato"
          disabled={readonlyMode}
          required
          error={baseErrors?.deadline}
          earliestStartDate={earliestStartDate ? new Date(earliestStartDate) : undefined}
        />
      </Row>

      <Row>
        <RowItemContainer>
          <DepartmentDropdown
            selectDepartment={selectDepartment}
            value={assignedToDepartmentId}
            disabled={readonlyMode}
            fullWidth
            error={baseErrors?.assignedToDepartmentId}
          />
        </RowItemContainer>

        <RowItemContainer>
          <AutoComplete
            id="project-number"
            onChange={(event, value, reason) =>
              handleProjectNumberChange(event, (value?.value.id ?? '') + value?.value.legalEntityId, reason)
            }
            getOptionLabel={(option) => option.label ?? ''}
            options={mappedProjectNumbers}
            value={selectedProjectNumber}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Projektnummer'}
                fullWidth
                required
                error={baseErrors?.projectNumber}
                dataTestId="task-create-project-select"
              />
            )}
            isOptionEqualToValue={(option, value) => option.value.id === value.value.id}
            disabled={readonlyMode || isLoadingProjectNumbers}
            fullWidth
            noOptionsText={'Intet projektnummer' ?? ''}
            loading={true}
          />
        </RowItemContainer>
      </Row>

      {!isMassCreation && (
        <Row>
          <TextField
            multiline
            maxRows={3}
            minRows={2}
            label="Opgavebeskrivelse"
            variant="outlined"
            onBlur={() => handleDescriptionBlur()}
            placeholder="Angiv en kort beskrivelse af opgaven"
            value={descriptionValue}
            onChange={(e) => setDescriptionValue(e.target.value)}
            required
            fullWidth
            dataTestId="task-description-textfield"
            error={baseErrors?.description}
          />
        </Row>
      )}
    </SectionContent>
  );
};

const RowItemContainer = styled.div`
  flex: 1 1 0;
  width: 0;
  display: flex;
  align-items: center;
`;

export default DetailsSection;
