import { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { TimeRegistrationDTO } from '../../api/api';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';
import { EditIcon } from '../../assets/icons/EditIcon';
import { KebabMenuIcon } from '../../assets/icons/KebabMenuIcon';
import Grow from '../../components/grow';
import IconButton from '../../components/icon-button/IconButton';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface Props {
  handleEdit: (args: any) => void;
  handleDelete: (id: number) => void;
  registration: TimeRegistrationDTO;
  overrideDisable?: boolean;
  isNegativeTime?: boolean;
}

const TimeRegMenu = (props: Props) => {
  const { id, approvedByUser, date, hours, category, description, project, workTaskId, categoryGroupId, isDeleted } =
    props.registration;
  const { handleEdit, handleDelete, overrideDisable, isNegativeTime = false } = props;
  const [open, setOpen] = useState(false);

  const rootRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(rootRef, () => setOpen(false));

  const editAndClose = useCallback(
    (args: any) => {
      handleEdit(args);
      setOpen(false);
    },
    [handleEdit]
  );

  const deleteAndClose = useCallback(
    (id: number) => {
      handleDelete(id);
    },
    [handleDelete]
  );

  return (
    <Root ref={rootRef} style={{ position: 'relative' }}>
      <IconButton padding="8px" onClick={() => setOpen((open) => !open)} data-testid="timereg-table-kebab-button">
        <KebabMenuIcon size="24px" />
      </IconButton>
      <Grow in={open}>
        <DropdownContainer style={{ position: 'absolute' }}>
          <MenuItem
            onClick={() => id && deleteAndClose(id)}
            data-testid="timereg-table-delete-button"
            disabled={isDeleted || isNegativeTime}
          >
            <DeleteIcon size="16px" />
            Slet
          </MenuItem>
          {id && date && category && !isNegativeTime ? (
            <MenuItem
              disabled={approvedByUser || overrideDisable}
              onClick={() => editAndClose({ id, date, hours, category, description, project, workTaskId, categoryGroupId })}
              data-testid="timereg-table-user-delete-button"
            >
              <EditIcon size="18px" />
              Rediger
            </MenuItem>
          ) : (
            <MenuItem disabled>
              <EditIcon size="18px" />
              Rediger
            </MenuItem>
          )}
        </DropdownContainer>
      </Grow>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 0;
  right: 35px;
  display: flex;
  flex-direction: column;
  padding: 18px 8px;
  z-index: ${(props) => props.theme.zIndex.kebabMenu};

  border-radius: 24px;
  border: 1px solid #dbdbdb;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 7%);
`;

const MenuItem = styled.span<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    background: #f2f4fe;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: auto;
      pointer-events: none;
      color: ${(props) => props.theme.palette.grey.black20};
      :hover {
        background: none;
      }
      svg {
        fill: ${(props) => props.theme.palette.grey.black20};
        stroke: ${(props) => props.theme.palette.grey.black20};
      }
    `}
`;

export default TimeRegMenu;
