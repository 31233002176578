import { useEffect, useState } from 'react';
import styled from 'styled-components';
import UserService from '../../../services/UserService';
import { CreatePortalLinkDto, PortalLinkDto } from '../../../api/api';
import NotificationService from '../../../services/NotificationService';
import { log } from '../../../utils/logging/log';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import { DialogBody } from '../../../stateManagement/reducers/confirmDialogReducer';
import { CreateLinkDTO, LinkDTO } from '../../../models/LinkModel';
import LinksView from '../../../blocks/link-table/LinksView';

const confirmationDialog = {
  headerText: `Er du sikker`,
  bodyText: `Er du sikker på at du vil slette dette link?`,
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Slet'
} as DialogBody;

const AdminPortalLinksView = () => {
  const [links, setLinks] = useState<PortalLinkDto[]>([]);
  const [loading, setLoading] = useState(false);
  const { getConfirmation } = useConfirmationDialog();

  useEffect(() => {
    setLoading(true);
    UserService.getAllPortalLinks()
      .then((data) => {
        setLinks(data.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)));
      })
      .catch((e) => {
        NotificationService.error('Kunne ikke hente links');
        log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const deletePortalLinkHandler = async (id: number) => {
    const confirmation = await getConfirmation(confirmationDialog);

    if (confirmation === 'confirm') {
      UserService.deletePortalLink(id)
        .then(() => {
          const newLinks = links.filter((link) => link.id !== id);
          setLinks(newLinks);
          updateOrderNumbersHandler(newLinks);
        })
        .then(() => {
          NotificationService.success('Linket blev slettet');
        })
        .catch((error) => {
          NotificationService.error('Linket kunne ikke slettet');
          log(error);
        });
    }
  };

  const updateOrderNumbersHandler = (_links: PortalLinkDto[]) => {
    setLoading(true);
    const updateOrderNumbers = _links.map((link, index) => ({ ...link, order: index + 1 }));
    UserService.updateOrderNumbers(updateOrderNumbers)
      .then(() => {
        setLinks(updateOrderNumbers);
      })
      .catch((e) => {
        NotificationService.error('Kunne ikke opdatere rækkefølge på links');
        log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createNewLinkHandler = (newLink: CreateLinkDTO) => {
    const newPortalLink = {
      link: newLink.url,
      linkText: newLink.linkText,
      order: links?.length + 1
    } as CreatePortalLinkDto;

    setLoading(true);
    UserService.createPortalLink(newPortalLink)
      .then((createdLink) => {
        setLinks((prevLinks) => [...prevLinks, createdLink]);
        NotificationService.success('Link blev oprettet');
      })
      .catch((e) => {
        NotificationService.error('Kunne ikke oprette link');
        log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateNewLinkHandler = (link: LinkDTO) => {
    const updatedPortalLink = {
      id: link.id,
      link: link.url,
      linkText: link.linkText,
      order: link.order
    } as CreatePortalLinkDto;

    setLoading(true);
    UserService.editPortalLink(link.id, updatedPortalLink)
      .then(() => {
        NotificationService.success('Link blev opdateret');
      })
      .catch((e) => {
        NotificationService.error('Kunne ikke opdatere link');
        log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <LinksView
        title="Portallink"
        loading={loading}
        links={links.map((l) => ({ ...l, url: l.link } as LinkDTO))}
        createLinkHandler={createNewLinkHandler}
        deleteLinkHandler={deletePortalLinkHandler}
        editLinkHandler={updateNewLinkHandler}
        updateOrderHandler={updateOrderNumbersHandler}
      />
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;
`;

export default AdminPortalLinksView;
