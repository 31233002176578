import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseWorkTaskTypeEnum, WorkTaskStatus } from '../../api/api';
import { RootState } from '../store';

interface TaskStatusDialogState {
  display?: boolean;
  taskId?: number;
  baseWorkTaskType?: BaseWorkTaskTypeEnum;
  taskTypeId?: number;
  taskFlsId?: number;
  hasFuturePartialTask: boolean;
  taskStatus: WorkTaskStatus | undefined;
}

const initialState: TaskStatusDialogState = {
  display: false,
  taskId: undefined,
  baseWorkTaskType: undefined,
  taskTypeId: undefined,
  taskFlsId: -1,
  hasFuturePartialTask: false,
  taskStatus: undefined
};

const taskStatusDialogSlice = createSlice({
  name: 'taskStatusDialog',
  initialState: initialState,
  reducers: {
    openTaskStatusDialog: (
      state: TaskStatusDialogState,
      payload: PayloadAction<{
        taskId: number;
        baseWorkTaskType: BaseWorkTaskTypeEnum;
        taskTypeId: number;
        taskFlsId: number;
        hasFuturePartialTask: boolean;
        taskStatus: WorkTaskStatus;
      }>
    ) => {
      state.display = true;
      state.taskId = payload.payload.taskId;
      state.baseWorkTaskType = payload.payload.baseWorkTaskType;
      state.taskTypeId = payload.payload.taskTypeId;
      state.taskFlsId = payload.payload.taskFlsId;
      state.hasFuturePartialTask = payload.payload.hasFuturePartialTask;
      state.taskStatus = payload.payload.taskStatus;
    },
    closeTaskStatusDialog: (state: TaskStatusDialogState) => {
      state.display = false;
    }
  }
});

export const { openTaskStatusDialog, closeTaskStatusDialog } = taskStatusDialogSlice.actions;
export const selectTaskStatusDialogState = (state: RootState) => state.taskStatusDialog;

export default taskStatusDialogSlice.reducer;
