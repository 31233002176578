import { TableCell, TableRow } from '@mui/material';
import n1LightTheme from '../../../../theme/light-theme';

interface Props {
  text: string;
}

const NoDataTableRow = (props: Props) => {
  const { text } = props;

  return (
    <TableRow>
      <TableCell sx={{ color: n1LightTheme.palette.grey.black40 }} colSpan={12}>
        {text}
      </TableCell>
    </TableRow>
  );
};

export default NoDataTableRow;
