import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import {
  InputType,
  QuestionDTO,
  SectionQuestionDTO,
  SectionQuestionTextDTO,
  TaskTypeSectionDTO
} from '../../../../../api/api';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import useTableInstance from '../../../../../hooks/useTableInstance';
import { getEnumDisplayValue } from '../../../../../utils/enumUtils';
import { SearchColumnFilter, SelectColumnFilter, multiSelectFilterFn } from '../../../../../blocks/table/TableFilterUtils';
import { ChecklistQuestionData } from '../../../admin-checklist-questions-view/useChecklistQuestionList';

export interface ReusableComponentsData {
  id?: number;
  label?: string;
  inputType?: InputType;
  hintText?: string;
}

const useAddQuestionTable = (
  allQuestions: QuestionDTO[],
  questionUpdatedCallback: (question: QuestionDTO, checked: boolean) => void,
  previousQuestions: SectionQuestionDTO[],
  activeSection?: TaskTypeSectionDTO,
  selectedQuestions?: SectionQuestionTextDTO[]
) => {
  const data = useMemo(() => {
    const alreadySelectedIds = previousQuestions.map((prevQuestion) => prevQuestion.questionId);
    return allQuestions
      .filter((question) => !alreadySelectedIds.includes(question.id))
      .map((q) => {
        return {
          ...q,
          questionType: q.questionType ? getEnumDisplayValue(q.questionType) : ''
        } as ChecklistQuestionData;
      });
  }, [allQuestions, previousQuestions]);

  const columns = useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'checked',
        disableFilters: true,
        disableSortBy: true,
        width: 50,
        Cell: (cellProps: CellProps<QuestionDTO>) => {
          const { cell } = cellProps;
          return (
            <Checkbox
              onChange={(e) => questionUpdatedCallback(cell.row.original, e.target.checked)}
              checked={selectedQuestions?.some((q) => q.questionId === cell.row.original.id)}
            />
          );
        }
      },
      {
        Header: 'Id',
        accessor: 'id',
        width: 50,
        minWidth: 50,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Navn',
        accessor: 'text',
        width: 200,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Svartype',
        accessor: 'questionType',
        width: 100,
        minWidth: 100,
        Filter: SelectColumnFilter,
        filter: multiSelectFilterFn,
        Cell: (cellProps: CellProps<QuestionDTO>) => {
          const { cell } = cellProps;
          return cell.row.original?.questionType ? getEnumDisplayValue(cell.row.original?.questionType) : '';
        }
      }
    ] as Column<ReusableComponentsData>[];
  }, [questionUpdatedCallback, selectedQuestions]);

  const tableInstance = useTableInstance<ReusableComponentsData>(data, columns);

  return { tableInstance };
};

export default useAddQuestionTable;
