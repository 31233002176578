import { useCallback, useState } from 'react';

import CustomerAppointmentDateForm from '../../../../blocks/customer-appointment-form/CustomerAppointmentDateForm';
import CustomerAppointmentContactForm from '../../../../blocks/customer-appointment-form/CustomerAppointmentContactForm';

import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';

import TaskService from '../../../../services/TaskService';

import { CustomerAppointmentDTO } from '../../../../api/api';
import Button from '../../../../components/button/Button';
import styled from 'styled-components';
import { AppointmentErrors } from '../../../../models/CreationErrors';
import NotificationService from '../../../../services/NotificationService';

interface Props extends TabProps {
  taskId?: string;
  onSubmitSuccess: (customerAppointment?: CustomerAppointmentDTO) => void;
}

export const CustomerAppointmentStep = (props: Props) => {
  const { taskId, onSubmitSuccess } = props;

  const [values, setValues] = useState<CustomerAppointmentDTO>();

  const [errors, setErrors] = useState<AppointmentErrors>();
  const [submitting, setSubmitting] = useState(false);

  const handleValidate = (values: CustomerAppointmentDTO) => {
    let errors: AppointmentErrors = {};

    if (values.appointmentRequest && !values.customerName) errors.customerName = true;

    if (values.appointmentRequest && (!values.customerPhoneNumber || values.customerPhoneNumber.length !== 8))
      errors.customerPhoneNumber = true;

    return errors;
  };

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>, values: CustomerAppointmentDTO) => {
      event.preventDefault();

      if (!taskId) return;

      setErrors(undefined);

      const errors = handleValidate(values);

      if (Object.keys(errors).length) return setErrors(errors);

      const appointment = { ...values, appointmentRequest: true };
      setSubmitting(true);
      TaskService.customerAppointment(parseInt(taskId), appointment)
        .then(() => {
          NotificationService.success(`Kontaktoplysninger og evt forespørgel til tidsaftale er registreret`);
          onSubmitSuccess(appointment);
          setSubmitting(false);
        })
        .catch((error) => {
          NotificationService.error(
            `Der opstod en fejl oprettelse af kontaktoplysninger og evt forespørgsel til tidsaftale`
          );
          setSubmitting(false);
        });
    },
    [onSubmitSuccess, taskId]
  );

  const handleChange = useCallback((key: keyof CustomerAppointmentDTO, value: string) => {
    setValues((prevState) => {
      const newValues = prevState ? { ...prevState, [key]: value } : { [key]: value };

      return newValues;
    });
  }, []);

  return (
    <CustomerAppointmentFormRoot onSubmit={(event) => values && handleSubmit(event, values)}>
      <CustomerAppointmentContactForm onChange={handleChange} errors={errors} />
      <CustomerAppointmentDateForm onChange={handleChange} errors={errors} />
      <CustomerAppointmentFormFooter>
        <Button type="submit" disabled={submitting}>
          Send forslag til planlægning
        </Button>
      </CustomerAppointmentFormFooter>
    </CustomerAppointmentFormRoot>
  );
};

const CustomerAppointmentFormRoot = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CustomerAppointmentFormFooter = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 20px;
`;

export default CustomerAppointmentStep;
