import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProfileDTO22 } from '../../api/api';
import { RootState } from '../store';

export interface UserProfileState {
  userProfile: ProfileDTO22;
  isLoaded: boolean;
}

const initialState: UserProfileState = {
  userProfile: {
    department: undefined,
    firstLoad: undefined,
    role: undefined,
    defaultWarehouseId: undefined,
    vehicle: undefined,
    name: undefined
  },
  isLoaded: false
};

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: initialState,
  reducers: {
    setUserProfileState: (state: UserProfileState, action: PayloadAction<ProfileDTO22>) => {
      state.userProfile = action.payload;
      state.isLoaded = true;
    }
  }
});

export const { setUserProfileState } = userProfileSlice.actions;
export const selectUserProfile = (state: RootState) => state.userProfile;

export default userProfileSlice.reducer;
