import { useCallback, useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { DepartmentDTO, ReasonCodeDTO, UpdateReasonCodeStatusDTO2 } from '../../../../../api/api';
import useTableInstance from '../../../../../hooks/useTableInstance';
import { DialogBody } from '../../../../../stateManagement/reducers/confirmDialogReducer';
import { getEnumDisplayValue } from '../../../../../utils/enumUtils';
import { useConfirmationDialog } from '../../../../../hooks/useConfirmationDialog';
import { MenuItem } from '../../../../../components/select/Select';
import KebabMenu from '../../../../../components/kebab-menu/KebabMenu';

const dialogBody: DialogBody = {
  headerText: `Er du sikker?`,
  bodyText: 'Er du sikker på at du vil slette denne årsag?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

const useSelectedFlowTable = (
  selectedReasonCodes: Array<UpdateReasonCodeStatusDTO2>,
  allReasonCodes: Array<ReasonCodeDTO>,
  allDepartments: Array<DepartmentDTO>,
  setSelectedReasonCodes: (selectedReasonCodes: UpdateReasonCodeStatusDTO2[]) => void
) => {
  const { getConfirmation } = useConfirmationDialog();

  const data = useMemo(() => {
    return selectedReasonCodes.map((reasonCode) => {
      return {
        ...reasonCode,
        name: allReasonCodes.find((r) => r.reasonCodeId === reasonCode.reasonCodeId)?.reasonText,
        sendToDepartment: allDepartments.find((d) => d.departmentId === reasonCode.sendToDepartmentId)?.name ?? '-'
      };
    });
  }, [selectedReasonCodes, allReasonCodes, allDepartments]);

  const getStatus = useCallback(
    (reasonCodeId?: number) => {
      const status = allReasonCodes.find((reason) => reason.reasonCodeId === reasonCodeId)?.status;
      if (status) return getEnumDisplayValue(status);
      return '';
    },
    [allReasonCodes]
  );

  const deleteReasonHandler = useCallback(
    async (reasonCodeId?: number) => {
      if (!reasonCodeId) return;

      const confirmation = await getConfirmation(dialogBody);
      if (confirmation === 'confirm') {
        const reasons = selectedReasonCodes.filter((rc) => rc.reasonCodeId !== reasonCodeId);
        setSelectedReasonCodes(reasons);
      }
    },
    [getConfirmation, selectedReasonCodes, setSelectedReasonCodes]
  );

  const swapElements = (_array: UpdateReasonCodeStatusDTO2[], index1: number, index2: number) => {
    if (
      !selectedReasonCodes ||
      index1 === selectedReasonCodes.length ||
      index2 === selectedReasonCodes.length ||
      index1 === -1 ||
      index2 === -1
    )
      return;
    const array = [..._array];
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
    setSelectedReasonCodes(array);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'reasonCodeId',
        disableFilters: true,
        width: 50
      },
      {
        Header: 'Navn',
        accessor: 'name',
        disableFilters: true,
        width: 200
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        width: 100,
        Cell: (cellProps: CellProps<UpdateReasonCodeStatusDTO2>) => {
          const { cell } = cellProps;
          return getStatus(cell.row.original.reasonCodeId);
        }
      },
      {
        Header: 'Sendes til',
        accessor: 'sendToDepartment',
        width: 200,
        disableFilters: true,
        Cell: (cellProps: CellProps<UpdateReasonCodeStatusDTO2>) => {
          const { cell } = cellProps;
          return cell.row.original.sendToCreatingDepartment ? 'Oprettende afdeling' : cell.value;
        }
      },
      {
        Header: '',
        accessor: 'delete',
        width: 50,
        disableFilters: true,
        Cell: (cellProps: CellProps<UpdateReasonCodeStatusDTO2>) => {
          const { cell } = cellProps;
          const index = cell.row.index;
          return (
            <KebabMenu>
              {index !== undefined && (
                <MenuItem onClick={() => swapElements(selectedReasonCodes, index, index - 1)}>Ryk op</MenuItem>
              )}
              {index !== undefined && (
                <MenuItem onClick={() => swapElements(selectedReasonCodes, index + 1, index)}>Ryk ned</MenuItem>
              )}
              <MenuItem onClick={() => deleteReasonHandler(cell.row.original.reasonCodeId)}>Slet årsag</MenuItem>
            </KebabMenu>
          );
        }
      }
    ] as Column<UpdateReasonCodeStatusDTO2>[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStatus, selectedReasonCodes]);

  const tableInstance = useTableInstance<UpdateReasonCodeStatusDTO2>(data, columns);

  return { tableInstance };
};

export default useSelectedFlowTable;
