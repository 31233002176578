import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { CreateTransferOrderDTO, WarehouseDTO } from '../../../api/api';
import Button from '../../../components/button/Button';
import LoadingOverlay from '../../../components/loading-overlay/LoadingOverlay';
import TextField from '../../../components/text-field/TextField';
import GoodsService from '../../../services/GoodsService';
import NotificationService from '../../../services/NotificationService';
import { selectUserProfile } from '../../../stateManagement/reducers/userProfileReducer';
import { log } from '../../../utils/logging/log';
import { Container, DialogContent } from '../components/Styles';
import GoodsList from './goods-list/GoodsList';
import SelectedGoodsList from '../components/SelectedGoodsList';
import { ProductDetails, SelectedGoods } from '../../../models/Goods';
import DatePicker from '../../../components/date-picker/DatePicker';
import { formatDateString } from '../../../utils/dateHandling';
import NavigationHeader from '../components/NavigationHeader';
import SelectedGoodsIcon from '../components/SelectedGoodsBadge';
import {
  selectOrderGoodsDeliveryDate,
  selectOrderGoodsList,
  selectOrderGoodsNotes,
  selectOrderGoodsWarehouseId,
  setDeliveryDate,
  setNotes,
  setOrderGoodsList,
  setSelectedWarehouseId
} from '../../../stateManagement/reducers/orderGoodsReducer';
import CriticalOrderPopover from '../../../blocks/critical-order-popover/CriticalOrderPopOver';
import BasicPopover from '../../../components/popover/Popover';
import BasketPopover from '../components/BasketPopover';

interface Props {
  onClose: () => void;
}

const OrderGoodsContent = (props: Props) => {
  const { onClose } = props;

  const userVehicle = useSelector(selectUserProfile).userProfile?.vehicle;
  const selectedGoods = useSelector(selectOrderGoodsList);
  const selectedWarehouseId = useSelector(selectOrderGoodsWarehouseId);
  const deliveryDate = useSelector(selectOrderGoodsDeliveryDate);
  const notes = useSelector(selectOrderGoodsNotes);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState<'select' | 'order'>('select');

  const [goods, setGoods] = useState<ProductDetails[]>([]);
  const [warehouses, setWarehouses] = useState<WarehouseDTO[]>();
  const [deliveryDateError, setDeliveryDateError] = useState(false);
  const [allowedDays, setAllowedDays] = useState<Date[] | null>([]);

  useEffect(() => {
    setIsLoading(true);
    GoodsService.getActiveProducts()
      .then((products) => {
        setGoods(products);
      })
      .catch((error) => {
        log(error);
        NotificationService.error('Kunne ikke hente vareliste');
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const isVehicleWarehouse = selectedWarehouseId.includes('V-');
      if (!isVehicleWarehouse) {
        setAllowedDays([]);
        dispatch(setDeliveryDate(''));
        return;
      }
      try {
        const days = (await GoodsService.getAllowedDeliveryDates(selectedWarehouseId)).map((str) => new Date(str));
        setAllowedDays(days);
        dispatch(setDeliveryDate(''));
      } catch (error) {
        log(error);
        NotificationService.error('Kan ikke hente tilladte datoer for det valgte køretøj');
      }
    };
    fetchData();
  }, [dispatch, selectedWarehouseId]);

  useEffect(() => {
    GoodsService.getOrdersWarehouses()
      .then((res) => {
        setWarehouses(res);
        const warehouse = res.find((w) => w.warehouseId === userVehicle?.vehicleId);
        dispatch(setSelectedWarehouseId(warehouse?.warehouseId ?? ''));
      })
      .catch((error) => {
        log(error);
        NotificationService.error('Kunne ikke hente varelagererne. Prøv igen.');
      });
  }, [dispatch, userVehicle?.vehicleId]);

  const selectedCount = useMemo(() => {
    let count = 0;
    selectedGoods.forEach((goods) => {
      count = count + (goods?.qty ?? 1);
    });
    return count;
  }, [selectedGoods]);

  const handleSelectGoods = useCallback(
    (goodsId?: string, amount?: number) => {
      if (!goodsId) return;
      const amountToAdd = amount ?? 1;
      let totalAddedOfGood = 1;

      const { name, itemId } = goods.find((good) => good?.itemId === goodsId) ?? {};
      const goodsToAdd: SelectedGoods = { name, itemId, qty: amountToAdd };

      if (selectedGoods.some((good) => good?.itemId === goodsToAdd?.itemId)) {
        let tmpSelectedGoods = selectedGoods.map((selectedGood) => {
          if (selectedGood.itemId === goodsToAdd?.itemId) {
            totalAddedOfGood = selectedGood?.qty ? selectedGood.qty + amountToAdd : amountToAdd;
            return { ...selectedGood, qty: totalAddedOfGood };
          } else {
            return selectedGood;
          }
        });
        dispatch(setOrderGoodsList(tmpSelectedGoods));
      } else {
        dispatch(setOrderGoodsList([...selectedGoods, { ...goodsToAdd }]));
        totalAddedOfGood = amountToAdd;
      }
      NotificationService.success(`${totalAddedOfGood} x ${name} ligger nu i kurven`);
    },
    [dispatch, goods, selectedGoods]
  );

  const submitOrder = useCallback(() => {
    if (selectedGoods.some((item) => (item.qty && item?.qty < 1) || !item.qty)) {
      NotificationService.error('Det er ikke muligt at bestille mindre end 1 af en vare');
      return;
    }
    if (!deliveryDate) {
      setDeliveryDateError(true);
      NotificationService.error('Ønsket leveringsdato mangler. Udfyld feltet for at fortsætte.');
      return;
    }
    if (!selectedWarehouseId) {
      NotificationService.error("Lager er ikke angivet. Tryk tilbage og udfyld feletet 'Til lager' for at fortsætte.");
      return;
    }

    setIsLoading(true);
    let body: CreateTransferOrderDTO = {
      warehouseId: selectedWarehouseId,
      description: notes,
      items: selectedGoods.map((good) => ({ itemId: good.itemId, qty: good.qty })),
      receiptDate: deliveryDate !== '' ? deliveryDate : undefined
    };
    GoodsService.createTransferOrder(body)
      .then(() => {
        NotificationService.success('Ordre bestilt');
        dispatch(setOrderGoodsList([]));
        onClose();
      })
      .catch((error) => {
        NotificationService.error(`Kunne ikke oprette ordren: ${error}`);
        log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedGoods, selectedWarehouseId, notes, deliveryDate, onClose]);

  const handleSetSelectedGoodsAmount = useCallback(
    (value: number, goodsId?: string) => {
      if (!goodsId) return;

      const tmpSelectedGoods = selectedGoods.map((selectedGood) => {
        return selectedGood.itemId === goodsId ? { ...selectedGood, qty: value } : selectedGood;
      });
      dispatch(setOrderGoodsList(tmpSelectedGoods));
    },
    [dispatch, selectedGoods]
  );

  const removeSelectedGoods = useCallback(
    (goodsId?: string) => {
      if (!goodsId) return;
      const tmpSelectedGoods = selectedGoods.filter((selectedGood) => {
        return selectedGood.itemId !== goodsId;
      });
      dispatch(setOrderGoodsList(tmpSelectedGoods));
    },
    [dispatch, selectedGoods]
  );

  const deliveryDateChanged = (date: Date) => {
    dispatch(setDeliveryDate(date.toISOString()));
    setDeliveryDateError(false);
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      {page === 'select' && (
        <Container>
          <NavigationHeader headerTitle={'Bestil varer'} onClose={onClose} />
          <DialogContent>
            <StyledGoodsList
              goods={goods}
              selectGoods={handleSelectGoods}
              selectedWarehouse={warehouses?.find((w) => w.warehouseId === selectedWarehouseId)}
              setSelectedWarehouseId={(id) => dispatch(setSelectedWarehouseId(id))}
              warehouses={warehouses}
            />
            <Footer>
              <BasicPopover
                buttonElement={<SelectedGoodsIcon show count={selectedCount} />}
                popoverElement={<BasketPopover removeGoods={removeSelectedGoods} selectedGoods={selectedGoods} />}
                disablePopover={selectedCount === 0}
                activeByClick
              />
              <Button onClick={() => setPage('order')}>Gå til bestilling</Button>
            </Footer>
          </DialogContent>
        </Container>
      )}

      {page === 'order' && (
        <Container scroll>
          <NavigationHeader headerTitle={'Varekurv'} onBackButton={() => setPage('select')} onClose={onClose} />
          <DialogContent>
            <SelectedGoodsList
              setGoodsAmount={(value, goodsId) => handleSetSelectedGoodsAmount(value, goodsId)}
              removeGoods={removeSelectedGoods}
              selectedGoods={selectedGoods}
              showAvailable={false}
              showWarehouse
              selectedWarehouse={warehouses?.find((w) => w.warehouseId === selectedWarehouseId)}
              clearSelectedGoods={() => dispatch(setOrderGoodsList([]))}
              renderFullHeight
            />
            <Row>
              <StyledDatePicker
                required
                error={deliveryDateError}
                centerY
                onDateChanged={(date) => deliveryDateChanged(date)}
                label="Ønsket leveringsdato"
                value={formatDateString(deliveryDate)}
                {...(allowedDays ? { allowedDays } : {})}
              />
              <TextField
                onChange={(e) => dispatch(setNotes(e.target.value))}
                label="Evt. bemærkning til ordren vil blive vist på pakkelabel"
                inputProps={{ maxLength: 40 }}
                helperText="Beskeden kan max indeholde 40 tegn"
                fullWidth
              />
            </Row>
            <Footer>
              <CriticalOrderPopover
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
              />
              <StyledButton onClick={submitOrder} isLoading={isLoading}>
                Bestil varer {selectedCount > 0 ? `(${selectedCount})` : ''}
              </StyledButton>
            </Footer>
          </DialogContent>
        </Container>
      )}
    </>
  );
};

const StyledGoodsList = styled(GoodsList)`
  min-height: 250px;
  .table-footer {
    justify-content: end;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 50%;
  margin-right: ${(props) => props.theme.spacing(4)};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.theme.spacing(5)};
  justify-content: end;
`;

const Row = styled.div`
  margin-top: ${(props) => props.theme.spacing(5)};
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing(8)};
`;

export default OrderGoodsContent;
