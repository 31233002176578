import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import styled from 'styled-components';

import { TransferOrderDTO } from '../../../../api/api';
import { ChevronDown } from '../../../../assets/icons/ChevronDown';
import { ChevronUp } from '../../../../assets/icons/ChevronUp';
import IconButton from '../../../../components/icon-button/IconButton';
import useTableInstance from '../../../../hooks/useTableInstance';
import MoveToIssueLocation from './MoveToIssueLocation';

interface TransferOrderData {
  transferId?: string;
  transferStatus?: string;
  itemId?: string;
  quantity?: number;
  name?: string;
  moveToIssueLocationStatusConsignorId?: string;
  toggleExpand?: string;
}

const useOrderTable = (rows: TransferOrderDTO[], fetchTransferOrders: () => void) => {
  const data = useMemo(() => {
    if (!rows.length) return [];

    const lines: TransferOrderData[] = [];

    rows.forEach((r) => {
      r.items?.forEach((i) => {
        lines.push({
          transferId: r.transferId,
          transferStatus: r.transferStatus,
          itemId: i.itemId,
          quantity: i.qty,
          name: i.name,
          moveToIssueLocationStatusConsignorId: r.moveToIssueLocationStatus + ';' + r.consignorId + ';' + r.transferStatus
        });
      });
    });

    return lines;
  }, [rows]);

  const aggregrateStatus = (groupedRows: any) => {
    return groupedRows[0];
  };

  const aggregrateLines = (groupedRows: any) => {
    return groupedRows.length;
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Status',
        accessor: 'transferStatus',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        aggregate: aggregrateStatus,
        Cell: (cellProps: CellProps<TransferOrderData>) => (cellProps.cell.isAggregated ? cellProps.cell.value : '')
      },
      {
        Header: 'Ordrenummer',
        accessor: 'transferId',
        width: 120,
        minWidth: 120,
        disableFilters: true
      },
      {
        Header: 'Navn',
        accessor: 'name',
        width: 120,
        minWidth: 120,
        disableFilters: true
      },
      {
        Header: 'Varenummer',
        accessor: 'itemId',
        width: 120,
        minWidth: 120,
        disableFilters: true
      },
      {
        Header: 'Antal',
        accessor: 'quantity',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        aggregate: aggregrateLines
      },

      {
        Header: '',
        accessor: 'moveToIssueLocationStatusConsignorId',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        disableSortBy: true,
        aggregate: (groupedRows: any) => {
          const strings = groupedRows[0].split(';');
          return (
            <MoveToIssueLocation
              consignorId={strings[1]}
              moveToIssueLocationStatus={strings[0]}
              transferStatus={strings[2]}
              fetchTransferOrdersCallback={fetchTransferOrders}
            ></MoveToIssueLocation>
          );
        },
        Cell: (cellProps: CellProps<TransferOrderData>) => (cellProps.cell.isAggregated ? cellProps.cell.value : '')
      },
      {
        Header: '',
        accessor: 'toggleExpand',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<TransferOrderData>) => {
          const handleToggleExpand = () => {
            cellProps.row.toggleRowExpanded();
          };

          if (!cellProps.cell.isAggregated) return '';

          return (
            <StyledIconButton onClick={handleToggleExpand}>
              {cellProps.row.isExpanded ? <ChevronUp size="16px" /> : <ChevronDown size="16px" />}
            </StyledIconButton>
          );
        }
      }
    ] as Column<TransferOrderData>[];
  }, [fetchTransferOrders]);

  const tableInstance = useTableInstance(data, columns, {
    initialState: { groupBy: ['transferId'] }
  });

  return tableInstance;
};

const StyledIconButton = styled(IconButton)`
  margin: -12px 0;
`;

export default useOrderTable;
