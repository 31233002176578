import styled from 'styled-components';
import Table from '../../../blocks/table/TableClient';
import useAssetsTable from './useAssetsTable';
import { MassCreationRequestItem } from '../../../models/MassCreationRequestItem';

interface Props {
  requestItems: MassCreationRequestItem[];
}

const FileStepResults = (props: Props) => {
  const { requestItems } = props;
  const { tableInstance } = useAssetsTable(requestItems ?? []);

  return <StyledTable loading={false} tableInstance={tableInstance} noPadding />;
};

const StyledTable = styled(Table)`
  margin-top: 30px;
  max-height: 80%;
`;

export default FileStepResults;
