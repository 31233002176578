import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Alert from '../../components/alert/Alert';
import styled from 'styled-components';
import CircularProgress from '../../components/circular-progress/CircularProgress';
import ImageService from '../../services/ImageService';
import { log } from '../../utils/logging/log';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { getGuid } from '../../utils/guidGenerating';

const Image = () => {
  const [searchParams] = useSearchParams();
  const [image, setImage] = useState<string>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const loadImage = async () => {
      try {
        const imageType = searchParams.get('imagetype') ?? '';
        const taskId = +(searchParams.get('taskid') ?? '');
        const filename = searchParams.get('filename') ?? '';
        const answerId = +(searchParams.get('answerid') ?? '');
        const assetId = searchParams.get('assetid') ?? '';
        const objectId = +(searchParams.get('objectid') ?? '-1');
        const downloadInfo = ImageService.createDownloadInfoFromFields(
          imageType,
          taskId,
          filename,
          answerId,
          assetId,
          objectId
        );
        const res = await ImageService.downloadImage(downloadInfo);
        const imageUrl = URL.createObjectURL(res.data);
        setImage(imageUrl);
        document.title = getGuid();
      } catch (err) {
        log(err);
        setError('Billedet kunne ikke hentes.');
      }
    };

    if (!image) {
      loadImage();
    }

    return () => {
      if (image) {
        URL.revokeObjectURL(image);
      }
    };
  }, [searchParams, image]);

  if (error) {
    return (
      <Container>
        <Alert margin="8px" height="42px" severity="error">
          {error}
        </Alert>
      </Container>
    );
  }

  if (!image) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return <>
    <ImageContainer>
      <TransformWrapper centerOnInit maxScale={50}>
        <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }} contentStyle={{ width: '100%', height: '100%' }}>
          <StyledImage alt={searchParams.get('filename') ?? 'Billede'} src={image} />
        </TransformComponent>
      </TransformWrapper>
    </ImageContainer>
  </>
};

const Container = styled.div`
  text-align: center;
  height: 100%;
`;

const ImageContainer = styled.div`
  height: 100vh;
  width: 100vw;
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export default Image;
