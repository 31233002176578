import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DepartmentDTO, ProfileDTO2Role, Role, UpdateProfileDTO2, WarehouseDTO } from '../../api/api';
import { CloseIcon } from '../../assets/icons/CloseIcon';
import Button from '../../components/button/Button';
import DepartmentDropdown from '../../components/department-dropdown/DepartmentDropdown';
import DialogView from '../../components/dialog-view/DialogView';
import IconButton from '../../components/icon-button/IconButton';
import LoadingOverlay from '../../components/loading-overlay/LoadingOverlay';
import TextField from '../../components/text-field/TextField';
import Typography from '../../components/typography/Typography';
import { useNavigate } from '../../hooks/useNavigate';
import NotificationService from '../../services/NotificationService';
import UserService from '../../services/UserService';
import { Row, Section, SectionContent, SectionHeader } from '../../styling/FormStyling';
import { log } from '../../utils/logging/log';
import GoodsService from '../../services/GoodsService';
import { selectUserProfile, setUserProfileState } from '../../stateManagement/reducers/userProfileReducer';
import AutoComplete from '../../components/auto-complete/AutoComplete';
import Select, { MenuItem } from '../../components/select/Select';
import { getEnumDisplayValue, mapEnum } from '../../utils/enumUtils';
import useUserAccess from '../../hooks/useUserAccess';
import TestingService, { allowedTestingEnviroments } from '../../services/TestingService';

type Errors = { [key in keyof UpdateProfileDTO2]: boolean | undefined };

const ProfileView = () => {
  const [isLoading, setIsLoading] = useState(false);

  const userProfileState = useSelector(selectUserProfile);

  const { officeUserGroupAllowed } = useUserAccess();

  const [profileValues, setProfileValues] = useState<UpdateProfileDTO2>();
  const [warehouses, setWarehouses] = useState<WarehouseDTO[]>([]);
  const [departments, setDepartments] = useState<DepartmentDTO[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseDTO | null>(null);
  const userRole = useSelector(selectUserProfile).userProfile.role;
  const [role, setRole] = useState<ProfileDTO2Role | undefined>(userRole);

  const [errors, setErrors] = useState<Errors>({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    UserService.getProfile()
      .then((res) => {
        setProfileValues({
          departmentId: res.department?.departmentId,
          name: res.name,
          defaultWarehouseId: res.defaultWarehouseId,
          phone: res.phone
        });
      })
      .catch((error) => {
        NotificationService.error(`Kunne ikke hente bruger data: ${error}`);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    GoodsService.getWarehouses()
      .then((res) => {
        setWarehouses(res);

        if (res && profileValues?.defaultWarehouseId) {
          const selected = res.find((warehouse) => warehouse.warehouseId === profileValues.defaultWarehouseId);
          selected && setSelectedWarehouse(selected);
        }
      })
      .catch((error) => {
        log(error);
      });
  }, [profileValues?.defaultWarehouseId]);

  const clearError = useCallback((key: keyof UpdateProfileDTO2) => {
    setErrors((errors) => ({ ...errors, [key]: false }));
  }, []);

  const handleChange = useCallback(
    (key: keyof UpdateProfileDTO2, value: string | number) => {
      setProfileValues((prevState) => {
        const newValues = prevState ? { ...prevState, [key]: value } : { [key]: value };
        return newValues;
      });

      clearError(key);
    },
    [clearError]
  );

  const handleWarehouseChange = useCallback(
    (value: WarehouseDTO | null, reason) => {
      if (reason === 'clear') {
        setSelectedWarehouse(null);
      }

      setProfileValues((prevValue) => ({ ...prevValue, defaultWarehouseId: value?.warehouseId }));

      const warehouse = warehouses.find((warehouse) => warehouse.warehouseId === value?.warehouseId);
      warehouse && setSelectedWarehouse(warehouse);
    },
    [warehouses]
  );

  const formValidated = () => {
    setErrors({});
    let tmpErrors: Errors = {};

    if (!profileValues?.name) tmpErrors.name = true;
    if (!profileValues?.departmentId && role !== ProfileDTO2Role.Technician) tmpErrors.departmentId = true;

    if (Object.keys(tmpErrors).length) {
      setErrors(tmpErrors);
      return false;
    }
    return true;
  };

  const submit = () => {
    if (!formValidated()) {
      NotificationService.error(`Formularen er ikke korrekt udfyldt.`);
      return;
    }

    setIsLoading(true);

    UserService.updateProfile(profileValues)
      .then(() => {
        const userprofile = { ...userProfileState.userProfile };
        userprofile.department = departments.find((d) => d.departmentId === profileValues?.departmentId);
        userprofile.name = profileValues?.name;
        userprofile.defaultWarehouseId = profileValues?.defaultWarehouseId;

        dispatch(setUserProfileState(userprofile));

        NotificationService.success('Profil opdateret');
        navigate(-1);
      })
      .catch((error) => {
        NotificationService.error(`Kunne ikke opdatere profil: ${error}`);
        log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const onChangeRole = useCallback(() => {
    TestingService.changeRole(role as unknown as Role);
  }, [role]);

  return (
    <DialogView handleClose={() => navigate(-1)}>
      <Container>
        {isLoading && <LoadingOverlay />}
        <Header>
          <Typography variant="h3">Min profil</Typography>
          <IconButton variant="outlined" onClick={() => navigate(-1)}>
            <CloseIcon size="18px" />
          </IconButton>
        </Header>
        <HR />
        <Section noBorder>
          <SectionHeader>Kontaktoplysninger</SectionHeader>
          <SectionContent>
            <Row>
              <TextField
                required
                label="Fulde navn"
                fullWidth
                error={errors?.name}
                onChange={(e) => handleChange('name', e.target.value)}
                value={profileValues?.name || ''}
              />
              {officeUserGroupAllowed() && (
                <DepartmentDropdown
                  fullWidth
                  error={errors?.departmentId}
                  value={profileValues?.departmentId || ''}
                  onDepartmentsLoaded={setDepartments}
                  selectDepartment={(department) =>
                    department?.departmentId && handleChange('departmentId', department?.departmentId)
                  }
                />
              )}
            </Row>
            <Row>
              <AutoComplete
                disabled={warehouses.length === 0}
                onChange={(_, value, reason) => handleWarehouseChange(value, reason)}
                getOptionLabel={(warehouse) =>
                  `${warehouse.warehouseId ? `(${warehouse.warehouseId})` : ''} ${warehouse.name}`
                }
                options={warehouses}
                value={selectedWarehouse}
                renderInput={(params) => (
                  <TextField {...params} label={'Lager'} fullWidth error={errors?.defaultWarehouseId} />
                )}
                isOptionEqualToValue={(option, value) => option.warehouseId === value.warehouseId}
                fullWidth
                noOptionsText={'Intet lager'}
              />
              <TextField
                label="Telefonnummer"
                fullWidth
                error={errors?.phone}
                onChange={(e) => handleChange('phone', e.target.value)}
                value={profileValues?.phone || ''}
                inputProps={{ maxLength: 20 }}
              />
            </Row>
            {allowedTestingEnviroments && (
              <Row>
                <Select
                  label="Rolle"
                  fullWidth
                  defaultValue={role}
                  value={role}
                  onChange={(e) => setRole(e.target.value as ProfileDTO2Role)}
                >
                  {mapEnum(ProfileDTO2Role).map((r, i) => {
                    return (
                      <MenuItem key={i} value={ProfileDTO2Role[r]}>
                        {getEnumDisplayValue(ProfileDTO2Role[r])}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Button onClick={onChangeRole} disabled={role === userRole}>
                  Skift rolle
                </Button>
              </Row>
            )}
          </SectionContent>
        </Section>
        <HR />
        <FormFooter>
          <Button onClick={submit}>Gem profil</Button>
        </FormFooter>
      </Container>
    </DialogView>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: start;
  padding: 32px 24px 24px 32px;

  background-color: white;
  width: 800px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

const HR = styled.hr`
  margin: 0 -24px 0 -32px;
  height: 1px;
  border: none;
  background-color: ${(props) => props.theme.palette.grey.black10};
`;

export default ProfileView;
