import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SelectedGoods } from '../../models/Goods';

interface OrderGoodsState {
  goodsList: SelectedGoods[];
  warehouseId: string;
  deliveryDate: string;
  notes: string;
}

const initialState: OrderGoodsState = {
  goodsList: [],
  warehouseId: '',
  deliveryDate: '',
  notes: ''
};

const orderGoodsSlice = createSlice({
  name: 'orderGoods',
  initialState: initialState,
  reducers: {
    setOrderGoodsList: (state: OrderGoodsState, action: PayloadAction<SelectedGoods[]>) => {
      state.goodsList = action.payload;
    },
    setSelectedWarehouseId: (state: OrderGoodsState, action: PayloadAction<string>) => {
      state.warehouseId = action.payload;
    },
    setDeliveryDate: (state: OrderGoodsState, action: PayloadAction<string>) => {
      state.deliveryDate = action.payload;
    },
    setNotes: (state: OrderGoodsState, action: PayloadAction<string>) => {
      state.notes = action.payload;
    }
  }
});

export const { setOrderGoodsList, setSelectedWarehouseId, setDeliveryDate, setNotes } = orderGoodsSlice.actions;
export const selectOrderGoodsList = (state: RootState) => state.orderGoodsReducer.goodsList;
export const selectOrderGoodsWarehouseId = (state: RootState) => state.orderGoodsReducer.warehouseId;
export const selectOrderGoodsDeliveryDate = (state: RootState) => state.orderGoodsReducer.deliveryDate;
export const selectOrderGoodsNotes = (state: RootState) => state.orderGoodsReducer.notes;

export default orderGoodsSlice.reducer;
