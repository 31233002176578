import { ContractorAgreementDTO2 } from '../api/api';
import WorkClient from '../api/workClient';

const putWorkInstruction = (taskId: number, body: ContractorAgreementDTO2) => {
  return WorkClient.editContractorAgreement2(taskId, body);
};

const getWorkInstructions = (taskId: number) => {
  return WorkClient.getContractorAgreements(taskId);
};

const createWorkInstruction = (taskId: number) => {
  return WorkClient.createContractorAgreement(taskId);
};

const deleteWorkInstruction = (workInstructionId: number) => {
  return WorkClient.deleteContractorAgreement(workInstructionId);
};

const WorkInstructionsService = {
  putWorkInstruction,
  getWorkInstructions,
  createWorkInstruction,
  deleteWorkInstruction
};

export default WorkInstructionsService;
