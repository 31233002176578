import { FileResponse } from '../api/api';
import {
  AttachmentDownloadInfo,
  DownloadInfo,
  GisComponentDownloadInfo,
  GisListViewFileDownloadInfo,
  ImageType,
  QADownloadInfo
} from '../models/DownloadInfo';
import AttachmentService from './AttachmentService';
import GisComponentService from './GisComponentService';
import QAService from './QAService';

const downloadQA = async (downloadInfo: QADownloadInfo) => {
  const { taskId, answerId, filename } = downloadInfo;
  if (!filename) throw Error('Billedet kan ikke hentes. Filename mangler');
  try {
    return await QAService.GetQAFile2(taskId ?? -1, filename, answerId ?? -1);
  } catch {
    return QAService.GetQAFile(taskId ?? -1, answerId ?? -1);
  }
};

const downloadGisComponent = (downloadInfo: GisComponentDownloadInfo) => {
  const { taskId, assetId, objectId, filename } = downloadInfo;
  if (!taskId) throw Error('Billedet kan ikke hentes. taskid mangler');
  if (!assetId) throw Error('Billedet kan ikke hentes. assetid mangler');
  if (!objectId) throw Error('Billedet kan ikke hentes. objectid mangler');
  if (!filename) throw Error('Billedet kan ikke hentes. fileName mangler');
  return GisComponentService.getComponentFile(taskId, assetId, objectId.toString(), filename);
};

const downloadGisListViewFile = (downloadInfo: GisListViewFileDownloadInfo) => {
  const { taskId, assetId, filename } = downloadInfo;
  if (!taskId) throw Error('Billedet kan ikke hentes. taskid mangler');
  if (!assetId) throw Error('Billedet kan ikke hentes. assetid mangler');
  if (!filename) throw Error('Billedet kan ikke hentes. fileName mangler');
  return GisComponentService.getListViewFile(taskId, assetId, filename);
};

const downloadAttachment = (downloadInfo: AttachmentDownloadInfo) => {
  const { taskId, filename } = downloadInfo;
  if (!taskId) throw Error('Billedet kan ikke hentes. taskid mangler');
  if (!filename) throw Error('Billedet kan ikke hentes. fileName mangler');
  return AttachmentService.getFile(taskId, filename);
};

const downloadImage = (downloadInfo: DownloadInfo): Promise<FileResponse> => {
  if (downloadInfo.imageType === ImageType.QADownloadInfo) {
    return downloadQA(downloadInfo);
  } else if (downloadInfo.imageType === ImageType.GisComponentDownloadInfo) {
    return downloadGisComponent(downloadInfo);
  } else if (downloadInfo.imageType === ImageType.GisListViewFileDownloadInfo) {
    return downloadGisListViewFile(downloadInfo);
  } else if (downloadInfo.imageType === ImageType.AttachmentDownloadInfo) {
    return downloadAttachment(downloadInfo);
  }
  throw Error(`Billedet kan ikke hentes. Billede-typen understøttes ikke`);
};

const getDeeplink = (downloadInfo: DownloadInfo) => {
  if (downloadInfo.imageType === ImageType.QADownloadInfo) {
    return `/image?imagetype=${downloadInfo.imageType}&taskid=${downloadInfo.taskId}&answerid=${downloadInfo.answerId}&filename=${downloadInfo.filename}`;
  } else if (downloadInfo.imageType === ImageType.GisComponentDownloadInfo) {
    return `/image?imagetype=${downloadInfo.imageType}&taskid=${downloadInfo.taskId}&filename=${downloadInfo.filename}&assetid=${downloadInfo.assetId}&objectid=${downloadInfo.objectId}`;
  } else if (downloadInfo.imageType === ImageType.GisListViewFileDownloadInfo) {
    return `/image?imagetype=${downloadInfo.imageType}&taskid=${downloadInfo.taskId}&filename=${downloadInfo.filename}&assetid=${downloadInfo.assetId}`;
  } else if (downloadInfo.imageType === ImageType.AttachmentDownloadInfo) {
    return `/image?imagetype=${downloadInfo.imageType}&taskid=${downloadInfo.taskId}&filename=${downloadInfo.filename}`;
  } else return '';
};

const createDownloadInfoFromFields = (
  imageType: string,
  taskId: number,
  filename: string,
  answerId: number,
  assetId: string,
  objectId: number
): DownloadInfo => {
  if (imageType === 'QADownloadInfo' && taskId && filename && answerId) {
    return { imageType: ImageType.QADownloadInfo, taskId, filename, answerId };
  } else if (imageType === 'GisComponentDownloadInfo' && taskId && assetId && objectId && filename) {
    return { imageType: ImageType.GisComponentDownloadInfo, taskId, assetId, objectId, filename };
  } else if (imageType === 'GisListViewFileDownloadInfo' && taskId && assetId && filename) {
    return { imageType: ImageType.GisListViewFileDownloadInfo, taskId, assetId, filename };
  } else if (imageType === 'AttachmentDownloadInfo') {
    return { imageType: ImageType.AttachmentDownloadInfo, taskId, filename };
  } else {
    throw Error('Kan ikke vise billedet. Der mangler parametre for at kunne hente billedet.');
  }
};

const ImageService = {
  downloadImage,
  getDeeplink,
  createDownloadInfoFromFields
};

export default ImageService;
