import { createContext, useReducer } from 'react';
import { TaskChecklist } from '../models/TaskChecklist';
import { IsRepeatableEnum, SectionDTO } from '../api/api';
import {
  convertAnswerDTOToQuestions,
  convertSectionDTOToSection,
  getShouldShowSection
} from '../views/task-view/task-details-view2/checklist-step/TaskChecklistUtils';
import { TabProps } from '../blocks/tabs-vertical/TabsVertical';

type TaskChecklistProviderActionType =
  | 'update-answer'
  | 'add-section'
  | 'remove-section'
  | 'collapse-checklist'
  | 'add'
  | 'set-checklists'
  | 'toggle-isCompleted';

type TaskChecklistProviderPayload =
  | { checklistId: number; value: any; answerId: number; sectionId: number }
  | { checklistId: number; sectionDTO: SectionDTO; index: number; taskSectionId: number; repeatableIndex: number }
  | { checklistId: number; taskSectionId: number }
  | { isCompleted: boolean; checklistId: number; taskSectionId?: number }
  | { checklistId: number; sectionDTO: SectionDTO; numberOfSections: number }
  | TaskChecklist
  | TaskChecklist[];

interface TaskChecklistProviderPayloadAction {
  type: TaskChecklistProviderActionType;
  payload: TaskChecklistProviderPayload;
}

const reducer = (state: TaskChecklist[], action: TaskChecklistProviderPayloadAction) => {
  let newState = [...state];
  let checklist: TaskChecklist | undefined;
  switch (action.type) {
    case 'set-checklists':
      newState = action.payload as TaskChecklist[];
      return newState;
    case 'update-answer':
      const payload = action.payload as { checklistId: number; value: any; answerId: number; sectionId: number };

      checklist = newState.find((c) => c.workTaskCheckListId === payload.checklistId);
      if (!checklist) return newState;

      const section = checklist.workTaskCheckListSections.find((x) => x.taskSectionId === payload.sectionId);
      if (!section) return state;

      const answer = section.questions.find((x) => payload.answerId === x.id);
      if (!answer) return state;

      answer.value = payload.value;

      const dependentSections = checklist.dependableSections?.get(payload.answerId);
      if (dependentSections) {
        checklist.workTaskCheckListSections
          .filter((x) => dependentSections.sections.findIndex((d) => x.taskSectionId === d) !== -1)
          .forEach((workTaskCheckListSection) => {
            workTaskCheckListSection.preconditionFilled = getShouldShowSection(
              answer?.dataType,
              workTaskCheckListSection.dependsOnQuestionOptionInvert ?? false,
              payload.value,
              workTaskCheckListSection.dependsOnQuestionOptionId
            );
          });
      }

      return newState;

    case 'add-section':
      const { checklistId, sectionDTO, numberOfSections } = action.payload as {
        checklistId: number;
        sectionDTO: SectionDTO;
        numberOfSections: number;
      };

      let newSection = convertSectionDTOToSection(sectionDTO);
      newSection.questions = convertAnswerDTOToQuestions(sectionDTO.answerDTOList ?? []);
      newSection.preconditionFilled = true;

      checklist = newState.find((c) => c.workTaskCheckListId === checklistId);
      if (!checklist) return newState;

      const indexOfButton = checklist.workTaskCheckListSections.findIndex(
        (c) => c.sectionId === sectionDTO.sectionId && c.isRepeatable2 === IsRepeatableEnum.RepeatableTaskTypeChecklist
      );

      //Needed for idempotency: https://react.dev/reference/react/useReducer#my-reducer-or-initializer-function-runs-twice
      if (numberOfSections < checklist.workTaskCheckListSections.filter((x) => x.sectionId === sectionDTO.sectionId).length)
        return newState;

      if (indexOfButton === -1) return newState;

      checklist.workTaskCheckListSections.splice(indexOfButton, 0, newSection);
      return [...newState];

    case 'remove-section':
      const removePayload = action.payload as {
        checklistId: number;
        taskSectionId: number;
      };

      checklist = newState.find((c) => c.workTaskCheckListId === removePayload.checklistId);
      if (!checklist) return newState;

      const index = checklist.workTaskCheckListSections.findIndex((x) => x.taskSectionId === removePayload.taskSectionId);
      if (index === -1) return newState;

      checklist.workTaskCheckListSections.splice(index, 1);

      return newState;

    case 'add':
      const newChecklist = action.payload as TaskChecklist;

      const indexOfAddButton = newState.findIndex(
        (c) =>
          c.taskTypeChecklistId === newChecklist.taskTypeChecklistId &&
          c.isRepeatable === IsRepeatableEnum.RepeatableTaskTypeChecklist
      );

      newState.splice(indexOfAddButton, 0, newChecklist);

      return newState;

    case 'toggle-isCompleted':
      const togglePayload = action.payload as { isCompleted: boolean; checklistId: number; taskSectionId?: number };
      checklist = newState.find((c) => c.workTaskCheckListId === togglePayload.checklistId);
      if (!checklist) return newState;

      if (togglePayload.checklistId && !togglePayload.taskSectionId) {
        checklist.isCompleted = togglePayload.isCompleted;
      }

      if (togglePayload.checklistId && togglePayload.taskSectionId) {
        const section = checklist?.workTaskCheckListSections.find((s) => s.taskSectionId === togglePayload.taskSectionId);
        if (!section) return newState;

        section.isCompleted = togglePayload.isCompleted;
      }

      return newState;

    default:
      return newState;
  }
};

interface Props extends TabProps {
  initialState: TaskChecklist[];
  children?: React.ReactNode;
  tabHeaderText?: string;
  tabId?: string;
}

export const TaskChecklistContext = createContext<TaskChecklist[]>([]);
export const TaskChecklistDispatchContext = createContext<React.Dispatch<TaskChecklistProviderPayloadAction>>(
  (value: any) => {}
);

const TaskChecklistProvider = (props: Props) => {
  const [checklist, dispatch] = useReducer(reducer, props.initialState);

  return (
    <TaskChecklistContext.Provider value={checklist}>
      <TaskChecklistDispatchContext.Provider value={dispatch}>{props.children}</TaskChecklistDispatchContext.Provider>
    </TaskChecklistContext.Provider>
  );
};

export default TaskChecklistProvider;
