import { useMemo } from 'react';
import { Column } from 'react-table';
import { InputType, ReuseableComponentAttributeDTO } from '../../../api/api';
import useTableInstance from '../../../hooks/useTableInstance';
import { getEnumDisplayValue } from '../../../utils/enumUtils';
import { SearchColumnFilter, SelectColumnFilter, multiSelectFilterFn } from '../../../blocks/table/TableFilterUtils';

export interface ReusableComponentsData {
  id?: number;
  label?: string;
  inputType?: InputType;
  hintText?: string;
}

const useReusableComponentsTable = (attributes: ReuseableComponentAttributeDTO[]) => {
  const data = useMemo(() => {
    return attributes.map((a) => {
      return {
        ...a,
        inputType: a.inputType ? getEnumDisplayValue(a.inputType) : ''
      } as ReusableComponentsData;
    });
  }, [attributes]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
        width: 50,
        minWidth: 50,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Navn',
        accessor: 'label',
        width: 200,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Type',
        accessor: 'inputType',
        width: 100,
        minWidth: 100,
        Filter: SelectColumnFilter,
        filter: multiSelectFilterFn
      },
      {
        Header: 'Hjælpetekst',
        accessor: 'hintText',
        width: 200,
        Filter: SearchColumnFilter,
        filter: 'text'
      }
    ] as Column<ReusableComponentsData>[];
  }, []);

  const tableInstance = useTableInstance<ReusableComponentsData>(data, columns);

  return { tableInstance };
};

export default useReusableComponentsTable;
