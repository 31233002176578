import { useCallback, useMemo, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { ContractorAgreementDTO2 } from '../../../../api/api';
import { DeleteIcon } from '../../../../assets/icons/DeleteIcon';
import IconButton from '../../../../components/icon-button/IconButton';
import LoadingOverlay from '../../../../components/loading-overlay/LoadingOverlay';
import useTableInstance from '../../../../hooks/useTableInstance';
import NotificationService from '../../../../services/NotificationService';
import WorkInstructionsService from '../../../../services/WorkInstructionsService';
import { formatDateString } from '../../../../utils/dateHandling';

export interface InstructionData {
  date?: string;
  technician?: string;
  contactPerson?: string;
  contactPersonPhone?: string;
  signed: boolean;
  actions?: number;
  id?: number;
}

const useInstructionTable = (instructions: ContractorAgreementDTO2[], onDeleteInstructionCallback: () => void) => {
  const data = useMemo(() => {
    if (!instructions.length) return [];

    return instructions.map((i) => {
      return {
        date: i.signedDate ? formatDateString(i.signedDate) : '',
        technician: i.technician,
        contactPerson: i.contactPersonName,
        contactPersonPhone: i.contactPersonPhoneNumber,
        signed: i.signature ? true : false,
        actions: i.id,
        id: i.id
      };
    });
  }, [instructions]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Medarbejder',
        accessor: 'technician',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: 'Kontaktperson',
        accessor: 'contactPerson',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: 'Kontaktperson tlf.nr.',
        accessor: 'contactPersonPhone',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: 'Underskrevet',
        accessor: 'signed',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<InstructionData>) => {
          return cellProps.cell.value ? 'Ja' : 'Nej';
        }
      },
      {
        Header: 'Dato for underskrift',
        accessor: 'date',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'actions',
        width: 60,
        minWidth: 60,
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<InstructionData>) => {
          const [deleting, setDeleting] = useState(false);
          const handleDeleteWorkInstruction = useCallback(() => {
            setDeleting(true);
            WorkInstructionsService.deleteWorkInstruction(cellProps.cell.value)
              .then(() => onDeleteInstructionCallback())
              .catch(() => NotificationService.error('Kunne ikke slette arbejdsinstruksen'))
              .finally(() => setDeleting(false));
          }, [cellProps.cell.value]);

          return (
            <>
              {deleting ? (
                <LoadingOverlay />
              ) : (
                <IconButton
                  variant="standard"
                  disabled={cellProps.row.original.signed}
                  onClick={handleDeleteWorkInstruction}
                  disabledBackground
                >
                  <DeleteIcon size="16px" />
                </IconButton>
              )}
            </>
          );
        }
      }
    ] as Column<InstructionData>[];
  }, [onDeleteInstructionCallback]);

  const tableInstance = useTableInstance<InstructionData>(data, columns);

  return { tableInstance };
};

export default useInstructionTable;
