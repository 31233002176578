import { UpdateWorkInvoiceDTO, WorkInvoiceProjectCategory } from '../api/api';
import WorkClient from '../api/workClient';

const getWorkInvoice = (workInvoiceId: number) => {
  return WorkClient.workInvoice(workInvoiceId);
};

const getWorkInvoiceId = (baseWorkTaskId: number) => {
  return WorkClient.getWorkInvoiceId(baseWorkTaskId);
};

const getProjectNumber = (workInvoiceId: number) => {
  return WorkClient.getProjectNumber(workInvoiceId);
};

const associateWorkInvoice = (taskId: number) => {
  return WorkClient.associateWorkInvoice(taskId);
};

const updateWorkInvoice = (workInvoiceId: number, updateWorkInvoice?: UpdateWorkInvoiceDTO) => {
  return WorkClient.updateWorkInvoice(workInvoiceId, updateWorkInvoice);
};

const updateUnknownDebtor = (workInvoiceId: number, isUnknownDebtor: boolean) => {
  return WorkClient.updateUnknownDebtor(workInvoiceId, isUnknownDebtor);
};

const updateProjectCategory = (workInvoiceId: number, projectCategory: WorkInvoiceProjectCategory) => {
  return WorkClient.updateProjectCategory(workInvoiceId, projectCategory);
};

const deleteWorkInvoice = (workInvoiceId: number, worktaskId?: number) => {
  return WorkClient.delete(workInvoiceId, worktaskId);
};

const WorkInvoiceService = {
  getWorkInvoice,
  getWorkInvoiceId,
  getProjectNumber,
  associateWorkInvoice,
  updateWorkInvoice,
  updateUnknownDebtor,
  updateProjectCategory,
  deleteWorkInvoice
};

export default WorkInvoiceService;
