import { useEffect, useState } from 'react';
import { WorkTaskStatus } from '../api/api';
import { getEnumDisplayValue } from '../utils/enumUtils';

export interface WorkTaskId {
  id: number;
  hasSubTasks: boolean;
  status?: string;
}

export const useStatusOptions = (workTaskIds: WorkTaskId[]) => {
  const [statusOptions, setStatusOptions] = useState<WorkTaskStatus[]>([]);

  useEffect(() => {
    const updateStatusOptions = (_workTaskIds: WorkTaskId[]) => {
      if (
        _workTaskIds.some(
          (w) =>
            w.status === getEnumDisplayValue(WorkTaskStatus.ReadyForPlanning) ||
            w.status === getEnumDisplayValue(WorkTaskStatus.Planned) ||
            w.status === getEnumDisplayValue(WorkTaskStatus.PlannedAppointment) ||
            w.status === getEnumDisplayValue(WorkTaskStatus.OnRoute) ||
            w.status === getEnumDisplayValue(WorkTaskStatus.Ongoing)
        )
      ) {
        setStatusOptions([]);
      } else if (_workTaskIds.some((w) => w.status === getEnumDisplayValue(WorkTaskStatus.Processed))) {
        setStatusOptions([WorkTaskStatus.Completed]);
      } else if (
        _workTaskIds.some(
          (w) =>
            w.status === getEnumDisplayValue(WorkTaskStatus.Created) ||
            w.status === getEnumDisplayValue(WorkTaskStatus.Pause)
        )
      ) {
        setStatusOptions([WorkTaskStatus.Completed, WorkTaskStatus.Pause]);
      } else if (_workTaskIds.some((w) => w.status === getEnumDisplayValue(WorkTaskStatus.Completed))) {
        setStatusOptions([WorkTaskStatus.Pause, WorkTaskStatus.Processed]);
      }
    };
    updateStatusOptions(workTaskIds);
  }, [workTaskIds]);

  return statusOptions;
};
