import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { QuestionDTO, SectionQuestionTextDTO, TaskTypeSectionDTO } from '../../../../../api/api';
import Table from '../../../../../blocks/table/TableClient';

import useAddQuestionTable from './useAddQuestionTable';
import { StyledBackButtonIcon } from '../../AdminTaskTypesDialog';
import Typography from '../../../../../components/typography/Typography';
import Button from '../../../../../components/button/Button';
import { ChevronLeft } from '../../../../../assets/icons/ChevronLeft';

interface Props {
  onBack: () => void;
  allQuestions: QuestionDTO[];
  activeSection?: TaskTypeSectionDTO;
  sections?: TaskTypeSectionDTO[];
  addQuestions: (questions: SectionQuestionTextDTO[]) => void;
}

const AddQuestion = (props: Props) => {
  const { onBack, activeSection, addQuestions, allQuestions } = props;
  const [selectedQuestions, setSelectedQuestions] = useState<SectionQuestionTextDTO[]>([]);

  const questionClickedHandler = useCallback(
    (question: QuestionDTO, checked: boolean) => {
      if (!question || !question.id) return;

      if (checked) {
        const newQuestion: SectionQuestionTextDTO = {
          questionId: question.id,
          order: 0,
          isMandatory: false,
          text: question.text,
          questionType: question.questionType
        };
        setSelectedQuestions([...selectedQuestions, newQuestion]);
      } else {
        const _selectedQuestions = selectedQuestions.filter((q) => q.questionId !== question.id);
        setSelectedQuestions(_selectedQuestions);
      }
    },
    [selectedQuestions]
  );

  // The questions which are about to be updated
  const previousQuestions = useMemo(() => {
    return activeSection?.questions ?? [];
  }, [activeSection]);

  const { tableInstance } = useAddQuestionTable(
    allQuestions,
    questionClickedHandler,
    previousQuestions,
    activeSection,
    selectedQuestions
  );

  return (
    <>
      <Header>
        <StyledBackButtonIcon onClick={onBack}>
          <ChevronLeft size="22px" />
        </StyledBackButtonIcon>
        <Typography variant="h4">Tilføj spørgsmål til {activeSection?.name}</Typography>
      </Header>
      <StyledTable noPadding loading={false} tableInstance={tableInstance} alwaysShowSort />
      <StyledButton onClick={() => addQuestions(selectedQuestions)}>Tilføj</StyledButton>
    </>
  );
};

const StyledTable = styled(Table)`
  position: relative;
  top: 20px;
  height: 80%;
`;

const Header = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-top: ${(props) => props.theme.spacing(10)};
  display: flex;
  align-self: flex-end;
`;

export default AddQuestion;
