import {
  FileParameter,
  ListViewCommentRequestDTO,
  ListViewFileUploadRequestDTO,
  ComponentFieldChangeDTO,
  ComponentCommentRequestDTO,
  ComponentFileUploadRequestDTO
} from '../api/api';
import WorkClient from '../api/workClient';

const getComponentChange = (workTaskId: number) => {
  return WorkClient.gisComponentChange(workTaskId);
};

const addListViewComment = (body: ListViewCommentRequestDTO) => {
  return WorkClient.listViewCommentPOST(body);
};

const deleteListViewComment = (listViewCommentId: number) => {
  return WorkClient.listViewCommentDELETE(listViewCommentId);
};

const getListViewFile = (workTaskId: number, assetId: string, fileName: string) => {
  return WorkClient.listViewFileUploadFileGET(workTaskId, assetId, fileName);
};

const getListViewThumbnail = (workTaskId: number, assetId: string, fileName: string) => {
  return WorkClient.listViewThumbnail(workTaskId, assetId, fileName);
};

const addListViewFileData = (body: ListViewFileUploadRequestDTO) => {
  return WorkClient.listViewFileUploadData(body);
};

const addListViewFile = (workTaskId: number, assetId: string, file: FileParameter) => {
  return WorkClient.listViewFileUploadFilePOST(workTaskId, assetId, file);
};

const deleteListViewFile = (fileId: number) => {
  return WorkClient.listViewFileUpload(fileId);
};

const addComponentComment = (body: ComponentCommentRequestDTO) => {
  return WorkClient.componentCommentPOST(body);
};

const deleteComponentComment = (componentCommentId: number) => {
  return WorkClient.componentCommentDELETE(componentCommentId);
};

const getComponentFile = (workTaskId: number, assetId: string, componentId: string, fileName: string) => {
  return WorkClient.componentFileUploadGET(workTaskId, assetId, componentId, fileName);
};

const getComponentThumbnail = (workTaskId: number, assetId: string, componentId: string, fileName: string) => {
  return WorkClient.componentThumbnail(workTaskId, assetId, componentId, fileName);
};

const addComponentFileData = (body: ComponentFileUploadRequestDTO) => {
  return WorkClient.componentFileUploadData(body);
};

const addComponentFile = (workTaskId: number, assetId: string, componentId: string, file: FileParameter) => {
  return WorkClient.componentFileUploadPOST(workTaskId, assetId, componentId, file);
};

const deleteComponentFile = (fileId: number) => {
  return WorkClient.componentFileUploadDELETE(fileId);
};

const updateComponentFieldChange = (body: ComponentFieldChangeDTO) => {
  return WorkClient.componentFieldChange(body);
};

const hasChanges = (workTaskId: number) => {
  return WorkClient.hasChanges(workTaskId);
};

const GisComponentService = {
  getComponentChange,
  addListViewComment,
  deleteListViewComment,
  getListViewFile,
  getListViewThumbnail,
  addListViewFileData,
  addListViewFile,
  deleteListViewFile,
  updateComponentFieldChange,
  addComponentComment,
  deleteComponentComment,
  getComponentFile,
  getComponentThumbnail,
  addComponentFileData,
  addComponentFile,
  deleteComponentFile,
  hasChanges
};

export default GisComponentService;
