import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../components/button/Button';
import NavigationHeader from '../components/NavigationHeader';
import { useDispatch, useSelector } from 'react-redux';
import { selectedGoods, setSelectedOrder } from '../../../stateManagement/reducers/returnGoodsReducer';
import useReturnGoodsList from './useReturnGoodsList';
import Table from '../../../blocks/table/TableClient';
import { SelectedGoodsData } from '../../../models/Goods';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import { DialogBody } from '../../../stateManagement/reducers/confirmDialogReducer';
import GoodsService from '../../../services/GoodsService';
import { CreateReturnOrderDTO, WorkTaskStatus } from '../../../api/api';
import { log } from '../../../utils/logging/log';
import { selectUserProfile } from '../../../stateManagement/reducers/userProfileReducer';
import NotificationService from '../../../services/NotificationService';

interface Props {
  onClose?: () => void;
  showReceipt?: () => void;
}

const dialogBody: DialogBody = {
  headerText: 'Er du sikker?',
  bodyText: 'Er du sikker på at denne vare ikke skal returneres?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

export interface ExtendedSelectedGoodsData extends SelectedGoodsData {
  minMax?: string;
}

const ReturnGoodsViewContent = (props: Props) => {
  const { onClose, showReceipt } = props;
  const { getConfirmation } = useConfirmationDialog();
  const dispatch = useDispatch();
  const goods = useSelector(selectedGoods);
  const [loading, setLoading] = useState(false);
  const [returnGoods, setReturnGoods] = useState<ExtendedSelectedGoodsData[]>([]);
  const [readOnly, setReadOnly] = useState(true);
  const warehouseId = useSelector(selectUserProfile).userProfile.defaultWarehouseId;
  const [page, setPage] = useState<'edit' | 'confirm'>('edit');

  useEffect(() => {
    const _returnGoods = goods.map((good) => {
      return {
        amount: 1,
        goodsName: good.product?.name,
        goodsId: good.product?.itemId,
        available: good.available,
        min: good.minOnHand,
        max: good.maxOnHand,
        minMax: `${good.minOnHand}/${good.maxOnHand}`
      } as ExtendedSelectedGoodsData;
    });
    setReturnGoods(_returnGoods);
  }, [goods]);

  const removeGood = async (goodId?: string) => {
    const confirmation = await getConfirmation(dialogBody);
    if (confirmation === 'confirm') {
      setReturnGoods((_prev) => _prev.filter((good) => good.goodsId !== goodId));
    }
  };

  const confirm = async () => {
    const confirmation = await getConfirmation({
      headerText: `Opret returordre`,
      bodyText: `Er du sikker på at du vil oprette denne returordre? Bemærk at den først vil være synlig under returordre om et par minutter.`,
      declineButtonText: 'Fortryd',
      confirmButtonText: 'Bekræft'
    } as DialogBody);

    if (confirmation !== 'confirm') {
      return;
    }

    setLoading(true);
    const body: CreateReturnOrderDTO = {
      items: returnGoods.map((obj) => {
        return {
          name: obj.goodsName,
          itemId: obj.goodsId,
          qty: obj.amount
        };
      }),
      fromWarehouseId: warehouseId
    };

    GoodsService.createReturnOrders(body)
      .then(() => {
        dispatch(setSelectedOrder({ ...body, transferStatus: WorkTaskStatus.Created }));
        showReceipt && showReceipt();
      })
      .catch((e) => {
        NotificationService.error('Kunne ikke oprette returordre');
        log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const ActionButtons = () => {
    switch (page) {
      case 'edit':
        return (
          <>
            <div />
            <StyledButton
              onClick={() => {
                setPage('confirm');
                setReadOnly(false);
              }}
            >
              {`Returner varer (${returnGoods.reduce((ac, current) => ac + (current.amount ?? 0), 0)})`}
            </StyledButton>
          </>
        );
      case 'confirm':
        return (
          <>
            <StyledButton
              variant="secondary"
              onClick={() => {
                setPage('edit');
                setReadOnly(true);
              }}
            >
              Tilbage
            </StyledButton>
            <StyledButton onClick={confirm}>Opret returnering</StyledButton>
          </>
        );
      default:
        return <></>;
    }
  };

  const { tableInstance } = useReturnGoodsList(returnGoods, readOnly, (_goods) => setReturnGoods(_goods), removeGood);

  return (
    <Container>
      <NavigationHeader headerTitle="Returner varer" onClose={onClose} />
      <ContentContainer>
        <StyledTable loading={loading} noPadding tableInstance={tableInstance} />
      </ContentContainer>
      <Footer>
        <ActionButtons />
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.primary};
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  width: calc(100% - 60px);
  padding: ${(props) => props.theme.spacing(7.5)};
  height: 100%;
  overflow-y: scroll;
`;

const Footer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.palette.grey.black5};
  width: 100%;
  height: 150px;
  bottom: 0;
`;

const StyledButton = styled(Button)`
  margin: 0px ${(props) => props.theme.spacing(5)};
`;

export const StyledTable = styled(Table)`
  width: 100%;
  height: 100%;
`;

export default ReturnGoodsViewContent;
