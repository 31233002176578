import { useCallback, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { WorkTaskStatus, WorkTaskStatusDTO } from '../../../../api/api';
import { TagIcon } from '../../../../assets/icons/TagIcon';
import Grow from '../../../../components/grow';
import StatusTag from '../../../../components/status-tag/StatusTag';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import NotificationService from '../../../../services/NotificationService';
import TaskService from '../../../../services/TaskService';
import { DialogBody } from '../../../../stateManagement/reducers/confirmDialogReducer';
import { setListShouldUpdate } from '../../../../stateManagement/reducers/taskListReducer';
import { Container, ExpandedToolMenu, MenuTrigger } from '../../../../styling/ListToolsStyling';
import { getEnumDisplayValue } from '../../../../utils/enumUtils';
import { useStatusOptions } from '../../../../hooks/useStatusOptions';

interface WorkTaskId {
  id: number;
  hasSubTasks: boolean;
  status?: string;
}
interface Props {
  setIsLoading: (isLoading: boolean) => void;
  fetchTasksCallback: () => void;
  workTaskIds: WorkTaskId[];
  worktaskHasDifferentDepartment: boolean;
}

const ChangeStatusMenu = (props: Props) => {
  const { workTaskIds, fetchTasksCallback, setIsLoading, worktaskHasDifferentDepartment } = props;

  const statusOptions = useStatusOptions(workTaskIds);

  const [open, setOpen] = useState(false);

  const { getConfirmation } = useConfirmationDialog();
  const dispatch = useDispatch();
  const rootRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(rootRef, () => setOpen(false));

  const handleChangeStatus = useCallback(
    async (newStatus: WorkTaskStatus) => {
      const body: WorkTaskStatusDTO[] = workTaskIds.map((w) => ({
        workTaskId: w.id,
        status: newStatus,
        workTaskHasSubtasks: w.hasSubTasks
      }));

      if (worktaskHasDifferentDepartment || body.length >= 10) {
        const differentAppartments =
          'Du er ved at ændre på en opgave der ligger i en anden afdeling end din egen. Vil du fortsætte?';
        const numberOfTasks = `Vil du ændre status på ${body.length} opgaver til "${getEnumDisplayValue(newStatus)}"?`;

        const dialogBody: DialogBody = {
          headerText: worktaskHasDifferentDepartment ? differentAppartments : numberOfTasks,
          bodyText: worktaskHasDifferentDepartment && body.length >= 10 ? numberOfTasks : '',
          declineButtonText: 'Fortryd',
          confirmButtonText: 'Bekræft'
        };
        const confirmation = await getConfirmation(dialogBody);
        if (confirmation !== 'confirm') return;
      }

      setIsLoading(true);
      TaskService.setTaskStatus(body)
        .then(() => {
          NotificationService.success(`Opdaterede status på ${body.length} opgaver til "${getEnumDisplayValue(newStatus)}"`);
          fetchTasksCallback();
          dispatch(setListShouldUpdate(true));
        })
        .catch((error) => {
          NotificationService.error(`Kunne ikke ændre status: ${error}`);
          setIsLoading(false);
        });
      setOpen(false);
    },
    [workTaskIds, setIsLoading, getConfirmation, fetchTasksCallback, dispatch, worktaskHasDifferentDepartment]
  );

  return (
    <Container ref={rootRef}>
      <MenuTrigger onClick={() => setOpen(!open)} variant="tertiary" square data-testid="change-status-button">
        <TagIcon size="20px" />
        Skift status
      </MenuTrigger>

      <Grow in={open}>
        <ExpandedToolMenu pointerOnItems>
          {statusOptions.length === 0 ? (
            <Container data-testid="no-options-item">Ikke muligt for en eller flere opgaver.</Container>
          ) : (
            statusOptions.map((status, i) => {
              return (
                <StatusTag
                  data-testid={`${status}-status-item`}
                  status={WorkTaskStatus[status]}
                  key={i}
                  onClick={() => handleChangeStatus(WorkTaskStatus[status])}
                >
                  {getEnumDisplayValue(WorkTaskStatus[status])}
                </StatusTag>
              );
            })
          )}
        </ExpandedToolMenu>
      </Grow>
    </Container>
  );
};

export default ChangeStatusMenu;
