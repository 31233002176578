import { useCallback, useEffect, useState } from 'react';
import GoogleMapReact, { Coords, MapOptions } from 'google-map-react';
import styled from 'styled-components';

import { GoogleApiSettings } from '../../utils/location/locationHandling';
import MapMarkerIcon, { MarkerType } from '../map-marker/MapMarker';

export interface MapMarker {
  lat: number;
  lng: number;
  markerType: MarkerType;
  onClick?: () => void;
  key: string;
  hoverText?: string;
}

interface MapProps {
  markers?: MapMarker[];
  options?: MapOptions;
  center?: Coords;
  coords?: Coords[];
  handleOnClick?: (e: any) => void;
  activateOnClick?: boolean;
  disableAutoFit?: boolean;
  zoom?: number;
}

const MapComponent = (props: MapProps) => {
  const { options, markers, handleOnClick, activateOnClick, disableAutoFit, zoom = 13 } = props;
  const { apiKey, libraries, id } = GoogleApiSettings;

  let finalOptions = { ...{ mapTypeControl: true, keyboardShortcuts: false }, ...options };

  const [map, setMap] = useState<google.maps.Map>();
  const [center, setCenter] = useState<Coords>({ lat: 56.2005884, lng: 9.5731196 });

  useEffect(() => {
    const coords: Coords[] = [];
    markers?.forEach((m) => coords.push({ lat: m.lat, lng: m.lng }));

    if (coords && coords.length > 1 && map) {
      let bounds = new google.maps.LatLngBounds();
      coords.forEach((point) => bounds.extend(point));
      !disableAutoFit && map.fitBounds(bounds);
    }

    if (coords && coords.length === 1) {
      setCenter(coords[0]);
    }
  }, [map, markers, disableAutoFit]);

  //Use this way to apply click events instead of the onClick directly on the GoogleMapReact-component, because the built in onClick applies faulty coordinates in fullscreen mode.
  useEffect(() => {
    if (activateOnClick && map && handleOnClick) {
      google.maps.event.clearListeners(map, 'click'); //Dont stack listeners for each coordinate change
      map.addListener('click', handleOnClick);
    } else if (map) {
      google.maps.event.clearListeners(map, 'click');
    }
  }, [activateOnClick, handleOnClick, map]);

  const handleApiLoaded = useCallback((maps: { map: google.maps.Map }) => {
    setMap(maps.map);
  }, []);

  return (
    <Container>
      <GoogleMapReact
        onGoogleApiLoaded={handleApiLoaded}
        bootstrapURLKeys={{ key: apiKey, libraries, id }}
        center={center}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals={true}
        options={finalOptions}
      >
        {!!markers &&
          markers.map((marker) => {
            return <MapMarkerIcon {...marker} onClick={marker.onClick} />;
          })}
      </GoogleMapReact>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export default MapComponent;
