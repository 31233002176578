import { Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';

import { ItemConsumptionDTO } from '../../../../api/api';
import { ChevronDown } from '../../../../assets/icons/ChevronDown';
import { ChevronUp } from '../../../../assets/icons/ChevronUp';
import IconButton from '../../../../components/icon-button/IconButton';
import { formatDateString } from '../../../../utils/dateHandling';

interface Props {
  itemOrder: ItemConsumptionDTO;
}

const GoodsTableRow = (props: Props) => {
  const { date, warehouseName, employeeName, items } = props.itemOrder;

  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledTableRow active={open} sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
        <TableCell>{date ? formatDateString(date) : '-'}</TableCell>
        <TableCell>{warehouseName ?? '-'}</TableCell>
        <TableCell>{employeeName ?? '-'}</TableCell>
        <TableCell>{items?.length ?? 0}</TableCell>
        <TableCell>
          <IconButton>{open ? <ChevronUp size="16px" /> : <ChevronDown size="16px" />}</IconButton>
        </TableCell>
      </StyledTableRow>
      <StyledSubTableRow active={open}>
        <TableCell style={{ padding: 0, borderBottom: 'unset' }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <SubTableHeader>
                  <TableCell sx={{ width: '20%' }}>Varenummer</TableCell>
                  <TableCell sx={{ width: '30%' }}>Varenavn</TableCell>
                  <TableCell sx={{ width: '30%' }}></TableCell>
                  <TableCell sx={{ width: '10%' }}>Antal</TableCell>
                  <TableCell sx={{ width: '10%' }}></TableCell>
                </SubTableHeader>
              </TableHead>
              <TableBody>
                {items &&
                  items.map((item, i) => (
                    <TableRow key={i}>
                      <TableCell>{item.itemId}</TableCell>
                      <TableCell>{item.itemName}</TableCell>
                      <TableCell></TableCell>
                      <TableCell>{item.qty}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </StyledSubTableRow>
    </>
  );
};

const StyledTableRow = styled(({ active, ...props }) => <TableRow {...props} />)<{
  active?: boolean;
}>`
  background: ${(props) => (props.active ? props.theme.palette.grey.black5 : '')};
  cursor: pointer;
  .MuiTableCell-root {
    font-weight: 800;
  }
`;

const StyledSubTableRow = styled(({ active, ...props }) => <TableRow {...props} />)<{
  active?: boolean;
}>`
  background: ${(props) => (props.active ? props.theme.palette.grey.black5 : '')};
`;

const SubTableHeader = styled((props) => <TableRow {...props} />)`
  .MuiTableCell-head {
    color: ${(props) => props.theme.palette.grey.black40};
  }
`;

export default GoodsTableRow;
