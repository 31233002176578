import styled from 'styled-components';
interface Props {
  children?: React.ReactNode;
  handleClose?: () => void;
  open?: boolean;
  onClick?: () => void;
}

const DialogView = (props: Props) => {
  const { children, handleClose, open, onClick } = props;

  return (
    <Backdrop onClick={() => handleClose && handleClose()} show={open === undefined ? true : open}>
      <Container
        onClick={(e) => {
          onClick && onClick();
          e.stopPropagation();
        }}
      >
        {children}
      </Container>
    </Backdrop>
  );
};

const Backdrop = styled.div<{ show: boolean }>`
  position: fixed;
  z-index: ${(props) => props.theme.zIndex.backdrop};
  inset: 0px;

  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: start;

  overflow: hidden;

  background: rgba(0, 0, 0, 0.75);
`;

const Container = styled.div`
  z-index: ${(props) => props.theme.zIndex.dialog};
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.palette.grey.black20};

  border-radius: 12px;
  & > div {
    border-radius: 12px;
  }
`;

export default DialogView;
