import {
  CreateItemConsumptionDTO,
  CreateReturnOrderDTO,
  CreateTransferJournalDTO,
  CreateTransferOrderDTO,
  Region2,
  UpdateStockDTO
} from '../api/api';
import WorkClient from '../api/workClient';

const getConsumedItems = (workTaskId: number) => {
  return WorkClient.getConsumedItems(workTaskId);
};

const getWarehouses = () => {
  return WorkClient.getWarehouses(Region2.OneERP);
};

const getOrdersWarehouses = () => {
  return WorkClient.getOrdersWarehouses();
};

const getItemsOnHand3 = (legalEntityId: string, warehouseId?: string, locationId?: string, itemId?: string) => {
  return WorkClient.getItemsOnHand3(legalEntityId, warehouseId, locationId, itemId);
};

const consumeItems = (body: CreateItemConsumptionDTO) => {
  return WorkClient.consumeItems(body);
};

const getEmergencyWarehouses = () => {
  return WorkClient.getEmergencyWarehouses();
};

const getJournalWarehouses = () => {
  return WorkClient.getJournalWarehouses();
};

const getActiveProducts = () => {
  return WorkClient.getProductList();
};

const getAllProducts = () => {
  return WorkClient.getProductListWithDiscontinued();
};

const createTransferOrder = (body?: CreateTransferOrderDTO) => {
  return WorkClient.createTransferOrder(body);
};

const createTransferJournal = (body: CreateTransferJournalDTO) => {
  return WorkClient.createTransferJournal(body);
};

const getTransferOrders = (warehouseId: string) => {
  return WorkClient.getActiveOrders(warehouseId);
};

const createItemMove = (consignorId: string) => {
  return WorkClient.createItemMove(consignorId);
};

const updateWarehouseStock = (legalEntityId: string, warehouseId: string, body: UpdateStockDTO[], locationId?: string) => {
  return WorkClient.updateWarehouseStock(legalEntityId, warehouseId, locationId, body);
};

const getAllowedDeliveryDates = (warehouseId: string) => {
  return WorkClient.getAllowedDeliveryDates(warehouseId);
};

const createReturnOrders = (body: CreateReturnOrderDTO) => {
  return WorkClient.createReturnOrder(body);
};

const getReturnOrders = (warehouseId: string) => {
  return WorkClient.geReturnOrders(warehouseId);
};

const checkIfWorkTaskHasConsumedItems = (workTaskId: number) => {
  return WorkClient.checkIfWorkTaskHasConsumedItems(workTaskId);
};

const moveConsumedItemsToWorkInvoiceProject = (workTaskId: number) => {
  return WorkClient.moveConsumedItemsToWorkInvoiceProject(workTaskId);
};

const GoodsService = {
  getConsumedItems,
  getWarehouses,
  getOrdersWarehouses,
  getItemsOnHand3,
  consumeItems,
  getEmergencyWarehouses,
  getJournalWarehouses,
  getActiveProducts,
  getAllProducts,
  createTransferOrder,
  createTransferJournal,
  getTransferOrders,
  createItemMove,
  updateWarehouseStock,
  getAllowedDeliveryDates,
  createReturnOrders,
  getReturnOrders,
  checkIfWorkTaskHasConsumedItems,
  moveConsumedItemsToWorkInvoiceProject
};

export default GoodsService;
