import { useState } from 'react';
import styled from 'styled-components';
import { QuestionProps } from '../question-wrapper/QuestionRenderer';
import DateTimePicker from '../date-time-picker/DateTimePicker';
import QuestionHeader from '../question-header/QuestionHeader';
import AuditInfo from '../audit-info/AuditInfo';
import QuestionRow from '../question-wrapper/QuestionRow';
import { getInitialsFromEmail } from '../../utils/initialsUtils';
import { getUserEmail } from '../../utils/authProvider/authProvider';
import dayjs from 'dayjs';
import { useAsyncDebounce } from 'react-table';

const QuestionDateTime = (props: QuestionProps) => {
  const { question, handleSave, required, showMandatoryFieldError } = props;
  const [value, setValue] = useState<string | null>(question.value ?? null);
  const [lastUpdatedBy, setLastUpdatedBy] = useState(question.updatedByEmail);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(question.timeStampValue);

  const handleChange = useAsyncDebounce((date: dayjs.Dayjs | null) => {
    if (date && date.isValid()) {
      setValue(date.toISOString());
      handleSave && handleSave(date.toISOString() ?? '');
      setLastUpdatedBy(getInitialsFromEmail(getUserEmail()));
      setLastUpdatedTime(new Date().toISOString() + '');
    }
  }, 750);

  return (
    <Container>
      <QuestionHeader
        text={question.text}
        required={required}
        helpText={question.helpText}
        error={showMandatoryFieldError && required && value == null}
      />
      <QuestionRow>
        <StyledDateTimePicker
          onAccept={(date) => handleChange(date ?? null)}
          onChange={(date) => handleChange(date ?? null)}
          defaultValue={value ? dayjs(value) : null}
        />
        <AuditInfo updatedByEmail={lastUpdatedBy} updatedTimeStamp={lastUpdatedTime} size="24px" />
      </QuestionRow>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  & label {
    margin-left: 0px;
  }
`;

const StyledDateTimePicker = styled(DateTimePicker)`
  background-color: ${(props) => props.theme.palette.background.primary};
  width: 100%;
`;

export default QuestionDateTime;
