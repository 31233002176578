import { useMemo } from 'react';
import { Column } from 'react-table';
import useTableInstance from '../../../../hooks/useTableInstance';
import { Stock } from '../../../../models/Goods';

interface StockData {
  available: number;
  name: string;
  itemId: string;
  minMax: string;
  warehouseId: string;
}

export const useEmergencyStorageTable = (
  stocks: Stock[],
  filterWarehouseId: string | undefined,
  filterItemId: string | undefined
) => {
  const data = useMemo(() => {
    if (!stocks.length) return [];
    return stocks
      .filter((x) => {
        const warehouseMatch = !filterWarehouseId || x.warehouseId === filterWarehouseId;
        const productMatch = !filterItemId || x.product?.itemId === filterItemId;
        return warehouseMatch && productMatch;
      })
      .map((stock) => {
        const idSuffix = stock.product?.batchNumber || stock.product?.serialNumber;
        return {
          minMax: `${stock.minOnHand}/${stock.maxOnHand}`,
          name: stock.product?.name,
          itemId: stock.product?.itemId + (idSuffix ? ` (${idSuffix})` : ''),
          available: stock.available,
          warehouseId: stock.warehouseName ? `${stock.warehouseName} (${stock.warehouseId})` : stock.warehouseId
        } as StockData;
      });
  }, [stocks, filterWarehouseId, filterItemId]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Navn',
        accessor: 'name',
        width: 400,
        minWidth: 400,
        disableFilters: true
      },
      {
        Header: 'Lager',
        accessor: 'warehouseId',
        width: 150,
        minWidth: 150,
        disableFilters: true
      },
      {
        Header: 'Varenummer',
        accessor: 'itemId',
        width: 120,
        minWidth: 120,
        disableFilters: true
      },
      {
        Header: 'Antal',
        accessor: 'available',
        width: 120,
        minWidth: 120,
        disableFilters: true
      },
      {
        Header: 'Min/Max',
        accessor: 'minMax',
        width: 75,
        minWidth: 75,
        disableFilters: true
      }
    ] as Column<StockData>[];
  }, []);

  const tableInstance = useTableInstance<StockData>(data, columns);

  return { tableInstance };
};
