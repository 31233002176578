import { useState } from 'react';
import styled from 'styled-components';
import { QuestionProps } from '../question-wrapper/QuestionRenderer';
import DatePicker from '../date-picker/DatePicker';
import { formatDateString } from '../../utils/dateHandling';
import QuestionHeader from '../question-header/QuestionHeader';
import AuditInfo from '../audit-info/AuditInfo';
import QuestionRow from '../question-wrapper/QuestionRow';
import { getInitialsFromEmail } from '../../utils/initialsUtils';
import { getUserEmail } from '../../utils/authProvider/authProvider';

const QuestionDate = (props: QuestionProps) => {
  const { question, handleSave, required, showMandatoryFieldError } = props;
  const [value, setValue] = useState<string | null>(question.value ?? null);
  const [lastUpdatedBy, setLastUpdatedBy] = useState(question.updatedByEmail);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(question.timeStampValue);

  const handleChange = (date: string | null) => {
    setValue(date);
    handleSave && handleSave(date ?? '');
    setLastUpdatedBy(getInitialsFromEmail(getUserEmail()));
    setLastUpdatedTime(new Date().toISOString() + '');
  };

  return (
    <Container>
      <QuestionHeader
        text={question.text}
        required={required}
        helpText={question.helpText}
        error={showMandatoryFieldError && required && value == null}
      />
      <QuestionRow>
        <StyledDatePicker
          onDateChanged={(date) => handleChange(date.toISOString())}
          value={formatDateString(value ?? '')}
          required={required}
          error={showMandatoryFieldError && required && value == null}
        />
        <AuditInfo updatedByEmail={lastUpdatedBy} updatedTimeStamp={lastUpdatedTime} size="24px" />
      </QuestionRow>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  & label {
    margin-left: 0px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  background-color: ${(props) => props.theme.palette.background.primary};
`;

export default QuestionDate;
