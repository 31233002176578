const tabletRegExp = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/;
export const isTablet = (): boolean => {
  if (!navigator || !navigator.userAgent) return false;

  return tabletRegExp.test(navigator.userAgent);
};

export const isiOS = (): boolean => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.userAgent) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

const mobileRegExp = /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/;
export const isMobile = (): boolean => {
  if (!navigator || !navigator.userAgent) return false;

  return mobileRegExp.test(navigator.userAgent);
};

export const isDevice = (): boolean => {
  return isTablet() || isMobile() || isiOS();
};
