import { useEffect, useState } from 'react';
import { QuestionProps } from '../question-wrapper/QuestionRenderer';
import TextField from '../text-field/TextField';
import QuestionHeader from '../question-header/QuestionHeader';
import styled from 'styled-components';
import AuditInfo from '../audit-info/AuditInfo';
import QuestionRow from '../question-wrapper/QuestionRow';
import { getInitialsFromEmail } from '../../utils/initialsUtils';
import { getUserEmail } from '../../utils/authProvider/authProvider';

interface Props extends QuestionProps {
  type: 'text' | 'number';
  showMandatoryFieldError: boolean;
}

const QuestionText = (props: Props) => {
  const { question, handleSave, type, required, showMandatoryFieldError } = props;
  const [value, setValue] = useState<string>('');
  const [lastUpdatedBy, setLastUpdatedBy] = useState(question.updatedByEmail);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(question.timeStampValue);

  useEffect(() => {
    setValue(question.value?.toString());
  }, [question.value]);

  const handleBlur = () => {
    if (handleSave) {
      handleSave(value);
    }
  };

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const newValue = e.target.value as string;
    setValue(newValue);
    setLastUpdatedBy(getInitialsFromEmail(getUserEmail()));
    setLastUpdatedTime(new Date().toISOString() + '');
  };

  return (
    <>
      <QuestionHeader
        text={question.text}
        required={required}
        helpText={question.helpText}
        error={showMandatoryFieldError && required && !value}
      />
      <QuestionRow>
        <StyledTextField
          type={type}
          fullWidth
          variant={'outlined'}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          required={required}
          error={showMandatoryFieldError && required && !value}
          multiline
        />
        <AuditInfo updatedByEmail={lastUpdatedBy} updatedTimeStamp={lastUpdatedTime} size="24px" />
      </QuestionRow>
    </>
  );
};

const StyledTextField = styled(TextField)`
  background-color: ${(props) => props.theme.palette.background.primary};
`;

export default QuestionText;
