import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { newTask, selectTasks, setAssetSearchStatus } from '../../../stateManagement/reducers/taskCreationReducer';

import {
  AssetResultDTO,
  BaseWorkTaskTypeEnum,
  CustomerAppointmentDTO,
  WorkInvoiceDTO,
  WorkTaskStatus
} from '../../../api/api';
import { AddIcon } from '../../../assets/icons/AddIcon';
import { CameraIcon } from '../../../assets/icons/CameraIcon';
import { ScheduleIcon } from '../../../assets/icons/ScheduleIcon';
import { WarningIcon } from '../../../assets/icons/WarningIcon';
import TabsVertical from '../../../blocks/tabs-vertical/TabsVertical';
import TabsVerticalToolsItem from '../../../blocks/tabs-vertical/tabs-vertical-tools/tabs-vertical-tools-item/TabsVerticalToolsItem';
import StatusTag from '../../../components/status-tag/StatusTag';
import useQuery from '../../../hooks/useQuery';
import useWorkTaskLock from '../../../hooks/useWorkTaskLock';
import { QueryParams } from '../../../models/QueryParams';
import { WorkTaskDTOExtended } from '../../../models/TaskType';
import { TaskComponent } from '../../../models/TaskTypeComponent';
import AssetService from '../../../services/AssetService';
import AttachmentService from '../../../services/AttachmentService';
import NotificationService from '../../../services/NotificationService';
import SubWorkTaskService from '../../../services/SubWorkTaskService';
import TaskService from '../../../services/TaskService';
import { setListShouldUpdate } from '../../../stateManagement/reducers/taskListReducer';
import { extractAttributeValuesFromComponents } from '../../../utils/componentHandling';
import { EDIT_TASK_ROUTE, TASKS_DETAILS_DIALOG_ROUTE } from '../../../utils/constants';
import { getEnumDisplayValue } from '../../../utils/enumUtils';
import { getGuid } from '../../../utils/guidGenerating';
import { log } from '../../../utils/logging/log';
import replaceQueryParams from '../../../utils/routing/replace-query-params';
import TaskChecklistStep from './checklist-step/TaskChecklistStep';
import CustomerAppointmentStep from './customer-appointment-step/CustomerAppointmentStep';
import TaskDetailsStep from './details-step/TaskDetailsStep';
import TaskGoodsStep from './goods-step/TaskGoodsStep';
import RelationalTree from './relational-tree/RelationalTree';
import TaskFilesStep from './task-files-step/TaskFilesStep';
import TaskMessagesStep from './task-messages-step/TaskMessagesStep';
import TaskTimeRegStep from './timereg-step/TaskTimeRegStep';
import WorkInstructionsStep from './work-instructions-step/WorkInstructionsStep';
import useChecklistLock from '../../../hooks/useChecklistLock';
import GisComponentService from '../../../services/GisComponentService';
import TaskChecklistProvider from '../../../stateManagement/TaskChecklistProvider';
import WorkTaskControlBar from '../../../blocks/worktask-control-bar/WorkTaskControlBar';
import { ConstructionIcon } from '../../../assets/icons/ContructionIcon';
import { DialogBody } from '../../../stateManagement/reducers/confirmDialogReducer';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import useUserAccess from '../../../hooks/useUserAccess';
import { selectTaskChecklistState } from '../../../stateManagement/reducers/taskChecklistReducer';
import { AddDocumentIcon } from '../../../assets/icons/AddDocumentIcon';
import WorkInvoiceStep from './work-invoice-step/WorkInvoiceStep';
import WorkInvoiceService from '../../../services/WorkInvoiceService';
import theme from '../../../theme/light-theme';
import { workInvoiceResponseDTOPopulated } from './work-invoice-step/WorkInvoiceHelper';
import { UnreadDot } from '../../../assets/icons/UnreadDot';
import GoodsService from '../../../services/GoodsService';
import TimeRegistrationService from '../../../services/TimeRegistrationService';
import { isInPlanningLoop } from '../task-list-view/TaskListFilterUtils';
import Typography from '../../../components/typography/Typography';
import ToolTip from '../../../components/tool-tip/ToolTip';
import { InfoIcon } from '../../../assets/icons/InfoIcon';
import { EditIcon } from '../../../assets/icons/EditIcon';
import GisView from './asset-step/GIS/GisView';
import addQueryParams from '../../../utils/routing/add-query-params';

export interface CollapsedSections {
  checklistId: number;
  taskTypeChecklistId: number;
  sections: number[];
}

export interface ExpandedCollapedChecklistsSections {
  expandedChecklistId?: number;
  expandedTaskTypeChecklistId?: number;
  collapsedSections?: CollapsedSections[];
}

enum InvoiceError {
  NONE = 'none',
  ALERT = 'alert',
  NOTIFICATION = 'notification'
}

interface Props {
  hideCloseButton?: boolean;
  toolsOpen: boolean;
  setToolsOpen: (open: boolean) => void;
  handleCloseTask: () => void;
}

const TaskDetailsViewContent = (props: Props) => {
  const { hideCloseButton, toolsOpen, setToolsOpen, handleCloseTask } = props;
  const { id, type: detailType } = useQuery<QueryParams>();
  const [task, setTask] = useState<WorkTaskDTOExtended>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editable, setEditable] = useState(false);
  const [activePanelId, setActivePanelId] = useState<string>('1');
  const [customerAppointment, setCustomerAppointment] = useState<CustomerAppointmentDTO | undefined>();
  const [numberOfFiles, setNumberOfFiles] = useState<number>();
  const [assetComponentHasChanges, setAssetComponentHasChanges] = useState<boolean>(); // Awaiting GIS to be reimplemented
  const [comment, setComment] = useState('');
  const [showWorkTaskControlBar, setShowWorkTaskControlBar] = useState(false);
  const [showBookButton, setShowBookButton] = useState(false);
  const [checklistReadOnly, setCheklistReadOnly] = useState(task ? task.status !== WorkTaskStatus.Ongoing : true);
  const [workInvoiceId, setWorkInvoiceId] = useState<number>();
  const [workInvoice, setWorkInvoice] = useState<WorkInvoiceDTO>();
  const [showWorkInvoiceError, setShowWorkInvoiceError] = useState<InvoiceError>(InvoiceError.NONE);

  const [expandedCollapsedChecklist, setExpandedCollapedChecklist] = useState<ExpandedCollapedChecklistsSections>({
    expandedChecklistId: undefined,
    expandedTaskTypeChecklistId: undefined,
    collapsedSections: []
  });

  const tasks = useSelector(selectTasks);
  const { officeUserGroupAllowed } = useUserAccess();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [scrollPositions, setScrollPositions] = useState<Map<string, number>>(
    new Map([
      ['1', 0],
      ['02', 0],
      ['2', 0],
      ['3', 0],
      ['4', 0],
      ['5', 0],
      ['6', 0],
      ['7', 0],
      ['8', 0],
      ['9', 0]
    ])
  );
  const showChecklistErrors = useSelector(selectTaskChecklistState).showChecklistErrors;
  const { getConfirmation } = useConfirmationDialog();

  const { addWorkTaskLock, releaseWorkTaskLock, workTaskLockedBy } = useWorkTaskLock();
  const { releaseChecklistLock } = useChecklistLock();

  const lockTask = useCallback(
    (worktaskId: number) => {
      addWorkTaskLock(worktaskId);
    },
    [addWorkTaskLock]
  );

  const fetchTask = useCallback(async () => {
    if (!id || !detailType) return;
    setIsLoading(true);
    releaseWorkTaskLock(parseInt(id));
    releaseChecklistLock();
    try {
      let task: WorkTaskDTOExtended = {};
      if (detailType === BaseWorkTaskTypeEnum.SubWorkTask) {
        const subTask = await SubWorkTaskService.getSubTask(parseInt(id));
        if (subTask.mainTask) {
          task = subTask.mainTask;
          // Map subTask props as mainTask props
          task.subTaskId = subTask.workTaskId;
          task.mainTaskAssignedToName = task.assignedToName;
          task.mainTaskStatus = task.status;
          task.createdByName = subTask.createdByName;
          task.createdByEmail = subTask.createdByEmail;
          task.assignedToName = subTask.assignedToName;
          task.assignedToEmail = subTask.assignedToEmail;
          task.taskType = subTask.taskType;
          task.status = subTask.status;
          task.subWorkTasks = [];
        }
      } else {
        task = await TaskService.getTask(parseInt(id));
      }
      const _workInvoiceId = await WorkInvoiceService.getWorkInvoiceId(parseInt(id));
      setWorkInvoiceId(_workInvoiceId);

      if (_workInvoiceId) {
        const _workInvoice = await WorkInvoiceService.getWorkInvoice(_workInvoiceId);
        setWorkInvoice(_workInvoice.workInvoiceDTO);
        fetchWorkInvoiceNotification(_workInvoice.workInvoiceDTO);
      }

      setTask(task);
      setShowBookButton(task.status === WorkTaskStatus.Created || task.status === WorkTaskStatus.Pause);
      setCheklistReadOnly(checklistReadOnly ? task.status !== WorkTaskStatus.Ongoing : checklistReadOnly);
      setCustomerAppointment(task.customerAppointment);
    } catch (error) {
      NotificationService.error('Arbejdskortet kunne ikke hentes', 5000);
      log(error);
    } finally {
      lockTask(parseInt(id));
      setIsLoading(false);
    }
    // checklistReadOnly & releaseChecklistLock should not trigger re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, detailType, releaseWorkTaskLock, lockTask]);

  useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  const fetchWorkInvoiceNotification = useCallback(
    async (workInvoiceDTO?: WorkInvoiceDTO) => {
      if (workInvoiceDTO) setWorkInvoice(workInvoiceDTO);
      const _workInvoice = workInvoiceDTO ?? workInvoice;

      if (!id || !_workInvoice) return;

      if (!workInvoiceResponseDTOPopulated(_workInvoice)) {
        setShowWorkInvoiceError(InvoiceError.ALERT);
        return;
      }
      if (_workInvoice?.projectCategory === undefined || _workInvoice?.projectCategory === null) {
        setShowWorkInvoiceError(InvoiceError.NONE);
        return;
      }

      if (!_workInvoice?.projectId) {
        setShowWorkInvoiceError(InvoiceError.NOTIFICATION);
        return;
      }

      const goodsToMove = await GoodsService.checkIfWorkTaskHasConsumedItems(parseInt(id)).catch((err) => log(err));
      if (goodsToMove) {
        setShowWorkInvoiceError(InvoiceError.NOTIFICATION);
        return;
      }

      const timeToMove = await TimeRegistrationService.checkIfWorkTaskHasTimeRegistrations(parseInt(id)).catch((err) =>
        log(err)
      );
      if (timeToMove) {
        setShowWorkInvoiceError(InvoiceError.NOTIFICATION);
        return;
      }

      setShowWorkInvoiceError(InvoiceError.NONE);
    },
    [workInvoice, id]
  );

  const getNumberOfFiles = useCallback(() => {
    task?.id &&
      AttachmentService.getNumberOfFiles(parseInt(task?.id))
        .then((res) => setNumberOfFiles(res))
        .catch((err) => log(err));
  }, [task?.id]);

  useEffect(() => {
    getNumberOfFiles();
  }, [getNumberOfFiles]);

  const getAssetComponentHasChanges = useCallback(() => {
    task?.id &&
      GisComponentService.hasChanges(parseInt(task?.id))
        .then((res) => setAssetComponentHasChanges(res))
        .catch((err) => log(err));
  }, [task?.id]);

  useEffect(() => {
    getAssetComponentHasChanges();
  }, [getAssetComponentHasChanges]);

  const tabClickedHandler = useCallback(
    (newTabId: string) => {
      setScrollPositions((_scrollPositions) =>
        _scrollPositions.set(activePanelId, document.getElementById('task-details-body')?.scrollTop ?? 0)
      );
      releaseChecklistLock();
      setActivePanelId(newTabId);
    },
    [activePanelId, releaseChecklistLock]
  );

  const scrollIntoPreviousPositionHandler = useCallback(
    (id: string) => {
      document.getElementById('task-details-body')?.scrollBy({ top: scrollPositions.get(id) });
    },
    [scrollPositions]
  );

  const renderMenuHeader = () => {
    const { status, taskType, id, children, parent } = task ?? {};

    if (status && taskType && id && ((children && children.length > 0) || parent)) {
      return <RelationalTree id={id} taskType={taskType ?? {}} children={children} parent={parent}></RelationalTree>;
    }
  };

  const handleCreateRelationalTask = () => {
    handleCreateTask('relational');
  };

  const handleCopyTask = () => {
    handleCreateTask('copy');
  };

  const handleCreateTask = async (type: 'copy' | 'relational') => {
    if (tasks.length < 3 && id) {
      releaseWorkTaskLock(parseInt(id ?? ''));
      let asset: AssetResultDTO = {};
      const taskId = getGuid();
      if (task?.assetId && task.assetType) {
        setIsLoading(true);
        await AssetService.getAssets(task.assetId, task.assetType)
          .then((response) => {
            setIsLoading(false);
            asset = response[0];
          })
          .catch((error) => {
            setIsLoading(false);
            NotificationService.error(`Kunne ikke kopiere opgave: ${error}`, 5000);
          });
      } else {
        asset = { location: task?.taskLocation, assetType: task?.assetType };
      }

      dispatch(
        newTask({
          taskCreationId: taskId,
          assignedToDepartmentId: task?.assignedToDepartment?.departmentId,
          description: task?.description,
          notesForPlanning: type === 'copy' ? task?.notesForPlanning : undefined,
          taskLocation: task?.taskLocation,
          assetId: task?.assetId,
          assetLocation: asset?.location,
          assetType: asset?.assetType,
          assetPrimaryVoltage: asset?.primaryVoltage,
          parentId: type === 'relational' ? parseInt(id) : undefined,
          creationType: type,
          componentsFromParent: extractAttributeValuesFromComponents(task?.components as TaskComponent[]),
          gisRegion: task?.gisRegion,
          stretches: task?.stretches
        })
      );
      dispatch(
        setAssetSearchStatus({
          taskCreationId: taskId,
          category: asset.assetType,
          locations: [asset]
        })
      );
      navigate(replaceQueryParams(['id', 'type'], TASKS_DETAILS_DIALOG_ROUTE, { id: taskId }, EDIT_TASK_ROUTE));
    } else {
      NotificationService.warning('Der kan højst være 3 åbne arbejdskort. Luk venligst en af de åbne arbejdskort.');
    }
  };

  const handleSuccessfulCustomerAppointmentSubmit = useCallback(
    (customerAppointment?: CustomerAppointmentDTO) => {
      customerAppointment && setCustomerAppointment(customerAppointment);
      tabClickedHandler('1');
      dispatch(setListShouldUpdate(true));
    },
    [dispatch, tabClickedHandler]
  );

  const handleNumberOfFilesChanged = useCallback(() => {
    getNumberOfFiles();
  }, [getNumberOfFiles]);

  const handleCreateSubTask = useCallback(
    async (taskTypeId?: number) => {
      if (!id || !taskTypeId || !task) return;

      let newTask = task;
      newTask.subWorkTasks = (await TaskService.getTask(parseInt(id))).subWorkTasks;
      setTask(newTask);

      setIsLoading(true);
      SubWorkTaskService.createSubTask(parseInt(id), taskTypeId)
        .then((response) => {
          task?.subWorkTasks?.push(response);
          NotificationService.success(
            `Tilføjet ${
              task?.taskType?.id === parseInt(window._env_.LAUS_TASK_ID) ? 'LAUS-mand' : 'ekstra tekniker'
            } tilhørende opgave ${id}`,
            5000
          );
          dispatch(setListShouldUpdate(true));
        })
        .catch((err) => {
          log(err);
          NotificationService.error(
            `Kunne ikke tilføje ${
              task?.taskType?.id === parseInt(window._env_.LAUS_TASK_ID) ? 'LAUS-mand' : 'ekstra tekniker'
            } til opgaven`,
            5000
          );
        })
        .finally(() => setIsLoading(false));
    },
    [id, task, dispatch]
  );

  const handleAssociateWorkInvoice = useCallback(() => {
    WorkInvoiceService.associateWorkInvoice(parseInt(id ?? ''))
      .then((res) => {
        tabClickedHandler('10');
        setWorkInvoiceId(res);
      })
      .catch((err) => {
        log(err);
        NotificationService.error(`Kunne ikke tilføje skadevolder/regningsarbejde til opgaven: ${id}`);
      });
  }, [id, tabClickedHandler]);

  const handleDeleteWorkInvoice = useCallback(() => {
    if (!workInvoiceId) return;
    WorkInvoiceService.deleteWorkInvoice(workInvoiceId, parseInt(id ?? ''))
      .then(() => {
        setWorkInvoiceId(undefined);
        tabClickedHandler('1');
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke slette skadevolder/regningsarbejde.');
      });
  }, [workInvoiceId, tabClickedHandler, id]);

  const renderTabsHeader = () => {
    if (task) {
      return (
        <StyledTabHeader>
          <Typography variant="h4" fontWeight="bold">
            {task.taskType?.description && (
              <ToolTip title={task.taskType?.description ?? ''}>
                <IconContainer>
                  <InfoIcon size="18px" />
                </IconContainer>
              </ToolTip>
            )}
            {detailType === BaseWorkTaskTypeEnum.SubWorkTask
              ? task?.taskType?.id === parseInt(window._env_.LAUS_TASK_ID)
                ? `LAUS-opgave for: `
                : `Underopgave for: `
              : ''}
            {task?.taskType?.name} {`(${task?.id})`} - {task?.assetId}
            {(task?.status === WorkTaskStatus.Created ||
              task?.status === WorkTaskStatus.ReadyForPlanning ||
              task?.status === WorkTaskStatus.Pause) &&
              !editable &&
              activePanelId === '1' &&
              detailType !== BaseWorkTaskTypeEnum.SubWorkTask && (
                <IconContainer clickable onClick={() => setEditable(true)}>
                  <EditIcon size="24px" padding="0px 0px 0px 5px" />
                </IconContainer>
              )}
          </Typography>
          {task.status && <StatusTag status={task?.status}>{getEnumDisplayValue(WorkTaskStatus[task?.status])}</StatusTag>}
        </StyledTabHeader>
      );
    }
    return <></>;
  };

  const handleDeleteTasks = useCallback(
    async (worktaskHasDifferentDepartment) => {
      if (!id || !task?.status) return;

      if (isInPlanningLoop([task.status])) {
        NotificationService.error(`Kunne ikke annullere opgave(r). En eller flere opgaver er i planning loopet.`);
        return;
      }

      const dialogBody: DialogBody = {
        headerText: `Er du sikker på at du vil annullere opgaven?`,
        bodyText:
          detailType === BaseWorkTaskTypeEnum.WorkTask
            ? `Annullerede opgaver kan stadigvæk findes på “Alle opgaver”, men skal oprettes igen hvis annulleringen fortrydes. ${
                worktaskHasDifferentDepartment ? 'Mindst én af de valgte opgaver er tildelt en anden afdeling.' : ''
              }`
            : 'Underopgaven forsvinder fra opgavekortet, og kan derfor ikke fremsøges igen',
        declineButtonText: 'Fortryd',
        confirmButtonText: 'Ja'
      };

      const confirmation = await getConfirmation(dialogBody);

      if (confirmation === 'confirm') {
        setIsLoading(true);
        if (detailType === BaseWorkTaskTypeEnum.WorkTask) {
          TaskService.cancelTasks([parseInt(id)])
            .then(() => {
              dispatch(setListShouldUpdate(true));
              handleCloseTask();
              NotificationService.success(`Annullerede opgave: ${id}.`);
            })
            .catch((error) => {
              NotificationService.error(`Der opstod en fejl ved annullering af opgaven: ${error}`);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else if (detailType === BaseWorkTaskTypeEnum.SubWorkTask) {
          SubWorkTaskService.cancelSubWorkTask(parseInt(id))
            .then(() => {
              if (task.id) {
                navigate(
                  addQueryParams(undefined, {
                    id: task.id,
                    type: BaseWorkTaskTypeEnum.WorkTask
                  }),
                  {
                    replace: true,
                    state: { payload: { state: { type: BaseWorkTaskTypeEnum.WorkTask } } }
                  }
                );
              } else {
                handleCloseTask();
              }
              NotificationService.success(`Underopgave slettet.`);
            })
            .catch((error) => {
              NotificationService.error(`Der opstod en fejl ved sletning af underopgaven: ${error}`);
            });
        }
      }
    },
    [id, task?.status, task?.id, getConfirmation, detailType, dispatch, handleCloseTask, navigate]
  );

  const renderWorkInvoiceNotification = () => {
    switch (showWorkInvoiceError) {
      case InvoiceError.ALERT:
        return (
          <WarningIcon
            color={activePanelId === '10' ? theme.palette.functions.error.tertiary : theme.palette.functions.error.primary}
            size="24px"
          />
        );
      case InvoiceError.NOTIFICATION:
        return <UnreadDot />;
      default:
        return undefined;
    }
  };

  return (
    <>
      <TabsVertical
        contentLoading={isLoading}
        headerComponent={renderMenuHeader()}
        activePanelId={activePanelId}
        onClose={!hideCloseButton ? handleCloseTask : undefined}
        handleActivePanelIdChange={(id: string) => tabClickedHandler(id)}
        toolsOpen={toolsOpen}
        setToolsOpen={(open: boolean) => setToolsOpen(open)}
        openBy={workTaskLockedBy(parseInt(id ?? ''))}
        tools={
          <>
            <TabsVerticalToolsItem
              onClick={() => tabClickedHandler('8')}
              htmlFor="image-upload-camera"
              icon={<CameraIcon size="20px" />}
              text="Tag billede"
            />
            <TabsVerticalToolsItem
              onClick={handleCreateRelationalTask}
              disabled={detailType === BaseWorkTaskTypeEnum.SubWorkTask}
              icon={<AddIcon size="20px" />}
              text="Opret relationsopgave"
            />
            <TabsVerticalToolsItem
              onClick={handleCopyTask}
              disabled={detailType === BaseWorkTaskTypeEnum.SubWorkTask}
              icon={<AddIcon size="20px" />}
              text="Opret kopi af arbejdskort"
            />
            <TabsVerticalToolsItem
              onClick={() => handleCreateSubTask(parseInt(window._env_.LAUS_TASK_ID))}
              disabled={
                task?.status === WorkTaskStatus.Completed ||
                task?.status === WorkTaskStatus.Processed ||
                !officeUserGroupAllowed() ||
                detailType === BaseWorkTaskTypeEnum.SubWorkTask
              }
              icon={<AddIcon size="20px" />}
              text="Tilføj LAUS-mand"
            />
            <TabsVerticalToolsItem
              onClick={() => handleCreateSubTask(task?.taskType?.id)}
              disabled={
                task?.status === WorkTaskStatus.Completed ||
                task?.status === WorkTaskStatus.Processed ||
                !officeUserGroupAllowed() ||
                detailType === BaseWorkTaskTypeEnum.SubWorkTask
              }
              icon={<AddIcon size="20px" />}
              text="Tilføj ekstra tekniker"
            />
            <TabsVerticalToolsItem
              onClick={() => tabClickedHandler('02')}
              disabled={!!task?.customerAppointment || detailType === BaseWorkTaskTypeEnum.SubWorkTask}
              icon={<ScheduleIcon size="20px" />}
              text="Opret forslag til tidsaftale"
            />
            {window._env_.DAMAGECLAIM_ENABLED === 'true' && (
              <TabsVerticalToolsItem
                onClick={handleAssociateWorkInvoice}
                icon={
                  <AddDocumentIcon
                    size="20px"
                    color={workInvoiceId ? `${theme.palette.grey.black20}` : `${theme.palette.grey.black90}`}
                  />
                }
                text="Tilknyt skadevolder/regningsarbejde"
                disabled={!!workInvoiceId}
              />
            )}
            {officeUserGroupAllowed() && (
              <TabsVerticalToolsItem
                onClick={() => setShowWorkTaskControlBar(!showWorkTaskControlBar)}
                icon={<ConstructionIcon size="20px" />}
                text="Vis opgavehandlinger"
              />
            )}
          </>
        }
      >
        <TaskDetailsStep
          tabId="1"
          task={task}
          customerAppointment={customerAppointment}
          contentHeaderText={renderTabsHeader()}
          tabHeaderText="Opgavedetaljer"
          editable={editable}
          setEditable={setEditable}
          setWorkTaskCallback={setTask}
          scrollIntoPreviousPosition={(id) => scrollIntoPreviousPositionHandler(id)}
          workInvoice={workInvoiceId}
          workInvoiceProjectNumber={workInvoice?.projectId}
        />
        <CustomerAppointmentStep
          hidden
          tabId="02"
          tabHeaderText=""
          taskId={task?.id}
          onSubmitSuccess={handleSuccessfulCustomerAppointmentSubmit}
          contentHeaderText={renderTabsHeader()}
        />

        {window._env_.TASK_ASSET_STEP_DISABLED === 'false' && (
          <GisView
            onAssetComponentChanges={() => getAssetComponentHasChanges()}
            task={task}
            tabId="2"
            contentHeaderText={renderTabsHeader()}
            tabHeaderText="Data"
            notification={assetComponentHasChanges ? <TabNotificationIcon>!</TabNotificationIcon> : undefined}
          />
        )}
        {task?.taskType?.enableContractorAgreements && detailType !== BaseWorkTaskTypeEnum.SubWorkTask && (
          <WorkInstructionsStep
            tabId="3"
            taskId={task.id ?? ''}
            contentHeaderText={renderTabsHeader()}
            tabHeaderText="Arbejdsinstruktion"
            onPDFCreatedCallback={getNumberOfFiles}
          />
        )}
        {detailType !== BaseWorkTaskTypeEnum.SubWorkTask && task?.id && (
          <TaskChecklistProvider
            initialState={[]}
            tabId={'4'}
            contentHeaderText={renderTabsHeader()}
            notification={
              showChecklistErrors.showError && showChecklistErrors.taskId === task.id ? (
                <WarningIcon
                  color={
                    activePanelId === '4' ? theme.palette.functions.error.tertiary : theme.palette.functions.error.primary
                  }
                  size="24px"
                />
              ) : undefined
            }
            tabHeaderText="Tjekliste"
            hideScrollbar
          >
            <TaskChecklistStep
              workTaskId={parseInt(task?.id)}
              tabId="4"
              scrollIntoPreviousPosition={(id) => scrollIntoPreviousPositionHandler(id)}
              expandedState={expandedCollapsedChecklist}
              setExpandedState={setExpandedCollapedChecklist}
              readOnly={checklistReadOnly}
              setReadOnly={setCheklistReadOnly}
            />
          </TaskChecklistProvider>
        )}
        <TaskGoodsStep
          tabId="5"
          contentHeaderText={renderTabsHeader()}
          tabHeaderText="Varer"
          taskId={task?.id ?? ''}
          projectNumber={task?.projectNumber ?? ''}
        />
        <TaskTimeRegStep
          tabId="6"
          contentHeaderText={renderTabsHeader()}
          tabHeaderText="Timer"
          idForFetchingProject={task?.projectNumber ?? ''}
          idForRegistration={parseInt(id ?? '')}
        />
        <TaskMessagesStep
          tabId="7"
          taskId={task?.id}
          contentHeaderText={renderTabsHeader()}
          tabHeaderText={`Beskeder ${task?.commentsCount && task?.commentsCount > 0 ? '(' + task?.commentsCount + ')' : ''}`}
          updateTask={setTask}
          comment={comment}
          setComment={(_comment) => setComment(_comment)}
        />
        <TaskFilesStep
          tabId="8"
          taskId={task?.id ?? ''}
          contentHeaderText={renderTabsHeader()}
          tabHeaderText={`Filer ${numberOfFiles && numberOfFiles > 0 ? '(' + numberOfFiles + ')' : ''}`}
          onFileRemoved={handleNumberOfFilesChanged}
          onFileUploaded={handleNumberOfFilesChanged}
        />
        {workInvoiceId && (
          <WorkInvoiceStep
            tabId="10"
            tabHeaderText="Skadevolder/regningsarbejde"
            contentHeaderText={renderTabsHeader()}
            taskStatus={task?.status}
            workInvoiceId={workInvoiceId}
            handleDeleteWorkInvoice={handleDeleteWorkInvoice}
            notification={renderWorkInvoiceNotification()}
            updateNotification={fetchWorkInvoiceNotification}
          />
        )}
      </TabsVertical>
      {showWorkTaskControlBar && (
        <WorkTaskControlBar
          selectedWorkTasks={[
            {
              workTaskId: id ?? '',
              hasSubTasks: task?.hasSubTasks ?? false,
              status: task?.status && getEnumDisplayValue(task.status),
              department: task?.assignedToDepartment?.name ?? '-'
            }
          ]}
          updateTasks={fetchTask}
          handleClose={() => setShowWorkTaskControlBar(false)}
          setLoading={(isLoading: boolean) => setIsLoading(isLoading)}
          showBookButton={showBookButton}
          handleDeleteTasks={handleDeleteTasks}
        />
      )}
    </>
  );
};

const StyledTabHeader = styled.div`
  display: block;
`;

const IconContainer = styled.div<{ clickable?: boolean }>`
  display: inline;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  margin-right: ${(props) => props.theme.spacing(1)};
`;

const TabNotificationIcon = styled.div`
  background-color: ${(props) => props.theme.palette.main.yellow.primary};
  color: ${(props) => props.theme.palette.grey.black90};
  border-radius: 50%;
  width: ${(props) => props.theme.spacing(5)};
  height: ${(props) => props.theme.spacing(5)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 2px 0px 2px;
  font-weight: 900;
`;

export default TaskDetailsViewContent;
