import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SectionContent } from '../../styling/FormStyling';
import { getUserEmail } from '../../utils/authProvider/authProvider';
import { getFullDateString } from '../../utils/dateHandling';
import { getFileType, isImageType } from '../../utils/stringHandling';
import FilePreview from '../file-preview/FilePreview';
import Button from '../button/Button';

export const AcceptedFiles: string[] = ['pdf', 'png', 'jpeg', 'xlsx', 'pptx', 'docx', 'svg', 'doc', 'xls', 'ppt', 'jpg'];
const AcceptString = '.pdf,.png,.jpeg,.xlsx,.pptx,.docx,.svg,.doc,.xls,.ppt,.jpg';

interface Props {
  files: File[];
  onFileSelection?: (files: File[]) => void;
  onRemoveSelectedFile?: (files: File[]) => void;
  onError?: (errors: Map<number, string>) => void;
  children?: React.ReactNode | React.ReactNode[];
}

const FileUpload = (props: Props) => {
  const { files = [], onFileSelection, onRemoveSelectedFile, onError, children } = props;

  const [selectedFiles, setSelectedFiles] = useState<File[]>(files);
  const [errors, setErrors] = useState<Map<number, string>>(new Map());

  const userEmail = getUserEmail();

  const handleFilesSelect = useCallback(
    (files: FileList | null) => {
      if (files) {
        let newFiles: File[] = [];
        Array.from(files).forEach((file, i) => {
          newFiles.push(file);
        });

        const mergedFiles = [...selectedFiles, ...newFiles];

        setSelectedFiles(mergedFiles);

        onFileSelection?.(mergedFiles);
      }
    },
    [onFileSelection, selectedFiles]
  );

  const removeSelectedFile = useCallback(
    (index: number) => {
      const newFiles = [...selectedFiles];

      newFiles.splice(index, 1);

      setSelectedFiles(newFiles);

      onRemoveSelectedFile?.(newFiles);
    },
    [onRemoveSelectedFile, selectedFiles]
  );

  useEffect(() => {
    let tmpErrors = new Map<number, string>();
    selectedFiles
      .filter((file) => file.name)
      .forEach((file, i) => {
        if (!AcceptedFiles.some((x) => x === getFileType(file.name))) {
          tmpErrors.set(i, 'Filtypen er ikke supporteret - kan ikke gemmes');
        } else if (file.size > 40000000) {
          tmpErrors.set(i, 'Filstørrelen overskrider 40 MB - kan ikke gemmes');
        }
      });

    setErrors(tmpErrors);
  }, [selectedFiles]);

  useEffect(() => {
    if (files?.length) {
      setSelectedFiles(files);
    }
  }, [files]);

  useEffect(() => {
    onError?.(errors);
  }, [errors, onError]);

  return (
    <SectionContent>
      <SelectedFilesContainer
        onDragOver={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDrop={(e: any) => {
          e.preventDefault();
          handleFilesSelect(e.dataTransfer.files);
        }}
      >
        {!!selectedFiles.length ? (
          selectedFiles.map((file, i) => (
            <FilePreview
              key={i}
              handleRemove={() => removeSelectedFile(i)}
              errorMessage={errors.get(i)}
              src={isImageType(file.type) ? URL.createObjectURL(file) : ''}
              date={getFullDateString(new Date())}
              name={file?.name}
              uploadedBy={userEmail}
            />
          ))
        ) : (
          <StyledHelperText>Her kan du se din fil når du har taget et billede eller uploadet fil</StyledHelperText>
        )}
      </SelectedFilesContainer>

      <ButtonContainer>
        <input
          style={{ display: 'none' }}
          type="file"
          id="image-upload"
          multiple
          accept={AcceptString}
          onChange={(e) => handleFilesSelect(e.target.files)}
        />
        <input
          capture="environment"
          style={{ display: 'none' }}
          type="file"
          id="image-upload-camera"
          multiple
          accept="image/*"
          onChange={(e) => handleFilesSelect(e.target.files)}
        />

        <label htmlFor="image-upload">
          <Button variant="secondary" element="span">
            Upload fil
          </Button>
        </label>

        {children}
      </ButtonContainer>
    </SectionContent>
  );
};

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.grey.black20};
`;

const SelectedFilesContainer = styled((props) => <FileContainer {...props} />)`
  & {
    flex-direction: column;
    row-gap: 16px;
    border: 1px dashed ${(props) => props.theme.palette.grey.black20};
    color: ${(props) => props.theme.palette.grey.black60};
  }
`;

const StyledHelperText = styled.span`
  line-height: 64px;
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 28px;
`;

export default FileUpload;
