import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import Field from './Field';
import ComponentDetails from './ComponentDetails';
import CommentsSection from './CommentsSection';
import { TabProps } from '../../../../../blocks/tabs-vertical/TabsVertical';
import {
  AssetType2,
  ComponentFieldChangeDTO,
  FileParameter,
  GisChangeInfoDTO,
  GisRegion2,
  ListViewCommentResponseDTO,
  ListViewFileUploadResponseDTO,
  NetstationComponentsResponseDTO,
  StationComponentDTO,
  WorkTaskDTO2,
  ComponentCommentResponseDTO,
  ComponentFileUploadResponseDTO
} from '../../../../../api/api';
import { DialogBody } from '../../../../../stateManagement/reducers/confirmDialogReducer';
import { useConfirmationDialog } from '../../../../../hooks/useConfirmationDialog';
import AssetService from '../../../../../services/AssetService';
import NotificationService from '../../../../../services/NotificationService';
import { log } from '../../../../../utils/logging/log';
import GisComponentService from '../../../../../services/GisComponentService';
import { renameFile } from '../../../../../utils/fileUtils';
import { getGuid } from '../../../../../utils/guidGenerating';
import Form from '../../../../../components/form/Form';
import Button from '../../../../../components/button/Button';
import UploadedFilesSection from '../UploadedFilesSection';
import Typography from '../../../../../components/typography/Typography';
import { DownloadInfo, ImageType } from '../../../../../models/DownloadInfo';

export type PageType = 'standard' | 'details';

interface Props extends TabProps {
  task?: WorkTaskDTO2;
  onAssetComponentChanges: () => void;
}

interface ChangeGroups {
  [key: string]: ComponentFieldChangeDTO[];
}

const dialogBody: DialogBody = {
  headerText: 'Er du sikker?',
  bodyText: 'Er du sikker på at du vil slette denne fil?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

const GisView = (props: Props) => {
  const { task, onAssetComponentChanges } = props;
  const [page, setPage] = useState<PageType>('standard');
  const [viewLoading, setViewLoading] = useState(false);
  const [componentChangesLoading, setComponentChangesLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { getConfirmation } = useConfirmationDialog();

  const [components, setComponents] = useState<NetstationComponentsResponseDTO>();
  const [selectedComponent, setSelectedComponent] = useState<StationComponentDTO | undefined>(undefined);
  const [listViewComments, setListViewComments] = useState<ListViewCommentResponseDTO[]>([]);
  const [listViewFiles, setListViewFiles] = useState<ListViewFileUploadResponseDTO[]>([]);
  const [addingComment, setAddingComment] = useState(false);
  const [changeGroups, setChangeGroups] = useState<ChangeGroups>({});
  const [componentComments, setComponentComments] = useState<ComponentCommentResponseDTO[]>([]);
  const [componentFiles, setComponentFiles] = useState<ComponentFileUploadResponseDTO[]>([]);

  useEffect(() => {
    if (!task?.assetId || task.assetType !== AssetType2.Netstation || task.gisRegion !== GisRegion2.South) return;
    setViewLoading(true);
    AssetService.netstationComponents(task?.assetId)
      .then((res) => {
        setComponents(res);
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke hente komponenter.');
      })
      .finally(() => {
        setViewLoading(false);
      });
  }, [task?.assetId, task?.assetType, task?.gisRegion]);

  const mapGisChangesToChangeGroups = (data: GisChangeInfoDTO) => {
    let groups: ChangeGroups = {};
    data.componentFieldChanges?.forEach((change) => {
      if (!change.componentObjectId) return;
      groups[change.componentObjectId] = groups[change.componentObjectId] || [];
      groups[change.componentObjectId].push(change);
    });
    return groups;
  };

  const fetchComponentChanges = useCallback(() => {
    if (!task?.id) return;
    setComponentChangesLoading(true);
    onAssetComponentChanges();
    GisComponentService.getComponentChange(parseInt(task.id))
      .then((data) => {
        data.listViewComments && setListViewComments(data.listViewComments);
        data.listViewFileUploads && setListViewFiles(data.listViewFileUploads);
        let groups = mapGisChangesToChangeGroups(data);
        data.componentFieldChanges && setChangeGroups(groups);
        data.componentComments && setComponentComments(data.componentComments);
        data.componentFileUploads && setComponentFiles(data.componentFileUploads);
      })
      .catch((error) => {
        log(error);
        NotificationService.error('Kunne ikke hente komponent ændringer.');
      })
      .finally(() => setComponentChangesLoading(false));
  }, [task?.id]);

  useEffect(() => {
    fetchComponentChanges();
  }, [fetchComponentChanges]);

  const handleOnClickField = useCallback((component: StationComponentDTO) => {
    setPage('details');
    setSelectedComponent(component);
  }, []);

  const submitComment = (comment: string) => {
    if (!task?.id) return;
    setButtonLoading(true);
    GisComponentService.addListViewComment({ workTaskId: parseInt(task.id), value: comment })
      .then((newComment: ListViewCommentResponseDTO) => {
        setListViewComments((prev) => [...prev, newComment]);
        setAddingComment(false);
        onAssetComponentChanges();
      })
      .catch((error) => {
        NotificationService.error('Kunne ikke sende kommentar');
        log(error);
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const deleteComment = (listViewCommentId: number) => {
    if (!task?.id || listViewCommentId === -1) return;
    setButtonLoading(true);
    GisComponentService.deleteListViewComment(listViewCommentId)
      .then(() => {
        const updatedListViewComments = listViewComments.filter(
          (listViewComment) => listViewComment.id !== listViewCommentId
        );
        setListViewComments(updatedListViewComments);
        onAssetComponentChanges();
      })
      .catch((error) => {
        NotificationService.error('Kunne ikke slette kommentar');
        log(error);
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const downloadFile = useCallback(
    (filename: string): DownloadInfo => {
      return {
        imageType: ImageType.GisListViewFileDownloadInfo,
        taskId: +(task?.id ?? '-1'),
        assetId: task?.assetId,
        filename
      };
    },
    [task?.id, task?.assetId]
  );

  const getThumbNail = useCallback(async (fileName: string) => {
    return GisComponentService.getListViewThumbnail(parseInt(task?.id ?? ''), task?.assetId ?? '', fileName)
      .then((res) => {
        return URL.createObjectURL(res.data);
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke downloade billede.');
        return '';
      });
  }, []);

  const removeFile = async (fileId: number) => {
    const confirmation = await getConfirmation(dialogBody);
    if (confirmation === 'confirm') {
      setViewLoading(true);
      GisComponentService.deleteListViewFile(fileId)
        .then(() => {
          const updatedListViewFiles = listViewFiles.filter((listViewFile) => listViewFile.id !== fileId);
          setListViewFiles(updatedListViewFiles);
          onAssetComponentChanges();
        })
        .catch((error) => {
          NotificationService.error('Kunne ikke slette fil');
          log(error);
        })
        .finally(() => {
          setViewLoading(false);
        });
    }
  };

  const uploadFiles = async (files: FileList | null) => {
    if (!task?.id || !files) return;
    setViewLoading(true);
    const filesToUpload: FileParameter[] = [];
    Array.from(files).forEach(async (file) => {
      if (file.name === 'image.jpg') file = renameFile(file, getGuid());
      filesToUpload.push({ data: file, fileName: file.name });
    });

    if (filesToUpload.length > 0) {
      const promises: Array<Promise<void | ListViewFileUploadResponseDTO>> = [];

      filesToUpload.forEach((file) => {
        promises.push(GisComponentService.addListViewFile(parseInt(task.id ?? ''), task.assetId ?? '', file));
        promises.push(
          GisComponentService.addListViewFileData({
            workTaskId: parseInt(task.id ?? ''),
            assetId: task.assetId ?? '',
            fileName: file.fileName
          })
        );
      });
      Promise.allSettled(promises)
        .then((results) => {
          const rejectedPromises = results.filter((r) => r.status === 'rejected');
          const fulfilledPromises = results.filter((r) => r.status === 'fulfilled');
          if (rejectedPromises.length > 0) {
            NotificationService.error(`Der opstod fejl under upload af en eller flere filer`);
          } else {
            NotificationService.success(`Der blev uploaded ${fulfilledPromises.length / 2} fil(er)`);
          }
        })
        .catch((e) => {
          log(e);
          NotificationService.error(`Der opstod fejl under upload af en eller flere filer`);
        })
        .finally(() => {
          setViewLoading(false);
          fetchComponentChanges();
          onAssetComponentChanges();
        });
    }
  };

  const deleteComponentComment = (commentId: number) => {
    const updatedComponentComments = componentComments.filter((comment) => comment.id !== commentId);
    setComponentComments(updatedComponentComments);
    onAssetComponentChanges();
  };

  const addComponentComment = (comment: ComponentCommentResponseDTO) => {
    setComponentComments((prev) => [...prev, comment]);
    onAssetComponentChanges();
  };

  const deleteComponentFile = (fileId: number) => {
    const updatedComponentFiles = componentFiles.filter((file) => file.id !== fileId);
    setComponentFiles(updatedComponentFiles);
    onAssetComponentChanges();
  };

  const componentHasUpdates = (componentId?: string) => {
    return !!(
      (componentId && changeGroups[componentId]) ||
      componentFiles.some((cf) => cf.componentId === componentId) ||
      componentComments.some((cc) => cc.componentObjectId === componentId)
    );
  };

  return (
    <Container>
      {viewLoading || componentChangesLoading ? (
        <CenteredCircularProgress />
      ) : (
        <>
          {components && (
            <>
              {page === 'standard' && (
                <>
                  <LeftColumn>
                    <>
                      {components?.mediumVoltageComponents && components?.mediumVoltageComponents?.length > 0 && (
                        <>
                          <Title variant="h4" fontWeight="bold">
                            10-20 kV komponenter
                          </Title>
                          {components?.mediumVoltageComponents?.map((component) => {
                            if (!component.objectId) return;
                            return (
                              <Field
                                key={component.objectId}
                                hasUpdates={componentHasUpdates(component.objectId.toString())}
                                component={component}
                                onClick={(c: StationComponentDTO) => handleOnClickField(c)}
                              />
                            );
                          })}
                        </>
                      )}
                    </>

                    {components?.transformerComponents && components?.transformerComponents?.length > 0 && (
                      <>
                        <Title variant="h4" fontWeight="bold">
                          Transformer
                        </Title>
                        {components?.transformerComponents?.map((component) => {
                          if (!component.objectId) return;
                          return (
                            <Field
                              key={component.objectId}
                              hasUpdates={componentHasUpdates(component.objectId.toString())}
                              component={component}
                              onClick={(c: StationComponentDTO) => handleOnClickField(c)}
                            />
                          );
                        })}
                      </>
                    )}

                    {components?.lowVoltageComponents && components?.lowVoltageComponents?.length > 0 && (
                      <>
                        <Title variant="h4" fontWeight="bold">
                          0,4 komponenter
                        </Title>
                        {components?.lowVoltageComponents?.map((component) => {
                          if (!component.objectId) return;
                          return (
                            <Field
                              key={component.objectId}
                              hasUpdates={componentHasUpdates(component.objectId.toString())}
                              component={component}
                              onClick={(c: StationComponentDTO) => handleOnClickField(c)}
                            />
                          );
                        })}
                      </>
                    )}
                    <CommentsSection
                      comments={listViewComments}
                      description="Kommentaren bruges af dokumentationsafdelingen, hvorfra de kan bruge dit input til at opdatatere GIS data."
                      onDeleteComment={(id) => deleteComment(id)}
                    >
                      {addingComment ? (
                        <Form
                          handleSubmitCallback={(text) => submitComment(text)}
                          cancelButtonText="Fortryd"
                          handleCancelCallback={() => setAddingComment(false)}
                          isLoading={buttonLoading}
                          submitButtonText="Send kommentar"
                        />
                      ) : (
                        <Button variant="secondary" onClick={() => setAddingComment(true)}>
                          Tilføj Kommentar
                        </Button>
                      )}
                    </CommentsSection>
                    <UploadedFilesSection
                      description="Tilknyt billede til kommentar"
                      files={listViewFiles}
                      handleDownload={(fileName) => downloadFile(fileName)}
                      handleRemove={(fileId) => removeFile(fileId)}
                      handleUpload={uploadFiles}
                      handleThumbnail={(fileName: string) => getThumbNail(fileName)}
                    ></UploadedFilesSection>
                  </LeftColumn>
                </>
              )}

              {page === 'details' && (
                <>
                  {selectedComponent && (
                    <ComponentDetails
                      setPage={(value) => setPage(value)}
                      fieldUpdated={fetchComponentChanges}
                      component={selectedComponent}
                      workTaskId={task?.id}
                      assetId={task?.assetId}
                      componentFieldChanges={
                        selectedComponent.objectId && changeGroups[selectedComponent.objectId]
                          ? changeGroups[selectedComponent.objectId]
                          : []
                      }
                      componentComments={componentComments.filter(
                        (c) => parseInt(c.componentObjectId ?? '') === selectedComponent.objectId
                      )}
                      onDeleteComment={(commentId) => deleteComponentComment(commentId)}
                      onSubmitComment={(comment: ComponentCommentResponseDTO) => addComponentComment(comment)}
                      componentFiles={componentFiles.filter(
                        (cf) => parseInt(cf.componentId ?? '') === selectedComponent.objectId
                      )}
                      onDeleteFile={(fileId) => deleteComponentFile(fileId)}
                    />
                  )}
                </>
              )}
            </>
          )}
          {!components && <StyledLabel variant="h5">Der er ingen komponenter at vise.</StyledLabel>}
        </>
      )}
    </Container>
  );
};

const Title = styled(Typography)`
  margin: ${(props) => props.theme.spacing(4)} 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const LeftColumn = styled.div`
  flex-direction: column;
`;

const CenteredCircularProgress = styled(CircularProgress)`
  position: sticky;
  top: 40%;
  left: 45%;
`;

const StyledLabel = styled(Typography)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: ${(props) => props.theme.palette.grey.black40};
`;

export default GisView;
