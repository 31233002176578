import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ChevronDown = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="35.771 38.329 15.17 8.368" xmlns="http://www.w3.org/2000/svg">
      <polyline fill="none" strokeWidth="1.5" points="36.219 38.777 43.372 45.93 50.524 38.777"></polyline>
    </IconSvg>
  );
};
