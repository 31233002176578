export type ReactRef<T> = React.Ref<T> | React.RefObject<T> | React.MutableRefObject<T>;

/**
 * Assigns a value to a ref function or object
 *
 * @param ref the ref to assign to
 * @param value the value we want to assign to the ref
 */

export const assignRef = <T,>(ref: ReactRef<T> | undefined, value: T) => {
  if (ref == null) return;

  if (typeof ref === 'function') {
    ref(value);

    return;
  }

  try {
    // @ts-expect-error - we already made the check above
    ref.current = value;
  } catch (error) {
    throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
  }
};

export default assignRef;
