import { DepartmentDTO } from '../api/api';
import WorkClient from '../api/workClient';

const GetDepartments = () => {
  return WorkClient.departmentAll();
};

const createDepartment = (name: string) => {
  return WorkClient.department(name);
};

const updateDepartment = (department: DepartmentDTO) => {
  return WorkClient.updateDepartment(department);
};

const DepartmentService = {
  GetDepartments,
  createDepartment,
  updateDepartment
};

export default DepartmentService;
