import styled from 'styled-components';
import Typography from '../../components/typography/Typography';
import Link from '../../components/link/Link';
import { NewsIcon } from '../../assets/icons/NewsIcon';
import { CloseIcon } from '../../assets/icons/CloseIcon';
import IconButton from '../../components/icon-button/IconButton';
import { useDispatch } from 'react-redux';
import { setShowNotification } from '../../stateManagement/reducers/newsReducer';
import { NewsDTONewsType } from '../../api/api';
import { getBackgroundColor } from './color-utils';

interface Props {
  text: string;
  onLinkClick: () => void;
  type?: NewsDTONewsType;
}

const width = 250;

const NewsNotification = (props: Props) => {
  const { text, onLinkClick, type } = props;
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setShowNotification({ show: false, news: undefined }));
  };

  return (
    <Container backgroundColor={getBackgroundColor(type)}>
      <StyledIconContainer>
        <NewsIcon size="16px" />
      </StyledIconContainer>
      <CenterContainer>
        <Typography elipsis textAlign="left" style={{ width: width - 50 }} variant="b">
          {text ?? 'Du har ulæste nyheder'}
        </Typography>
        <Link disableUnderline onClick={onLinkClick}>
          Gå til nyheder
        </Link>
      </CenterContainer>
      <StyledIconButton data-testid="news-notification-close-button" onClick={onClose}>
        <CloseIcon size="18px" />
      </StyledIconButton>
    </Container>
  );
};

const Container = styled.div<{ backgroundColor?: string }>`
  display: flex;
  position: absolute;
  left: 50%;
  height: 50px;
  width: ${width}px;
  padding: 10px 20px;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 6px;
  border-radius: 8px;
  z-index: ${(props) => props.theme.zIndex.dialog};
  background-color: ${(props) => props.backgroundColor};
`;

const StyledIconContainer = styled.div`
  width: ${(props) => props.theme.spacing(5)};
  height: 100%;
  padding-top: ${(props) => props.theme.spacing(1)};
  padding-right: ${(props) => props.theme.spacing(1)};
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-left: ${(props) => props.theme.spacing(1)};
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: ${(props) => props.theme.spacing(2.5)};
  padding: 0;
`;

export default NewsNotification;
