import { useCallback, useState } from 'react';

import { CreateTransferJournalItemDTO, StockDTO } from '../../../../api/api';
import SelectedGoodsIcon from '../../components/SelectedGoodsBadge';
import { Header, StyledTable, StyledTextField } from '../../components/Styles';
import { useMoveGoodsList } from './useMoveGoodsList';

interface Props {
  goods: StockDTO[];
  selectGoods: (goodsId?: string, goodsBatch?: string, goodsSerial?: string) => void;
  selectedGoods: CreateTransferJournalItemDTO[];
  selectedCount?: number;
  className?: string;
}

const MoveGoodsList = (props: Props) => {
  const { goods, selectGoods, className, selectedCount, selectedGoods } = props;

  const [showCart, setShowCart] = useState(false);

  const handleSelectGoods = useCallback(
    (goodsId?: string, goodsBatch?: string, goodsSerial?: string) => {
      setShowCart((prev) => !prev);
      selectGoods(goodsId, goodsBatch, goodsSerial);
    },
    [selectGoods]
  );

  const { tableInstance } = useMoveGoodsList(goods, selectedGoods, handleSelectGoods);
  const { setGlobalFilter } = tableInstance;

  return (
    <>
      <Header>
        <StyledTextField compact onChange={(e) => setGlobalFilter(e.target.value)} label="Søg blandt varer" />
        <SelectedGoodsIcon show={showCart} count={selectedCount} />
      </Header>
      <StyledTable tableInstance={tableInstance} loading={false} alwaysShowSort showPagination className={className} />
    </>
  );
};

export default MoveGoodsList;
