import { useCallback, useEffect, useState } from 'react';
import TextField from '../text-field/TextField';
import { AssetResultDTO, AssetType2 } from '../../api/api';
import styled from 'styled-components';
import AssetTypeDropdown from '../asset-type-dropdown/AssetTypeDropdown';
import AssetService from '../../services/AssetService';
import useDebounce from '../../hooks/useDebounce';
import TextRow from '../../views/task-view/task-details-view2/components/TextRow';
import { getEnumDisplayValue } from '../../utils/enumUtils';
import AutoComplete from '../auto-complete/AutoComplete';
import { getNearestAddressByLatLng } from '../../utils/location/locationHandling';
import NotificationService from '../../services/NotificationService';

interface Props {
  id?: string;
  onSelect: (asset: AssetResultDTO) => void;
  label: string;
  required?: boolean;
  inputMode?: boolean;
  disabled?: boolean;
  error?: boolean;
  prevAsset?: AssetResultDTO;
  inLineStyle?: boolean;
}

const AssetAutocomplete = (props: Props) => {
  const { id, label, inputMode, disabled, required, error, onSelect, prevAsset, inLineStyle = false } = props;
  const [searchType, setSearchType] = useState<AssetType2>(prevAsset?.assetType ?? AssetType2.Netstation);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedAsset, setSelectedAsset] = useState<AssetResultDTO | undefined>(prevAsset);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<AssetResultDTO[]>([]);
  const debouncedSearchInput = useDebounce(searchTerm, 250);

  const handleEnterPressed = (event: any) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      assetChangeHandler();
    }
  };

  useEffect(() => {
    if (debouncedSearchInput !== '') assetChangeHandler(debouncedSearchInput);
  }, [debouncedSearchInput]);

  const onAssetSelectedHandler = useCallback(
    (_asset: AssetResultDTO | null) => {
      if (!_asset) return;

      getNearestAddressByLatLng([
        {
          lat: _asset.location?.latitude ?? 0,
          lng: _asset.location?.longitude ?? 0
        }
      ])
        .then((result) => {
          let asset = _asset;
          if (!!result) {
            const { streetName, city, houseNumber, postalCode } = result[0];
            const newLocation = {
              ..._asset.location,
              ...{ streetName, city, houseNumber, postalCode }
            };
            asset = { ..._asset, ...{ location: newLocation } };
          }
          setSelectedAsset(asset);
          onSelect(asset);
          asset.assetId && setSearchTerm(asset.assetId);
        })
        .catch(() => {
          NotificationService.warning(`Kunne ikke hente adressen. Dette har ingen betydning for opgaveoprettelsen`);
          setSelectedAsset({ ..._asset });
          onSelect({ ..._asset });
        });
    },
    [onSelect]
  );

  const assetChangeHandler = useCallback(
    (sTerm?: string, sType?: AssetType2) => {
      const term = sTerm ?? searchTerm;
      const type = sType ?? searchType;

      if (!term || !type) return;
      AssetService.assetSearchResults(term, type)
        .then((result) => {
          setOptions(result);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [searchTerm, searchType]
  );

  const onAssetTypeChange = useCallback(
    (event) => {
      const type = event.target.value as AssetType2;
      setSearchType(type);
      setSearchTerm('');

      const asset = { assetId: '', assetType: type } as AssetResultDTO;
      setSelectedAsset(asset);
      onSelect(asset);
    },
    [onSelect]
  );

  return (
    <>
      {inputMode ? (
        <Container inLine={inLineStyle}>
          <AssetTypeDropdown
            value={searchType}
            assetTypeChange={(event) => {
              onAssetTypeChange(event);
            }}
            removeAssetOption={[AssetType2.InstallationNumber]}
            disabled={disabled}
          />
          <AutoComplete
            autoSelect
            noOptionsText={'Ingen muligheder'}
            id={id ?? 'assets'}
            options={options}
            getOptionLabel={(option) => (option.assetId ? ` ${option.assetId}` : '')}
            disabled={disabled}
            fullWidth
            renderInput={(params) => (
              <TextField
                label={label}
                required={required}
                error={error}
                onChange={(e) => {
                  setLoading(true);
                  setSearchTerm(e.target.value);
                }}
                {...params}
              />
            )}
            onKeyDown={handleEnterPressed}
            defaultValue={selectedAsset}
            value={selectedAsset}
            loading={loading}
            isOptionEqualToValue={(option: AssetResultDTO, value: AssetResultDTO) => {
              return option.assetId === value.assetId;
            }}
            onChange={(_, asset) => {
              onAssetSelectedHandler(asset);
            }}
          />
        </Container>
      ) : (
        <TextRow
          value={prevAsset?.assetId + ' (' + getEnumDisplayValue(AssetType2[searchType]) + ')'}
          label={label}
          required
          inputMode={false}
          disabled={true}
        />
      )}
    </>
  );
};

export default AssetAutocomplete;

const Container = styled.div<{ inLine: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.inLine ? `row` : `column`)};
  column-gap: 24px;
  row-gap: 24px;

  width: 100%;
`;
