import { configureStore } from '@reduxjs/toolkit';
import { Middleware } from 'redux';
import taskCreationReducer from './reducers/taskCreationReducer';
import workPlanViewReducer from './reducers/workPlanViewReducer';
import confirmDialogReducer from './reducers/confirmDialogReducer';
import notificationReducer from './reducers/notificationReducer';
import timeRegistrationReducer from './reducers/timeRegistrationReducer';
import taskStatusDialogReducer from './reducers/taskStatusDialogReducer';
import taskListReducer from './reducers/taskListReducer';
import userProfileReducer from './reducers/userProfileReducer';
import massCreationReducer from './reducers/massCreationReducer';
import signalRMiddleware from './middlewares/signalRMiddleware';
import signalRReducer from './reducers/signalRReducer';
import taskChecklistReducer from './reducers/taskChecklistReducer';
import countGoodsReducer from './reducers/countGoodsReducer';
import moveGoodsReducer from './reducers/moveGoodsReducer';
import orderGoodsReducer from './reducers/orderGoodsReducer';
import returnGoodsReducer from './reducers/returnGoodsReducer';
import newsReducer from './reducers/newsReducer';
import calendarReducer from './reducers/calendarReducer';

const middleware: Middleware[] = [signalRMiddleware];

export const store = configureStore({
  reducer: {
    userProfile: userProfileReducer,
    taskCreation: taskCreationReducer,
    massCreation: massCreationReducer,
    workPlanView: workPlanViewReducer,
    confirmDialog: confirmDialogReducer,
    taskStatusDialog: taskStatusDialogReducer,
    notifications: notificationReducer,
    timeRegistration: timeRegistrationReducer,
    taskList: taskListReducer,
    signalRReducer: signalRReducer,
    taskChecklistReducer: taskChecklistReducer,
    countGoodsReducer: countGoodsReducer,
    orderGoodsReducer: orderGoodsReducer,
    moveGoodsReducer: moveGoodsReducer,
    returnGoodsReducer: returnGoodsReducer,
    newsReducer: newsReducer,
    calendarReducer: calendarReducer
  },
  middleware: middleware
});

export type RootState = ReturnType<typeof store.getState>;
