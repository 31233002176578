import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { BaseWorkTaskTypeEnum, NearbyTaskItemDTO } from '../../api/api';
import useTableInstance from '../../hooks/useTableInstance';
import StatusTag from '../../components/status-tag/StatusTag';
import { getEnumDisplayValue } from '../../utils/enumUtils';
import { formatDate } from '../../utils/dateHandling';
import Link from '../../components/link/Link';
import { useNavigateToTask } from '../../hooks/useNavigateToTask';

interface NearbyTasksData {
  WorkTaskId: string;
  AssetId: string;
  TaskType: string;
  Status: string;
  CreatedByName: string;
}

const useNearbyTasksTable = (data: NearbyTaskItemDTO[]) => {
  const navigateToTask = useNavigateToTask();
  const columns = useMemo(() => {
    return [
      {
        Header: 'Work ID',
        accessor: 'WorkTaskId',
        disableFilters: true,
        width: 55,
        minWidth: 55,
        Cell: (cellProps: CellProps<NearbyTasksData>) => {
          const { cell } = cellProps;
          return <Link onClick={() => navigateToTask(cell.value, BaseWorkTaskTypeEnum.WorkTask)}>{cell.value}</Link>;
        }
      },
      {
        Header: 'Asset ID',
        accessor: 'AssetId',
        disableFilters: true,
        width: 50,
        minWidth: 50
      },
      {
        Header: 'Arbejdstype',
        accessor: 'TaskType',
        disableFilters: true,
        width: 80,
        minWidth: 80
      },
      {
        Header: 'Udført',
        accessor: 'PlannedArrival',
        disableFilters: true,
        width: 75,
        minWidth: 75,
        Cell: (cellProps: CellProps<NearbyTasksData>) => {
          const { cell } = cellProps;
          return cell.value ? formatDate(new Date(cell.value)) ?? '-' : '-';
        }
      },
      {
        Header: 'Status',
        accessor: 'Status',
        disableFilters: true,
        width: 80,
        minWidth: 80,
        Cell: (cellProps: CellProps<NearbyTasksData>) => {
          const { cell } = cellProps;
          return <StatusTag statusValue={cell.value}>{cell.value}</StatusTag>;
        }
      },
      {
        Header: 'Opgavestiller',
        accessor: 'CreatedByName',
        disableFilters: true,
        width: 120,
        minWidth: 120
      }
    ] as Column<NearbyTasksData>[];
  }, []);

  const tableData = useMemo(() => {
    if (!data.length) return [];

    return data.map((x) => {
      return {
        WorkTaskId: x.workTaskId,
        AssetId: x.assetId,
        TaskType: x.taskType?.name,
        Status: x.status && getEnumDisplayValue(x.status),
        PlannedArrival: x.plannedArrival,
        CreatedByName: x.createdByName
      } as NearbyTasksData;
    });
  }, [data]);

  const tableInstance = useTableInstance<NearbyTasksData>(tableData, columns, {
    initialState: {
      sortBy: [
        {
          id: 'WorkTaskId',
          desc: true
        }
      ]
    }
  });

  return { tableInstance };
};

export default useNearbyTasksTable;
