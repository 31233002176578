import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const SendIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
      <path d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" />
    </IconSvg>
  );
};
