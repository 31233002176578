import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import styled, { css } from 'styled-components';

type Props = TextFieldProps & {
  compact?: boolean;
  width?: string;
  hideButtons?: boolean;
  dataTestId?: string;
  marked?: boolean;
};

const TextField = (props: Props) => {
  const { compact, width, hideButtons, dataTestId, marked, ...rest } = props;

  return (
    <StyledMuiTextField
      {...rest}
      width={width}
      compact={compact ?? false}
      hideButtons={hideButtons ?? false}
      marked={marked}
      data-testid={dataTestId}
      onFocus={(e: any) =>
        e.target.addEventListener('wheel', function (e: any) {
          e.preventDefault();
        })
      }
    />
  );
};

const disabledStyles = css`
  .MuiOutlinedInput-notchedOutline {
    background: ${(props) => props.theme.palette.grey.black5};
    & {
      border-color: transparent !important;
    }
  }
  .MuiOutlinedInput-input {
    z-index: ${(props) => props.theme.zIndex.main};
  }
  .MuiInputLabel-root {
    top: -2px;
  }
`;
const StyledMuiTextField = styled(({ compact, hideButtons, marked, ...props }) => <MuiTextField {...props} />)<{
  compact: boolean;
  width?: string;
  hideButtons?: boolean;
  marked?: boolean;
}>`
  border-radius: 8px;
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  .MuiOutlinedInput-input {
    width: ${(props) => props.width};
    padding: ${(props) => (props.compact ? '7px 16px' : '12px 16px')};
  }
  .MuiInputLabel-root {
    line-height: 1rem;
    top: ${(props) => (props.compact ? '-5px' : '')};
    max-width: ${(props) => (props.InputProps?.endAdornment !== undefined ? 'calc(100% - 24px - 16px)' : '')};
  }
  .MuiFormHelperText-root {
    margin-left: 8px;
  }
  .MuiInputBase-inputMultiline {
    padding: 0;
  }

  ${({ hideButtons }) =>
    hideButtons &&
    css`
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `};

  background-color: ${(props) => (props.marked ? props.theme.palette.functions.warning.tertiary : '')};

  ${(props) => props.disabled && disabledStyles};
`;

export default TextField;
