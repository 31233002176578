import styled from 'styled-components';
import CircularProgress from '../circular-progress/CircularProgress';

interface Props {
  width?: string;
  position?: string;
}

const LoadingOverlay = (props: Props) => {
  const { width, position } = props;
  return (
    <OverlayContainer width={width} position={position}>
      <CenteredCircularProgress />
    </OverlayContainer>
  );
};

const OverlayContainer = styled.div<{ width?: string; position?: string }>`
  position: ${(props) => props.position ?? 'absolute'};
  top: 0;
  left: 0;
  border-radius: 12px;
  width: ${(props) => props.width ?? '100%'};
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: ${(props) => props.theme.zIndex.loading};
`;

const CenteredCircularProgress = styled(CircularProgress)`
  position: sticky;
  top: 40%;
`;

export default LoadingOverlay;
