import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import useTableInstance from '../../../hooks/useTableInstance';
import QuantityCell from '../components/QuantityCell';
import styled from 'styled-components';
import { ExtendedSelectedGoodsData } from './ReturnGoodsViewContent';

const useReturnGoodsList = (
  selectedGoods: ExtendedSelectedGoodsData[],
  readOnly: boolean,
  setSelectedReturnGoods?: (selectedGoods: ExtendedSelectedGoodsData[]) => void,
  removeGood?: (goodId?: string) => void
) => {
  const updateAmount = (value: number, goodsId?: string) => {
    if (!setSelectedReturnGoods) return;
    const updated = selectedGoods.map((good) => {
      if (good.goodsId === goodsId) {
        return {
          ...good,
          amount: value
        };
      } else {
        return good;
      }
    });
    setSelectedReturnGoods(updated);
  };

  const removeGoods = (goodsId?: string) => {
    if (!removeGood) return;
    removeGood(goodsId);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Varenummer',
        accessor: 'goodsId',
        width: 100,
        disableFilters: true
      },
      {
        Header: 'Varenavn',
        accessor: 'goodsName',
        width: 220,
        disableFilters: true
      },
      {
        Header: 'I bil',
        accessor: 'available',
        width: 50,
        disableFilters: true
      },
      {
        Header: 'Min/max',
        accessor: 'minMax',
        width: 50,
        disableFilters: true
      },
      {
        Header: 'Antal',
        accessor: 'amount',
        width: 150,
        disableFilters: true,
        Cell: (cellProps: CellProps<ExtendedSelectedGoodsData>) => {
          return readOnly ? (
            <ButtonsContainer>
              <QuantityCell
                minValue={1}
                row={cellProps.row.original}
                qty={cellProps.value}
                maxAvailableStock={parseInt(cellProps.row.original.available ?? '') - (cellProps.row.original.min ?? 0)}
                removeGoods={removeGoods}
                setGoodsAmount={updateAmount}
              />
            </ButtonsContainer>
          ) : (
            cellProps.row.original.amount
          );
        }
      }
    ] as Column<ExtendedSelectedGoodsData>[];
  }, [selectedGoods, readOnly]);

  const getColumns = useMemo(() => {
    return readOnly ? columns : columns.filter((c) => c.accessor !== 'minMax' && c.accessor !== 'available');
  }, [columns, readOnly]);

  const tableInstance = useTableInstance<ExtendedSelectedGoodsData>(selectedGoods, getColumns);
  return { tableInstance };
};

const ButtonsContainer = styled.div`
  display: flex;
`;

export default useReturnGoodsList;
