import { TabProps } from '../../../../../blocks/tabs-vertical/TabsVertical';
import AddReason from './AddReason';
import ReasonsTable from './ReasonsTable';
import { DepartmentDTO, ReasonCodeDTO, UpdateReasonCodeStatusDTO2 } from '../../../../../api/api';
import styled from 'styled-components';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import DepartmentDropdown from '../../../../../components/department-dropdown/DepartmentDropdown';
import { useEffect, useState } from 'react';
import AdminService from '../../../../../services/AdminService';
import { CreateAndUpdateTaskTypeDTO2Extended } from '../../../../../models/CreateAndUpdateTaskTypeDTOExtended';
import Button from '../../../../../components/button/Button';
import { log } from '../../../../../utils/logging/log';
import { FormState } from '../TaskTypeChecklist/TaskTypeChecklists';

interface Props extends TabProps {
  properties: CreateAndUpdateTaskTypeDTO2Extended;
  setProperties: (properties: CreateAndUpdateTaskTypeDTO2Extended) => void;
  setFooter: (left?: React.SetStateAction<JSX.Element>, right?: React.SetStateAction<JSX.Element>) => void;
  selectedReasonCodes: UpdateReasonCodeStatusDTO2[];
  setSelectedReasonCodes: (selectedReasonCodes: UpdateReasonCodeStatusDTO2[]) => void;
  newReasonCodes: UpdateReasonCodeStatusDTO2[];
  setNewReasonCodes: (newReasonCodes: UpdateReasonCodeStatusDTO2[]) => void;
  allReasonCodes: ReasonCodeDTO[];
}

const TaskTypeTaskFlow = (props: Props) => {
  const {
    properties,
    setProperties,
    setFooter,
    selectedReasonCodes,
    setSelectedReasonCodes,
    newReasonCodes,
    setNewReasonCodes,
    allReasonCodes
  } = props;
  const [formState, setFormState] = useState(FormState.MAIN);
  const [departments, setDepartments] = useState<DepartmentDTO[]>([]);

  useEffect(() => {
    AdminService.getDepartments().then((res) => setDepartments(res ?? []));
  }, []);

  const updateDepartment = (d: DepartmentDTO) => {
    setProperties({ ...properties, defaultDepartmentId: d.departmentId });
  };

  const updateSendTaskToPlanningOnCreation = (s: boolean) => {
    setProperties({ ...properties, sendTaskToPlanningOnCreation: s });
  };

  useEffect(() => {
    switch (formState) {
      case FormState.MAIN:
        setFooter(
          <Button
            variant="secondary"
            onClick={() => {
              setFormState(FormState.ADD);
            }}
          >
            Tilføj årsag
          </Button>
        );
        break;
      case FormState.ADD:
        setFooter(
          <></>,
          <>
            <Button variant="primary" onClick={handleAddReasons}>
              Tilføj årsagsforklaringer
            </Button>
          </>
        );
        break;

      default:
        log(`Could not render footer.`);
    }
  }, [formState, newReasonCodes]);

  const handleAddReasons = () => {
    setSelectedReasonCodes([...selectedReasonCodes, ...newReasonCodes]);
    setFormState(FormState.MAIN);
    setNewReasonCodes([]);
  };

  return (
    <>
      {formState === FormState.MAIN && (
        <MainDiv>
          <DepartmentDropdown
            selectDepartment={updateDepartment}
            value={properties.defaultDepartmentId}
            fullWidth
            label="Afdeling"
          />
          <ReasonsTable
            allReasonCodes={allReasonCodes}
            allDepartments={departments}
            selectedReasonCodes={selectedReasonCodes}
            setSelectedReasonCodes={(selectedReasonCodes) => setSelectedReasonCodes(selectedReasonCodes)}
          />
          <Checkbox
            label="Skal automatisk sendes til FLS ved opgaveoprettelse"
            checked={properties.sendTaskToPlanningOnCreation}
            onChange={() => updateSendTaskToPlanningOnCreation(!properties.sendTaskToPlanningOnCreation)}
          />
        </MainDiv>
      )}
      {formState === FormState.ADD && (
        <AddReason
          allReasonCodes={allReasonCodes}
          onBack={() => {
            setFormState(FormState.MAIN);
            setNewReasonCodes([]);
          }}
          newReasonCodes={newReasonCodes ?? []}
          setNewReasonCodes={(reasonCode) => setNewReasonCodes(reasonCode)}
          selectedReasonCodes={selectedReasonCodes}
        />
      )}
    </>
  );
};

const MainDiv = styled.div`
  height: 85%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;
`;

export default TaskTypeTaskFlow;
