import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import useTableInstance from '../../../hooks/useTableInstance';
import { CheckmarkIcon } from '../../../assets/icons/CheckmarkIcon';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import { MassLookupAssetDTO } from '../../../api/api';
import { MassCreationRequestItem } from '../../../models/MassCreationRequestItem';

const useAssetsTable = (data: MassCreationRequestItem[]) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Asset ID',
        accessor: 'assetId',
        disableFilters: true
      },
      {
        Header: 'Beskrivelse',
        accessor: 'description',
        disableFilters: true
      },
      {
        Header: 'Status',
        accessor: 'exists',
        disableFilters: true,

        sortType: (a, b) => {
          if (a.original.exist ?? false > (b.original.exist ?? false)) return -1;
          if (b.original.exist ?? false > (a.original.exist ?? false)) return 1;
        },
        Cell: (cellProps: CellProps<MassLookupAssetDTO>) => {
          const { cell } = cellProps;
          return cell.row.original.exist ? (
            <CheckmarkIcon color="green" size="18px" />
          ) : (
            <CloseIcon color="red" size="18px" />
          );
        }
      }
    ] as Column<MassLookupAssetDTO>[];
  }, []);

  const tableData = useMemo(() => {
    if (!data.length) return [];

    return data;
  }, [data]);

  const tableInstance = useTableInstance<MassLookupAssetDTO>(tableData, columns);

  return { tableInstance };
};

export default useAssetsTable;
