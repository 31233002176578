import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const EraseIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} data-testid="add-icon" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 16.15 2.845 C 17.681 1.314 20.159 1.314 21.691 2.845 L 28.443 9.597 C 29.974 11.128 29.974 13.606 28.443 15.137 L 18.156 25.426 L 22.837 25.426 C 23.559 25.426 24.144 26.01 24.144 26.731 C 24.144 27.452 23.559 28.038 22.837 28.038 L 7.167 28.038 C 6.822 28.038 6.49 27.899 6.245 27.656 L 1.563 22.973 C 0.033 21.441 0.033 18.962 1.563 17.432 L 16.15 2.845 Z M 14.462 25.426 L 18.379 21.508 L 9.779 12.909 L 3.409 19.278 C 2.899 19.789 2.899 20.616 3.409 21.126 L 7.71 25.426 L 14.462 25.426 Z M 11.627 11.062 L 20.225 19.661 L 26.597 13.291 C 27.107 12.781 27.107 11.954 26.597 11.443 L 19.844 4.691 C 19.334 4.181 18.507 4.181 17.996 4.691 L 11.627 11.062 Z"
      ></path>
    </IconSvg>
  );
};
