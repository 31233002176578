import { TabProps } from '../../../../../blocks/tabs-vertical/TabsVertical';
import AddField from './AddField';
import Sections from './Sections';
import { ReuseableComponentAttributeDTO, TaskComponentDTO } from '../../../../../api/api';
import { useCallback, useEffect, useState } from 'react';
import { FormState } from '../TaskTypeChecklist/TaskTypeChecklists';

interface Props extends TabProps {
  sections: TaskComponentDTO[];
  setSections: (sections: TaskComponentDTO[]) => void;
  allAttributes: ReuseableComponentAttributeDTO[];
  setDynamicFieldsCreatedSectionId: (sectionid: number) => void;
  setFooter: (left?: React.SetStateAction<JSX.Element>, right?: React.SetStateAction<JSX.Element>) => void;
}

const TaskTypeDynamicFields = (props: Props) => {
  const { sections, setSections, allAttributes, setDynamicFieldsCreatedSectionId, setFooter } = props;
  const [activeSection, setActiveSection] = useState<TaskComponentDTO>();
  const [formState, setFormstate] = useState<FormState>(FormState.MAIN);

  const addSection = (section: TaskComponentDTO) => {
    setSections([...sections, section]);
    section.id && setDynamicFieldsCreatedSectionId(section.id);
  };

  const setSection = useCallback(
    (section: TaskComponentDTO) => {
      const _sections = sections.map((s) => {
        if (s.id === section.id) {
          return section;
        }
        return s;
      });
      setSections(_sections);
      setFormstate(FormState.MAIN);
    },
    [sections, setSections]
  );

  const removeSection = (section: TaskComponentDTO) => {
    const _sections = sections.filter((s) => s.id !== section.id);
    setSections(_sections);
  };

  const handleSetActiveSection = (section: TaskComponentDTO) => {
    setActiveSection(section);
    setFormstate(FormState.ADD);
  };

  useEffect(() => {
    setFooter(<></>);
  }, []);

  return (
    <>
      {sections.values}
      {formState === FormState.MAIN && (
        <Sections
          sections={sections}
          addSection={(section) => addSection(section)}
          setActiveSection={(section) => handleSetActiveSection(section)}
          removeSection={(section) => removeSection(section)}
          setSections={(sections) => setSections(sections)}
        />
      )}
      {formState === FormState.ADD && (
        <AddField
          sections={sections}
          setSection={(section) => setSection(section)}
          activeSection={activeSection}
          allAttributes={allAttributes}
          onBack={() => setFormstate(FormState.MAIN)}
        />
      )}
    </>
  );
};

export default TaskTypeDynamicFields;
