import styled from 'styled-components';
import Typography from '../../../../components/typography/Typography';
import { ChevronLeft } from '../../../../assets/icons/ChevronLeft';

interface Props {
  title?: string;
  onClick: () => void;
}

const NavigationHeader = (props: Props) => {
  const { onClick, title } = props;
  return (
    <StyledShell>
      <Typography variant="h4" onClick={onClick}>
        <ChevronLeft strokeWidth="5" padding="0 12px 0 24px" size="14px" />
        {title}
      </Typography>
    </StyledShell>
  );
};

export const StyledShell = styled.div`
  height: 72px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;
  background-color: ${(props) => props.theme.palette.grey.black5};
`;

export default NavigationHeader;
