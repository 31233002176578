import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  highlighted?: boolean;
  htmlFor?: string;
  icon?: React.ReactNode;
  text: string;
}

const TabsVerticalToolsItem = (props: Props) => {
  const { disabled = false, onClick, highlighted, htmlFor, icon, text } = props;

  return htmlFor ? (
    <label htmlFor={htmlFor}>
      <TabsVerticalToolsItemRoot highlighted={highlighted} disabled={disabled} onClick={onClick}>
        <TabsVerticalToolsItemIcon>{icon}</TabsVerticalToolsItemIcon>
        <TabsVerticalToolsItemText>{text}</TabsVerticalToolsItemText>
      </TabsVerticalToolsItemRoot>
    </label>
  ) : (
    <TabsVerticalToolsItemRoot highlighted={highlighted} disabled={disabled} onClick={onClick}>
      <TabsVerticalToolsItemIcon>{icon}</TabsVerticalToolsItemIcon>
      <TabsVerticalToolsItemText>{text}</TabsVerticalToolsItemText>
    </TabsVerticalToolsItemRoot>
  );
};

const TabsVerticalToolsItemRoot = styled.span<{ highlighted?: boolean; disabled?: boolean }>`
  font-size: 16px;
  margin-bottom: 8px;
  padding: 0 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  height: 48px;
  border: none;
  background: none;
  width: 100%;
  color: #343434;
  cursor: pointer;
  box-sizing: border-box;

  ${({ highlighted, theme }) =>
    highlighted &&
    css`
      border: 2px solid ${theme.palette.functions.action.primary};
      color: ${theme.palette.functions.action.primary};

      svg {
        fill: ${theme.palette.functions.action.primary};
        stroke: ${theme.palette.functions.action.primary};
      }
    `}

  &:hover {
    background-color: ${(props) => !props.disabled && '#eef2fa'};
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: #d3d3d3;
      pointer-events: none;

      svg {
        fill: #d3d3d3;
        stroke: #d3d3d3;
      }
    `}
`;

export const TabsVerticalToolsItemIcon = styled.span`
  width: 34px;
  text-align: left;
`;

export const TabsVerticalToolsItemText = styled.span`
  font-weight: bold;
  margin-top: -2px;
`;

export default TabsVerticalToolsItem;
