import { useMemo } from 'react';
import { Column } from 'react-table';
import { QuestionDTO } from '../../../api/api';
import useTableInstance from '../../../hooks/useTableInstance';
import { getEnumDisplayValue } from '../../../utils/enumUtils';
import { SearchColumnFilter, SelectColumnFilter, multiSelectFilterFn } from '../../../blocks/table/TableFilterUtils';

export interface ChecklistQuestionData {
  id?: number;
  text?: string;
  questionType?: string;
}

const useChecklistQuestionList = (questions: QuestionDTO[]) => {
  const data = useMemo(() => {
    return questions.map((q) => {
      return {
        ...q,
        questionType: q.questionType ? getEnumDisplayValue(q.questionType) : ''
      } as ChecklistQuestionData;
    });
  }, [questions]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
        width: 50,
        minWidth: 50,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Tekst',
        accessor: 'text',
        width: 200,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Type',
        accessor: 'questionType',
        width: 100,
        minWidth: 100,
        Filter: SelectColumnFilter,
        filter: multiSelectFilterFn
      }
    ] as Column<ChecklistQuestionData>[];
  }, []);

  const tableInstance = useTableInstance<ChecklistQuestionData>(data, columns);

  return { tableInstance };
};

export default useChecklistQuestionList;
