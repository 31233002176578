import styled from 'styled-components';
import Typography from '../typography/Typography';
import { ChevronUp } from '../../assets/icons/ChevronUp';
import { ChevronDown } from '../../assets/icons/ChevronDown';

interface Props {
  header?: string;
  headerContent?: JSX.Element;
  isExpanded?: boolean;
  setIsExpanded?: () => void;
  direction?: string;
  noBorder?: boolean;
  children?: JSX.Element;
}

export const Section = (props: Props) => {
  const { header, headerContent, isExpanded = true, setIsExpanded, direction, noBorder, children } = props;

  return (
    <Container direction={direction} noBorder={noBorder} isExpanded={isExpanded}>
      <HeaderContainer onClick={setIsExpanded} isCollapsable={!!setIsExpanded}>
        <StyledTypography variant="h5" fontWeight="bold">
          {header}
        </StyledTypography>
        <div>
          {headerContent}
          {setIsExpanded && (isExpanded ? <ChevronUp size="16px" /> : <ChevronDown size="16px" />)}
        </div>
      </HeaderContainer>
      {isExpanded && children}
    </Container>
  );
};

export default Section;

export const Container = styled.div<{ direction?: string; noBorder?: boolean; isExpanded?: boolean }>`
  display: flex;
  flex-direction: ${(props) => props.direction ?? 'column'};
  border-top: 1px solid ${(props) => (props.noBorder ? 'transparent' : props.theme.palette.grey.black10)};
  border-bottom: ${(props) => `1px solid ${props.noBorder ? 'transparent' : props.theme.palette.grey.black10}`};
  padding-bottom: ${(props) => (props.isExpanded ? props.theme.spacing(8) : '')};
`;

export const StyledTypography = styled(Typography)`
  margin: ${(props) => props.theme.spacing(6)} 0;
`;

const HeaderContainer = styled.div<{ isCollapsable?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${(props) => (props.isCollapsable ? 'pointer' : 'default')};
`;
