import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { DetailedTimeRegistrationDTO, TimeRegistrationDTO, BaseWorkTaskTypeEnum } from '../../api/api';
import { getLocationString } from '../../utils/location/locationHandling';
import { StyledApprovedSpan } from './TimeRegTableTask';
import { TimeRegistrationSO } from '../../stateManagement/reducers/timeRegistrationReducer';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../components/table/Table';
import TimeRegMenu from './TimeRegMenu';
import InternalTimeRegForm from '../internal-timereg-form/InternalTimeRegForm';
import { useNavigateToTask } from '../../hooks/useNavigateToTask';
import Button from '../../components/button/Button';
import Typography from '../../components/typography/Typography';
import { WarningIcon } from '../../assets/icons/WarningIcon';
import theme from '../../theme/light-theme';
import ToolTip from '../../components/tool-tip/ToolTip';
export interface TimeRegError {
  id: string;
  errorMsg: string;
}
interface Props {
  timeRegistrations?: DetailedTimeRegistrationDTO[];
  handleDelete: (id: number) => void;
  handleEdit: (timeReg: TimeRegistrationSO) => void;
  errors: TimeRegError[];
}

const TimeRegTableUser = (props: Props) => {
  const { timeRegistrations, handleDelete, handleEdit, errors } = props;

  const [openInternalTimeReg, setOpenInternalTimeReg] = useState(false);
  const [internalTask, setInternalTask] = useState<DetailedTimeRegistrationDTO>();
  const navigateToTask = useNavigateToTask();

  const handleOnClickTask = useCallback(
    (task: DetailedTimeRegistrationDTO) => {
      if (!task.workTaskId) return;
      if (task && task.baseWorkTaskType === BaseWorkTaskTypeEnum.InternalWorkTask) {
        setOpenInternalTimeReg(true);
        setInternalTask(task);
      } else {
        navigateToTask(task.workTaskId, task.baseWorkTaskType);
      }
    },
    [navigateToTask]
  );

  return (
    <>
      {openInternalTimeReg && internalTask && (
        <InternalTimeRegForm
          taskId={internalTask.workTaskId}
          open={openInternalTimeReg}
          handleReturn={() => setOpenInternalTimeReg(false)}
          task={internalTask}
          disabled={true}
        />
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Projekt</TableCell>
              <TableCell>Opgave</TableCell>
              <TableCell>Adresse</TableCell>
              <TableCell>Timeart</TableCell>
              <TableCell>Antal</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeRegistrations &&
              timeRegistrations.map((row, i) => (
                <TableRow key={i}>
                  <StyledCell>
                    <IconContainer>
                      {errors.find((e) => e.id === row.project?.id) && (
                        <ToolTip
                          title={errors.find((e) => e.id === row.project?.id)?.errorMsg ?? 'Linjen er ikke registreret. '}
                        >
                          <div>
                            <WarningIcon margin="2px 0px" color={theme.palette.functions.warning.primary} size="16px" />
                          </div>
                        </ToolTip>
                      )}
                    </IconContainer>
                    <ProjectNumberContainer>
                      <Typography>{row?.project?.id ?? '-'}</Typography>
                      <Typography>{row?.project?.name ?? ''}</Typography>
                    </ProjectNumberContainer>
                  </StyledCell>
                  <TableCell>
                    {row?.workTaskId ? (
                      <StyledLink onClick={() => handleOnClickTask(row)}>{row?.taskType ?? '-'}</StyledLink>
                    ) : (
                      <>{row?.taskType ?? '-'}</>
                    )}
                  </TableCell>
                  <TableCell>{row?.address ? getLocationString(row.address) : '-'}</TableCell>
                  <TableCell>{row?.category?.name ?? '-'}</TableCell>
                  <TableCell>{row.hours === 1 ? `${row.hours} time` : `${row.hours} timer`}</TableCell>
                  <TableCell>{renderApproved(row)}</TableCell>
                  <TableCell sx={{ padding: '0' }} align="right">
                    <ButtonCell>
                      {row.hours === 0 && (
                        <Button variant="secondary" onClick={() => handleEdit(row)}>
                          Rediger
                        </Button>
                      )}
                      {row?.id && (
                        <TimeRegMenu
                          registration={row}
                          handleDelete={(id: number) => handleDelete(id)}
                          handleEdit={handleEdit}
                          isNegativeTime={row?.hours !== undefined ? row.hours < 0 : false}
                        />
                      )}
                    </ButtonCell>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const StyledLink = styled.a`
  color: ${(props) => props.theme.palette.functions.action.secondary};
  cursor: pointer;
`;

export const renderApproved = (row: TimeRegistrationDTO) => {
  return row.approvedByUser !== undefined ? (
    <StyledApprovedSpan approved={row.approvedByUser}>
      {row.approvedByUser ? 'Godkendt' : 'Ikke godkendt'}
    </StyledApprovedSpan>
  ) : (
    '-'
  );
};

const ButtonCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledCell = styled(TableCell)`
  display: flex !important;
  align-items: center;
  text-align: start;
`;

const IconContainer = styled.div`
  width: 20px;
  height: 50px;
  margin-right: ${(props) => props.theme.spacing(2.5)};
`;

const ProjectNumberContainer = styled.div`
  height: 50px;
`;

export default TimeRegTableUser;
