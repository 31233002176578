import { useCallback, useEffect, useState } from 'react';
import { DeleteIcon } from '../../../../assets/icons/DeleteIcon';
import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';
import Button from '../../../../components/button/Button';
import Checkbox from '../../../../components/checkbox/Checkbox';
import Typography from '../../../../components/typography/Typography';

import { Container, Row, SectionContent } from '../../../../styling/FormStyling';
import TextField from '../../../../components/text-field/TextField';
import styled from 'styled-components';
import Select, { MenuItem } from '../../../../components/select/Select';
import Section from '../../../../components/section/Section';
import { WorkInvoiceDTO, UpdateWorkInvoiceDTO, WorkInvoiceProjectCategory, WorkTaskStatus } from '../../../../api/api';
import { SelectChangeEvent } from '@mui/material/Select/Select';
import { getEnumDisplayValue } from '../../../../utils/enumUtils';
import WorkInvoiceService from '../../../../services/WorkInvoiceService';
import { log } from '../../../../utils/logging/log';
import NotificationService from '../../../../services/NotificationService';
import StatusIndicator, { STATUS } from '../../../../components/status-indicator/StatusIndicator';
import TimeRegistrationService from '../../../../services/TimeRegistrationService';
import GoodsService from '../../../../services/GoodsService';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import { workInvoiceInfoPopulated } from './WorkInvoiceHelper';
import CircularProgress from '../../../../components/circular-progress/CircularProgress';
import { DialogBody } from '../../../../stateManagement/reducers/confirmDialogReducer';

const unknownDebtorDialog: DialogBody = {
  headerText: 'Er du sikker på at skifte til ukendt betaler?',
  bodyText: `Det oprettede projeknummer bliver afkoblet og betalerinformation vil blive slettet og skal indtastes igen, hvis der skiftes til kendt betaler`,
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Ja'
};

interface Props extends TabProps {
  workInvoiceId?: number;
  handleDeleteWorkInvoice: () => void;
  taskStatus?: WorkTaskStatus;
  updateNotification: (_workInvoice?: WorkInvoiceDTO) => void;
}

const WorkInvoiceStep = (props: Props) => {
  const { workInvoiceId, handleDeleteWorkInvoice, taskStatus, updateNotification } = props;

  const [unknownDebtor, setunknownDebtor] = useState(false);
  const [isProjectNumberMenuOpen, setIsProjectNumberMenuOpen] = useState(
    taskStatus === WorkTaskStatus.Completed || taskStatus === WorkTaskStatus.Processed
  );
  const [projectCategory, setProjectCategory] = useState<WorkInvoiceProjectCategory>();
  const [workInvoiceInfo, setWorkInvoiceInfo] = useState<UpdateWorkInvoiceDTO>();
  const [projectNumber, setProjectNumber] = useState<string>();
  const [workTaskId, setWorkTaskId] = useState<number>();
  const [goodsToTransfer, setGoodsToTransfer] = useState(false);
  const [timeToTransfer, setTimeToTransfer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setloadingPage] = useState(true);

  const { getConfirmation } = useConfirmationDialog();

  const checkIfTaskTimeOrGoodsToMove = useCallback(
    (projectNum?: string, workTask?: number) => {
      const _projectNumber = projectNum ?? projectNumber;
      const _workTaskId = workTask ?? workTaskId;
      if (!_projectNumber || !_workTaskId) {
        setGoodsToTransfer(false);
        setTimeToTransfer(false);
        return;
      }

      TimeRegistrationService.checkIfWorkTaskHasTimeRegistrations(_workTaskId)
        .then((res) => {
          setTimeToTransfer(res);
        })
        .catch((err) => {
          log(err);
          NotificationService.error(
            'Kunne ikke tjekke om der er timeregistereringer der kan flyttes. Prøv at indlæse skadevolder/regningsarbejde igen.'
          );
        });

      GoodsService.checkIfWorkTaskHasConsumedItems(_workTaskId)
        .then((res) => {
          setGoodsToTransfer(res);
        })
        .catch((err) => {
          log(err);
          NotificationService.error(
            'Kunne ikke tjekke om der er timeregistereringer der kan flyttes. Prøv at indlæse skadevolder/regningsarbejde igen.'
          );
        });
    },
    [projectNumber, workTaskId]
  );

  const fetchWorkInvoice = useCallback(() => {
    if (!workInvoiceId) return;
    WorkInvoiceService.getWorkInvoice(workInvoiceId)
      .then((res) => {
        const workInvoice = res.workInvoiceDTO;
        setWorkInvoiceInfo({
          cvrNo: workInvoice?.cvrNo ?? undefined,
          vehicleRegNo: workInvoice?.vehicleRegNo ?? undefined,
          policeCaseNo: workInvoice?.policeCaseNo ?? undefined,
          contactPersonName: workInvoice?.contactPersonName ?? undefined,
          contactPersonPhoneNumber: workInvoice?.contactPersonPhoneNumber ?? undefined,
          contactPersonAddress: workInvoice?.contactPersonAddress ?? undefined,
          note: workInvoice?.note ?? undefined
        });
        setProjectNumber(workInvoice?.projectId ?? undefined);
        setProjectCategory(workInvoice?.projectCategory ?? undefined);
        setWorkTaskId(workInvoice?.workTaskId ?? undefined);
        setunknownDebtor(workInvoice?.unknownDebtor ?? false);

        workInvoice?.projectId && checkIfTaskTimeOrGoodsToMove(workInvoice.projectId, workInvoice?.workTaskId);
        updateNotification(workInvoice);
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke indhente skadevolder/regningsarbejdet.');
      })
      .finally(() => {
        setloadingPage(false);
      });
    // updateNotification should not trigger re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workInvoiceId]);

  useEffect(() => {
    fetchWorkInvoice();
  }, [fetchWorkInvoice]);

  const handleUpdateWorkInvoiceInfo = useCallback(
    (reason?) => {
      if (!workInvoiceId) return;
      let _workInvoiceInfo = workInvoiceInfo;
      if (reason === 'clear') {
        _workInvoiceInfo = {
          note: workInvoiceInfo?.note
        };
      }
      WorkInvoiceService.updateWorkInvoice(workInvoiceId, _workInvoiceInfo)
        .then(() => {
          reason !== 'clear' &&
            updateNotification({ ..._workInvoiceInfo, projectCategory: projectCategory, unknownDebtor: unknownDebtor });
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Informationen er ikke gemt korrekt.');
        });
    },
    [workInvoiceId, workInvoiceInfo, updateNotification, projectCategory, unknownDebtor]
  );

  const handleunknownDebtor = useCallback(async () => {
    if (!workInvoiceId) return;

    let confirmation = 'confirm';

    if (unknownDebtor === false) {
      if (projectNumber) {
        confirmation = await getConfirmation({
          ...unknownDebtorDialog,
          bodyText: `Det oprettede projeknummer bliver afkoblet og eventuelle registrerede timer eller varer vil blive flyttet til det oprindelige projektnummer. Alt betalerinformation vil blive slettet og skal indtastes igen, hvis der skiftes til kendt betaler`
        });
      } else if (workInvoiceInfoPopulated(workInvoiceInfo)) {
        confirmation = await getConfirmation({
          ...unknownDebtorDialog,
          bodyText: `Betalerinformation vil blive slettet og skal indtastes igen, hvis der skiftes til kendt betaler`
        });
      }
    } else {
      if (projectNumber) {
        confirmation = await getConfirmation({
          ...unknownDebtorDialog,
          headerText: 'Er du sikker på at skifte til kendt betaler?',
          bodyText: `Projeknummeret bliver afkoblet og eventuelle registrerede timer eller varer vil blive flyttet til det oprindelige projektnummer.`
        });
      }
    }

    if (confirmation === 'confirm') {
      WorkInvoiceService.updateUnknownDebtor(workInvoiceId, !unknownDebtor)
        .then(() => {
          setunknownDebtor(!unknownDebtor);
          if (!unknownDebtor) {
            setWorkInvoiceInfo((prev) => ({
              ...prev,
              cvrNo: '',
              vehicleRegNo: '',
              policeCaseNo: '',
              contactPersonName: '',
              contactPersonPhoneNumber: '',
              contactPersonAddress: ''
            }));
            handleUpdateWorkInvoiceInfo('clear');
          }
          if (projectNumber) {
            setProjectNumber(undefined);
          }
          updateNotification({ unknownDebtor: !unknownDebtor, projectCategory: projectCategory });
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Kunne ikke opdatere ukendt betaler. Prøv igen.');
        });
    }
  }, [
    workInvoiceId,
    workInvoiceInfo,
    getConfirmation,
    handleUpdateWorkInvoiceInfo,
    projectNumber,
    unknownDebtor,
    updateNotification,
    projectCategory
  ]);

  const deleteWorkInvoice = useCallback(async () => {
    const confirmation = await getConfirmation({
      headerText: 'Er du sikker på du vil fjerne skadevolder/regningsarbejde?',
      bodyText: '',
      declineButtonText: 'Fortryd',
      confirmButtonText: 'Ja'
    });

    if (confirmation === 'confirm') {
      handleDeleteWorkInvoice();
    }
  }, [getConfirmation, handleDeleteWorkInvoice]);

  const handleSetVoltageLevel = (event: SelectChangeEvent<any>) => {
    const answer = event.target.value;
    WorkInvoiceService.updateProjectCategory(workInvoiceId ?? -1, answer)
      .then(() => {
        setProjectCategory(answer);
        updateNotification({
          ...workInvoiceInfo,
          projectCategory: answer,
          projectId: projectNumber,
          unknownDebtor: unknownDebtor
        });
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Spændingsniveauet er ikke gemt korrekt.');
      });
  };

  const handleCreateProjectNumber = () => {
    setLoading(true);
    WorkInvoiceService.getProjectNumber(workInvoiceId ?? -1)
      .then((res) => {
        setProjectNumber(res);
        checkIfTaskTimeOrGoodsToMove(res);
        updateNotification({
          ...workInvoiceInfo,
          projectCategory: projectCategory,
          projectId: res,
          unknownDebtor: unknownDebtor
        });
      })
      .catch((err) => {
        log(err);
        NotificationService.error(
          'Kunne ikke oprette eller tilknytte projektnummer på skadevolder/regningsarbejde. Prøv igen.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTransferTime = () => {
    if (!workTaskId) return;
    setLoading(true);
    TimeRegistrationService.moveTimeRegistrationsToWorkInvoiceProject(workTaskId)
      .then(() => {
        setTimeToTransfer(false);
        updateNotification({
          ...workInvoiceInfo,
          projectCategory: projectCategory,
          projectId: projectNumber,
          unknownDebtor: unknownDebtor
        });
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke flytte timer. Prøv igen.');
      })
      .finally(() => setLoading(false));
  };

  const handleTransferGoods = () => {
    if (!workTaskId) return;
    setLoading(true);
    GoodsService.moveConsumedItemsToWorkInvoiceProject(workTaskId)
      .then(() => {
        setGoodsToTransfer(false);
        updateNotification({
          ...workInvoiceInfo,
          projectCategory: projectCategory,
          projectId: projectNumber,
          unknownDebtor: unknownDebtor
        });
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke flytte varer. Prøv igen.');
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container>
      {loadingPage ? (
        <CircularProgress />
      ) : (
        <>
          <Section
            header="Skade/regningsarbejde information"
            headerContent={
              <Button variant="secondary" onClick={deleteWorkInvoice}>
                <DeleteIcon size="20px" />
                Fjern
              </Button>
            }
          >
            <SectionContent>
              <Typography color="greyedOut">
                Minimum et af nedenstående felter skal udfyldes, men udfyld så mange som muligt
              </Typography>
              <Checkbox label="Ukendt betaler?" checked={unknownDebtor} onChange={handleunknownDebtor} />
              <Row>
                <TextField
                  label="CVR Nummer"
                  value={workInvoiceInfo?.cvrNo}
                  fullWidth
                  disabled={unknownDebtor || projectNumber !== undefined}
                  onBlur={() => handleUpdateWorkInvoiceInfo()}
                  onChange={(e) => setWorkInvoiceInfo((prev) => ({ ...prev, cvrNo: e.target.value }))}
                />
                <TextField
                  label="Køretøjets registreringsnummer"
                  value={workInvoiceInfo?.vehicleRegNo}
                  fullWidth
                  disabled={unknownDebtor || projectNumber !== undefined}
                  onBlur={() => handleUpdateWorkInvoiceInfo()}
                  onChange={(e) => setWorkInvoiceInfo((prev) => ({ ...prev, vehicleRegNo: e.target.value }))}
                />
              </Row>
              <Row>
                <StyledTextField
                  label="Sagsnummer hos politiet"
                  value={workInvoiceInfo?.policeCaseNo}
                  disabled={unknownDebtor || projectNumber !== undefined}
                  onBlur={() => handleUpdateWorkInvoiceInfo()}
                  onChange={(e) => setWorkInvoiceInfo((prev) => ({ ...prev, policeCaseNo: e.target.value }))}
                />
              </Row>

              <Typography variant="h6" fontWeight="bold">
                Kontaktinformation
              </Typography>
              <Row>
                <TextField
                  label="Kontaktpersons navn"
                  value={workInvoiceInfo?.contactPersonName}
                  fullWidth
                  disabled={unknownDebtor || projectNumber !== undefined}
                  onBlur={() => handleUpdateWorkInvoiceInfo()}
                  onChange={(e) => setWorkInvoiceInfo((prev) => ({ ...prev, contactPersonName: e.target.value }))}
                />
                <TextField
                  label="Kontaktpersons telefonnummer"
                  value={workInvoiceInfo?.contactPersonPhoneNumber}
                  fullWidth
                  disabled={unknownDebtor || projectNumber !== undefined}
                  onBlur={() => handleUpdateWorkInvoiceInfo()}
                  onChange={(e) => setWorkInvoiceInfo((prev) => ({ ...prev, contactPersonPhoneNumber: e.target.value }))}
                />
              </Row>
              <Row>
                <StyledTextField
                  label="Kontaktpersons adresse"
                  value={workInvoiceInfo?.contactPersonAddress}
                  disabled={unknownDebtor || projectNumber !== undefined}
                  onBlur={() => handleUpdateWorkInvoiceInfo()}
                  onChange={(e) => setWorkInvoiceInfo((prev) => ({ ...prev, contactPersonAddress: e.target.value }))}
                />
              </Row>
              <Row>
                <TextField
                  label="Bemærkning"
                  value={workInvoiceInfo?.note}
                  fullWidth
                  onBlur={() => handleUpdateWorkInvoiceInfo()}
                  disabled={projectNumber !== undefined}
                  onChange={(e) => setWorkInvoiceInfo((prev) => ({ ...prev, note: e.target.value }))}
                  multiline
                  minRows={1}
                  maxRows={5}
                />
              </Row>
            </SectionContent>
          </Section>
          <Section
            header={'Projektdetaljer'}
            isExpanded={isProjectNumberMenuOpen}
            setIsExpanded={() => setIsProjectNumberMenuOpen(!isProjectNumberMenuOpen)}
          >
            <SectionContent>
              {projectNumber === undefined && (
                <Row>
                  <Column>
                    <Typography>Angiv projektserie*</Typography>
                    <Select fullWidth value={projectCategory} onChange={(e) => handleSetVoltageLevel(e)}>
                      {Object.keys(WorkInvoiceProjectCategory).map((projectCategory) => (
                        <MenuItem key={projectCategory} value={projectCategory}>
                          {getEnumDisplayValue(projectCategory as WorkInvoiceProjectCategory)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Column>
                  <Column align="flex-end">
                    <Button
                      disabled={projectCategory === undefined || !workInvoiceInfoPopulated(workInvoiceInfo, unknownDebtor)}
                      onClick={handleCreateProjectNumber}
                      isLoading={loading}
                    >
                      {unknownDebtor ? 'Tilknyt ' : 'Opret '} projektnummer
                    </Button>
                  </Column>
                </Row>
              )}

              {projectNumber && (
                <>
                  <Row>
                    <Column>
                      <Typography>Projektserie</Typography>
                      <Typography fontWeight="bold">{projectCategory && getEnumDisplayValue(projectCategory)}</Typography>
                    </Column>
                    <Column>
                      <Typography>Projektnummer</Typography>
                      <Typography fontWeight="bold">{projectNumber}</Typography>
                    </Column>
                  </Row>

                  <Row>
                    <Column>
                      <StyledTypography variant="h5" fontWeight="bold">
                        Timer
                      </StyledTypography>
                      <StyledTypography color="greyedOut">
                        {'Ved at klikke nedenfor overfører du alle timer fra denne opgave til projektnummer: ' +
                          projectNumber}
                      </StyledTypography>
                      <StyledTypography color="greyedOut">
                        Vær opmærksom på at gøre det samme for evt. relationsopgaver.
                      </StyledTypography>
                      {timeToTransfer ? (
                        <Button variant="primary" onClick={handleTransferTime} isLoading={loading}>
                          Overfør alle timer
                        </Button>
                      ) : (
                        <Row>
                          <StatusIndicator status={STATUS.GREEN} />
                          <Typography variant="h5" fontWeight="bold">
                            Der er ingen timer at flytte
                          </Typography>
                        </Row>
                      )}
                    </Column>

                    <Column>
                      <StyledTypography variant="h5" fontWeight="bold">
                        Varer
                      </StyledTypography>
                      <StyledTypography color="greyedOut">
                        {'Ved at klikke nedenfor overfører du alle varer fra denne opgave til projektnummer: ' +
                          projectNumber}
                      </StyledTypography>
                      <StyledTypography color="greyedOut">
                        Vær opmærksom på at gøre det samme for evt. relationsopgaver.
                      </StyledTypography>
                      {goodsToTransfer ? (
                        <Button variant="primary" onClick={handleTransferGoods} isLoading={loading}>
                          Overfør alle varer
                        </Button>
                      ) : (
                        <Row>
                          <StatusIndicator status={STATUS.GREEN} />
                          <Typography variant="h5" fontWeight="bold">
                            Der er ingen varer at flytte
                          </Typography>
                        </Row>
                      )}
                    </Column>
                  </Row>
                </>
              )}
            </SectionContent>
          </Section>
        </>
      )}
    </Container>
  );
};

export default WorkInvoiceStep;

const StyledTextField = styled(TextField)`
  width: calc(50% - 12px);
`;

const Column = styled.div<{ align?: 'flex-start' | 'flex-end' }>`
  align-content: ${(props) => props.align};
  width: calc(50% - 12px);
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;
