import { useCallback, useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, IconButton, RadioGroup } from '@mui/material';
import styled from 'styled-components';

import { ContractorAgreementDTO2 } from '../../../../api/api';
import { AddIcon } from '../../../../assets/icons/AddIcon';
import { DeleteIcon } from '../../../../assets/icons/DeleteIcon';
import { DocumentIcon } from '../../../../assets/icons/DocumentIcon';
import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';
import Button from '../../../../components/button/Button';
import TextField from '../../../../components/text-field/TextField';
import WorkInstructionsService from '../../../../services/WorkInstructionsService';
import { ColumnFlex } from '../../../../styling/Styling';
import { SectionContent, SectionHeader, Section, Row, Container } from '../../../../styling/FormStyling';
import TaskService from '../../../../services/TaskService';
import { downloadFileFromBlob } from '../../../../utils/fileUtils';
import { SaveIcon } from '../../../../assets/icons/SaveIcon';
import NotificationService from '../../../../services/NotificationService';
import { SignIcon } from '../../../../assets/icons/SignIcon';
import TextRow from '../components/TextRow';
import SignaturePreview from '../../../../components/signature-preview/SignaturePreview';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import { StyledFooter } from '../details-step/TaskDetailsStep';
import LoadingOverlay from '../../../../components/loading-overlay/LoadingOverlay';
import DatePicker from '../../../../components/date-picker/DatePicker';
import { formatDateString } from '../../../../utils/dateHandling';
import Checkbox from '../../../../components/checkbox/Checkbox';
import SignDialog from '../../../../blocks/sign-dialog/SignDialog';
import { PageType } from './WorkInstructionsStep';

interface Props extends TabProps {
  taskId: string;
  instruction: ContractorAgreementDTO2;
  onPDFCreatedCallback: () => void;
  setPage: (page: PageType) => void;
  page: PageType;
  addNewInstruction: (instruction: ContractorAgreementDTO2) => void;
}

type FormState = 'unsigned' | 'signed' | 'editing';

const WorkInstruction = (props: Props) => {
  const { taskId, instruction, onPDFCreatedCallback, page, setPage, addNewInstruction } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [initialBody, setInitialBody] = useState<ContractorAgreementDTO2>(instruction);
  const [body, setBody] = useState<ContractorAgreementDTO2>(instruction);
  const [isDirty, setIsDirty] = useState(false);
  const [formState, setFormState] = useState<FormState>('unsigned');
  const [formValidForSigning, setFormValidForSigning] = useState(false);

  const { getConfirmation } = useConfirmationDialog();

  useEffect(() => {
    setInitialBody(instruction);
    setBody({ ...instruction, signedBy: instruction.signedBy ?? instruction.contactPersonName });
    setFormState(!!instruction.signature ? 'signed' : 'unsigned');
    setIsLoading(false);
  }, [instruction]);

  const evaluteRequiredFieldsValid = useCallback(() => {
    if (body.voltages?.length === 0) return false;
    if (!body.contactPersonName) return false;
    if (!body.contactPersonPhoneNumber) return false;
    if (!body.contractorCompanyName) return false;
    if (!body.workingPeriodStart) return false;
    if (!body.workingPeriodEnd) return false;
    if (!body.signedBy) return false;

    let stretchesValid = true;

    if (body?.stretches && body?.stretches?.length > 0) {
      body.stretches.forEach((s) => {
        stretchesValid = !!s.from && !!s.to;
      });
    }

    return stretchesValid;
  }, [
    body.voltages,
    body.contactPersonName,
    body.contactPersonPhoneNumber,
    body.contractorCompanyName,
    body.workingPeriodStart,
    body.workingPeriodEnd,
    body.stretches,
    body.signedBy
  ]);

  const evaluateFieldsDirty = useCallback(() => {
    if (
      body.voltages?.length !== initialBody.voltages?.length ||
      body.voltages?.some((v) => !initialBody.voltages?.find((iv) => iv === v))
    )
      return true;
    if (body.contactPersonName !== initialBody.contactPersonName) return true;
    if (body.contactPersonPhoneNumber !== initialBody.contactPersonPhoneNumber) return true;
    if (body.contractorCompanyName !== initialBody.contractorCompanyName) return true;
    if (body.contractorCompanyPhoneNumber !== initialBody.contractorCompanyPhoneNumber) return true;
    if (body.contactPersonEmail !== initialBody.contactPersonEmail) return true;
    if (body.description !== initialBody.description) return true;
    if (body.lerNumber !== initialBody.lerNumber) return true;
    if (body.withVoltage !== initialBody.withVoltage) return true;
    if (body.workingPeriodStart !== initialBody.workingPeriodStart) return true;
    if (body.workingPeriodEnd !== initialBody.workingPeriodEnd) return true;
    if (body.horizontalDistance !== initialBody.horizontalDistance) return true;
    if (body.verticalDistance !== initialBody.verticalDistance) return true;
    if (body.stretches?.length !== initialBody.stretches?.length) return true;

    if (initialBody.stretches && body.stretches && body.stretches?.length > 0) {
      let stretchesAreDirty = false;
      for (let index = 0; index < body?.stretches?.length; index++) {
        const initial = initialBody.stretches[index];
        const second = body.stretches[index];

        stretchesAreDirty = initial.from !== second.from || initial.to !== second.to;
        if (stretchesAreDirty) break;
      }
    }

    return false;
  }, [
    body.voltages,
    body.contactPersonName,
    body.contactPersonPhoneNumber,
    body.contractorCompanyName,
    body.contractorCompanyPhoneNumber,
    body.contactPersonEmail,
    body.description,
    body.lerNumber,
    body.withVoltage,
    body.workingPeriodStart,
    body.workingPeriodEnd,
    body.horizontalDistance,
    body.verticalDistance,
    body.stretches,
    initialBody.voltages,
    initialBody.contactPersonName,
    initialBody.contactPersonPhoneNumber,
    initialBody.contractorCompanyName,
    initialBody.contractorCompanyPhoneNumber,
    initialBody.contactPersonEmail,
    initialBody.description,
    initialBody.lerNumber,
    initialBody.withVoltage,
    initialBody.workingPeriodStart,
    initialBody.workingPeriodEnd,
    initialBody.horizontalDistance,
    initialBody.verticalDistance,
    initialBody.stretches
  ]);

  useEffect(() => {
    setIsDirty(evaluateFieldsDirty());
    setFormValidForSigning(evaluteRequiredFieldsValid());
  }, [body, initialBody, evaluateFieldsDirty, evaluteRequiredFieldsValid]);

  const handleSaveWorkInstruction = (body: ContractorAgreementDTO2) => {
    if (taskId) {
      setIsUpdating(true);
      WorkInstructionsService.putWorkInstruction(parseInt(taskId), body)
        .then((res) => {
          setInitialBody(res);
          setFormState(!!res.signature ? 'signed' : 'unsigned');
          NotificationService.success('Arbejdsinstruktionen blev opdateret');
          addNewInstruction(body);
          setPage('table');
        })
        .catch(() => {
          NotificationService.error('Der opstod en fejl i opdateringen af arbejdsinstruktionen');
        })
        .finally(() => {
          setIsUpdating(false);
        });
    }
  };

  const handleChangeStretches = (value: string, type: 'from' | 'to', index: number) => {
    let stretchesCopy = [...(body.stretches || [{ from: '', to: '' }])];
    stretchesCopy[index][type] = value;
    setBody((prev) => ({ ...prev, stretches: stretchesCopy }));
  };

  const handleAddStretch = () => {
    let stretchesCopy = [...(body.stretches || [{ from: '', to: '' }])];
    stretchesCopy.push({ from: '', to: '' });
    setBody((prev) => ({ ...prev, stretches: stretchesCopy }));
  };

  const handleDeleteStretch = (index: number) => {
    if (body.stretches && body.stretches.length > 1) {
      let stretchesCopy = [...(body.stretches || [{ from: '', to: '' }])];
      stretchesCopy.splice(index, 1);
      setBody((prev) => ({ ...prev, stretches: stretchesCopy }));
    } else {
      setBody((prev) => ({ ...prev, stretches: [{ from: '', to: '' }] }));
    }
  };

  const generatePdf = useCallback(() => {
    if (!body.id) return;

    setIsLoading(true);
    TaskService.getPdf(parseInt(taskId), body.id)
      .then((res) => {
        downloadFileFromBlob(`arbejdsinstruks-${taskId}`, res.data);
        onPDFCreatedCallback();
      })
      .finally(() => setIsLoading(false));
  }, [taskId, body.id, onPDFCreatedCallback]);

  const handleVoltageChange = (value: string) => {
    setBody((prev) => ({ ...prev, voltage: parseInt(value) }));
  };

  const handleWithVoltageChange = (value: boolean) => {
    setBody((prev) => ({ ...prev, withVoltage: value }));
  };

  const handleDateFromChange = (date?: string) => {
    setBody((prev) => ({ ...prev, workingPeriodStart: date }));
  };

  const handleDateToChange = (date?: string) => {
    setBody((prev) => ({ ...prev, workingPeriodEnd: date }));
  };

  const saveSignature = (dataURL: string) => {
    if (!taskId) return;

    const newBody = { ...body, signature: dataURL, signedDate: new Date().toISOString() };
    setBody(newBody);
    handleSaveWorkInstruction(newBody);
    setFormState('signed');
  };

  const editForm = async () => {
    const confirmation = await getConfirmation({
      headerText: 'Åbn for redigering af felter',
      bodyText: `Ændring af felter vil kræve en ny underskrift`,
      declineButtonText: 'Fortryd',
      confirmButtonText: 'Bekræft'
    });

    if (confirmation === 'confirm') {
      setFormState('editing');
      setInitialBody(body);
      setBody((prev) => ({
        ...prev,
        signedBy: body.contactPersonName ?? '',
        signature: '',
        signedDate: ''
      }));
    }
  };

  const cancelEditForm = async () => {
    const confirmation = await getConfirmation({
      headerText: 'Annuller ændringer?',
      bodyText: `Felterne ændres tilbage til sidst underskrevne`,
      declineButtonText: 'Fortryd',
      confirmButtonText: 'Bekræft'
    });

    if (confirmation === 'confirm') {
      setFormState('signed');
      setBody(initialBody);
    }
  };

  const handleOnChangeVoltage = useCallback((e: any, voltage: number) => {
    if (e.target.checked) {
      setBody((prev) => ({
        ...prev,
        voltages: prev.voltages?.length ? [...prev.voltages, voltage] : [voltage]
      }));
    } else {
      setBody((prev) => {
        const newVoltages = prev.voltages ?? [];
        const index = prev.voltages?.indexOf(voltage);
        if (index !== undefined) {
          newVoltages.splice(index, 1);
          return { ...prev, voltages: newVoltages };
        }

        return prev;
      });
    }
  }, []);

  return (
    <Container>
      {page === 'sign' ? (
        <SignDialog handleClose={() => setPage('edit')} handleConfirm={saveSignature}></SignDialog>
      ) : (
        <>
          {isLoading && <LoadingOverlay />}
          <Section noBorder>
            <SectionHeader>Instruktion for arbejde nær*</SectionHeader>
            <SectionContent>
              <StyledRadioGroup
                disabled={formState === 'signed'}
                row
                value={body.voltages}
                onChange={(_: any, value: string) => handleVoltageChange(value)}
              >
                <Checkbox
                  disabled={formState === 'signed'}
                  label="60 kV"
                  checked={body.voltages?.some((x) => x === 60000)}
                  onChange={(e) => handleOnChangeVoltage(e, 60000)}
                />
                <Checkbox
                  disabled={formState === 'signed'}
                  label="15 kV"
                  checked={body.voltages?.some((x) => x === 15000)}
                  onChange={(e) => handleOnChangeVoltage(e, 15000)}
                />
                <Checkbox
                  disabled={formState === 'signed'}
                  label="10 kV"
                  checked={body.voltages?.some((x) => x === 10000)}
                  onChange={(e) => handleOnChangeVoltage(e, 10000)}
                />
                <Checkbox
                  disabled={formState === 'signed'}
                  label="20 kV"
                  checked={body.voltages?.some((x) => x === 20000)}
                  onChange={(e) => handleOnChangeVoltage(e, 20000)}
                />
                <Checkbox
                  disabled={formState === 'signed'}
                  label="0,4 kV"
                  checked={body.voltages?.some((x) => x === 400)}
                  onChange={(e) => handleOnChangeVoltage(e, 400)}
                />
              </StyledRadioGroup>
            </SectionContent>
          </Section>

          <Section>
            <SectionHeader>Kontaktoplysninger på entreprenør</SectionHeader>
            <SectionContent>
              <Row>
                <TextField
                  disabled={formState === 'signed'}
                  fullWidth
                  required
                  label={'Kontaktperson'}
                  value={body.contactPersonName}
                  onChange={(e) =>
                    setBody((prev) => ({
                      ...prev,
                      contactPersonName: e.target.value,
                      signedBy: e.target.value
                    }))
                  }
                />
                <TextField
                  disabled={formState === 'signed'}
                  fullWidth
                  required
                  label={'Kontaktperson tlf. nr.'}
                  value={body.contactPersonPhoneNumber}
                  onChange={(e) => setBody((prev) => ({ ...prev, contactPersonPhoneNumber: e.target.value }))}
                />
              </Row>
              <Row>
                <TextField
                  disabled={formState === 'signed'}
                  fullWidth
                  required
                  onChange={(e) => setBody((prev) => ({ ...prev, contractorCompanyName: e.target.value }))}
                  label={'Entreprenør / opgavestiller'}
                  value={body.contractorCompanyName}
                />
                <TextField
                  disabled={formState === 'signed'}
                  fullWidth
                  label={'Firma tlf. nr.'}
                  value={body.contractorCompanyPhoneNumber}
                  onChange={(e) => setBody((prev) => ({ ...prev, contractorCompanyPhoneNumber: e.target.value }))}
                />
              </Row>
              <Row>
                <TextField
                  disabled={formState === 'signed'}
                  fullWidth
                  label={'Email på kontaktperson'}
                  value={body.contactPersonEmail}
                  onChange={(e) => setBody((prev) => ({ ...prev, contactPersonEmail: e.target.value }))}
                />
                <div style={{ width: '100%' }}></div>
              </Row>
            </SectionContent>
          </Section>

          <Section>
            <SectionHeader>Detaljer</SectionHeader>
            <SectionContent>
              <Row>
                <TextField
                  disabled={formState === 'signed'}
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={4}
                  label={'Opgavebeskrivelse'}
                  value={body.description}
                  onChange={(e) => setBody((prev) => ({ ...prev, description: e.target.value }))}
                />
                <ColumnFlex fullWidth rowGap="22px">
                  <TextField fullWidth disabled label={'LER nummer'} value={body.lerNumber} />
                  <FormGroup>
                    <FormControlLabel
                      value={body.withVoltage}
                      control={
                        <Checkbox
                          disabled={formState === 'signed'}
                          checked={body.withVoltage}
                          onChange={(e) => handleWithVoltageChange(e.target.checked)}
                        />
                      }
                      label="Udføres med spænding på anlæg"
                    />
                  </FormGroup>
                </ColumnFlex>
              </Row>
              <Row>
                <DatePicker
                  onDateChanged={(date) => handleDateFromChange(date.toISOString())}
                  disabled={formState === 'signed'}
                  label="Arbejdsperiode fra"
                  required
                  value={body.workingPeriodStart ? formatDateString(body.workingPeriodStart) : ''}
                />
                <DatePicker
                  onDateChanged={(date) => handleDateToChange(date.toISOString())}
                  disabled={formState === 'signed'}
                  label="Arbejdsperiode til"
                  required
                  value={body.workingPeriodEnd ? formatDateString(body.workingPeriodEnd) : ''}
                />
              </Row>
              <Row>
                <TextField
                  disabled={formState === 'signed'}
                  type="text"
                  fullWidth
                  label={'Respektafstand'}
                  helperText="Vandret i meter"
                  value={body.horizontalDistance}
                  onChange={(e) => setBody((prev) => ({ ...prev, horizontalDistance: e.target.value }))}
                  hideButtons
                />
                <TextField
                  disabled={formState === 'signed'}
                  type="text"
                  fullWidth
                  label={'Respektafstand'}
                  helperText="Lodret i meter"
                  value={body.verticalDistance}
                  onChange={(e) => setBody((prev) => ({ ...prev, verticalDistance: e.target.value }))}
                  hideButtons
                />
              </Row>
            </SectionContent>
          </Section>

          <Section>
            <SectionHeader>Strækning</SectionHeader>
            <SectionContent>
              {body?.stretches?.map((stretch, i) => {
                return (
                  <StyledRow key={i}>
                    <TextField
                      disabled={formState === 'signed'}
                      required
                      fullWidth
                      label={'Fra station'}
                      value={stretch.from}
                      onChange={(e) => handleChangeStretches(e.target.value, 'from', i)}
                    />
                    <TextField
                      disabled={formState === 'signed'}
                      required
                      fullWidth
                      label={'Til station'}
                      value={stretch.to}
                      onChange={(e) => handleChangeStretches(e.target.value, 'to', i)}
                    />
                    <IconButton disabled={formState === 'signed'} onClick={() => handleDeleteStretch(i)}>
                      <DeleteIcon margin="5px" size="20px" />
                    </IconButton>
                  </StyledRow>
                );
              })}
              {body.stretches && (
                <Row>
                  <Button disabled={formState === 'signed'} onClick={() => handleAddStretch()} variant="secondary">
                    <AddIcon size="20px" />
                    Tilføj strækning
                  </Button>
                </Row>
              )}
            </SectionContent>
          </Section>
          <Section>
            <SectionHeader>Underskrift</SectionHeader>
            <SectionContent>
              <TextRow
                disabled={formState === 'signed'}
                inputMode
                label="Underskrivers navn"
                onChange={(value) => setBody((prev) => ({ ...prev, signedBy: value }))}
                value={body.signedBy ?? ''}
              />
              {body.signature && (
                <SignaturePreview
                  dataURL={body.signature}
                  signedBy={initialBody.signedBy ?? body.signedBy ?? ''}
                  signedDate={body.signedDate ?? ''}
                />
              )}
            </SectionContent>
          </Section>
          <StyledFooter>
            <StyledSection direction="row">
              {formState === 'editing' && (
                <Button variant="secondary" onClick={() => cancelEditForm()}>
                  Annuller
                </Button>
              )}
              {formState === 'signed' && (
                <Button variant="secondary" onClick={() => editForm()}>
                  <SignIcon size="22px" />
                  Ret
                </Button>
              )}
              {formState !== 'signed' && (
                <Button disabled={!formValidForSigning} variant="secondary" onClick={() => setPage('sign')}>
                  <SignIcon size="22px" />
                  Underskriv
                </Button>
              )}
              <Button
                isLoading={isUpdating}
                disabled={
                  !isDirty ||
                  formState !== 'unsigned' ||
                  !body.workingPeriodStart ||
                  !body.workingPeriodEnd ||
                  body.voltages?.length === 0
                }
                variant="secondary"
                onClick={() => handleSaveWorkInstruction(body)}
              >
                <>
                  <SaveIcon size="22px" />
                  Gem
                </>
              </Button>
              <Button onClick={generatePdf} disabled={formState !== 'signed'}>
                <DocumentIcon size="22px" />
                Gem som PDF
              </Button>
            </StyledSection>
          </StyledFooter>
        </>
      )}
    </Container>
  );
};

const StyledSection = styled((props) => <Section {...props} />)`
  padding: 16px 0;
  justify-content: flex-end;
  column-gap: 24px;
  border-top: 0;
`;

const StyledRadioGroup = styled((props) => <RadioGroup {...props} />)`
  column-gap: 32px;
`;

const StyledRow = styled((props) => <Row {...props} />)`
  align-items: center;
`;

export default WorkInstruction;
