import { useContext } from 'react';
import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';
import QuestionaireSection from '../../../../components/questionaire-section/QuestionaireSection';
import { TaskChecklistContext, TaskChecklistDispatchContext } from '../../../../stateManagement/TaskChecklistProvider';
import { IsRepeatableEnum } from '../../../../api/api';
import RepeatableSection from '../../../../components/repeatable-section/RepeatableSection';
import { Section } from '../../../../models/Section';
import { SectionWrapper } from '../../../../models/SectionWrapper';
import TaskChecklistService from '../../../../services/TaskChecklistService';
import NotificationService from '../../../../services/NotificationService';
import { log } from '../../../../utils/logging/log';
import useChecklistLock from '../../../../hooks/useChecklistLock';

interface Props extends TabProps {
  workTaskId: number;
  invalidSectionsIds?: number[];
  collapsedSections: number[];
  setCollapsedSections: (sections: number[]) => void;
  readOnly: boolean;
  checklistId: number;
  taskTypeChecklistId: number;
  showIsCompleted?: boolean;
}

const TaskChecklist = (props: Props) => {
  const {
    workTaskId,
    invalidSectionsIds,
    collapsedSections,
    setCollapsedSections,
    checklistId,
    taskTypeChecklistId,
    readOnly,
    showIsCompleted
  } = props;

  const checklist = useContext(TaskChecklistContext).find(
    (c) => c.workTaskCheckListId === checklistId && c.taskTypeChecklistId === taskTypeChecklistId
  );
  const { addChecklistEdited } = useChecklistLock();
  const dispatchChecklist = useContext(TaskChecklistDispatchContext);

  const handleSetCollapsedSection = (sectionId?: number) => {
    if (!sectionId) return;

    const newCollapsedSections =
      collapsedSections && collapsedSections.length > 0 && collapsedSections?.includes(sectionId)
        ? collapsedSections.filter((s) => {
            return s !== sectionId;
          })
        : [...collapsedSections, sectionId];

    setCollapsedSections && setCollapsedSections(newCollapsedSections);
  };

  const setToggleSectionIsCompleted = (newToggleState: boolean, sectionId: number) => {
    TaskChecklistService.setTaskSectionCompletionStatus(newToggleState, sectionId)
      .then(() => {
        dispatchChecklist({
          type: 'toggle-isCompleted',
          payload: {
            isCompleted: newToggleState,
            checklistId: checklistId,
            taskSectionId: sectionId
          }
        });
        addChecklistEdited(checklistId);
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke ændre markeringen af sektionen. Prøv igen.');
      });
  };

  return (
    <>
      {checklist?.workTaskCheckListSections
        .reduce((acc: { sectionId: number; sections: Section[] }[], section: Section) => {
          let index = acc.findIndex((a) => a.sectionId === section.sectionId);
          if (index !== -1) acc[index].sections.push(section);
          else {
            acc.push({ sectionId: section.sectionId, sections: [section] });
          }
          return acc;
        }, [] as SectionWrapper[])
        .map(
          (sectionWrapper, i) =>
            sectionWrapper.sections[0].preconditionFilled && (
              <div key={i}>
                {sectionWrapper.sections[0].isRepeatable2 === IsRepeatableEnum.NotRepeatable && (
                  <QuestionaireSection
                    key={sectionWrapper.sections[0].header + sectionWrapper.sections[0].taskSectionId}
                    header={sectionWrapper.sections[0].header}
                    questions={sectionWrapper.sections[0].questions}
                    workTaskSectionId={sectionWrapper.sections[0].taskSectionId}
                    checklistId={checklistId}
                    isExpanded={
                      !(
                        collapsedSections &&
                        collapsedSections.length > 0 &&
                        collapsedSections?.find((s) => s === sectionWrapper.sectionId)
                      )
                    }
                    setIsExpanded={() => handleSetCollapsedSection(sectionWrapper.sectionId)}
                    readOnly={readOnly}
                    showIsCompleted={showIsCompleted}
                    isCompleted={sectionWrapper.sections[0].isCompleted}
                    toggleIsCompleted={() =>
                      setToggleSectionIsCompleted(
                        !sectionWrapper.sections[0].isCompleted,
                        sectionWrapper.sections[0].taskSectionId ?? -1
                      )
                    }
                  />
                )}
                {sectionWrapper.sections[0].isRepeatable2 !== IsRepeatableEnum.NotRepeatable && (
                  <RepeatableSection
                    sectionWrapper={sectionWrapper}
                    key={sectionWrapper.sections[0].header + sectionWrapper.sections[0].taskSectionId}
                    header={sectionWrapper.sections[0].header}
                    taskTypeSectionId={sectionWrapper.sections[0].sectionId}
                    workTaskSectionId={sectionWrapper.sections[0].taskSectionId}
                    setIsExpanded={(id) => handleSetCollapsedSection(id)}
                    showError={invalidSectionsIds?.some((i) => i === sectionWrapper.sections[0].taskSectionId)}
                    checklistId={checklistId}
                    readOnly={readOnly}
                    workTaskId={workTaskId}
                    collapsedSections={collapsedSections}
                    toggleIsCompleted={(newToggleState, taskSectionId) =>
                      setToggleSectionIsCompleted(newToggleState, taskSectionId)
                    }
                  />
                )}
              </div>
            )
        )}
    </>
  );
};

export default TaskChecklist;
