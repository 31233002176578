import styled from 'styled-components';
import { useCallback, useState } from 'react';

import TextField from '../../components/text-field/TextField';

import { CustomerAppointmentDTO } from '../../api/api';
import { AppointmentErrors } from '../../models/CreationErrors';

interface Props {
	errors?: AppointmentErrors;
	onChange?: (key: keyof CustomerAppointmentDTO, value: string) => void;
	initialValue?: CustomerAppointmentDTO;
}

export const CustomerAppointmentContactForm = (props: Props) => {
	const { errors, onChange, initialValue } = props;

	const [values, setValues] = useState<CustomerAppointmentDTO>(initialValue ?? {});

	const handleChange = useCallback(
		(key: keyof CustomerAppointmentDTO, value: string) => {
			onChange?.(key, value);

			setValues((prevState) => (prevState ? { ...prevState, [key]: value } : { [key]: value }));
		},
		[onChange]
	);

	return (
		<>
			<CustomerAppointmentFormSection>
				<CustomerAppointmentFormHeader>Kundens kontaktoplysninger</CustomerAppointmentFormHeader>

				<CustomerAppointmentFormRow>
					<TextField
						required
						label="Kontaktperson"
						error={errors?.customerName}
						value={values?.customerName || ''}
						helperText="Skriv navnet på primær kontaktperson"
						onChange={(event) => handleChange('customerName', event.target.value)}
					/>

					<TextField
						required
						type="number"
						label="Kontaktperson tlf. nr."
						error={errors?.customerPhoneNumber}
						value={values?.customerPhoneNumber || ''}
						helperText="Skriv tlf. nr. på primær kontaktperson"
						onChange={(event) => handleChange('customerPhoneNumber', event.target.value)}
					/>
				</CustomerAppointmentFormRow>

				<CustomerAppointmentFormRow>
					<TextField
						label="Email"
						value={values?.customerEmail || ''}
						helperText="Email på kontaktperson"
						onChange={(event) => handleChange('customerEmail', event.target.value)}
					/>
				</CustomerAppointmentFormRow>

				<CustomerAppointmentFormRow>
					<TextField
						rows={4}
						multiline
						label="Bemærkninger"
						helperText="Eventuelle bemærkninger"
						onChange={(event) => handleChange('customerRemarks', event.target.value)}
					/>
				</CustomerAppointmentFormRow>
			</CustomerAppointmentFormSection>
		</>
	);
};

export const CustomerAppointmentFormSection = styled.section`
	display: flex;
	flex-direction: column;
	border-bottom: ${(props) => `1px solid ${props.theme.palette.grey.black10}`};

	&:last-of-type {
		border-bottom: none;
	}
`;

export const CustomerAppointmentFormHeader = styled.h3`
	margin: 24px 0;
`;

export const CustomerAppointmentFormRow = styled.div<{ direction?: 'row' | 'column' }>`
	width: 100%;
	display: flex;
	flex-direction: ${(props) => props.direction || 'row'};
	margin-bottom: 20px;

	& > * {
		width: calc(50% - 8px);
	}

	& > *:nth-child(1) {
		margin-right: 8px;
	}

	& > *:nth-child(2) {
		margin-left: 8px;
	}
`;

export default CustomerAppointmentContactForm;
