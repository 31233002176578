import { useEffect, useState } from 'react';
import { HorizontalTabProps } from '../../../../components/tabs-horizontal/TabsHorizontal';
import GoodsService from '../../../../services/GoodsService';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../../../stateManagement/reducers/userProfileReducer';
import { TransferOrderDTO } from '../../../../api/api';
import Table from '../../../../blocks/table/TableClient';
import NotificationService from '../../../../services/NotificationService';
import { log } from '../../../../utils/logging/log';
import { useReturnOrdersTable } from './useReturnOrdersTable';
import styled from 'styled-components';

interface Props extends HorizontalTabProps {
  handleOpenReceipt: () => void;
}

const ReturnGoodsTab = (props: Props) => {
  const { handleOpenReceipt } = props;
  const warehouseId = useSelector(selectUserProfile).userProfile.defaultWarehouseId;
  const [returnOrders, setReturnOrders] = useState<TransferOrderDTO[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!warehouseId) return;
    setLoading(true);
    GoodsService.getReturnOrders(warehouseId)
      .then((data) => {
        setReturnOrders(data);
      })
      .catch((e) => {
        log(e);
        NotificationService.error('Kunne ikke hente returordere');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [warehouseId]);

  const { tableInstance } = useReturnOrdersTable(returnOrders, handleOpenReceipt);

  return (
    <TableContainer>
      <Table loading={loading} tableInstance={tableInstance} alwaysShowSort noPadding showPagination></Table>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  width: 100%;
`;

export default ReturnGoodsTab;
