import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MassCreationTask } from '../../models/MassCreationTask';
import { RootState } from '../store';
import { MassCreationRequestItemDTO, MassCreationStretchRequestItemDTO } from '../../api/api';

export enum STATUS {
  READY,
  UPLOADING,
  DONE
}

export enum MassCreationTypes {
  ASSET,
  STRETCH
}

interface MassCreationState {
  task: MassCreationTask;
  showResultsTable: boolean;
  status: STATUS;
  requestItems: MassCreationRequestItemDTO[];
  fileName: string;
  massCreationType: MassCreationTypes;
}

const initialState: MassCreationState = {
  task: {
    assetType: undefined,
    gisRegion: undefined
  },
  showResultsTable: false,
  status: STATUS.READY,
  requestItems: [],
  fileName: '',
  massCreationType: MassCreationTypes.ASSET
};

const massCreationSlice = createSlice({
  name: 'massCreation',
  initialState,
  reducers: {
    updateMassCreationTask: (state: MassCreationState, action: PayloadAction<MassCreationTask>) => {
      state.task = { ...state.task, ...action.payload };
    },

    setRequestItems: (state: MassCreationState, action: PayloadAction<MassCreationRequestItemDTO[]>) => {
      state.requestItems = action.payload;
    },
    resetMassCreation: (state: MassCreationState) => {
      state.task = initialState.task;
      state.showResultsTable = initialState.showResultsTable;
      state.status = initialState.status;
      state.requestItems = initialState.requestItems;
      state.fileName = initialState.fileName;
      state.massCreationType = initialState.massCreationType;
    },
    setShowResultsTable: (state: MassCreationState, action: PayloadAction<boolean>) => {
      state.showResultsTable = action.payload;
    },
    setStatus: (state: MassCreationState, action: PayloadAction<STATUS>) => {
      state.status = action.payload;
    },
    setFileName: (state: MassCreationState, action: PayloadAction<string>) => {
      state.fileName = action.payload;
    },
    setStretchItems: (state: MassCreationState, action: PayloadAction<MassCreationStretchRequestItemDTO[]>) => {
      state.task.stretchItems = action.payload;
    },
    setMassCreationType: (state: MassCreationState, action: PayloadAction<MassCreationTypes>) => {
      state.massCreationType = action.payload;
    }
  }
});

export const {
  resetMassCreation,
  setShowResultsTable,
  setStatus,
  setFileName,
  updateMassCreationTask,
  setRequestItems,
  setStretchItems,
  setMassCreationType
} = massCreationSlice.actions;

export const massCreation = (state: RootState) => state.massCreation;

export default massCreationSlice.reducer;
