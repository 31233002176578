import { CustomerAppointmentDTO } from '../../api/api';
import { AppointmentErrors, BaseErrors } from '../../models/CreationErrors';
import { TaskCreationTask } from '../../models/TaskCreationTask';

export const validateBaseFields = (task: TaskCreationTask): BaseErrors => {
  let errors: BaseErrors = { taskLocation: false, earliestStartDate: false };

  if (!task.normTimeMin) errors.normTimeMin = true;

  if (!task.earliestStartDate) errors.earliestStartDate = true;

  if (!task.deadline) errors.deadline = true;

  if (!task.assignedToDepartmentId) errors.assignedToDepartmentId = true;

  if (!task.projectNumber) errors.projectNumber = true;

  if (!task.description) errors.description = true;

  if (!task.normTimeValidated) errors.normTimeValidated = true;

  if (!task.taskLocation && !task.assetLocation) errors.taskLocation = true;

  if (!task.taskTypeId) errors.taskTypeId = true;

  return errors;
};

export const validateMassCreationBaseFields = (task: TaskCreationTask): BaseErrors => {
  let errors: BaseErrors = { taskLocation: false, earliestStartDate: false };

  if (!task.normTimeMin) errors.normTimeMin = true;

  if (!task.earliestStartDate) errors.earliestStartDate = true;

  if (!task.deadline) errors.deadline = true;

  if (!task.assignedToDepartmentId) errors.assignedToDepartmentId = true;

  if (!task.projectNumber) errors.projectNumber = true;

  if (!task.normTimeValidated) errors.normTimeValidated = true;

  if (!task.taskTypeId) errors.taskTypeId = true;

  return errors;
};

export const validateCustomerAppointment = (customerAppointment?: CustomerAppointmentDTO): AppointmentErrors => {
  const { appointmentRequest, customerName, customerPhoneNumber } = customerAppointment ?? {};

  let errors: AppointmentErrors = {};

  if (appointmentRequest && !customerName) errors.customerName = true;

  if (appointmentRequest && (!customerPhoneNumber || customerPhoneNumber.length !== 8)) errors.customerPhoneNumber = true;

  return errors;
};
