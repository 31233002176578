import styled from 'styled-components';
import { WarningIcon } from '../../assets/icons/WarningIcon';
import Button from '../../components/button/Button';
import BasicPopover from '../../components/popover/Popover';
import Typography from '../../components/typography/Typography';
import { PopoverOrigin } from '@mui/material';

interface Props {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

const CriticalOrderPopover = (props: Props) => {
  const { anchorOrigin, transformOrigin } = props;
  return (
    <BasicPopover
      buttonElement={
        <Button variant="secondary" square>
          <WarningIcon size="20px" />
          Kritisk ordre
        </Button>
      }
      popoverElement={
        <CriticalOrder>
          <Typography variant="h6" fontWeight="bold">
            Er der tale om en Driftskritisk situation, haster din ordre.
          </Typography>
          <Typography margin="0 0 0 8px">1. Bestil varerne til først mulige leveringsdag</Typography>
          <Typography margin="0 0 0 8px">
            2. Når du har bestilt varerne, skal du STRAKS kontakte PMM på tlf. 75 18 77 44 mandag til fredag fra kl. 8-15.
            Vagt telefonen fra kl. 15:01-07:59 tlf. 87 22 87 30.
          </Typography>
          <Typography margin="0 0 0 16px">• Oplys ordrenummeret som findes under fanen bestillinger</Typography>
          <Typography margin="0 0 0 16px">
            • Oplys hvornår du vil have varen leveret. PMM sørger for at den bliver leveret.
          </Typography>
        </CriticalOrder>
      }
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    />
  );
};

const CriticalOrder = styled.div`
  padding: ${(props) => props.theme.spacing(2)};
  width: 400px;
  border: 1px;
  border-color: ${(props) => props.theme.palette.text.main};
`;

export default CriticalOrderPopover;
