export const downloadFileFromBlob = (filename: string, data: Blob) => {
  const link = document.createElement('a');
  link.download = filename;
  link.target = '_blank';
  link.href = URL.createObjectURL(data);
  link.click();
};

export const renameFile = (file: File, name: string) => {
  return new File([file], `${name}.jpg`, { type: file.type });
};
