import { useEffect, useState } from 'react';
import Select, { OptionProps } from 'react-select';
import styled from 'styled-components';
import { TechnicianDTO } from '../../api/api';
interface Props {
  technicians: TechnicianDTO[];
  selectedTechnician?: TechnicianDTO;
  onSelectTechnician: (technician: TechnicianDTO | undefined) => void;
  isLoading: boolean;
}

const SelectTechnician = (props: Props) => {
  const { technicians, selectedTechnician, onSelectTechnician, isLoading } = props;
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: TechnicianDTO;
  } | null>();

  useEffect(() => {
    setSelectedOption(
      !!selectedTechnician
        ? {
            label: `${selectedTechnician?.name} - ${selectedTechnician?.initials}`,
            value: selectedTechnician
          }
        : null
    );
  }, [selectedTechnician]);

  const handleChangeTechnician = (option: any) => {
    onSelectTechnician(option?.value);
  };

  const mappedTechnicians = technicians.map((t) => {
    return { label: `${t.name} - ${t.initials}`, value: t };
  });

  const CustomOption = (props: OptionProps<any>) => {
    return (
      <StyledOption data-testid="technician-option" {...props.innerProps}>
        {props.label}
      </StyledOption>
    );
  };

  return (
    <Container data-testid="select-technician">
      <Select
        options={mappedTechnicians}
        components={{ Option: CustomOption }}
        onChange={handleChangeTechnician}
        placeholder="Vælg medarbejder/enhed"
        isClearable={true}
        value={selectedOption}
        noOptionsMessage={() => (isLoading ? 'Henter valgmuligheder...' : 'Ingen valgmuligheder')}
      />
    </Container>
  );
};

const Container = styled.div`
  text-align: left;
`;

const StyledOption = styled((props) => <div {...props} />)`
  padding: 8px 12px;
  :hover {
    background-color: rgb(222, 235, 255);
  }
`;

export default SelectTechnician;
