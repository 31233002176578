import { memo, useCallback, useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';

import { SearchIcon } from '../../../assets/icons/SearchIcon';
import TextField from '../../../components/text-field/TextField';
import useDebounce from '../../../hooks/useDebounce';

interface Props {
  setGlobalFilter: (filterValue: any) => void;
}

const GlobalSearchField = (props: Props) => {
  const { setGlobalFilter } = props;

  const [searchInput, setSearchInput] = useState('');

  const debouncedSearchInput = useDebounce(searchInput, 500);

  useEffect(() => {
    setGlobalFilter(debouncedSearchInput);
  }, [debouncedSearchInput, setGlobalFilter]);

  const handleFilterInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearchInput(value);
  }, []);

  return (
    <TextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon size="18px" />
          </InputAdornment>
        )
      }}
      label="Søg på en opgave"
      compact
      onChange={handleFilterInputChange}
      value={searchInput}
      dataTestId="search-task"
    />
  );
};

export default memo(GlobalSearchField);
