import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const VehicleDriveIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="none"
        strokeWidth="2"
        d="M 15.801 19.691 C 15.801 20.948 14.784 21.966 13.53 21.966 C 12.276 21.966 11.258 20.948 11.258 19.691 C 11.258 18.435 12.276 17.417 13.53 17.417 C 14.784 17.417 15.801 18.435 15.801 19.691 Z"
      ></path>
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 22.865 21.08 C 23.628 21.08 24.251 20.461 24.251 19.692 C 24.251 18.925 23.628 18.305 22.865 18.305 C 22.101 18.305 21.479 18.925 21.479 19.692 C 21.479 20.461 22.101 21.08 22.865 21.08 Z M 22.865 22.854 C 24.609 22.854 26.024 21.439 26.024 19.692 C 26.024 17.947 24.609 16.531 22.865 16.531 C 21.121 16.531 19.707 17.947 19.707 19.692 C 19.707 21.439 21.121 22.854 22.865 22.854 Z"
      ></path>
      <rect stroke="none" x="11.643" y="13.247" width="8.86" height="1.774" rx="1.2"></rect>
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 7.62 7.916 C 7.633 7.437 8.026 7.054 8.507 7.054 L 23.343 7.054 C 23.701 7.054 24.023 7.27 24.161 7.6 L 26.102 12.273 L 29.109 14.576 C 29.329 14.743 29.457 15.003 29.457 15.279 L 29.457 19.764 C 29.457 20.254 29.06 20.651 28.57 20.651 L 25.875 20.651 C 25.971 20.349 26.024 20.026 26.024 19.692 C 26.024 19.41 25.987 19.138 25.918 18.877 L 27.684 18.877 L 27.684 15.717 L 24.836 13.537 C 24.712 13.442 24.616 13.317 24.555 13.173 L 22.751 8.828 L 9.368 8.828 L 9.086 18.877 L 10.478 18.877 C 10.409 19.138 10.371 19.409 10.371 19.691 C 10.371 20.026 10.424 20.349 10.52 20.651 L 8.174 20.651 C 7.934 20.651 7.705 20.554 7.538 20.382 C 7.371 20.211 7.28 19.979 7.287 19.739 L 7.62 7.916 Z M 19.812 18.877 L 16.582 18.877 C 16.651 19.138 16.689 19.409 16.689 19.691 C 16.689 20.026 16.637 20.349 16.541 20.651 L 19.854 20.651 C 19.758 20.349 19.707 20.026 19.707 19.692 C 19.707 19.41 19.743 19.138 19.812 18.877 Z"
      ></path>
      <rect y="10.033" width="3.667" height="0.545" x="1.137"></rect>
      <rect y="12.701" width="3.667" height="0.545" x="0.946"></rect>
      <rect y="15.458" width="3.667" height="0.545" x="0.754"></rect>
    </IconSvg>
  );
};
