import localforage from 'localforage';
import { useCallback, useEffect, useState } from 'react';

export function useTaskFiles(taskCreationId?: string) {
  const [files, setFiles] = useState<File[]>();
  const [id, setId] = useState(taskCreationId);

  const fetchFiles = useCallback(async (taskCreationId: string) => {
    localforage.getItem(taskCreationId).then((storedFiles) => {
      setFiles(storedFiles as File[]);
    });
  }, []);

  useEffect(() => {
    if (id) {
      fetchFiles(id);
    }
  }, [id, fetchFiles]);

  useEffect(() => {
    if (taskCreationId !== id) {
      setId(taskCreationId);
    }
  }, [id, taskCreationId]);

  return { files, fetchFiles };
}

export default useTaskFiles;
