import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ArrowDownIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
      <path d="M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z" />
    </IconSvg>
  );
};
