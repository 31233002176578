import { FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { QuestionOption } from '../../models/Question';
import { QuestionProps } from '../question-wrapper/QuestionRenderer';
import QuestionHeader from '../question-header/QuestionHeader';
import Select from '../select/Select';
import styled from 'styled-components';
import AuditInfo from '../audit-info/AuditInfo';
import QuestionRow from '../question-wrapper/QuestionRow';
import { getInitialsFromEmail } from '../../utils/initialsUtils';
import { getUserEmail } from '../../utils/authProvider/authProvider';

const QuestionDropdown = (props: QuestionProps) => {
  const { question, handleSave, required, showMandatoryFieldError } = props;
  const [value, setValue] = useState(question.value ?? '');
  const [lastUpdatedBy, setLastUpdatedBy] = useState(question.updatedByEmail);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(question.timeStampValue);

  const handleChange = (event: SelectChangeEvent<any>) => {
    const answer = event.target.value;
    question.value = answer;
    handleSave && handleSave(answer);
    setValue(answer);
    setLastUpdatedBy(getInitialsFromEmail(getUserEmail()));
    setLastUpdatedTime(new Date().toISOString() + '');
  };

  const handleClose = () => {
    setTimeout(() => {
      const activeElement = document.activeElement as HTMLElement | null;
      if (activeElement) {
        activeElement.blur();
      }
    }, 0);
  };

  const mappedOptions = question.options
    ? question.options &&
      [...question.options]
        .sort((a: QuestionOption, b: QuestionOption) => {
          if (a.displayValue === null || a.displayValue === undefined) return 1;
          if (b.displayValue === null || b.displayValue === undefined) return -1;
          return a.displayValue.localeCompare(b.displayValue);
        })
        .map((o: QuestionOption) => (
          <MenuItem key={o.value} value={o.value}>
            {o.displayValue}
          </MenuItem>
        ))
    : [];

  return (
    <div>
      <QuestionHeader
        text={question.text}
        required={required}
        helpText={question.helpText}
        error={showMandatoryFieldError && required && (value === '' || !value || value === undefined)}
      />
      <QuestionRow>
        <FormControl fullWidth>
          <StyledSelect
            onChange={handleChange}
            value={value}
            error={showMandatoryFieldError && required && (value === '' || !value || value === undefined)}
            onClose={handleClose}
          >
            {[
              <MenuItem key="default" value={undefined}>
                --- Intet valgt ---
              </MenuItem>,
              ...mappedOptions
            ]}
          </StyledSelect>
        </FormControl>
        <AuditInfo updatedByEmail={lastUpdatedBy} updatedTimeStamp={lastUpdatedTime} size="24px" />
      </QuestionRow>
    </div>
  );
};

const StyledSelect = styled(Select)`
  background-color: ${(props) => props.theme.palette.background.primary};
`;

export default QuestionDropdown;
