import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import styled from 'styled-components';
import { DepartmentDTO, ReasonCodeDTO, UpdateReasonCodeStatusDTO2, WorkTaskStatus } from '../../../../../api/api';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import Select, { MenuItem } from '../../../../../components/select/Select';
import useTableInstance from '../../../../../hooks/useTableInstance';
import { getEnumDisplayValue } from '../../../../../utils/enumUtils';
import { CREATING_DEPARTMENT } from './AddReason';

const useAddReasonTable = (
  newReasonCodes: Array<UpdateReasonCodeStatusDTO2>,
  selectedReasonCodes: Array<UpdateReasonCodeStatusDTO2>,
  allReasonCodes: Array<ReasonCodeDTO>,
  departments: Array<DepartmentDTO>,
  departmentChangeCallback: (reasonCodeId: string, newDepartmentId: string) => void,
  reasonSelectedCallback: (reasonCodeId: string, checked: boolean) => void,
  searchTerm: string
) => {
  const data = useMemo(() => {
    return allReasonCodes
      .filter((reasonCode) => !selectedReasonCodes.some((r) => r.reasonCodeId === reasonCode.reasonCodeId))
      .filter((reasonCode) => reasonCode.reasonText?.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [selectedReasonCodes, allReasonCodes, searchTerm]);

  const columns = useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'reasonCodeId',
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<ReasonCodeDTO>) => {
          const { cell } = cellProps;
          return (
            <Checkbox
              onChange={(e) =>
                cell.row.original.status &&
                reasonSelectedCallback(cell.row.original.reasonCodeId?.toString() ?? '', e.target.checked)
              }
              checked={newReasonCodes.some((obj) => obj.reasonCodeId === cell.row.original.reasonCodeId)}
            />
          );
        }
      },
      {
        Header: 'Navn',
        accessor: 'reasonText',
        disableFilters: true,
        width: 200
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        width: 100,
        Cell: (cellProps: CellProps<ReasonCodeDTO>) => {
          const { cell } = cellProps;
          return cell.row.original.status ? getEnumDisplayValue(cell.row.original.status) : '';
        }
      },
      {
        Header: 'Sendes til',
        accessor: 'sendToDepartment',
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<ReasonCodeDTO>) => {
          const { cell } = cellProps;
          const code = newReasonCodes.find((obj) => obj.reasonCodeId === cell.row.original.reasonCodeId);
          return (
            <StyledSelect
              onChange={(event: any) => {
                departmentChangeCallback(cell.row.original.reasonCodeId?.toString() ?? '', event.target.value);
              }}
              disabled={
                !newReasonCodes.some((obj) => obj.reasonCodeId === cell.row.original.reasonCodeId) ||
                cell.row.original.status === WorkTaskStatus.Processed
              }
              value={code?.sendToCreatingDepartment ? CREATING_DEPARTMENT : code?.sendToDepartmentId ?? ''}
            >
              {departments.map((d) => (
                <MenuItem key={d.departmentId} value={d.departmentId}>
                  {d.name}
                </MenuItem>
              ))}
              <MenuItem key={CREATING_DEPARTMENT} value={CREATING_DEPARTMENT}>
                Oprettende afdeling
              </MenuItem>
            </StyledSelect>
          );
        }
      }
    ] as Column<ReasonCodeDTO>[];
  }, [newReasonCodes, departmentChangeCallback, reasonSelectedCallback]);

  const tableInstance = useTableInstance<ReasonCodeDTO>(data, columns);

  return { tableInstance };
};

const StyledSelect = styled(Select)`
  min-width: 200px;
`;

export default useAddReasonTable;
