import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { WorkTaskStatus } from '../../../../api/api';
import Button from '../../../../components/button/Button';
import Select, { MenuItem } from '../../../../components/select/Select';
import TextField from '../../../../components/text-field/TextField';
import { FormProps } from '../../../../models/CommonProps/FormProps';
import ReasonCode from '../../../../models/ReasonCode';
import { Row } from '../../../../styling/FormStyling';
import { getEnumDisplayValue } from '../../../../utils/enumUtils';

type ReasonCodeComponentFormErrors = { [key in keyof ReasonCode]: boolean };

const ReasonCodeForm = (props: FormProps<ReasonCode>) => {
  const { initialBody, handleCancel, handleSubmit } = props;

  const [body, setBody] = useState<ReasonCode>(initialBody ?? {});
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<ReasonCodeComponentFormErrors>({});

  const handleChangeValue = useCallback((key: keyof ReasonCode, value: any) => {
    setBody((prev) => {
      return { ...prev, [key]: value };
    });
  }, []);

  const submitForm = useCallback(() => {
    const newErrors = {
      status: !body.status,
      text: !body.text
    } as ReasonCodeComponentFormErrors;

    if (Object.values(newErrors).some((value) => value)) {
      setErrors(newErrors);
      return;
    }

    setSubmitting(true);
    handleSubmit &&
      handleSubmit(body).then((res) => {
        setSubmitting(false);
      });
  }, [body, handleSubmit]);

  return (
    <Container>
      <Row>
        <TextDiv>
          <TextField
            value={body.text || ''}
            onChange={(e) => handleChangeValue('text', e.target.value)}
            label="Navn"
            required
            error={errors.text}
            fullWidth
          />
        </TextDiv>
        <SelectDiv>
          <Select
            value={body.status || ''}
            onChange={(e) => handleChangeValue('status', e.target.value)}
            label="Status"
            fullWidth
            required
            error={errors.status}
          >
            {Object.keys(WorkTaskStatus)
              .filter(
                (s) =>
                  s === WorkTaskStatus.Completed.toString() ||
                  s === WorkTaskStatus.Pause.toString() ||
                  s === WorkTaskStatus.Processed.toString()
              )
              .map((s) => (
                <MenuItem key={s} value={s}>
                  {getEnumDisplayValue(s as WorkTaskStatus)}
                </MenuItem>
              ))}
          </Select>
        </SelectDiv>
      </Row>
      <ButtonContainer>
        <Button variant="secondary" onClick={() => handleCancel && handleCancel()}>
          Annuller
        </Button>
        <Button onClick={() => submitForm()} isLoading={submitting}>
          Gem
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px;
  justify-content: flex-end;
  width: 600px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextDiv = styled.div`
  flex: 2;
`;

const SelectDiv = styled.div`
  flex: 1;
`;

export default ReasonCodeForm;
