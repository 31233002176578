import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const AddIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} data-testid="add-icon" viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
      <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
    </IconSvg>
  );
};
