import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  InvalidChecklistDTO2 } from '../../api/api';
import { RootState } from '../store';

interface TaskChecklistState {
  showChecklistErrors: { showError: boolean; taskId?: string };
  checklistErrors: InvalidChecklistDTO2[];
  isChecklistValid: boolean,
}

const initialState: TaskChecklistState = {
  isChecklistValid: false,
  showChecklistErrors: { showError: false, taskId: undefined },
  checklistErrors: []
};

const taskChecklistSlice = createSlice({
  name: 'taskChecklist',
  initialState: initialState,
  reducers: {
    setShowChecklistErrors: (
      state: TaskChecklistState,
      payload: PayloadAction<{ showError: boolean; taskId: string | undefined }>
    ) => {
      state.showChecklistErrors = payload.payload;
    },
    setChecklistErrors: (state: TaskChecklistState, payload: PayloadAction<InvalidChecklistDTO2[]>) => {
      state.checklistErrors = payload.payload;
    }
  }
});

export const { setShowChecklistErrors, setChecklistErrors } = taskChecklistSlice.actions;
export const selectTaskChecklistState = (state: RootState) => state.taskChecklistReducer;

export default taskChecklistSlice.reducer;
