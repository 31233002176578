import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { PickerValidDate } from '@mui/x-date-pickers/models';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import 'dayjs/locale/da';

const DateTimePickerComponent = (props: DateTimePickerProps<PickerValidDate>) => {
  const { ...rest } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="da">
      <DateTimePicker
        label=" "
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock
        }}
        ampm={false}
        ampmInClock={false}
        views={['day', 'month', 'year', 'hours', 'minutes']}
        displayWeekNumber
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DateTimePickerComponent;
