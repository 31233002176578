import { useEffect, useState } from 'react';
import TextField from '../text-field/TextField';
import { LocationDTO } from '../../api/api';
import { FormControl } from '@mui/material';
import AutoComplete from '../auto-complete/AutoComplete';

interface Props {
  onSelect: (address: LocationDTO) => void;
  label: string;
  noOptionsText?: string;
  prevLocation?: LocationDTO;
  required?: boolean;
}

const AddressAutocomplete = (props: Props) => {
  const { onSelect, label, noOptionsText, prevLocation, required } = props;

  const [options, setOptions] = useState<LocationDTO[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<LocationDTO | undefined>(prevLocation);

  const addressChangeHandler = async (_searchTerm: string) => {
    const response = await fetch(`https://api.dataforsyningen.dk/adgangsadresser?q=${_searchTerm}&autocomplete&fuzzy`);
    const options = await response.json();
    const mappedOptions: LocationDTO[] = options.map((obj: any) => {
      return {
        city: obj.postnrnavn,
        houseNumber: obj.husnr,
        latitude: obj.y,
        longitude: obj.x,
        postalCode: obj.postnr,
        streetName: obj.vejnavn
      };
    });
    setOptions(mappedOptions);
  };

  const onAddressSelectedHandler = (_address: LocationDTO | null) => {
    if (!_address) return;
    setSelectedAddress(_address);
    onSelect(_address);
  };

  useEffect(() => {
    prevLocation && onAddressSelectedHandler(prevLocation);
  }, [prevLocation]);

  return (
    <FormControl fullWidth>
      <AutoComplete
        noOptionsText={noOptionsText ?? 'Ingen muligheder'}
        id="addresses"
        options={options}
        getOptionLabel={(option) =>
          option.streetName && option.houseNumber && option.postalCode && option.city
            ? ` ${option.streetName} ${option.houseNumber}, ${option.postalCode ?? ''} ${option.city}`
            : ''
        }
        fullWidth
        renderInput={(params) => (
          <TextField
            required={required}
            label={label ?? 'Adresse'}
            onChange={(e) => addressChangeHandler(e.target.value)}
            {...params}
          />
        )}
        value={selectedAddress}
        onChange={(_, value) => onAddressSelectedHandler(value)}
      />
    </FormControl>
  );
};

export default AddressAutocomplete;
