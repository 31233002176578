import { useCallback, useContext, useEffect, useState } from 'react';
import { IsRepeatableEnum } from '../../../../api/api';
import { log } from '../../../../utils/logging/log';
import NotificationService from '../../../../services/NotificationService';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import TaskChecklistService from '../../../../services/TaskChecklistService';
import TaskChecklist from './TaskChecklist';
import Button from '../../../../components/button/Button';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import { DialogBody } from '../../../../stateManagement/reducers/confirmDialogReducer';
import ExpandCollapseTaskChecklistHeaders from './ExpandCollapseTaskChecklistHeaders';
import {
  selectTaskChecklistState,
  setChecklistErrors,
  setShowChecklistErrors
} from '../../../../stateManagement/reducers/taskChecklistReducer';
import { TaskChecklistContext, TaskChecklistDispatchContext } from '../../../../stateManagement/TaskChecklistProvider';
import { ConvertChecklistDTOToTaskChecklist } from './TaskChecklistUtils';
import useChecklistLock from '../../../../hooks/useChecklistLock';
import Toggle from '../../../../components/toggle/Toggle';
import { LockIcon } from '../../../../assets/icons/LockIcon';
import { UnlockIcon } from '../../../../assets/icons/UnlockIcon';
import n1LightTheme from '../../../../theme/light-theme';
import Typography from '../../../../components/typography/Typography';
import { ContentFooterContainer } from '../../../../blocks/tabs-vertical/TabsVertical';
import { Divider } from '@mui/material';
import { TaskChecklist as TaskChecklistModel } from '../../../../models/TaskChecklist';
import { CollapsedSections, ExpandedCollapedChecklistsSections } from '../TaskDetailsViewContent';
import {
  selectEditedChecklistIds,
  setEditingChecklistOnWorktaskId
} from '../../../../stateManagement/reducers/signalRReducer';

interface Props {
  workTaskId: number;
  tabId: string;
  scrollIntoPreviousPosition: (id: string) => void;
  expandedState: ExpandedCollapedChecklistsSections;
  setExpandedState: React.Dispatch<React.SetStateAction<ExpandedCollapedChecklistsSections>>;
  readOnly: boolean;
  setReadOnly: (readOnly: boolean) => void;
}

const Dialog: DialogBody = {
  headerText: 'Slet tjekliste',
  bodyText: 'Er du sikker på, at du vil slette tjeklisten? Alle svar på den valgte tjekliste slettes.',
  confirmButtonText: 'Bekræft',
  declineButtonText: 'Fortryd'
};

const TaskChecklistStep = (props: Props) => {
  const { workTaskId, tabId, scrollIntoPreviousPosition, expandedState, setExpandedState, readOnly, setReadOnly } = props;
  const { addChecklistLock, releaseChecklistLock, checklistLockedBy, addChecklistEdited } = useChecklistLock();

  const [isValidating, setIsValidating] = useState(false);

  const checklists = useContext(TaskChecklistContext);
  const dispatchChecklist = useContext(TaskChecklistDispatchContext);
  const dispatch = useDispatch();
  const [loadingChecklists, setLoadingChecklists] = useState(false);
  const [checklistIsCreated, setChecklistIsCreated] = useState(false);
  const checklistErrorState = useSelector(selectTaskChecklistState);
  const checklistChanges = useSelector(selectEditedChecklistIds);
  const { getConfirmation } = useConfirmationDialog();

  const getChecklists = useCallback(() => {
    setLoadingChecklists(true);

    TaskChecklistService.getChecklistsForWorkTask(workTaskId)
      .then((res) => {
        if (res.checklists && res.checklists.length > 0) {
          res.checklists.sort((a, b) => {
            if ((a.order || a.order === 0) && (b.order || b.order === 0)) {
              if (a.order !== b.order) {
                return a.order - b.order;
              }
            }
            return 0;
          });
          setChecklistIsCreated(res.checklistsAreCreated ?? false);
          dispatch(setEditingChecklistOnWorktaskId(workTaskId));
          dispatchChecklist({
            type: 'set-checklists',
            payload: res.checklists.map((c) => ConvertChecklistDTOToTaskChecklist(c))
          });

          if (res.checklists && res.checklists.length === 1) {
            const checklist = res.checklists[0];
            setExpandedState((prevState) => {
              return {
                ...prevState,
                expandedChecklistId: checklist.workTaskCheckListId ?? -1,
                expandedTaskTypeChecklistId: checklist.taskTypeChecklistId ?? -1
              };
            });
            checklist.workTaskCheckListId && addChecklistLock(checklist.workTaskCheckListId);
          }
        }
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke hente tjeklisten.');
      })
      .finally(() => {
        setLoadingChecklists(false);
        tabId && scrollIntoPreviousPosition(tabId);
      });
  }, [addChecklistLock]);

  const addChecklist = useCallback(
    (taskTypeChecklistId) => {
      setLoadingChecklists(true);

      TaskChecklistService.addChecklist(workTaskId, taskTypeChecklistId)
        .then((res) => {
          releaseChecklistLock();
          res.workTaskCheckListId && addChecklistLock(res.workTaskCheckListId);
          dispatchChecklist({
            type: 'add',
            payload: ConvertChecklistDTOToTaskChecklist(res)
          });
          setExpandedState((prevState) => {
            return {
              ...prevState,
              expandedChecklistId: res.workTaskCheckListId,
              expandedTaskTypeChecklistId: res.taskTypeChecklistId
            };
          });
          document.getElementById(`checklist-id-${res.workTaskCheckListId}`)?.scrollIntoView();
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Kunne ikke oprette en tjekliste');
        })
        .finally(() => setLoadingChecklists(false));
    },
    [addChecklistLock, dispatchChecklist, releaseChecklistLock, setExpandedState, workTaskId]
  );

  const handleDeleteRepeatableChecklist = useCallback(
    async (workTaskChecklistId: number) => {
      const confirmation = await getConfirmation(Dialog);

      if (confirmation === 'decline') return;

      if (confirmation === 'confirm') {
        setLoadingChecklists(true);
        TaskChecklistService.removeChecklist(workTaskChecklistId)
          .then(() => {
            getChecklists();
          })
          .catch((err) => {
            log(err);
            NotificationService.error('Kunne ikke slette tjeklisten');
          })
          .finally(() => setLoadingChecklists(false));
      }
    },
    [getChecklists, getConfirmation]
  );

  useEffect(() => {
    getChecklists();
  }, [getChecklists]);

  const handleSetExpanded = (checklistId?: number, taskTypeChecklistId?: number) => {
    if (checklists && checklists.length > 1) {
      if (
        expandedState.expandedChecklistId === checklistId &&
        expandedState.expandedTaskTypeChecklistId === taskTypeChecklistId
      ) {
        setExpandedState((prevState) => {
          return {
            ...prevState,
            expandedChecklistId: -1,
            expandedTaskTypeChecklistId: -1
          };
        });
        releaseChecklistLock();
      } else {
        expandedState.expandedChecklistId !== 0 && releaseChecklistLock();
        setExpandedState((prevState) => {
          return {
            ...prevState,
            expandedChecklistId: checklistId,
            expandedTaskTypeChecklistId: taskTypeChecklistId
          };
        });

        checklistId && addChecklistLock(checklistId);
      }
    }
  };

  const toggleChecklistIsCompleted = (newToggleState: boolean, checklistId: number) => {
    TaskChecklistService.setChecklistCompletionStatus(newToggleState, checklistId)
      .then(() => {
        dispatchChecklist({
          type: 'toggle-isCompleted',
          payload: { isCompleted: newToggleState, checklistId: checklistId }
        });
        addChecklistEdited(checklistId);
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke ændre markeringen af tjeklisten. Prøv igen.');
      });
  };

  const validateChecklist = useCallback(() => {
    setIsValidating(true);
    TaskChecklistService.validateChecklists(workTaskId)
      .then((res) => {
        if (res.length === 0) {
          dispatch(setShowChecklistErrors({ showError: false, taskId: undefined }));
        } else {
          dispatch(setShowChecklistErrors({ showError: true, taskId: workTaskId.toString() }));
        }
        dispatch(setChecklistErrors(res));
      })
      .catch(() => {
        NotificationService.error('Kunne ikke validere tjekliste.');
      })
      .finally(() => {
        setIsValidating(false);
      });
  }, [dispatch, workTaskId]);

  const handleToggleChecklistReadOnly = useCallback(
    async (toggleInput: boolean) => {
      if (checklistIsCreated === false) {
        const confirmation = await getConfirmation({
          headerText: 'Tjeklisten bliver låst, hvis du redigerer',
          bodyText: `Hvis du vælger at redigere tjeklisten, forbliver tjeklisten den samme selvom der sker ændringer til den i administrationsmodulet.`,
          declineButtonText: 'Fortryd',
          confirmButtonText: 'Bekræft'
        });
        if (confirmation !== 'confirm') return;

        if (confirmation === 'confirm') {
          TaskChecklistService.createChecklists(workTaskId)
            .then(() => {
              getChecklists();
              setReadOnly(toggleInput);
            })
            .catch((error) => {
              NotificationService.error(`Der er sket en fejl i låsningen af tjeklisten: ${error}`);
            });
        }
      } else {
        setReadOnly(toggleInput);
      }
    },
    [checklistIsCreated, getChecklists, getConfirmation, setReadOnly, workTaskId]
  );

  const renderFooter = useCallback(() => {
    return (
      <Footer>
        <FooterLeft>
          <Toggle
            id="edit-checklist"
            handleChange={() => handleToggleChecklistReadOnly(!readOnly)}
            checked={!readOnly}
            variant="secondary"
            label="Rediger tjekliste"
          />
        </FooterLeft>
        <FooterRight>
          {readOnly ? (
            <>
              <StyledTypography color="greyedOut">Read only</StyledTypography>
              <LockIcon color={n1LightTheme.palette.grey.black20} size="20px" />
            </>
          ) : (
            <>
              {!readOnly && (
                <CheckChecklistButton isLoading={isValidating} variant="secondary" onClick={validateChecklist}>
                  Tjek tjekliste
                </CheckChecklistButton>
              )}
              <UnlockIcon color={n1LightTheme.palette.functions.action.primary} size="20px" />
            </>
          )}
        </FooterRight>
      </Footer>
    );
  }, [handleToggleChecklistReadOnly, isValidating, readOnly, validateChecklist]);

  const getHeader = (checklist: TaskChecklistModel) => {
    if (checklist === undefined) return '';

    const checklistName = checklist.checkListName;
    const firstSection = checklist.workTaskCheckListSections && checklist.workTaskCheckListSections[0];
    const firstQuestion = firstSection && firstSection.questions && firstSection.questions[0];
    const answerToFirstQuestion = firstQuestion && firstQuestion.value;
    return checklistName + `${answerToFirstQuestion && checklist.isRepeatable ? `: ${answerToFirstQuestion}` : ``} `;
  };

  const handleSetCollapsedSections = (checklistId?: number, taskTypeChecklistId?: number, newSections?: number[]) => {
    const prevCollapsedSections = expandedState.collapsedSections ?? [];

    let matchFound = false;
    const collapsedSections: CollapsedSections[] = prevCollapsedSections.map((s) => {
      if (s.checklistId === checklistId && s.taskTypeChecklistId === taskTypeChecklistId) {
        matchFound = true;
        return {
          ...s,
          sections: newSections ?? []
        };
      } else {
        return s;
      }
    });

    if (!matchFound) {
      collapsedSections.push({
        checklistId: checklistId,
        taskTypeChecklistId: taskTypeChecklistId,
        sections: newSections
      } as CollapsedSections);
    }

    setExpandedState((prevState) => {
      return {
        ...prevState,
        collapsedSections: collapsedSections
      };
    });
  };

  const getTitle = (checklist: TaskChecklistModel) => {
    if (checklist.isRepeatable === IsRepeatableEnum.NotRepeatable) return checklist.checkListHeader;

    const repeatableChecklists = checklists.filter(
      (c) =>
        c.taskTypeChecklistId === checklist.taskTypeChecklistId &&
        c.isRepeatable === IsRepeatableEnum.RepeatableWorkTaskChecklist
    );

    if (repeatableChecklists.length === 0) return;

    const firstRepeatableChecklist = repeatableChecklists.reduce((prev, curr) =>
      (prev.workTaskCheckListId ?? 0) < (curr.workTaskCheckListId ?? 0) ? prev : curr
    );

    if (checklist.workTaskCheckListId !== firstRepeatableChecklist.workTaskCheckListId) return '';

    return checklists.find(
      (c) =>
        c.isRepeatable === IsRepeatableEnum.RepeatableTaskTypeChecklist &&
        checklist.taskTypeChecklistId === firstRepeatableChecklist.taskTypeChecklistId
    )?.checkListHeader;
  };

  return (
    <>
      {checklists.map((c, i) => {
        const checklistSectionErrors =
          checklistErrorState.checklistErrors.find((x) => x.checklistId === c.workTaskCheckListId)?.invalidSectionIds ?? [];
        if (c.isRepeatable !== IsRepeatableEnum.RepeatableTaskTypeChecklist) {
          return (
            <div key={c.workTaskCheckListId}>
              {getTitle(c) && (
                <Typography margin="15px 0px 0px 0px" fontWeight="bold" variant="h5">
                  {getTitle(c)}
                </Typography>
              )}
              <ExpandCollapseTaskChecklistHeaders
                id={c.workTaskCheckListId}
                header={getHeader(c)}
                isChecklist
                isCollapsable={checklists?.length > 1}
                isExpanded={
                  checklists?.length === 1 ||
                  (c.workTaskCheckListId === expandedState.expandedChecklistId &&
                    c.taskTypeChecklistId === expandedState.expandedTaskTypeChecklistId)
                }
                setIsExpanded={() => handleSetExpanded(c.workTaskCheckListId, c.taskTypeChecklistId)}
                onDelete={() => handleDeleteRepeatableChecklist(c.workTaskCheckListId ?? -1)}
                isRepeatable={c.isRepeatable === IsRepeatableEnum.RepeatableWorkTaskChecklist}
                showError={
                  checklistSectionErrors.length > 0 &&
                  checklistErrorState.showChecklistErrors.taskId === workTaskId.toString()
                }
                openBy={checklistLockedBy(c.workTaskCheckListId ?? -1)}
                checkListChanges={checklistChanges.some((cc) => cc === c.workTaskCheckListId)}
                readOnly={readOnly}
                showIsCompleted={checklistIsCreated}
                isCompleted={c.isCompleted}
                toggleIsCompleted={() => toggleChecklistIsCompleted(!c.isCompleted, c.workTaskCheckListId ?? 0)}
              >
                <TaskChecklist
                  workTaskId={workTaskId}
                  invalidSectionsIds={checklistSectionErrors}
                  readOnly={readOnly}
                  collapsedSections={
                    expandedState.collapsedSections?.find(
                      (checklist) =>
                        checklist.checklistId === c.workTaskCheckListId &&
                        checklist.taskTypeChecklistId === c.taskTypeChecklistId
                    )?.sections ?? []
                  }
                  setCollapsedSections={(collapsedSections?: number[]) =>
                    handleSetCollapsedSections(c.workTaskCheckListId, c.taskTypeChecklistId, collapsedSections)
                  }
                  checklistId={c.workTaskCheckListId ?? 0}
                  taskTypeChecklistId={c.taskTypeChecklistId ?? 0}
                  showIsCompleted={checklistIsCreated}
                />
              </ExpandCollapseTaskChecklistHeaders>
              {c.isRepeatable === IsRepeatableEnum.NotRepeatable && <StyledDivider />}
            </div>
          );
        }

        return (
          <div key={i}>
            {!readOnly && (
              <>
                <ButtonContainer>
                  <Button
                    isLoading={loadingChecklists}
                    onClick={() => addChecklist(c.taskTypeChecklistId)}
                    variant="secondary"
                  >{`Tilføj ${c.checkListName}`}</Button>
                </ButtonContainer>
                <StyledDivider />
              </>
            )}
            {readOnly &&
              checklists.filter((checklist) => checklist.taskTypeChecklistId === c.taskTypeChecklistId)?.length > 1 && (
                <StyledDivider />
              )}
          </div>
        );
      })}
      <ContentFooterContainer>{renderFooter()}</ContentFooterContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  padding-top: ${(props) => props.theme.spacing(6)};
`;

const Footer = styled.div`
  display: flex;
  height: 40px;
  align-items: top;
  justify-content: space-between;
`;

const FooterLeft = styled.div`
  display: flex;
  align-items: center;
`;

const FooterRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CheckChecklistButton = styled(Button)`
  margin-right: ${(props) => props.theme.spacing(4)};
`;

const StyledTypography = styled(Typography)`
  margin-right: ${(props) => props.theme.spacing(4)};
`;

const StyledDivider = styled(Divider)`
  && {
    margin: ${(props) => props.theme.spacing(10)} 0px ${(props) => props.theme.spacing(4)} 0px;
    border-color: ${(props) => props.theme.palette.grey.black60};
  }
`;

export default TaskChecklistStep;
