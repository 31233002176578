import Typography from '../../../../../components/typography/Typography';
import styled from 'styled-components';
import Button from '../../../../../components/button/Button';
import TextField from '../../../../../components/text-field/TextField';
import DialogView from '../../../../../components/dialog-view/DialogView';
import { ComponentPropertyDTO, StationComponentDTO, ComponentFieldChangeDTO } from '../../../../../api/api';
import { useEffect, useState } from 'react';
import GisComponentService from '../../../../../services/GisComponentService';

interface Props {
  onClose: (propertyIsChanged: boolean) => void;
  property: ComponentPropertyDTO | undefined;
  component: StationComponentDTO;
  workTaskId: string | undefined;
}

const DataFieldUpdateDialog = (props: Props) => {
  const { onClose, property, component, workTaskId } = props;
  const [newValue, setNewValue] = useState<string | undefined>(undefined);
  const [oldValue, setOldValue] = useState<string | undefined>(property?.value);
  const onSave = () => {
    let fieldChange: ComponentFieldChangeDTO = {
      workTaskId: Number(workTaskId),
      componentObjectId: String(component.objectId),
      fieldName: property?.name,
      oldValue: oldValue,
      newValue: newValue,
      componentType: component.componentType
    };
    GisComponentService.updateComponentFieldChange(fieldChange).then(() => {
      onClose(true);
    });
  };
  useEffect(() => {
    setOldValue(property?.value);
  }, [property]);
  return (
    <DialogView>
      <Wrapper>
        <Typography variant="h3">Opdater Data: {property?.name}</Typography>
        <TextField label="Nuværende data" disabled={true} value={oldValue} onChange={(e) => setOldValue(e.target.value)} />
        <TextField label="Ny data" value={newValue} onChange={(e) => setNewValue(e.target.value)} />
        <ButtonContainer>
          <Button variant="secondary" onClick={() => onClose(false)}>
            Fortryd
          </Button>
          <Button onClick={onSave}>Gem</Button>
        </ButtonContainer>
      </Wrapper>
    </DialogView>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: ${(props) => props.theme.spacing(4)};
`;

const Wrapper = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing(6)};
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing(8)};
  min-width: 700px;
`;

export default DataFieldUpdateDialog;
