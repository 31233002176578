import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomerAppointmentDTO } from '../../../../../api/api';
import CustomerAppointmentContactForm from '../../../../../blocks/customer-appointment-form/CustomerAppointmentContactForm';
import CustomerAppointmentDateForm from '../../../../../blocks/customer-appointment-form/CustomerAppointmentDateForm';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import { SectionProps } from '../../../../../models/CommonProps/SectionProps';
import { TaskCreationTask } from '../../../../../models/TaskCreationTask';
import { updateTask } from '../../../../../stateManagement/reducers/taskCreationReducer';
import { SectionHeader } from '../../../../../styling/FormStyling';

interface Props extends SectionProps {
  task: TaskCreationTask;
}

const CustomerAppointmentSection = (props: Props) => {
  const { task, readonlyMode = false } = props;
  const { taskCreationId = '', customerAppointment } = task;

  const [openContact, setOpenContact] = useState(customerAppointment ? true : false);
  const [openDate, setOpenDate] = useState(false);
  const [values, setValues] = useState(customerAppointment);

  const dispatch = useDispatch();

  const handleCustomerAppointmentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOpenContact(e.target.checked);
      if (e.target.checked) {
        dispatch(updateTask({ id: taskCreationId, task: { customerAppointment: { ...values, appointmentRequest: true } } }));
      } else {
        dispatch(updateTask({ id: taskCreationId, task: { customerAppointment: undefined } }));
      }
    },
    [dispatch, taskCreationId, values]
  );

  const handlePressDateCheckmark = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOpenDate(e.target.checked);

      if (e.target.checked) {
        dispatch(updateTask({ id: taskCreationId, task: { customerAppointment: { ...values, appointmentRequest: true } } }));
      } else {
        dispatch(
          updateTask({
            id: taskCreationId,
            task: {
              customerAppointment: { ...values, appointmentRequest: true, startDate: undefined, endDate: undefined, preferredTimeOfDay: undefined }
            }
          })
        );
      }
    },
    [dispatch, taskCreationId, values]
  );

  const handleChange = useCallback(
    async (key: keyof CustomerAppointmentDTO, value: string) => {
      setValues((prevState) => {
        const newValues = prevState
          ? { ...prevState, [key]: value, appointmentRequest: true }
          : { [key]: value, appointmentRequest: true };
        dispatch(updateTask({ id: taskCreationId, task: { customerAppointment: newValues } }));
        return newValues;
      });
    },
    [dispatch, taskCreationId]
  );

  return (
    <>
      <Checkbox label="Ja" checked={openContact} disabled={readonlyMode} onChange={handleCustomerAppointmentChange} />
      {openContact && (
        <>
          <CustomerAppointmentContactForm
            initialValue={customerAppointment}
            onChange={handleChange}
            errors={task.appointmentErrors}
          />

          <SectionHeader>Er der også et konkret tidsønske til aftalen?</SectionHeader>
          <Checkbox label="Ja" checked={openDate} disabled={readonlyMode} onChange={handlePressDateCheckmark} />
          {openDate && (
            <CustomerAppointmentDateForm
              initialValue={customerAppointment}
              onChange={handleChange}
              errors={task.appointmentErrors}
            />
          )}
        </>
      )}
    </>
  );
};

export default CustomerAppointmentSection;
