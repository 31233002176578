import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { QuestionDTO, QuestionOptionDTO, QuestionType } from '../../../../api/api';
import { AddIcon } from '../../../../assets/icons/AddIcon';
import { DeleteIcon } from '../../../../assets/icons/DeleteIcon';
import Button from '../../../../components/button/Button';
import IconButton from '../../../../components/icon-button/IconButton';
import Select, { MenuItem } from '../../../../components/select/Select';
import TextField from '../../../../components/text-field/TextField';
import { FormProps } from '../../../../models/CommonProps/FormProps';
import { Section } from '../../../../styling/FormStyling';
import { getEnumDisplayValue } from '../../../../utils/enumUtils';

type ChecklistQuestionFormErrors = { [key in keyof QuestionDTO]: boolean };

const ChecklistQuestionForm = (props: FormProps<QuestionDTO>) => {
  const { initialBody, handleCancel, handleSubmit } = props;
  const [body, setBody] = useState<QuestionDTO>(initialBody ?? {});
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<ChecklistQuestionFormErrors>({});
  const [errorText, setErrorText] = useState('');

  const handleChangeValue = useCallback(
    (key: keyof QuestionDTO, value: any) => {
      setBody({ ...body, [key]: value });
    },
    [body]
  );

  const submitForm = useCallback(() => {
    const newErrors = {
      text: !body.text,
      status: !body.questionType
    } as ChecklistQuestionFormErrors;

    if (Object.values(newErrors).some((value) => value)) {
      setErrors(newErrors);
      return;
    }

    setSubmitting(true);
    handleSubmit?.(body)?.then((res) => {
      setSubmitting(false);
    });
  }, [body, handleSubmit]);

  const handleAddOption = useCallback(() => {
    if (!body.options) handleChangeValue('options', [{ questionOptionOrder: 1 }]);
    else
      handleChangeValue('options', [...body.options, { questionOptionOrder: body.options.length + 1 } as QuestionOptionDTO]);
  }, [body?.options, handleChangeValue]);

  const handleEditOption = useCallback(
    (option: QuestionOptionDTO) => {
      if (!body.options) return;

      handleChangeValue(
        'options',
        body.options.map((o) => (option.questionOptionOrder === o.questionOptionOrder ? option : o))
      );
    },
    [body, handleChangeValue]
  );

  const handleDeleteOption = useCallback(
    (option: QuestionOptionDTO) => {
      const newOptions = body.options
        ?.filter((o) => o.questionOptionOrder !== option.questionOptionOrder)
        .map((o, index) => {
          return { ...o, questionOptionOrder: index + 1 };
        });
      handleChangeValue('options', newOptions);
    },
    [body.options, handleChangeValue]
  );

  useEffect(() => {
    if (body.link?.match(/^http(s)?:\/\//) || !body.link) {
      setErrorText('');
    } else {
      setErrorText('Links skal starte med http:// eller https://');
    }
  }, [body.link]);

  return (
    <Wrapper>
      <Container>
        <StyledTopDiv>
          <TextDiv>
            <TextField
              value={body.text}
              onChange={(e) => handleChangeValue('text', e.target.value)}
              label="Tekst"
              required
              error={errors.text}
              multiline
              fullWidth
            />
          </TextDiv>
          <TextDiv>
            <TextField
              value={body.helpText || ''}
              onChange={(e) => handleChangeValue('helpText', e.target.value)}
              label="Hjælpetekst"
              error={errors.text}
              multiline
              rows="5"
              fullWidth
            />
          </TextDiv>
          <TextDiv>
            <TextField
              value={body.linkText || ''}
              onChange={(e) => handleChangeValue('linkText', e.target.value)}
              label="Label til link"
              error={errors.text}
              multiline
              rows="1"
              fullWidth
            />
          </TextDiv>
          <TextDiv>
            <TextField
              value={body.link || ''}
              onChange={(e) => handleChangeValue('link', e.target.value)}
              label="Link"
              error={errors.text || !!errorText}
              helperText={errorText}
              rows="1"
              fullWidth
            />
          </TextDiv>
          <SelectDiv>
            <Select
              value={body.questionType ?? QuestionType.Text}
              onChange={(e) => handleChangeValue('questionType', e.target.value)}
              label="Type"
              fullWidth
              required
              error={errors.questionType}
            >
              {Object.keys(QuestionType)
                .filter((s) => s !== QuestionType.Header)
                .map((s) => (
                  <MenuItem key={s} value={s}>
                    {getEnumDisplayValue(s as QuestionType)}
                  </MenuItem>
                ))}
            </Select>
          </SelectDiv>
        </StyledTopDiv>
        {body.questionType === QuestionType.SingleChoice && (
          <Section>
            <StyledOptionsSection>
              {body.options &&
                [...body.options]
                  .sort((a: QuestionOptionDTO, b: QuestionOptionDTO) => {
                    if (a.questionOptionText === null || a.questionOptionText === undefined) return 1;
                    if (b.questionOptionText === null || b.questionOptionText === undefined) return -1;
                    return a.questionOptionText.localeCompare(b.questionOptionText);
                  })
                  .map((option) => {
                    return (
                      <StyledOptionRow key={option.questionOptionOrder}>
                        <TextField
                          fullWidth
                          value={option.questionOptionText ?? ''}
                          onChange={(e) => handleEditOption({ ...option, questionOptionText: e.target.value })}
                        />
                        <IconButton onClick={() => handleDeleteOption(option)}>
                          <DeleteIcon margin="5px" size="20px" />
                        </IconButton>
                      </StyledOptionRow>
                    );
                  })}
            </StyledOptionsSection>
            <Button onClick={() => handleAddOption()} variant="secondary">
              <AddIcon size="20px" />
              Tilføj
            </Button>
          </Section>
        )}
        <ButtonContainer>
          <Button variant="secondary" onClick={() => handleCancel && handleCancel()}>
            Annuller
          </Button>
          <Button onClick={() => submitForm()} isLoading={submitting}>
            Gem
          </Button>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  padding: 24px;
  justify-content: flex-end;
  width: 800px;
`;

const Container = styled.div`
  overflow: auto;
  max-height: calc(100vh - 210px);
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextDiv = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const SelectDiv = styled.div`
  width: 200px;
  margin-bottom: 10px;
`;

const StyledOptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 12px;
  margin: 12px 0px;
`;

const StyledTopDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 24px;
`;

const StyledOptionRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export default ChecklistQuestionForm;
