import styled from 'styled-components';
import Hyperlink from '../hyperlink/HyperLink';

interface Props {
  input: JSX.Element;
  link?: string;
  linkText?: string;
}

const QuestionContainer = (props: Props) => {
  const { input, link, linkText } = props;
  return (
    <ControlGroup>
      {input}
      {link && <Hyperlink label={linkText} link={link} />}
    </ControlGroup>
  );
};

const ControlGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0px;
  flex: 1;
  width: 100%;
`;

export default QuestionContainer;
