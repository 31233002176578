import LinksView from '../../../../../blocks/link-table/LinksView';
import { CreateLinkDTO, LinkDTO } from '../../../../../models/LinkModel';
import { TaskTypeExternalResourceDTO } from '../../../../../api/api';
import { TabProps } from '../../../../../blocks/tabs-vertical/TabsVertical';

interface Props extends TabProps {
  links: TaskTypeExternalResourceDTO[];
  setLinks: (links: TaskTypeExternalResourceDTO[]) => void;
}

const TaskTypeExternalLinks = (props: Props) => {
  const { links, setLinks } = props;

  const createLinkHandler = (newLink: CreateLinkDTO) => {
    const nLink = { ...newLink, name: newLink.linkText } as TaskTypeExternalResourceDTO;
    setLinks([...links, nLink]);
  };

  const deleteLinkHandler = (deleteLinkId: number) => {
    const updatedLinks = links.filter((l) => l.id !== deleteLinkId);
    updateOrderHandler(updatedLinks.map((l) => ({ ...l, linkText: l.name } as LinkDTO)));
  };

  const editLinkHandler = (editedLink: LinkDTO) => {
    const updatedLinks = links.map((l) =>
      (editedLink.id !== undefined && l.id === editedLink.id) ||
      (editedLink.id === undefined && l.order === editedLink.order)
        ? {
            ...l,
            ...({
              ...editedLink,
              name: editedLink.linkText ?? l.name
            } as TaskTypeExternalResourceDTO)
          }
        : l
    );

    setLinks(updatedLinks);
  };

  const updateOrderHandler = (_links: LinkDTO[]) => {
    const updatedLinks = _links.map(
      (link, index) => ({ ...link, name: link.linkText, order: index + 1 } as TaskTypeExternalResourceDTO)
    );
    setLinks(updatedLinks);
  };

  return (
    <LinksView
      title="Tilføj links til opgavetypen"
      links={links.map((link) => ({ ...link, linkText: link.name } as LinkDTO))}
      createLinkHandler={createLinkHandler}
      deleteLinkHandler={deleteLinkHandler}
      editLinkHandler={editLinkHandler}
      updateOrderHandler={updateOrderHandler}
    />
  );
};

export default TaskTypeExternalLinks;
