import { ToastModel } from '../models/Toast';
import { addToast } from '../stateManagement/reducers/notificationReducer';
import { store } from '../stateManagement/store';

const showToast = (toast: ToastModel) => {
  store.dispatch(addToast({ toast }));
};

const success = (text: string, duration: number = 5000) => {
  store.dispatch(addToast({ toast: { text, severity: 'success', duration } }));
};

const error = (text: string, duration: number = 5000) => {
  store.dispatch(addToast({ toast: { text, severity: 'error', duration } }));
};

const warning = (text: string, duration: number = 5000) => {
  store.dispatch(addToast({ toast: { text, severity: 'warning', duration } }));
};

const NotificationService = {
  showToast,
  success,
  error,
  warning
};

export default NotificationService;
