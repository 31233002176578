import { RefObject, useEffect, useState } from 'react';

const useIsTruncated = (ref: RefObject<any>) => {
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setIsTruncated(ref.current.scrollHeight - ref.current.offsetHeight > 1);
    }
  }, [ref]);

  return isTruncated;
};

export default useIsTruncated;
