import assignRef, { ReactRef } from '../assign-ref/assign-ref';

/**
 * Combine multiple React refs into a single ref function.
 *
 * @param refs the refs to assign to values to
 */
export const mergeRefs = <T>(...refs: (ReactRef<T> | undefined)[]) => {
  return (node: T | null) => {
    refs.forEach((ref) => assignRef(ref, node));
  };
};

export default mergeRefs;
