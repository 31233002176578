import { Button } from "@mui/material";
import Avatar from "../avatar/Avatar";
import styled from "styled-components";
import { getUserInitials } from "../../utils/stringHandling";
import Typography from "../typography/Typography";

interface Props {
  name?: string;
}

const Person = (props: Props) => {
  const { name } = props;
  return (
    <Container>
      <StyledButton variant="text">
        <Avatar text={getUserInitials(name)} size={26} fontSize={12} />
        <Name variant="h6">
          {name}
        </Name>
      </StyledButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`
const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Name = styled(Typography)`
  position: relative;
  top: 2px;
  margin-left: ${(props) => props.theme.spacing(1)};
  text-transform: capitalize;
`
export default Person;
