import { CreateNewsDTO, UpdateNewsDTO } from '../api/api';
import WorkClient from '../api/workClient';

const getAllNews = () => {
  return WorkClient.getAll();
};

const getUserNews = () => {
  return WorkClient.getByUser();
};

const getNumberOfUnreadNews = () => {
  return WorkClient.getUnreadTotal();
};

const updateRead = (newsId: number) => {
  return WorkClient.readNews(newsId);
};

const createNews = (body: CreateNewsDTO) => {
  return WorkClient.createNews(body);
};

const updateNews = (body: UpdateNewsDTO) => {
  return WorkClient.updateNews(body);
};
const deleteNews = (id: number) => {
  return WorkClient.deleteNews(id);
};

const NewsService = {
  getAllNews,
  getUserNews,
  getNumberOfUnreadNews,
  updateRead,
  createNews,
  updateNews,
  deleteNews
};

export default NewsService;
