import WorkClient from '../api/workClient';
import { CreateInternalTaskDTO, WorkTaskStatusDTO } from '../api/api';

const getInternalTasks = () => {
  return WorkClient.internalTasksAll();
};

const getPickupLocations = () => {
  return WorkClient.getPickupLocations();
};

const setInternalTaskStatus = (tasks: WorkTaskStatusDTO[]) => {
  return WorkClient.setInternalTaskStatus(tasks);
};
const sendInternalTasksToPlanning = (tasks: number[]) => {
  return WorkClient.sendInternalTasksToPlanning(tasks);
};

const createInternalTask = (body?: CreateInternalTaskDTO) => {
  return WorkClient.internalTasks(body);
};

const getNumberOfInternalTaskInCreated = () => {
  return WorkClient.numberOfStatusCreatedInternalTasks();
};

const InternalTaskService = {
  getInternalTasks,
  setInternalTaskStatus,
  sendInternalTasksToPlanning,
  getPickupLocations,
  createInternalTask,
  getNumberOfInternalTaskInCreated
};

export default InternalTaskService;
