import styled from 'styled-components';
import Typography from '../../../components/typography/Typography';
import { StyledTable } from './ReturnGoodsViewContent';
import { TransferOrderDTO } from '../../../api/api';
import useTransferOrderList from './useTransferOrderList';

interface Props {
  returnOrder: TransferOrderDTO;
}

const Receipt = (props: Props) => {
  const { returnOrder } = props;

  const { tableInstance } = useTransferOrderList(returnOrder.items ?? []);

  return (
    <>
      <ReceiptContainer>
        <Typography fontWeight="bold" variant="h4">
          Returordre registreret
        </Typography>
        <List>
          <ListItems>1. Pak varerne i en kasse</ListItems>
          <ListItems>2. Skriv ordreID på kassen (se nedenfor).</ListItems>
          <ListItems>3. Bekræft at varer er afleveret på returpallen.</ListItems>
        </List>
        <div>
          <Typography variant="h4">
            OrdreID: <strong>{returnOrder.transferId ?? 'Afventer D365'}</strong>
          </Typography>
        </div>
        <Typography variant="h5" margin="20px 0px" fontWeight="bold">
          Din returordre
        </Typography>
        <StyledTable loading={false} noPadding tableInstance={tableInstance} />
      </ReceiptContainer>
    </>
  );
};

const ReceiptContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  align-items: flex-start;
  padding: ${(props) => props.theme.spacing(7.5)};
  background-color: ${(props) => props.theme.palette.background.primary};
  overflow-y: scroll;
`;

const List = styled.ul`
  list-style: none;
  margin: ${(props) => props.theme.spacing(7.5)} 0;
  padding: 0;
  text-align: start;
`;

const ListItems = styled.li`
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;

export default Receipt;
