import styled from 'styled-components';
import Typography from '../typography/Typography';
import { selectUserProfile } from '../../stateManagement/reducers/userProfileReducer';
import { useSelector } from 'react-redux';
import { getEnumDisplayValue } from '../../utils/enumUtils';

const EnviromentIndicator = () => {
  const userRole = useSelector(selectUserProfile).userProfile.role;

  return (
    <>
      {(window._env_.ENVIRONMENT === 'Local' ||
        window._env_.ENVIRONMENT === 'Dev' ||
        window._env_.ENVIRONMENT === 'Test') && (
        <Container>
          <Typography variant="h2" color="inherit" fontWeight="regular">
            {window._env_.ENVIRONMENT}: {userRole && getEnumDisplayValue(userRole)}
          </Typography>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  position: fixed;
  pointer-events: none;
  margin-left: 50vw;
  margin-top: 75vh;
  z-index: ${(props) => props.theme.zIndex.enviromentIndicator};
  color: ${(props) => props.theme.palette.functions.warning.primary};
`;

export default EnviromentIndicator;
