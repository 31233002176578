import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Checkbox from '../../../../components/checkbox/Checkbox';
import DatePicker from '../../../../components/date-picker/DatePicker';
import TextField from '../../../../components/text-field/TextField';
import { formatDateString } from '../../../../utils/dateHandling';
import CircularProgress from '../../../../components/circular-progress/CircularProgress';

export type InputTypes = 'text' | 'date' | 'multilineText' | 'checkbox' | 'number' | 'email' | 'link';

interface Props {
  label: string;
  value: string;
  type?: InputTypes;
  inputMode?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  isDirty?: (key: string, value: boolean) => void;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  hideLabel?: boolean;
  helperText?: string;
  isLoading?: boolean;
  dataTestId?: string;
}

const TextRow = (props: Props) => {
  const {
    label,
    type,
    inputMode: inputState,
    isDirty,
    disabled,
    required,
    error,
    hideLabel,
    helperText,
    isLoading,
    dataTestId
  } = props;

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(props.value);
  }, [inputState, props.value]);

  const onChange = (text: string) => {
    props.onChange && props.onChange(text);
    setValue(text);

    if (isDirty) {
      isDirty(label, text !== props.value);
    }
  };

  const onBlur = (text: string) => {
    props.onBlur && props.onBlur(text);
  };

  switch (type) {
    case 'link':
      return inputState ? (
        <RowBase>
          <TextField
            label={label}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={(e) => onBlur(e.target.value)}
            disabled={disabled}
            fullWidth
            required={required}
            error={error}
            helperText={helperText}
            data-testid={dataTestId}
          />
        </RowBase>
      ) : (
        <RowBase>
          {!hideLabel && <RowLabel>{label}</RowLabel>}
          {isLoading ? (
            <CircularProgress position="absolute" margin="0" size="1rem" />
          ) : (
            <RowValue>
              <StyledLink onClick={() => window.open(value, '_blank', 'noopener,noreferrer')}>{value}</StyledLink>
            </RowValue>
          )}
        </RowBase>
      );

    case 'multilineText':
      return inputState ? (
        <RowBase>
          <TextField
            label={label}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={(e) => onBlur(e.target.value)}
            disabled={disabled}
            fullWidth
            multiline
            required={required}
            error={error}
            helperText={helperText}
            data-testid={dataTestId}
          />
        </RowBase>
      ) : (
        <RowBase>
          {!hideLabel && <RowLabel>{label}</RowLabel>}
          {isLoading ? (
            <CircularProgress position="absolute" margin="0" size="1rem" />
          ) : (
            <RowValue preWrap>{value}</RowValue>
          )}
        </RowBase>
      );

    case 'date':
      return inputState ? (
        <DatePicker
          onDateChanged={(date) => onChange(date.toISOString())}
          value={formatDateString(value)}
          label={label}
          disabled={disabled}
        />
      ) : (
        <RowBase>
          {!hideLabel && <RowLabel>{label}</RowLabel>}
          {isLoading ? (
            <CircularProgress position="absolute" margin="0" size="1rem" />
          ) : (
            <RowValue>{formatDateString(value as string)}</RowValue>
          )}
        </RowBase>
      );
    case 'checkbox':
      return inputState ? (
        <RowBase>
          <Checkbox label={label} checked={value === 'true'} onChange={(e) => onChange(e.target.checked.toString())} />
        </RowBase>
      ) : (
        <RowBase>
          {!hideLabel && <RowLabel>{label}</RowLabel>}
          {isLoading ? (
            <CircularProgress position="absolute" margin="0" size="1rem" />
          ) : (
            <RowValue>{value === 'true' ? 'Ja' : 'Nej'}</RowValue>
          )}
        </RowBase>
      );
    default:
      return inputState ? (
        <RowBase>
          <TextField
            label={label}
            value={value}
            type={type}
            onChange={(e) => onChange(e.target.value)}
            onBlur={(e) => onBlur(e.target.value)}
            disabled={disabled}
            fullWidth
            required={required}
            error={error}
            helperText={helperText}
            dataTestId={dataTestId}
          />
        </RowBase>
      ) : (
        <RowBase>
          {!hideLabel && <RowLabel>{label}</RowLabel>}
          {isLoading ? <CircularProgress position="absolute" margin="0" size="1rem" /> : <RowValue>{value}</RowValue>}
        </RowBase>
      );
  }
};

export const RowBase = styled.div`
  display: flex;
  width: 100%;
  column-gap: 1rem;
`;

export const RowLabel = styled.span`
  flex: 1;
  color: ${(props) => props.theme.palette.grey.black40};
  font-size: 14px;
`;

export const RowValue = styled.span<{ preWrap?: boolean }>`
  flex: 2;
  overflow-wrap: anywhere;
  color: ${(props) => props.theme.palette.main.black.primary};
  font-size: 16px;
  white-space: ${(props) => props.preWrap && 'pre-wrap'};
`;

const StyledLink = styled.div`
  color: ${(props) => props.theme.palette.functions.action.secondary};
  &:hover {
    cursor: pointer;
  }
`;

export default TextRow;
