import { memo } from 'react';
import { CSVLink } from 'react-csv';
import { Headers } from 'react-csv/components/CommonPropTypes';
import styled from 'styled-components';
import { DownloadIcon } from '../../../assets/icons/DownloadIcon';
import Button from '../../../components/button/Button';

interface Props<T extends object> {
  data: T[];
  headers: Headers;
  fileNameFunction: () => string;
}

const ExcelExportButton = <T extends object>(props: Props<T>) => {
  const { data, headers, fileNameFunction } = props;

  return (
    <StyledCSVLink separator={';'} data={data} headers={headers} filename={fileNameFunction()}>
      <StyledButton variant="secondary">
        <DownloadIcon size="16px" zIndex={0} />
        Eksporter
      </StyledButton>
    </StyledCSVLink>
  );
};

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none;
  display: flex;
  column-gap: 12px;
`;
const StyledButton = styled(Button)`
  && {
    height: 37px;
    padding: 8px;
    column-gap: 12px;
    border-radius: 8px;
  }
  ${(props) => ({ ...props.theme.typography.p })};
  font-weight: 700;
`;

export default memo(ExcelExportButton, <T extends object>(prevProps: Props<T>, props: Props<T>) => {
  return prevProps.data.length === props.data.length && prevProps.headers?.length === props.headers?.length;
});
