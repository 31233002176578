import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useState } from 'react';
import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';
import { DialogBody } from '../../stateManagement/reducers/confirmDialogReducer';
import TabsVertical from '../../blocks/tabs-vertical/TabsVertical';
import CreateTaskDialogHeader from './MassCreationTaskViewHeader';
import DialogView from '../../components/dialog-view/DialogView';
import Button from '../../components/button/Button';
import Alert from '../../components/alert/Alert';
import DetailsStep from '../create-task-view/details-step/DetailsStep';
import FileStep from './file-step/FileStep';
import TaskTypeStepDetails from '../create-task-view/task-type-step/TaskTypeStep';
import { validateMassCreationBaseFields } from '../../utils/validation/taskCreationValidation';
import { validateDynamicField } from '../../utils/validation/taskComponentAttributeValidation';
import NotificationService from '../../services/NotificationService';
import TaskService from '../../services/TaskService';
import { TASKS_ROUTE } from '../../utils/constants';
import { setListShouldUpdate } from '../../stateManagement/reducers/taskListReducer';
import { log } from '../../utils/logging/log';
import {
  MassCreationTypes,
  massCreation,
  resetMassCreation,
  setShowResultsTable,
  updateMassCreationTask
} from '../../stateManagement/reducers/massCreationReducer';
import IconButton from '../../components/icon-button/IconButton';
import { Typography } from '@mui/material';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';

const MassCreationTaskView = () => {
  const { task, massCreationType } = useSelector(massCreation);

  const [activePanelId, setActivePanelId] = useState<string>('1');
  const [isLoading, setIsLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [invalidCsv, setInvalidCsv] = useState(true);
  const showResults = useSelector(massCreation).showResultsTable;

  const { getConfirmation } = useConfirmationDialog();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseDialog = async () => {
    const confirmation = await getConfirmation(dialogBody);
    if (confirmation === 'confirm') {
      navigate(-1);
      dispatch(resetMassCreation());
    }
  };

  const createTask = (event: any) => {
    event.stopPropagation();
    if (!task) return;

    let hasErrors = false;
    const baseErrors = validateMassCreationBaseFields(task);
    dispatch(updateMassCreationTask({ baseErrors }));
    const dynamicFieldsErrors = task.components?.some((c) => c.attributes?.some((a) => !validateDynamicField(a)));
    dispatch(updateMassCreationTask({ dynamicFieldsErrors }));
    hasErrors = dynamicFieldsErrors || Object.values(baseErrors).some((value) => value);

    if (hasErrors) {
      NotificationService.error('Du mangler at udfylde et felt');
      return;
    }

    setIsLoading(true);

    let massCreationService;

    if (Number(massCreationType) === MassCreationTypes.ASSET) {
      massCreationService = TaskService.massCreateWorkTasks(task);
    } else if (Number(massCreationType) === MassCreationTypes.STRETCH) {
      massCreationService = TaskService.massCreateStretchWorkTasks(task);
    } else {
      log('MassCreationType not defined.');
      return;
    }
    massCreationService
      .then(() => {
        dispatch(resetMassCreation());
        dispatch(setListShouldUpdate(true));
        navigate(TASKS_ROUTE);

        NotificationService.success(`Masseoprettede ${task.requestItems?.length ?? task.stretchItems?.length} opgaver.`);
      })
      .catch((error) => {
        setWarningMessage('Der opstod en fejl i oprettelsen.');
        log(error);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(resetMassCreation());
      });
  };

  if (!task) return null;

  return (
    <DialogView handleClose={handleCloseDialog}>
      <StyledDialogContent>
        <TabsVertical
          headerComponent={
            <CreateTaskDialogHeader
              activeStep={Number(activePanelId)}
              handleStepChange={(id: string) => !invalidCsv && setActivePanelId(id)}
            />
          }
          activePanelId={activePanelId}
          handleActivePanelIdChange={(id: string) => setActivePanelId(id)}
          onClose={handleCloseDialog}
        >
          <FileStep
            disableNextButtonHandler={(value) => setInvalidCsv(value)}
            tabId="1"
            contentHeaderText={
              <>
                {showResults ? (
                  <>
                    <IconButton onClick={() => dispatch(setShowResultsTable(false))}>
                      <ChevronLeft size="16px" />
                    </IconButton>
                    <Typography variant="h5">Output</Typography>
                  </>
                ) : (
                  <Typography variant="h5">Masseoprettelse</Typography>
                )}
              </>
            }
            task={task}
            tabHeaderText="Fil"
            footerComponent={
              <Footer>
                <Button disabled={invalidCsv} onClick={() => setActivePanelId('2')}>
                  Næste
                </Button>
              </Footer>
            }
          />
          <TaskTypeStepDetails
            isMassCreation
            task={task}
            tabId="2"
            hidden={invalidCsv}
            contentHeaderText={<h2>Masseoprettelse</h2>}
            footerComponent={
              <Footer>
                <Button onClick={() => setActivePanelId('3')}>Næste</Button>
              </Footer>
            }
            tabHeaderText="Arbejdstype"
          />
          <DetailsStep
            isMassCreation
            tabId="3"
            contentHeaderText={<h2>Masseoprettelse</h2>}
            hidden={invalidCsv}
            footerComponent={
              <Footer>
                {warningMessage && (
                  <Alert severity="error" height="42px">
                    {warningMessage}
                  </Alert>
                )}
                <CreateTaskButton isLoading={isLoading} onClick={createTask} data-testid="confirm-task-button">
                  Opret opgave
                </CreateTaskButton>
              </Footer>
            }
            tabHeaderText="Detaljer"
            task={task}
          />
        </TabsVertical>
      </StyledDialogContent>
    </DialogView>
  );
};

const dialogBody: DialogBody = {
  headerText: 'Vil du slette oprettelse af nyt arbejdskort?',
  bodyText: 'Bemærk, hvis du sletter arbejdskortet, fjernes alle indtastede værdier',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft sletning'
};

const StyledDialogContent = styled.div`
  && {
    padding: 0;
    height: 80vh;
    width: 60vw;
    max-width: 1200px;
    overflow: hidden;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CreateTaskButton = styled(Button)`
  margin-left: 20px;
`;

export default MassCreationTaskView;
