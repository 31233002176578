import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TaskComponentDTO } from '../../../../../api/api';
import { CheckmarkIcon } from '../../../../../assets/icons/CheckmarkIcon';
import { DeleteIcon } from '../../../../../assets/icons/DeleteIcon';
import { TabProps } from '../../../../../blocks/tabs-vertical/TabsVertical';
import IconButton from '../../../../../components/icon-button/IconButton';
import TextField from '../../../../../components/text-field/TextField';

import { FormDialogContainer, Main } from '../../AdminTaskTypesView';
import Section from './components/Section';
import Button from '../../../../../components/button/Button';

interface Props extends TabProps {
  sections: TaskComponentDTO[];
  addSection: (section: TaskComponentDTO) => void;
  setActiveSection: (section: TaskComponentDTO) => void;
  removeSection: (section: TaskComponentDTO) => void;
  setSections: (section: TaskComponentDTO[]) => void;
}

const Sections = (props: Props) => {
  const { sections, addSection, setActiveSection, removeSection, setSections } = props;
  const [addingSection, setAddingSection] = useState(false);
  const [newSectionName, setNewSectionName] = useState('');
  const myRef = useRef<null | HTMLFormElement>(null);

  const createNewSection = (e: any) => {
    e.preventDefault();

    const id = Math.random() * 1_000_000; //Only temporary ID. The section will get a new ID when it is saved and added to the DB.

    addSection({
      id,
      title: newSectionName,
      order: 0,
      attributes: []
    });

    reset();
  };

  const reset = () => {
    setNewSectionName('');
    setAddingSection(false);
  };

  const setSection = (section: TaskComponentDTO) => {
    setSections(sections.map((s) => (s.id === section.id ? section : s)));
  };

  useEffect(() => {
    if (addingSection) myRef.current?.scrollIntoView();
  }, [addingSection]);

  return (
    <Main>
      <FormDialogContainer>
        {sections.map((section: TaskComponentDTO) => (
          <Section
            key={section.id}
            id={section.id}
            title={section.title}
            order={section.order}
            attributes={section.attributes}
            setActiveSection={() => setActiveSection(section)}
            removeSection={() => removeSection(section)}
            setSection={(section) => setSection(section)}
          />
        ))}
        {addingSection ? (
          <Form ref={myRef} onSubmit={(e) => createNewSection(e)}>
            <StyledTextField
              label="Sektionsnavn"
              required
              autoFocus
              value={newSectionName}
              onChange={(e) => setNewSectionName(e.target.value)}
            />
            <ButtonContainer>
              <StyledIconButton disabled={!newSectionName} onClick={(e) => createNewSection(e)}>
                <CheckmarkIcon size="18px" />
              </StyledIconButton>
              <StyledIconButton onClick={reset}>
                <DeleteIcon size="18px" />
              </StyledIconButton>
            </ButtonContainer>
          </Form>
        ) : (
          <Button variant="secondary" onClick={() => setAddingSection(true)}>
            Tilføj sektion
          </Button>
        )}
      </FormDialogContainer>
    </Main>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  padding: 10px;
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const StyledTextField = styled(TextField)`
  width: 50%;
`;
const StyledIconButton = styled(IconButton)`
  padding: 10px 10px;
`;

export default Sections;
