export const msalConfig = {
  auth: {
    clientId: window._env_.AUTH_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/a6230a1c-393a-4c9e-9938-a643402658d9',
    redirectUri: window._env_.REACT_APP_FRONTEND_URL,
    postLogoutRedirectUri: window._env_.AUTH_LOGOUT_URL
  },
  system: {
    allowNativeBroker: false
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [window._env_.AUTH_CLIENT_ID + '/.default']
};
