import removeQueryParams from './remove-query-params';

export const replaceQueryParams = (
  paramsFrom: string | string[],
  subpathFrom?: string,
  paramsTo?: { [key: string]: any },
  subpathTo?: string
): string => {
  const basePath = removeQueryParams(paramsFrom, subpathFrom);

  const urlSearchParams = new URLSearchParams();

  if (paramsTo) {
    Object.keys(paramsTo).forEach((paramTo) => {
      urlSearchParams.set(paramTo, paramsTo[paramTo]);
    });
  }

  return `${basePath}${subpathTo ? subpathTo : ''}${urlSearchParams.toString() !== '' ? '?' : ''}${urlSearchParams}`;
};

export default replaceQueryParams;
