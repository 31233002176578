import { useEffect, useState } from 'react';
import DialogView from '../../../components/dialog-view/DialogView';
import TextField from '../../../components/text-field/TextField';
import Button from '../../../components/button/Button';
import styled from 'styled-components';
import { DialogContent } from '@mui/material';
import Typography from '../../../components/typography/Typography';
import IconButton from '../../../components/icon-button/IconButton';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import { LinkDTO, CreateLinkDTO } from '../../../models/LinkModel';
import { linkIncludesHttp } from '../../../utils/linkValidation';

interface Props {
  closeDialog: () => void;
  createLinkHandler: (link: CreateLinkDTO) => void;
  editLinkHandler: (link: LinkDTO) => void;
  allPortalLinks: LinkDTO[];
  editingLink?: LinkDTO;
}

const CreateEditLinkForm = (props: Props) => {
  const { closeDialog, createLinkHandler, editLinkHandler, editingLink } = props;
  const [linkText, setLinkText] = useState('');
  const [link, setLink] = useState('');
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    setLink(editingLink?.url ?? '');
    setLinkText(editingLink?.linkText ?? '');
  }, [editingLink]);

  useEffect(() => {
    if (!link || linkIncludesHttp(link)) {
      setErrorText('');
    } else {
      setErrorText('Links skal starte med http:// eller https://');
    }
  }, [link]);

  const closeDialogHandler = () => {
    setLink('');
    setLinkText('');
    closeDialog();
  };

  const handleClick = () => {
    if (editingLink) {
      editLinkHandler({ ...editingLink, linkText, url: link });
    } else {
      createLinkHandler({ linkText, url: link } as LinkDTO);
    }
  };
  return (
    <DialogView handleClose={closeDialogHandler}>
      <Header>
        <Typography variant="h4">{editingLink ? 'Opdater link' : 'Opret nyt link'}</Typography>
        <RoundIconButton onClick={closeDialogHandler}>
          <CloseIcon size="12px" />
        </RoundIconButton>
      </Header>
      <StyledDialogContent>
        <TextField label="Navn" fullWidth onChange={(e) => setLinkText(e.target.value)} value={linkText} required />
        <TextField
          label="URL"
          fullWidth
          helperText={errorText}
          error={!!errorText}
          onChange={(e) => setLink(e.target.value)}
          value={link}
          required
        />
      </StyledDialogContent>
      <StyledDialogFooter>
        <Button onClick={handleClick}>{editingLink ? 'Opdater link' : 'Opret link'}</Button>
      </StyledDialogFooter>
    </DialogView>
  );
};

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50vw;
  height: 150px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.spacing(6)};
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.black5};
  border-radius: 0 !important;
`;

const StyledDialogFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${(props) => props.theme.spacing(4)} ${(props) => props.theme.spacing(6)};
  border-top: 1px solid ${(props) => props.theme.palette.grey.black5};
  border-radius: 0 !important;
`;

const RoundIconButton = styled(IconButton)`
  border: 1px solid ${(props) => props.theme.palette.grey.black20};
`;

export default CreateEditLinkForm;
