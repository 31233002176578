import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface DialogBody {
  headerText?: string;
  bodyText?: string;
  confirmButtonText?: string;
  declineButtonText?: string;
}

interface ConfirmDialogState {
  display?: boolean;
  content?: DialogBody;
  locked?: boolean;
}

const initialState: ConfirmDialogState = {
  display: false,
  locked: false,
  content: {
    headerText: 'Bekræft venligst',
    bodyText: 'Er du sikker på, at du vil udføre denne handling?',
    confirmButtonText: 'Bekræft',
    declineButtonText: 'Afvis'
  }
};

const confirmDialogSlice = createSlice({
  name: 'confirmDialog',
  initialState: initialState,
  reducers: {
    displayConfirmDialog: (state: ConfirmDialogState, action: PayloadAction<boolean>) => {
      state.display = action.payload;
    },
    getConfirmationDialog: (
      state: ConfirmDialogState,
      action: PayloadAction<{ dialogBody: DialogBody; locked: boolean }>
    ) => {
      state.display = true;
      state.locked = action.payload.locked;
      state.content = { ...state.content, ...action.payload.dialogBody };
    }
  }
});

export const { displayConfirmDialog, getConfirmationDialog } = confirmDialogSlice.actions;
export const selectConfirmDialogState = (state: RootState) => state.confirmDialog;

export default confirmDialogSlice.reducer;
