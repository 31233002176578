import { getInitialsFromEmail } from "./initialsUtils";

export const getFileType = (text: string) => {
  let array = text.split('.');
  return array[array.length - 1].toLowerCase();
};

export const isImageType = (type?: string) => {
  return type ? type.includes('image') : false;
};

export const getUserInitials = (userName?: string): string => {
  if (userName) {
    const names = userName.split(' ').filter((name) => name.charAt(0) !== '(');

    if (!!names?.length) {
      const firstLetter = names[0].charAt(0);
      const secondLetter = names[names.length - 1].charAt(0);

      return firstLetter + secondLetter;
    }
  }

  return 'NN';
};


export const getCreatedByText = (name?: string, email?: string) => {
  let createdByString = name;
  let initials = getInitialsFromEmail(email ?? '');
  if (createdByString && initials) {
    createdByString = name + ' (' + initials + ')';
  } else if (initials) {
    createdByString = initials;
  }
  return createdByString ?? '-';
};