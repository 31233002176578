import { memo, useMemo } from 'react';
import { AssetType2 } from '../../api/api';
import { getEnumDisplayValue, mapEnum } from '../../utils/enumUtils';
import Select, { MenuItem } from '../select/Select';

interface Props {
  value?: AssetType2;
  assetTypeChange: (value: any) => void;
  removeAssetOption?: AssetType2[];
  disabled?: boolean;
  showNothingSelectedValue?: boolean;
}

export type DropdownItem = {
  label: string;
  value: AssetType2;
};

const AssetTypeDropdown = (props: Props) => {
  const { value, assetTypeChange, removeAssetOption, disabled, showNothingSelectedValue } = props;

  const dropdownMenu = useMemo(() => {
    const dropdownOptions = mapEnum(AssetType2)
      .filter((type) => {
        return !removeAssetOption?.includes(type as AssetType2);
      })
      .map((type) => {
        return {
          label: getEnumDisplayValue(AssetType2[type]),
          value: type
        } as DropdownItem;
      });

    // Return the dropdown list sorted
    return dropdownOptions.sort((a, b) => a.label.localeCompare(b.label));
  }, [removeAssetOption]);

  return (
    <Select
      data-testid="asset-type-dropdown"
      id="type"
      fullWidth
      value={value}
      onChange={assetTypeChange}
      variant="outlined"
      disabled={disabled}
      displayEmpty
    >
      {showNothingSelectedValue && <MenuItem value={undefined}>Intet valgt</MenuItem>}
      {dropdownMenu.map((item: DropdownItem) => {
        return (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default memo(AssetTypeDropdown);
