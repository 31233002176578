import styled from 'styled-components';

import Table from '../../../blocks/table/TableClient';
import Button from '../../../components/button/Button';
import TextField from '../../../components/text-field/TextField';

const headerHeight = '67px';
const rowGapSum = '24px';

export const Container = styled.div<{ scroll?: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: 24px;
  height: 100%;
  min-width: 900px;
  overflow-x: hidden;
  overflow-y: ${(props) => (props.scroll ? 'scroll' : 'hidden')};
  background-color: white;
  padding-bottom: ${(props) => props.theme.spacing(8)};
`;

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 24px 24px;
  margin: 0 -24px;

  border-bottom: 1px solid ${(props) => props.theme.palette.grey.black10};
`;

export const DialogContent = styled.div`
  padding: 0 24px 16px 24px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  height: calc(100% - ${headerHeight} - ${rowGapSum});
`;

export const DialogFooter = styled.div`
  display: flex;
  width: 100%;
  margin-top: auto;
`;

export const StyledTable = styled(Table)`
  &.table-outer-container {
    margin: 0;
    padding: 0;
  }
  .table-content-container {
    margin: 0;
    padding: 0;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: auto;
  margin-left: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing(6)};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
`;

export const StyledTextField = styled(TextField)`
  width: 40%;
`;
