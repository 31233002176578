export const addQueryParams = (pathname?: string, params?: { [key: string]: any }, subpath?: string): string => {
  const _pathname = pathname ?? window.location.pathname;
  const { search } = window.location;

  const urlSearchParams = new URLSearchParams(search);

  if (params) {
    Object.keys(params).forEach((param) => {
      urlSearchParams.set(param, params[param]);
    });
  }

  return `${_pathname !== '/' ? _pathname : ''}${subpath ? subpath : ''}${
    urlSearchParams.toString() !== '' ? '?' : ''
  }${urlSearchParams}`;
};

export default addQueryParams;
