import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProductDTO, ProductDetailDTO, UpdateStockDTO } from '../../../api/api';
import { selectUserProfile } from '../../../stateManagement/reducers/userProfileReducer';
import styled from 'styled-components';
import Button from '../../../components/button/Button';
import Link from '../../../components/link/Link';
import Typography from '../../../components/typography/Typography';
import { SelectedGoods } from '../../../models/Goods';
import GoodsService from '../../../services/GoodsService';
import NotificationService from '../../../services/NotificationService';
import { log } from '../../../utils/logging/log';
import NavigationHeader from '../components/NavigationHeader';
import GoodsList from '../order-goods-dialog/goods-list/GoodsList';
import SelectedGoodsBadge from '../components/SelectedGoodsBadge';
import LoadingOverlay from '../../../components/loading-overlay/LoadingOverlay';
import BasketPopover from '../components/BasketPopover';
import BasicPopover from '../../../components/popover/Popover';

import CountGoodsList from '../components/CountGoodsList';
import { DialogBody } from '../../../stateManagement/reducers/confirmDialogReducer';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import {
  resetLastUpdated,
  selectGoods,
  selectGoodsLastUpdated,
  selectInitialGoods,
  setGoods,
  setInitialGoods
} from '../../../stateManagement/reducers/countGoodsReducer';

interface Props {
  setPage: (page: string) => void;
  page: string;
  onClose?: () => void;
}

const CountGoodsViewContent = (props: Props) => {
  const { setPage, page, onClose } = props;

  const [completeGoodsList, setCompleteGoodsList] = useState<ProductDetailDTO[]>([]);
  const [selectedGoods, setSelectedGoods] = useState<SelectedGoods[]>([]);
  const { getConfirmation } = useConfirmationDialog();

  const goods = useSelector(selectGoods);
  const initialGoods = useSelector(selectInitialGoods);
  const lastUpdated = useSelector(selectGoodsLastUpdated);

  const [loading, setLoading] = useState(false);
  const vehicle = useSelector(selectUserProfile).userProfile.vehicle;
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    GoodsService.getAllProducts()
      .then((products) => {
        setCompleteGoodsList(products);
      })
      .catch((error) => {
        log(error);
        NotificationService.error('Kunne ikke hente vareliste');
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!vehicle?.legalEntityId || !vehicle.vehicleId || lastUpdated) return;
    setLoading(true);
    GoodsService.getItemsOnHand3(vehicle?.legalEntityId, vehicle?.vehicleId, vehicle?.defaultIssueLocationId)
      .then((res) => {
        const items = res.map((s) => {
          return {
            itemId: s.product?.itemId,
            name: s.product?.name,
            serialNumber: s.product?.serialNumber,
            batchNumber: s.product?.batchNumber,
            qty: s.available,
            checked: false
          };
        });
        dispatch(setInitialGoods(items));
        dispatch(setGoods(items));
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Bilens lager kunne ikke hentes');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, goods, initialGoods, lastUpdated, vehicle]);

  const handleSelectGoods = useCallback(
    (goodsId?: string) => {
      if (!goodsId) return;
      var existingGood = goods.find((g) => g.itemId === goodsId);
      if (existingGood) {
        NotificationService.warning(`${existingGood.name} er allerede registreret`);
        return;
      }
      const { name, itemId } = completeGoodsList.find((good) => good?.itemId === goodsId) ?? {};
      const goodsToAdd: SelectedGoods = { name, itemId, qty: 0, checked: false };
      setSelectedGoods((prevState) => [...prevState, goodsToAdd]);
      NotificationService.success(`${name} registreret`);

      dispatch(setGoods([...goods, goodsToAdd]));
    },
    [completeGoodsList, dispatch, goods]
  );

  const handleOnClose = async () => {
    if (!onClose) return;
    if (isDirty) {
      const dialogBody: DialogBody = {
        headerText: 'Er du sikker på, at du vil lukke vareoptælling?',
        bodyText: 'Vareoptællingen er ikke registreret før du trykker på “Registrer varer” i bunden af siden',
        declineButtonText: 'Fortryd',
        confirmButtonText: 'Ja'
      };

      const confirmation = await getConfirmation(dialogBody);
      if (confirmation === 'confirm') {
        onClose();
      }
    } else {
      onClose();
    }
  };

  const removeSelectedGoods = (goodsId?: string) => {
    if (!goodsId) return;
    setSelectedGoods((prevState) => [...prevState].filter((good) => good.itemId !== goodsId));
    dispatch(setGoods(goods.filter((good) => good.itemId !== goodsId)));
  };

  const updateGoodQuantity = (goodsId: string, value: number) => {
    const updatedGoodsArray = goods.map((g) => {
      if (g.itemId === goodsId) {
        return { ...g, qty: value };
      } else {
        return g;
      }
    });

    dispatch(setGoods(updatedGoodsArray));
  };

  const handleCheckGood = (goodsId: string, checked: boolean) => {
    dispatch(setGoods(goods.map((good) => (good.itemId === goodsId ? { ...good, checked } : good))));
  };

  const reset = async () => {
    const dialogBody: DialogBody = {
      headerText: 'Er du sikker på, at du vil nulstille vareoptælling?',
      declineButtonText: 'Fortryd',
      confirmButtonText: 'Ja'
    };

    const confirmation = await getConfirmation(dialogBody);

    if (confirmation === 'confirm') {
      dispatch(setGoods(initialGoods));
      dispatch(resetLastUpdated());
    }
  };

  const submit = () => {
    if (!vehicle || !vehicle?.legalEntityId || !vehicle?.vehicleId) {
      NotificationService.error('Der er sket en fejl i registeringen af vareoptællingen', 5000);
      return;
    }

    const countedStock = goods.map((s) => {
      return {
        product: {
          name: s.name,
          itemId: s.itemId,
          serialNumber: s.serialNumber,
          batchNumber: s.batchNumber
        } as ProductDTO,
        quantity: s.qty
      } as UpdateStockDTO;
    });

    GoodsService.updateWarehouseStock(
      vehicle?.legalEntityId,
      vehicle?.vehicleId,
      countedStock,
      vehicle?.defaultIssueLocationId
    )
      .then(() => {
        NotificationService.success('Vareoptællingen er registeret', 5000);
        dispatch(resetLastUpdated());
        onClose && onClose();
      })
      .catch((error) => {
        log(error);
        NotificationService.error('Der er sket en fejl i registeringen af vareoptællingen', 5000);
        setLoading(false);
      });
  };

  const isDirty = useMemo(() => {
    return JSON.stringify(goods) !== JSON.stringify(initialGoods);
  }, [goods, initialGoods]);

  return (
    <Container>
      {loading && <LoadingOverlay />}
      <NavigationHeader
        headerTitle={page === 'add' ? 'Tilføj varetype til bilens lager' : 'Lav optælling'}
        onBackButton={page === 'add' ? () => setPage('count') : undefined}
        onClose={handleOnClose}
      />

      <ContentContainer>
        {page === 'count' && (
          <CountGoodsList
            goods={goods}
            handleCheckGood={handleCheckGood}
            lastUpdated={lastUpdated}
            updateGoodQuantity={updateGoodQuantity}
            reset={reset}
            isDirty={isDirty}
          />
        )}
        {page === 'add' && <StyledGoodsList goods={completeGoodsList} selectGoods={handleSelectGoods} />}
      </ContentContainer>
      {
        <Footer>
          {page === 'count' && (
            <>
              <TextBoks onClick={() => setPage('add')}>
                <Typography variant="h6">
                  Har du varetyper der ikke er på listen?
                  <StyledLink
                    onClick={() => {
                      setPage('add');
                      setSelectedGoods([]);
                    }}
                    minWidth="80px"
                  >
                    Slå dem op her
                  </StyledLink>
                </Typography>
              </TextBoks>
              <StyledButton onClick={submit}>Registrer varer</StyledButton>
            </>
          )}
          {page === 'add' && (
            <>
              <Space />
              <BasicPopover
                buttonElement={<SelectedGoodsBadge show count={selectedGoods.length} />}
                popoverElement={<BasketPopover removeGoods={removeSelectedGoods} selectedGoods={selectedGoods} />}
                disablePopover={selectedGoods.length === 0}
                activeByClick
              />
            </>
          )}
        </Footer>
      }
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  height: 100%;
  width: 100%;
`;

const ContentContainer = styled.div`
  background: white;
  width: calc(100% - 50px);
  height: calc(100% - 182px);
  padding-top: 10px;
`;

const Footer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.palette.grey.black5};
  width: 100%;
  height: 100px;
  bottom: 0;
`;

const StyledButton = styled(Button)`
  margin: 0px 20px;
`;

const StyledGoodsList = styled(GoodsList)`
  .table-footer {
    justify-content: end;
  }
`;

const TextBoks = styled.div`
  display: flex;
  padding-left: 30px;
`;
const Space = styled.div`
  display: flex;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  position: relative;
  z-index: ${(props) => props.theme.zIndex.main};
  padding: 1em;
  margin: -1em;
`;

export default CountGoodsViewContent;
