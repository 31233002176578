import {
  AccessConditionsDTO,
  CustomerAppointmentDTO,
  DepartmentDTO,
  LocationDTO,
  ProjectDTO2,
  StretchDTO,
  TaskComponentValueDTO
} from '../../../../api/api';
import { MappedProjectNumber } from '../../../../components/project-number-dropdown/ProjectNumberDropdown';
import { AppointmentErrors } from '../../../../models/CreationErrors';
import { WorkTaskDTOExtended } from '../../../../models/TaskType';

export interface PayloadAction {
  type: ActionType;
  payload: any;
}

export type TaskErrors = { description?: boolean };

export type ActionType =
  | 'set-department'
  | 'set-description'
  | 'set-earliest-start-date'
  | 'set-deadline'
  | 'set-customer-appointment'
  | 'set-remaining-time'
  | 'set-notes-for-planning'
  | 'set-task-location'
  | 'set-access-conditions'
  | 'set-is-access-conditions-loading'
  | 'set-show-access-conditions-dialog'
  | 'set-is-updating-task'
  | 'set-components'
  | 'set-is-project-number-editable'
  | 'set-project-number-object'
  | 'set-external-projects'
  | 'set-is-loading-project-numbers'
  | 'set-stretches'
  | 'set-project-number-and-name'
  | 'set-task'
  | 'set-appointment-errors'
  | 'set-dynamic-field-errors'
  | 'set-work-task-errors'
  | 'initialize';

export interface DetailStep {
  department?: DepartmentDTO;
  description?: string;
  earliestStartDate?: string;
  deadline?: string;
  customerAppointment?: CustomerAppointmentDTO;
  remainingTime?: number;
  notesForPlanning?: string;
  taskLocation?: LocationDTO;
  accessConditions?: AccessConditionsDTO;
  isAccessConditionsLoading?: boolean;
  showAccessConditionsDialog?: boolean;
  isUpdatingTask?: boolean;
  components?: TaskComponentValueDTO[];
  isProjectNumberEditable?: boolean;
  projectNumberObject?: MappedProjectNumber;
  externalProjects?: ProjectDTO2[];
  isLoadingProjectNumbers?: boolean;
  stretches?: StretchDTO[];
  projectNumberAndName?: string;
  task?: WorkTaskDTOExtended;
  appointmentErrors?: AppointmentErrors;
  dynamicFieldErrors?: boolean;
  workTaskErrors?: TaskErrors;
}

export const getInitialState = (task?: WorkTaskDTOExtended, customerAppointment?: CustomerAppointmentDTO) => {
  return {
    workTaskErrors: {},
    dynamicFieldErrors: false,
    appointmentErrors: {},
    task,
    isUpdatingTask: false,
    department: task?.assignedToDepartment,
    description: task?.description,
    earliestStartDate: task?.earliestStartDate,
    deadline: task?.deadline,
    customerAppointment: customerAppointment,
    remainingTime: task?.remainingTimeMin,
    notesForPlanning: task?.notesForPlanning,
    taskLocation: task?.taskLocation,
    accessConditions: {},
    isAccessConditionsLoading: false,
    showAccessConditionsDialog: false,
    components: [],
    isProjectNumberEditable: false,
    projectNumberObject: undefined,
    externalProjects: [],
    isLoadingProjectNumbers: false,
    stretches: task?.stretches ?? [],
    projectNumberAndName: '-'
  };
};

export const reducer = (state: DetailStep, action: PayloadAction) => {
  switch (action.type) {
    case 'initialize':
      return { ...state, ...action.payload } as DetailStep;
    case 'set-department':
      return { ...state, department: action.payload };
    case 'set-description':
      return { ...state, description: action.payload };
    case 'set-earliest-start-date':
      return { ...state, earliestStartDate: action.payload };
    case 'set-deadline':
      return { ...state, deadline: action.payload };
    case 'set-customer-appointment':
      return { ...state, customerAppointment: action.payload };
    case 'set-remaining-time':
      return { ...state, remainingTime: action.payload };
    case 'set-notes-for-planning':
      return { ...state, notesForPlanning: action.payload };
    case 'set-task-location':
      return { ...state, taskLocation: action.payload };
    case 'set-access-conditions':
      return { ...state, accessConditions: action.payload };
    case 'set-is-access-conditions-loading':
      return { ...state, isAccessConditionsLoading: action.payload };
    case 'set-show-access-conditions-dialog':
      return { ...state, showAccessConditionsDialog: action.payload };
    case 'set-is-updating-task':
      return { ...state, isUpdatingTask: action.payload };
    case 'set-components':
      return { ...state, components: action.payload };
    case 'set-is-project-number-editable':
      return { ...state, isProjectNumberEditable: action.payload };
    case 'set-project-number-object':
      return { ...state, projectNumberObject: action.payload };
    case 'set-external-projects':
      return { ...state, externalProjects: action.payload };
    case 'set-is-loading-project-numbers':
      return { ...state, isLoadingProjectNumbers: action.payload };
    case 'set-stretches':
      return { ...state, stretches: action.payload };
    case 'set-project-number-and-name':
      return { ...state, projectNumberAndName: action.payload };
    case 'set-task':
      return { ...state, task: action.payload };
    case 'set-appointment-errors':
      return { ...state, appointmentErrors: action.payload };
    case 'set-dynamic-field-errors':
      return { ...state, dynamicFieldErrors: action.payload };
    case 'set-work-task-errors':
      return { ...state, workTaskErrors: action.payload };
    default:
      return state;
  }
};
