import { useEffect, useState } from 'react';
import styled from 'styled-components';
import InternalTimeForm from '../../blocks/internal-time-form/InternalTimeForm';
import InternalTaskList from './internal-task-list/InternalTaskList';
import Button from '../../components/button/Button';
import { AddIcon } from '../../assets/icons/AddIcon';
import Typography from '../../components/typography/Typography';
import { GreyAreaHorizontalPadding } from '../../styling/StylingConstants';
const InternalTimeOfficeView = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    if (shouldRefresh) setShouldRefresh(false);
  }, [shouldRefresh]);

  return (
    <Container>
      <Header>
        <Typography variant="h2">Interne opgaver</Typography>
        <Button onClick={() => setDialogOpen(true)}>
          <AddIcon size="20px" />
          Opret intern opgave
        </Button>
      </Header>
      <InternalTaskList setShouldRefresh={(value) => setShouldRefresh(value)} shouldRefresh={shouldRefresh} />
      {dialogOpen && (
        <InternalTimeForm setShouldRefresh={(value) => setShouldRefresh(value)} onClose={() => setDialogOpen(false)} />
      )}
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;

  & > *:nth-child(1) {
    padding-left: ${(props) => props.theme.spacing(4)};
    padding-right: ${(props) => props.theme.spacing(4)};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${GreyAreaHorizontalPadding}px ${GreyAreaHorizontalPadding}px 0px;
  line-height: 40px;
`;

export default InternalTimeOfficeView;
