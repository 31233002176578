import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { QuestionDTO, SectionQuestionTextDTO, TaskTypeSectionTextDTO } from '../../../../../api/api';
import { TabProps } from '../../../../../blocks/tabs-vertical/TabsVertical';
import { FormDialogContainer, Main } from '../../AdminTaskTypesView';
import Section from './components/Section';
import Button from '../../../../../components/button/Button';
import { ExtendedSectionQuestionTextDTO } from '../../../../../models/Question';
import AddTitle from '../../../../../components/add-title/AddTitle';

interface Props extends TabProps {
  sections: TaskTypeSectionTextDTO[];
  setSections: (sections: TaskTypeSectionTextDTO[], newSection?: number) => void;
  addQuestion: (section: TaskTypeSectionTextDTO) => void;
  allQuestions: QuestionDTO[];
}

const Sections = (props: Props) => {
  const { addQuestion, setSections, sections, allQuestions } = props;
  const [addingSection, setAddingSection] = useState(false);
  const myRef = useRef<null | HTMLFormElement>(null);

  const createNewSection = (name: string) => {
    const id = Math.random() * 1_000_000; //Only temporary ID. The section will get a new ID when it is saved and added to the DB.

    setSections(
      [
        ...sections,
        {
          sectionId: id,
          name: name,
          order: sections.length,
          questions: [],
          isRepeatable: false
        }
      ],
      id
    );
    setAddingSection(false);
  };

  const handleSetQuestions = useCallback(
    (section: TaskTypeSectionTextDTO, questions?: SectionQuestionTextDTO[]) => {
      if (!questions) return;
      const _sections: TaskTypeSectionTextDTO[] = sections.map((s) => {
        if (s.sectionId === section.sectionId) {
          return { ...s, questions: questions } as TaskTypeSectionTextDTO;
        }
        return s;
      });
      setSections(_sections ?? []);
    },
    [sections, setSections]
  );

  useEffect(() => {
    if (addingSection) myRef.current?.scrollIntoView();
  }, [addingSection]);

  const handleAddSection = () => {
    setAddingSection(true);
  };

  const swapSections = (index1: number, index2: number) => {
    if (index1 === sections.length || index2 === sections.length || index1 === -1 || index2 === -1) return;
    const array = [...sections];
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
    setSections(array);
  };

  const handleSetSection = (section: TaskTypeSectionTextDTO) => {
    const _sections = sections.map((s) => {
      if (s.sectionId === section.sectionId) {
        return section;
      }
      return s;
    });
    setSections(_sections);
  };

  const deleteSection = (section: TaskTypeSectionTextDTO) => {
    const _sections = sections.filter((s) => s.sectionId !== section.sectionId);
    setSections(_sections);
  };

  const getOtherAllowedChecklistQuestions = (sectionId?: number) => {
    if (!sectionId) return [];
    return sections
      .filter((s) => s.sectionId !== sectionId)
      .filter((s) => !s.isRepeatable)
      .flatMap((s) =>
        (s.questions ?? []).map((q) => ({
          ...q,
          sectionId: s.sectionId
        }))
      )
      .map((checkListQuestion) => {
        const matchingQuestion = allQuestions.find((question) => question.id === checkListQuestion.questionId);

        if (matchingQuestion) {
          return {
            ...checkListQuestion,
            text: matchingQuestion.text,
            questionType: matchingQuestion.questionType,
            options: matchingQuestion.options
          };
        }
        return checkListQuestion as ExtendedSectionQuestionTextDTO;
      });
  };

  const validateIfQuestinoIsDependableQuestion = (questionId: number) => {
    return sections.find((section) => section.dependsOnQuestionSectionQuestionId === questionId)?.sectionId;
  };

  return (
    <Main>
      <FormDialogContainer>
        {sections.map((section: TaskTypeSectionTextDTO, index: number) => (
          <Section
            key={section.sectionId}
            section={section}
            index={index}
            addQuestion={() => addQuestion(section)}
            setQuestions={(questions) => handleSetQuestions(section, questions)}
            deleteSection={() => deleteSection(section)}
            swapSections={(index1, index2) => swapSections(index1, index2)}
            setSection={(section: TaskTypeSectionTextDTO) => handleSetSection(section)}
            otherCheckListQuestions={getOtherAllowedChecklistQuestions(section.sectionId)}
            validateIfQuestinoIsDependableQuestion={validateIfQuestinoIsDependableQuestion}
          />
        ))}
        {!addingSection && (
          <Button variant="secondary" onClick={() => handleAddSection()}>
            Tilføj sektion
          </Button>
        )}
        {addingSection && (
          <AddTitle
            createTitle={(name) => createNewSection(name)}
            resetTitle={() => setAddingSection(false)}
            label={'Sektion'}
          />
        )}
      </FormDialogContainer>
    </Main>
  );
};

export const Form = styled.form`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export default Sections;
