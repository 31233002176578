import { useCallback, useEffect, useState } from 'react';
import { AssetResultDTO, StretchDTO } from '../../../../../api/api';
import { SectionProps } from '../../../../../models/CommonProps/SectionProps';
import { DeleteIcon } from '../../../../../assets/icons/DeleteIcon';
import { IconButton } from '@mui/material';
import AssetAutocomplete from '../../../../../components/asset-autocomplete/AssetAutocomplete';

interface Props extends SectionProps {
  index: number;
  handleRemoveStretch?: (index: number) => void;
  handleUpdateStretch?: (index: number, stretch: StretchDTO) => void;
  stretch: StretchDTO;
  showErrors?: boolean;
  mode: 'create' | 'read' | 'write';
  isEditable: boolean;
}

const Stretch = (props: Props) => {
  const { index, handleRemoveStretch, handleUpdateStretch, stretch, readonlyMode, showErrors, mode, isEditable } = props;
  const { from, assetTypeFrom, to, assetTypeTo } = stretch;
  const [localFrom, setLocalFrom] = useState(from);
  const [localAssetFrom, setLocalAssetFrom] = useState(assetTypeFrom);
  const [localTo, setLocalTo] = useState(to);
  const [localAssetTo, setLocalAssetTo] = useState(assetTypeTo);

  useEffect(() => {
    setLocalFrom(from);
    setLocalAssetFrom(assetTypeFrom);
    setLocalTo(to);
    setLocalAssetTo(assetTypeTo);
  }, [assetTypeFrom, assetTypeTo, from, to]);

  const updateStretches = useCallback(
    (asset: AssetResultDTO, isFromAsset: boolean) => {
      if (isFromAsset) {
        asset?.assetId && setLocalFrom(asset.assetId);
        asset?.assetType && setLocalAssetFrom(asset.assetType);
      } else {
        asset?.assetId && setLocalTo(asset.assetId);
        asset?.assetType && setLocalAssetTo(asset.assetType);
      }

      handleUpdateStretch?.(index, {
        from: isFromAsset ? asset.assetId ?? localFrom : localFrom,
        assetTypeFrom: isFromAsset ? asset.assetType : localAssetFrom,
        to: !isFromAsset ? asset.assetId ?? localTo : localTo,
        assetTypeTo: !isFromAsset ? asset.assetType : localAssetTo
      });
    },
    [handleUpdateStretch, index, localAssetFrom, localAssetTo, localFrom, localTo]
  );

  return (
    <>
      <AssetAutocomplete
        id="stretch-from-textfield"
        label={'Fra station'}
        onSelect={(asset) => updateStretches(asset, true)}
        required
        error={showErrors && !localFrom}
        inputMode={mode === 'create' || mode === 'write'}
        disabled={mode === 'read'}
        prevAsset={
          localFrom && localAssetFrom ? ({ assetId: localFrom, assetType: localAssetFrom } as AssetResultDTO) : undefined
        }
        inLineStyle
      />
      <AssetAutocomplete
        id="stretch-to-textfield"
        label={'Til station'}
        onSelect={(asset) => updateStretches(asset, false)}
        required
        error={showErrors && !localTo}
        inputMode={mode === 'create' || mode === 'write'}
        disabled={mode === 'read'}
        prevAsset={localTo && localAssetTo ? ({ assetId: localTo, assetType: localAssetTo } as AssetResultDTO) : undefined}
        inLineStyle
      />
      {!readonlyMode && (mode === 'create' || (mode === 'write' && isEditable)) && (
        <IconButton onClick={() => handleRemoveStretch && handleRemoveStretch(index)} data-testid="remove-stretch">
          <DeleteIcon margin="5px" size="18px" />
        </IconButton>
      )}
    </>
  );
};

export default Stretch;
