import styled, { css } from 'styled-components';
import CircularProgress from '../circular-progress/CircularProgress';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'action';
  children?: React.ReactNode;
  element?: 'span' | 'button';
  isLoading?: boolean;
  square?: boolean;
  active?: boolean;
  compact?: boolean;
  fixedWidth?: string;
}

const Button = (props: Props) => {
  const { variant = 'primary', children, element, isLoading, square, active, compact, fixedWidth, onClick, ...rest } = props;

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!onClick || isLoading) return;

    onClick(event);
  };

  return (
    <StyledButton
      as={element}
      variant={variant}
      isLoading={isLoading}
      square={square ?? compact}
      active={active}
      compact={compact}
      fixedWidth={fixedWidth}
      onClick={onClickHandler}
      {...rest}
    >
      {isLoading && <CircularProgress position="absolute" margin="0" size="1rem" />}
      {children}
    </StyledButton>
  );
};

const rootStyles = css<Props>`
  margin: 0;
  min-width: ${(props) => props.fixedWidth ?? ''};
  width: ${(props) => props.fixedWidth ?? 'fit-content'};
  height: ${(props) => (props.compact ? '' : '44px')};
  padding: ${(props) => (props.compact ? '4px 12px' : '12px 24px')};
  display: flex;
  align-items: center;
  border-radius: ${(props) => (props.square ? '8px' : '22px')};
  white-space: nowrap;
  column-gap: 8px;
  position: relative;
  box-sizing: border-box;

  font-family: inherit;
  font-size: ${(props) => (props.compact ? '14px' : '16px')};
  font-weight: ${(props) => (props.compact ? '400' : '800')};

  color: ${(props) => props.isLoading && 'transparent !important'};

  :hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  ${(props) => props.variant === 'primary' && !props.disabled && primaryStyles};
  ${(props) => props.variant === 'secondary' && !props.disabled && secondaryStyles};
  ${(props) => props.variant === 'tertiary' && !props.disabled && tertiaryStyles};
  ${(props) => props.variant === 'success' && !props.disabled && successStyles};
  ${(props) => props.variant === 'action' && !props.disabled && actionStyles};
  ${(props) => props.disabled && disabledStyles};
  ${(props) => props.active && activeStyles};
`;

const primaryStyles = css`
  background: ${(props) => props.theme.palette.main.yellow.primary};
  color: ${(props) => props.theme.palette.main.black.primary};
  border: 1px solid transparent;

  :hover {
    background: ${(props) => props.theme.palette.main.yellow.secondary};
  }
`;

const secondaryStyles = css`
  background: #ffffff;
  color: ${(props) => props.theme.palette.main.black.primary};
  border: ${(props) => `1px solid ${props.theme.palette.grey.black20}`};

  :hover {
    background: ${(props) => props.theme.palette.grey.black5};
  }
`;

const tertiaryStyles = css`
  background: ${(props) => props.theme.palette.main.black.primary};
  color: #ffffff;
  border: 1px solid transparent;
  color: #ffffff;

  :hover {
    background: ${(props) => props.theme.palette.main.black.secondary};
  }

  svg {
    fill: #ffffff;
    stroke: #ffffff;
  }
`;

const successStyles = css`
  background: ${(props) => props.theme.palette.functions.success.primary};
  color: #ffffff;
  border: 1px solid transparent;

  svg {
    fill: #ffffff;
    stroke: #ffffff;
  }

  :hover {
    background: ${(props) => props.theme.palette.functions.success.secondary};
  }
`;

const actionStyles = css`
  background: ${(props) => props.theme.palette.functions.action.primary};
  color: #fff;
  border: 1px solid transparent;

  svg {
    fill: #ffffff;
    stroke: #ffffff;
  }

  :hover {
    background: ${(props) => props.theme.palette.functions.action.secondary};
  }
`;

const disabledStyles = css`
  background: ${(props) => props.theme.palette.grey.black5};
  color: ${(props) => props.theme.palette.grey.black40};
  border: 1px solid transparent;

  svg {
    fill: ${(props) => props.theme.palette.grey.black40};
    stroke: ${(props) => props.theme.palette.grey.black40};
  }
`;

const activeStyles = css`
  background: ${(props) => props.theme.palette.main.black.primary};
  border: 1px solid transparent;
  color: #ffffff;

  svg {
    fill: #ffffff;
    stroke: #ffffff;
  }

  :hover {
    background: ${(props) => props.theme.palette.main.black.secondary};
  }
`;

const StyledButton = styled.button<Props>`
  ${rootStyles};
`;

export default Button;
