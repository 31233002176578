import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { InputType, ReuseableComponentAttributeDTO } from '../../../../api/api';
import Button from '../../../../components/button/Button';
import Select, { MenuItem } from '../../../../components/select/Select';
import TextField from '../../../../components/text-field/TextField';
import { FormProps } from '../../../../models/CommonProps/FormProps';
import { Row } from '../../../../styling/FormStyling';
import { getEnumDisplayValue } from '../../../../utils/enumUtils';

type ReusableComponentFormErrors = { [key in keyof ReuseableComponentAttributeDTO]: boolean };

const ReusableComponentForm = (props: FormProps<ReuseableComponentAttributeDTO>) => {
  const { initialBody, handleCancel, handleSubmit } = props;

  const [body, setBody] = useState<ReuseableComponentAttributeDTO>(initialBody ?? {});
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<ReusableComponentFormErrors>({});

  const handleChangeValue = useCallback((key: keyof ReuseableComponentAttributeDTO, value: any) => {
    setBody((prev) => {
      return { ...prev, [key]: value };
    });
  }, []);

  const submitForm = useCallback(() => {
    const newErrors = {
      inputType: !body.inputType,
      label: !body.label,
      minLength: body?.minLength && body.maxLength && body.minLength > body?.maxLength,
      maxLength: body?.minLength && body.maxLength && body.minLength > body?.maxLength
    } as ReusableComponentFormErrors;

    if (Object.values(newErrors).some((value) => value)) {
      setErrors(newErrors);
      return;
    }

    setSubmitting(true);
    handleSubmit &&
      handleSubmit(body).then((res) => {
        setSubmitting(false);
      });
  }, [body, handleSubmit]);

  return (
    <Container>
      <Row>
        <TextField
          value={body.label || ''}
          onChange={(e) => handleChangeValue('label', e.target.value)}
          label="Navn"
          required
          error={errors.label}
          fullWidth
        />
        <Select
          value={body.inputType || ''}
          onChange={(e) => handleChangeValue('inputType', e.target.value)}
          label="Type"
          fullWidth
          required
          error={errors.inputType}
        >
          {Object.keys(InputType).map((type) => (
            <MenuItem key={type} value={type}>
              {getEnumDisplayValue(type as InputType)}
            </MenuItem>
          ))}
        </Select>
      </Row>
      <Row>
        <TextField
          value={body.hintText || ''}
          onChange={(e) => handleChangeValue('hintText', e.target.value)}
          label="Hjælpetekst"
        />
        <TextField
          value={body.minLength || ''}
          onChange={(e) => handleChangeValue('minLength', e.target.value)}
          label="Minimumslængde"
          type={'number'}
          error={errors.minLength}
        />
      </Row>
      <Row>
        <TextField
          value={body.maxLength || ''}
          onChange={(e) => handleChangeValue('maxLength', e.target.value)}
          label="Maxlængde"
          type={'number'}
          error={errors.maxLength}
        />
      </Row>
      <ButtonContainer>
        <Button variant="secondary" onClick={() => handleCancel && handleCancel()}>
          Annuller
        </Button>
        <Button onClick={() => submitForm()} isLoading={submitting}>
          Gem
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default ReusableComponentForm;
