import styled from 'styled-components';
import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';
import Typography from '../../../../components/typography/Typography';
import { AttachmentIcon } from '../../../../assets/icons/AttachmentIcon';
import { FileContainer, UploadedFilesContainer } from '../task-files-step/TaskFilesStep';
import { ComponentFileUploadResponseDTO, ListViewFileUploadResponseDTO } from '../../../../api/api';
import FilePreview from '../../../../components/file-preview/FilePreview';
import Button from '../../../../components/button/Button';
import { CameraIcon } from '../../../../assets/icons/CameraIcon';
import { useState } from 'react';
import IconBadge from '../../../../components/icon-badge/IconBadge';
import { DownloadInfo } from '../../../../models/DownloadInfo';

const AllowedFiles = '.png,.jpeg,.jpg';

interface Props extends TabProps {
  description?: string;
  files: ListViewFileUploadResponseDTO[] | ComponentFileUploadResponseDTO[];
  handleDownload: (fileName: string) => DownloadInfo;
  handleRemove: (fileId: number) => void;
  handleUpload: (files: FileList | null, onUploadedCallback: () => void) => void;
  handleThumbnail: (fileName: string) => Promise<string>;
}

const UploadedFilesSection = (props: Props) => {
  const { description, files, handleDownload, handleRemove, handleUpload, handleThumbnail } = props;
  const [isLoading, setIsLoading] = useState(false);

  const onUpload = (files: FileList | null) => {
    setIsLoading(true);
    handleUpload(files, () => setIsLoading(false));
  };

  return (
    <Section>
      <SectionHeader variant="h6">
        Billeder
        <IconBadge Icon={() => <AttachmentIcon color="white" size="18px" />} text={String(files.length)} />
      </SectionHeader>
      <SectionText>{description}</SectionText>
      <UploadedFilesContainer>
        {files?.map((obj: ListViewFileUploadResponseDTO | ComponentFileUploadResponseDTO, i: any) => {
          return (
            <FileContainer key={i}>
              <FilePreview
                name={obj.fileName ?? ''}
                date={obj.uploadedTime}
                uploadedBy={obj.uploadedByName}
                isImage
                handleDownload={() => handleDownload(obj.fileName ?? '')}
                handleRemove={() => handleRemove(obj.id ?? -1)}
                handleThumbnail={() => handleThumbnail(obj.fileName ?? '')}
              />
            </FileContainer>
          );
        })}
      </UploadedFilesContainer>
      <ButtonContainer>
        <input
          style={{ display: 'none' }}
          type="file"
          id="image-upload"
          multiple
          accept={AllowedFiles}
          onChange={(e) => onUpload(e.target.files)}
        />
        <input
          capture="environment"
          style={{ display: 'none' }}
          type="file"
          id="image-upload-camera"
          multiple
          accept="image/*"
          onChange={(e) => onUpload(e.target.files)}
        />
        <label htmlFor="image-upload">
          <Button isLoading={isLoading} variant="secondary" element="span">
            <CameraIcon /> Upload
          </Button>
        </label>
      </ButtonContainer>
    </Section>
  );
};

const Section = styled.div`
  border: 1px solid ${(props) => props.theme.palette.grey.black20};
  padding: ${(props) => props.theme.spacing(5)};
  border-radius: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(7)};
`;

const SectionHeader = styled(Typography)`
  display: flex;
  font-size: ${(props) => props.theme.spacing(4)};
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const SectionText = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(3)};
  color: ${(props) => props.theme.palette.grey.black60};
  font-size: ${(props) => props.theme.spacing(4)};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export default UploadedFilesSection;
