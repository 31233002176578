import { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { ConditionalQuestion } from '../../models/Question';
import { IsRepeatableEnum } from '../../api/api';
import { log } from '../../utils/logging/log';
import { AddIcon } from '../../assets/icons/AddIcon';
import Button from '../button/Button';
import NotificationService from '../../services/NotificationService';
import TaskChecklistService from '../../services/TaskChecklistService';
import { TaskChecklistDispatchContext } from '../../stateManagement/TaskChecklistProvider';
import { SectionWrapper } from '../../models/SectionWrapper';
import QuestionaireSection from '../questionaire-section/QuestionaireSection';
import ExpandCollapseTaskChecklistHeaders from '../../views/task-view/task-details-view2/checklist-step/ExpandCollapseTaskChecklistHeaders';
import { DialogBody } from '../../stateManagement/reducers/confirmDialogReducer';
import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';

interface Props {
  header: string;
  addSectionButton?: boolean;
  updateConditionalQuestionValue?: (conditionalQuestion: ConditionalQuestion) => void;
  taskTypeSectionId?: number;
  workTaskSectionId?: number;
  setIsExpanded?: (sectionId?: number) => void;
  showError?: boolean;
  readOnly: boolean;
  checklistId: number;
  sectionWrapper: SectionWrapper;
  workTaskId: number;
  collapsedSections: number[];
  toggleIsCompleted?: (newToggleState: boolean, taskSectionId: number) => void;
}

const Dialog: DialogBody = {
  headerText: 'Slet sektion',
  bodyText: 'Er du sikker på, at du vil slette sektionen? Alle svar på den valgte sektion slettes.',
  confirmButtonText: 'Bekræft',
  declineButtonText: 'Fortryd'
};

const RepeatableSection = (props: Props) => {
  const {
    header,
    taskTypeSectionId,
    setIsExpanded,
    showError,
    checklistId,
    readOnly,
    sectionWrapper,
    workTaskId,
    collapsedSections,
    toggleIsCompleted
  } = props;

  const dispatch = useContext(TaskChecklistDispatchContext);
  const { getConfirmation } = useConfirmationDialog();

  const addSection = useCallback(() => {
    TaskChecklistService.createRepeatableSectionInChecklist(workTaskId, taskTypeSectionId ?? 0, checklistId)
      .then((res) => {
        dispatch({
          type: 'add-section',
          payload: {
            checklistId,
            sectionDTO: res,
            numberOfSections: sectionWrapper.sections.length
          }
        });
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke tilføje sektionen.');
      });
  }, [checklistId, dispatch, sectionWrapper.sections.length, taskTypeSectionId, workTaskId]);

  const handleDeleteSection = useCallback(
    async (taskSectionId: number) => {
      const confirmation = await getConfirmation(Dialog);

      if (confirmation === 'decline') return;

      if (confirmation === 'confirm') {
        TaskChecklistService.deleteRepetableSectionInTasklist(taskSectionId ?? 0)
          .then(() => {
            dispatch({
              type: 'remove-section',
              payload: { checklistId, taskSectionId }
            });
          })
          .catch((err) => {
            log(err);
            NotificationService.error('Kunne ikke slette sektionen.');
          });
      }
    },
    [checklistId, dispatch, getConfirmation]
  );

  return (
    <ExpandCollapseTaskChecklistHeaders
      header={header}
      isCollapsable={true}
      isRepeatable={false}
      isExpanded={
        !(
          collapsedSections &&
          collapsedSections.length > 0 &&
          collapsedSections?.find((s) => s === sectionWrapper.sectionId)
        )
      }
      setIsExpanded={() => setIsExpanded && setIsExpanded(sectionWrapper.sectionId)}
      subSectionsLength={sectionWrapper.sections.length - 1}
      showError={showError}
      readOnly={readOnly}
    >
      {sectionWrapper.sections
        .filter((x) => x.isRepeatable2 === IsRepeatableEnum.RepeatableWorkTaskChecklist)
        .map((section, index) => {
          return (
            <QuestionaireSection
              key={section.taskSectionId}
              header={`${section.header} ${index + 1}`}
              questions={section.questions}
              checklistId={checklistId}
              onDelete={() => handleDeleteSection(section.taskSectionId ?? 0)}
              readOnly={readOnly}
              workTaskSectionId={section.taskSectionId}
              isExpanded={
                !(
                  collapsedSections &&
                  collapsedSections.length > 0 &&
                  collapsedSections?.find((s) => s === section.taskSectionId)
                )
              }
              setIsExpanded={() => setIsExpanded && setIsExpanded(section.taskSectionId)}
              isRepeatable
              showIsCompleted
              isCompleted={section.isCompleted}
              toggleIsCompleted={() =>
                toggleIsCompleted && toggleIsCompleted(!section.isCompleted, section.taskSectionId ?? -1)
              }
            />
          );
        })}
      {!readOnly && (
        <StyledButton variant="secondary" onClick={() => addSection()}>
          <AddIcon size="18px" />
          Tilføj {header}
        </StyledButton>
      )}
    </ExpandCollapseTaskChecklistHeaders>
  );
};

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

export default RepeatableSection;
