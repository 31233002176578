import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import useTableInstance from '../../hooks/useTableInstance';
import { SearchColumnFilter } from '../table/TableFilterUtils';
import KebabMenu from '../../components/kebab-menu/KebabMenu';
import { MenuItem } from '../../components/select/Select';
import { LinkDTO } from '../../models/LinkModel';

const useLinksList = (
  links: LinkDTO[],
  swapLinks: (updatedLinks: LinkDTO[]) => void,
  editLink: (portalLink: LinkDTO) => void,
  deleteLink: (linkId: number) => void
) => {
  const data = useMemo(() => {
    return links;
  }, [links]);

  const swapElements = (_array: LinkDTO[], index1: number, index2: number) => {
    if (!links || index1 === links.length || index2 === links.length || index1 === -1 || index2 === -1) return;
    const array = [..._array];
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
    swapLinks(array);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Rækkefølge',
        accessor: 'order',
        width: 100,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Link navn',
        accessor: 'linkText',
        width: 100,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Url',
        accessor: 'link',
        width: 300,
        Filter: SearchColumnFilter,
        filter: 'text',
        Cell: (cellProps: CellProps<LinkDTO>) => {
          const { cell } = cellProps;
          return (
            <a href={cell.row.original.url} target="_blank" rel="noreferrer">
              {cell.row.original.url}
            </a>
          );
        }
      },
      {
        Header: '',
        accessor: 'delete',
        width: 100,
        disableFilters: true,
        Cell: (cellProps: CellProps<LinkDTO>) => {
          const { cell } = cellProps;
          const index = cell.row.index;
          const id = cell.row.original.id;
          return (
            <KebabMenu>
              {index !== undefined && <MenuItem onClick={() => swapElements(links, index, index - 1)}>Ryk op</MenuItem>}
              {index !== undefined && <MenuItem onClick={() => swapElements(links, index + 1, index)}>Ryk ned</MenuItem>}
              <MenuItem onClick={() => editLink(cell.row.original)}>Rediger link</MenuItem>
              <MenuItem onClick={() => id && deleteLink(id)}>Slet link</MenuItem>
            </KebabMenu>
          );
        }
      }
    ] as Column<LinkDTO>[];
  }, [data]);

  const tableInstance = useTableInstance<LinkDTO>(data, columns);
  return { tableInstance };
};

export default useLinksList;
