import { memo, useCallback, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Headers } from 'react-csv/components/CommonPropTypes';
import styled from 'styled-components';
import { DownloadIcon } from '../../../assets/icons/DownloadIcon';
import Button from '../../../components/button/Button';

interface Props<T extends object> {
  data: T[];
  headers: Headers;
  fileNameFunction: () => string;
  loadAllTasks?: () => void;
  isLoadingList?: boolean;
}

const ExcelExportButtonServerSide = <T extends object>(props: Props<T>) => {
  const { data, headers, fileNameFunction, loadAllTasks, isLoadingList } = props;
  const [showExport, setShowExport] = useState(false);
  const [listLoaded, setListLoaded] = useState(false);

  const handleOnClick = useCallback(() => {
    setShowExport(true);
    loadAllTasks && loadAllTasks();
  }, [loadAllTasks]);

  useEffect(() => {
    // Reset button when new filters are loaded into list
    if (showExport && listLoaded && isLoadingList) {
      setShowExport(false);
      setListLoaded(false);
    }

    if (showExport && !isLoadingList) {
      setListLoaded(true);
    }
  }, [isLoadingList, listLoaded, showExport]);

  return (
    <>
      {showExport ? (
        <StyledButton variant="secondary" isLoading={isLoadingList}>
          <StyledCSVLink separator={';'} data={data} headers={headers} filename={fileNameFunction()}>
            <DownloadIcon size="16px" zIndex={0} />
            Eksporter
          </StyledCSVLink>
        </StyledButton>
      ) : (
        <StyledButton variant="secondary" onClick={() => handleOnClick()}>
          <DownloadIcon size="16px" zIndex={0} />
          Klargør
        </StyledButton>
      )}
    </>
  );
};

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none;
  display: flex;
  column-gap: 12px;
  color: ${(props) => props.theme.palette.text.primary};
`;
const StyledButton = styled(Button)`
  && {
    height: 37px;
    padding: 8px;
    column-gap: 12px;
    border-radius: 8px;
  }
  ${(props) => ({ ...props.theme.typography.p })};
  font-weight: 700;
  width: 110px;
`;

export default memo(ExcelExportButtonServerSide, <T extends object>(prevProps: Props<T>, props: Props<T>) => {
  return (
    prevProps.data.length === props.data.length &&
    prevProps.headers?.length === props.headers?.length &&
    prevProps.loadAllTasks === props.loadAllTasks &&
    prevProps.isLoadingList === props.isLoadingList
  );
});
