import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NewsDTO } from '../../api/api';

interface NewsNotification {
  show: boolean;
  news?: NewsDTO;
}
interface NewsState {
  news: NewsDTO[];
  showNotification: NewsNotification;
}

const initialState: NewsState = {
  news: [],
  showNotification: { news: undefined, show: false }
};

const newsSlice = createSlice({
  name: 'news',
  initialState: initialState,
  reducers: {
    setNews: (state: NewsState, action: PayloadAction<NewsDTO[]>) => {
      state.news = action.payload;
    },
    setShowNotification: (state: NewsState, action: PayloadAction<NewsNotification>) => {
      state.showNotification = action.payload;
    }
  }
});

export const { setNews, setShowNotification } = newsSlice.actions;
export const showNotification = (state: RootState) => state.newsReducer.showNotification;
export const selectNews = (state: RootState) => state.newsReducer.news;

export default newsSlice.reducer;
