import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import { updateTask } from '../../stateManagement/reducers/taskCreationReducer';

import { AccessConditionsDTO, AssetType2, LocationDTO } from '../../api/api';
import { TabProps } from '../tabs-vertical/TabsVertical';
import TextField from '../../components/text-field/TextField';
import { log } from '../../utils/logging/log';
import NotificationService from '../../services/NotificationService';
import AssetService from '../../services/AssetService';
import Button from '../../components/button/Button';
import DialogView from '../../components/dialog-view/DialogView';
import Alert from '../../components/alert/Alert';

interface Props extends TabProps {
  id: string;
  assetId: string;
  assetType?: AssetType2;
  assetLocation?: LocationDTO;
  accessConditions: AccessConditionsDTO | undefined;
  setAccessConditions: (conditions: AccessConditionsDTO) => void;
  onClose: () => void;
}

const AccessConditionDialog = (props: Props) => {
  const { id, onClose, assetId, assetType, assetLocation, accessConditions, setAccessConditions } = props;

  const dispatch = useDispatch();
  const [accessConditionsFormBody, setAccessConditionsFormBody] = useState<AccessConditionsDTO>(accessConditions ?? {});
  const [updatingAccessConditions, setUpdatingAccessConditions] = useState(false);

  const finalizeAccessConditionsUpdate = useCallback(
    (accessInfo, keyBoxInfo, objectId) => {
      const newConditions = { ...accessConditions, accessInfo, keyBoxInfo, objectId };
      setAccessConditions(newConditions);
      dispatch(updateTask({ id: id, task: { accessConditions: newConditions } }));

      onClose();
      NotificationService.success('Opdaterede adgangsforhold', 5000);
    },
    [accessConditions, dispatch, onClose, setAccessConditions, id]
  );

  const handleSaveAccessConditions = useCallback(() => {
    if (!accessConditionsFormBody || !assetType || !assetLocation) return;

    const { accessInfo, keyBoxInfo, objectId } = accessConditionsFormBody;
    setUpdatingAccessConditions(true);

    if (objectId) {
      AssetService.updateAccessConditions({ accessInfo, keyBoxInfo, objectId }, assetType)
        .then(() => {
          finalizeAccessConditionsUpdate(accessInfo, keyBoxInfo, objectId);
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Kunne ikke opdatere adgangsforhold.', 5000);
        })
        .finally(() => setUpdatingAccessConditions(false));
    } else {
      AssetService.addAccessConditions(
        { accessInfo, keyBoxInfo, assetId, x: assetLocation.longitude, y: assetLocation.latitude },
        assetType
      )
        .then((res) => {
          finalizeAccessConditionsUpdate(accessInfo, keyBoxInfo, res);
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Kunne ikke opdatere adgangsforhold.', 5000);
        })
        .finally(() => setUpdatingAccessConditions(false));
    }
  }, [accessConditionsFormBody, assetType, assetLocation, finalizeAccessConditionsUpdate, assetId]);

  const handleEnterPressed = (event: any) => {
    if (event.key === 'Enter') {
      handleSaveAccessConditions();
    }
  };

  return (
    <DialogView>
      <AccessConditionsDialogContainer>
        <TextField
          label={'Nøgleboks'}
          value={accessConditionsFormBody?.keyBoxInfo ?? ''}
          onChange={(e) => setAccessConditionsFormBody((prev) => ({ ...prev, keyBoxInfo: e.target.value }))}
          onKeyUp={handleEnterPressed}
          helperText="Vigtig info, når man står derude. F.eks. placering af nøglebox, kode, afhentning af nøgle i station"
        />
        <TextField
          label={'Adgang'}
          value={accessConditionsFormBody?.accessInfo ?? ''}
          onChange={(e) => setAccessConditionsFormBody((prev) => ({ ...prev, accessInfo: e.target.value }))}
          onKeyUp={handleEnterPressed}
          helperText="Vigtig info til planlægning af opgaven. F.eks. tidevand, krav om at man ringer x antal dage i forvejen"
        />
        <TextField label={'Teknikergodkendelse'} disabled value={accessConditionsFormBody?.technicianApproval ?? ' '} />
        <Alert severity="warning" height="62px">
          Specielle adgangsforhold som for eksempel sikkerhedsgodkendelse, skal udfyldes i formularen "Ændringer af
          adgangsforhold" i Etrack.
        </Alert>
        <Alert severity="warning" height="42px">
          Bemærk: Ændringerne gemmes direkte i GIS.
        </Alert>
        <AccessConditionsDialogButtonDiv>
          <Button variant="secondary" onClick={() => onClose()}>
            Annuller
          </Button>
          <Button onClick={() => handleSaveAccessConditions()} isLoading={updatingAccessConditions}>
            Gem
          </Button>
        </AccessConditionsDialogButtonDiv>
      </AccessConditionsDialogContainer>
    </DialogView>
  );
};

const AccessConditionsDialogContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  row-gap: 24px;
  width: 550px;
`;

const AccessConditionsDialogButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default AccessConditionDialog;
