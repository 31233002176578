import { isDevice } from '../utils/device-handling/deviceDetectionUtils';
import addQueryParams from '../utils/routing/add-query-params';
import { TASKS_DETAILS, TASKS_DETAILS_DIALOG_ROUTE } from '../utils/constants';
import { BaseWorkTaskTypeEnum } from '../api/api';
import { useNavigate } from './useNavigate';
import routeInPathname from '../utils/routing/route-in-pathname';
import { useLocation, useNavigate as useReactNavigate } from 'react-router-dom';

export const useNavigateToTask = () => {
  const navigate = useNavigate();
  const reactNavigate = useReactNavigate();

  const path = useLocation().pathname;
  const includedRoute = routeInPathname(path);

  return (workTaskId: number, baseWorkTaskType: BaseWorkTaskTypeEnum | undefined) => {
    if (includedRoute === TASKS_DETAILS || includedRoute === TASKS_DETAILS_DIALOG_ROUTE) {
      reactNavigate(
        addQueryParams(undefined, {
          id: workTaskId,
          type: baseWorkTaskType
        }),
        {
          replace: true,
          state: {
            payload: { state: { type: baseWorkTaskType } }
          }
        }
      );
    } else if (isDevice()) {
      navigate(
        '../' +
          addQueryParams(TASKS_DETAILS, {
            id: workTaskId,
            type: baseWorkTaskType
          }),
        {
          state: { type: baseWorkTaskType }
        }
      );
    } else {
      navigate(
        addQueryParams(
          undefined,
          {
            id: workTaskId,
            type: baseWorkTaskType
          },
          TASKS_DETAILS_DIALOG_ROUTE
        ),
        {
          state: {
            type: baseWorkTaskType
          }
        }
      );
    }
  };
};
