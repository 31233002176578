import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';
import Button from '../../../../components/button/Button';
import FilePreview from '../../../../components/file-preview/FilePreview';
import AttachmentService from '../../../../services/AttachmentService';
import { AttachmentDTO } from '../../../../api/api';
import NotificationService from '../../../../services/NotificationService';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import { getGuid } from '../../../../utils/guidGenerating';
import { log } from '../../../../utils/logging/log';
import { renameFile } from '../../../../utils/fileUtils';
import { DownloadInfo, ImageType } from '../../../../models/DownloadInfo';

const AcceptString = '.pdf,.png,.jpeg,.xlsx,.pptx,.docx,.svg,.doc,.xls,.ppt,.jpg';

interface Props extends TabProps {
  taskId: string;
  onFileUploaded?: () => void;
  onFileRemoved?: () => void;
}

const TaskFilesStep = (props: Props) => {
  const { taskId, onFileUploaded, onFileRemoved } = props;
  const [attachments, setAttachments] = useState<AttachmentDTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getConfirmation } = useConfirmationDialog();

  const fetchAttachments = useCallback(() => {
    setIsLoading(true);
    AttachmentService.getAttachments(parseInt(taskId))
      .then((result) => {
        setAttachments(result);
        setIsLoading(false);
      })
      .catch((error) => {
        log(error);
        setIsLoading(false);
      });
  }, [taskId]);

  useEffect(() => {
    fetchAttachments();
  }, [fetchAttachments]);

  const handleUpload = async (files: FileList | null) => {
    if (!files) return;
    setIsLoading(true);

    const filesToUpload: File[] = [];
    Array.from(files).forEach(async (file) => {
      if (file.name === 'image.jpg') file = renameFile(file, getGuid());
      filesToUpload.push(file);
    });

    if (filesToUpload.length > 0) {
      const promises = filesToUpload.map((file) => AttachmentService.postAttachment(parseInt(taskId), file));

      Promise.allSettled(promises)
        .then((results) => {
          const rejectedPromises = results.filter((r) => r.status === 'rejected');
          onFileUploaded && onFileUploaded();

          if (rejectedPromises.length > 0) {
            NotificationService.error(`Der opstod fejl under upload af en eller flere filer`);
          }
        })
        .catch((e) => {
          log(e);
          NotificationService.error(`Der opstod fejl under upload af en eller flere filer`);
        })
        .finally(() => {
          fetchAttachments();
        });
    }
  };

  const handleDelete = async (name: string) => {
    const confirmation = await getConfirmation({
      headerText: 'Er du sikker?',
      bodyText: `Vil du slette filen ${name}?`,
      declineButtonText: 'Fortryd',
      confirmButtonText: 'Bekræft'
    });

    if (confirmation === 'confirm') {
      setIsLoading(true);
      AttachmentService.deleteAttachment(parseInt(taskId), name)
        .then(() => {
          NotificationService.success(`${name} blev slettet`);
          onFileRemoved && onFileRemoved();
        })
        .catch((error) => {
          NotificationService.error(`Kunne ikke slette filen. ${error}`);
        })
        .finally(() => {
          fetchAttachments();
        });
    }
  };

  const handleDownload = useCallback(
    (filename: string): DownloadInfo => {
      return { imageType: ImageType.AttachmentDownloadInfo, taskId: +taskId, filename };
    },
    [taskId]
  );

  const getThumbnail = useCallback(async (fileName: string): Promise<string> => {
    let filename = fileName.substring(0, fileName.lastIndexOf('.'));
    return AttachmentService.getThumbnail(parseInt(taskId), filename)
      .then((res) => {
        return URL.createObjectURL(res.data);
      })
      .catch((err) => {
        log(err);
        return '';
      });
  }, []);

  return (
    <Container>
      <StyledHeader>Tilføjede dokumenter</StyledHeader>
      <UploadedFilesContainer>
        {attachments.map((attachment, i) => {
          return (
            <FileContainer key={attachment.filename}>
              <FilePreview
                name={attachment.filename ?? ''}
                handleRemove={() => handleDelete(attachment.filename ?? '')}
                handleDownload={(fileName: string) => handleDownload(fileName)}
                handleThumbnail={() => getThumbnail(attachment.filename ?? '')}
                date={attachment.createdOn ?? ''}
                uploadedBy={attachment.uploadedBy ?? ''}
                isImage={attachment.isImage}
              />
            </FileContainer>
          );
        })}
      </UploadedFilesContainer>
      <ButtonContainer>
        <input
          style={{ display: 'none' }}
          type="file"
          id="image-upload"
          multiple
          accept={AcceptString}
          onChange={(e) => handleUpload(e.target.files)}
        />
        <input
          capture="environment"
          style={{ display: 'none' }}
          type="file"
          id="image-upload-camera"
          multiple
          accept="image/*"
          onChange={(e) => handleUpload(e.target.files)}
        />

        <label htmlFor="image-upload">
          <Button isLoading={isLoading} variant="secondary" element="span">
            Upload filer
          </Button>
        </label>
      </ButtonContainer>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledHeader = styled.h3`
  margin: 20px 0px;
`;
export const UploadedFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 1rem;
  border: 1px solid lightgrey;
  padding: 10px;
  border-radius: 20px;
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.grey.black20};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export default TaskFilesStep;
