import {
  AddAccessConditionsDTO,
  AssetType2,
  MassLookupDTO,
  StationComponentEnum,
  UpdateAccessConditionsDTO
} from '../api/api';
import WorkClient from '../api/workClient';

const getAssets = (number: string, category: AssetType2) => {
  return WorkClient.asset(number, category);
};

const updateAccessConditions = (conditions: UpdateAccessConditionsDTO, type: AssetType2) => {
  return WorkClient.accessConditionsPUT(type, conditions);
};

const addAccessConditions = (conditions: AddAccessConditionsDTO, type: AssetType2) => {
  return WorkClient.accessConditionsPOST(type, conditions);
};

const massLookup = (body: MassLookupDTO) => {
  return WorkClient.massLookup(body);
};

const assetSearchResults = (assetIdentifier: string, type: AssetType2) => {
  return WorkClient.assetDetails(assetIdentifier, type);
};

const netstationComponents = (stationId: string) => {
  return WorkClient.netstationComponents(stationId);
};

const getComponentDetails = (objectId: number, componentType: StationComponentEnum) => {
  return WorkClient.netstationComponentDetails(objectId, componentType);
};

const AssetService = {
  getAssets,
  addAccessConditions,
  updateAccessConditions,
  massLookup,
  assetSearchResults,
  netstationComponents,
  getComponentDetails
};

export default AssetService;
