import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  setSelectedTaskId,
  setSelectedDate,
  getSelectedDate
} from '../../../../stateManagement/reducers/workPlanViewReducer';
import { store } from '../../../../stateManagement/store';
import DayOverviewHeader from './day-overview-header/DayOverviewHeader';
import DayOverViewTask from './day-overview-task/DayOverViewTask';
import { WorkTaskStatus } from '../../../../api/api';
import ExpandCollapse from '../../../../components/expand-collapse/ExpandCollapse';
import Typography from '../../../../components/typography/Typography';
import { Switch } from '@mui/material';
import { getDistanceInDays, getDistanceInDaysFromToday } from '../../../../utils/dateHandling';
import { WorkPlanItemDTOExtended } from '../../../../models/WorkPlanItem';

interface Props {
  tasks: WorkPlanItemDTOExtended[];
  handleShowDoneTasks?: (show: boolean) => void;
  fetchTasks: () => void;
  taskReadyForDriveAndStart?: number;
  isCalendar?: boolean;
  date?: Date;
}

const handleOnSelectTask = (taskId: number | undefined) => {
  store.dispatch(setSelectedTaskId(taskId));
};

const DayOverview = (props: Props) => {
  const { tasks, handleShowDoneTasks, fetchTasks, taskReadyForDriveAndStart, isCalendar = false, date } = props;

  const isToday = (date && getDistanceInDaysFromToday(date) === 0) ?? false;
  const [showDoneTasks, setShowDoneTasks] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(isToday || !isCalendar);
  const [toggleChecked, setToggleChecked] = useState<boolean>(false);

  const selectedDate = useSelector(getSelectedDate);

  const handleOnClickHeader = () => {
    const isSelectedDay = selectedDate && date && getDistanceInDays(date, selectedDate) === 0;
    const newSelectedDate = !isSelectedDay ? date : undefined;
    store.dispatch(setSelectedDate(newSelectedDate));
  };

  const onClickToggle: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    setToggleChecked(!toggleChecked);
    handleOnToggleShowSelected();
  };

  const handleOnToggleShowSelected = () => {
    handleShowDoneTasks && handleShowDoneTasks(!showDoneTasks);
    setShowDoneTasks(!showDoneTasks);
  };

  useEffect(() => {
    const isExpanded =
      (!isCalendar || (selectedDate !== undefined && date !== undefined && getDistanceInDays(date, selectedDate) === 0)) ??
      false;
    setExpanded(isExpanded);
  }, [selectedDate, date, isCalendar]);

  const numberOfFinishedTasks = tasks.reduce((sum, task) => {
    if (
      task.status === WorkTaskStatus.Completed ||
      task.status === WorkTaskStatus.Pause ||
      task.status === WorkTaskStatus.Processed
    ) {
      return sum + 1;
    }
    return sum;
  }, 0);

  return (
    <Container
      isMarked={
        !isCalendar && tasks[0]?.plannedArrival ? getDistanceInDaysFromToday(new Date(tasks[0].plannedArrival)) < 0 : false
      }
    >
      <DayOverviewHeader
        expanded={expanded}
        handleOnClick={handleOnClickHeader}
        tasks={tasks}
        date={date}
        isCalendar={isCalendar}
        isUnfinished={
          !isCalendar && tasks[0]?.plannedArrival ? getDistanceInDaysFromToday(new Date(tasks[0].plannedArrival)) < 0 : false
        }
      />
      <ExpandCollapse in={expanded} height={'100%'}>
        {tasks.filter(
          (task) =>
            isCalendar ||
            showDoneTasks ||
            (task.status !== WorkTaskStatus.Completed &&
              task.status !== WorkTaskStatus.Pause &&
              task.status !== WorkTaskStatus.Processed)
        ).length > 0 ? (
          <div data-testid={`${date && getDistanceInDaysFromToday(date)}-collapse-day-overview`}>
            {numberOfFinishedTasks > 0 && isToday && !isCalendar && (
              <StyledRightDiv>
                <StyledToggle
                  checked={toggleChecked}
                  color="default"
                  onClick={onClickToggle}
                  data-testid="show-done-toggle"
                />
                <Typography variant="span">Vis udførte</Typography>
              </StyledRightDiv>
            )}
            {tasks
              .filter(
                (task) =>
                  isCalendar ||
                  showDoneTasks ||
                  (task.status !== WorkTaskStatus.Completed &&
                    task.status !== WorkTaskStatus.Pause &&
                    task.status !== WorkTaskStatus.Processed)
              )
              .map((task) => {
                return (
                  <DayOverViewTask
                    key={task.workTaskId}
                    handleSelect={handleOnSelectTask}
                    task={task}
                    enableActions={
                      task.workTaskId === taskReadyForDriveAndStart ||
                      (!isCalendar && tasks[0]?.plannedArrival
                        ? getDistanceInDaysFromToday(new Date(tasks[0].plannedArrival)) < 0
                        : false)
                    }
                    fetchTasks={fetchTasks}
                    isUnfinished={
                      !isCalendar && tasks[0]?.plannedArrival
                        ? getDistanceInDaysFromToday(new Date(tasks[0].plannedArrival)) < 0
                        : false
                    }
                    isCalendar={isCalendar}
                  />
                );
              })}
          </div>
        ) : (
          <div>
            {numberOfFinishedTasks > 0 && isToday && (
              <StyledRightDiv>
                <StyledToggle
                  checked={toggleChecked}
                  color="default"
                  onClick={onClickToggle}
                  data-testid="show-done-toggle"
                />
                <Typography variant="span">Vis udførte</Typography>
              </StyledRightDiv>
            )}
            <EmptyTaskList>
              <Typography variant="h6" fontWeight="bold">
                {isToday ? 'Din liste af planlagte opgaver i dag er tom' : 'Du har ingen planlagte opgaver på denne dag'}
              </Typography>
              {isToday && <Typography variant="h6">Kontakt planlægning for at få opgaver tildelt</Typography>}
            </EmptyTaskList>
          </div>
        )}
      </ExpandCollapse>
    </Container>
  );
};

const Container = styled.div<{ isMarked?: boolean }>`
  width: 100%;
  background-color: ${(props) => (props.isMarked ? props.theme.palette.main.yellow.tertiary : '')};
`;

const StyledRightDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const StyledToggle = styled(Switch)`
  margin-left: 25px;
`;

const EmptyTaskList = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export default DayOverview;
