import styled from 'styled-components';
import { TaskTypeDetailedDTO2, BaseWorkTaskTypeEnum, WorkTaskRelativeDTO } from '../../../../api/api';
import { ArrowDownRightIcon } from '../../../../assets/icons/ArrowDownRightIcon';
import addQueryParams from '../../../../utils/routing/add-query-params';
import { useNavigate } from 'react-router-dom';
import useWorkTaskLock from '../../../../hooks/useWorkTaskLock';

interface Props {
  parent?: WorkTaskRelativeDTO;
  taskType: TaskTypeDetailedDTO2;
  id: string;
  children?: WorkTaskRelativeDTO[];
}

const RelationalTree = (props: Props) => {
  const { parent, taskType, id, children } = props;

  const navigate = useNavigate();
  const { releaseWorkTaskLock } = useWorkTaskLock();

  const handleOnClickRelative = (id: number) => {
    navigate(
      addQueryParams(undefined, {
        id: id,
        type: BaseWorkTaskTypeEnum.WorkTask
      }),
      {
        replace: true,
        state: { payload: { state: { type: BaseWorkTaskTypeEnum.WorkTask } } }
      }
    );
    releaseWorkTaskLock(id);
  };

  return (
    <StyledTagContainer>
      <StyledChildContainer onClick={() => handleOnClickRelative(parent?.workTaskId ?? 0)}>
        {parent?.taskTypeName} {parent?.workTaskId}
      </StyledChildContainer>
      <StyledChildContainer indent={false} color="#0085FF">
        {!!parent && <ArrowDownRightIcon color="#0085FF" size="14px" />}
        {taskType?.name + ' ' + id}
      </StyledChildContainer>
      {children?.map((child) => (
        <StyledChildContainer
          key={child.workTaskId}
          indent={!!parent}
          onClick={() => handleOnClickRelative(child.workTaskId ?? 0)}
        >
          <ArrowDownRightIcon size="14px" />
          {child.taskTypeName} {child.workTaskId}
        </StyledChildContainer>
      ))}
    </StyledTagContainer>
  );
};

export const StyledTagContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const StyledChildContainer = styled.div<{ indent?: boolean; color?: string }>`
  padding-left: ${(props) => (props.indent ? '15px' : '0px')};
  color: ${(props) => props.color};
  cursor: pointer;
`;

export default RelationalTree;
