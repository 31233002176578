import { Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  ADD_TIME_ROUTE,
  EDIT_TASK_ROUTE,
  IMAGE_ROUTE,
  MASS_CREATION,
  PORTAL_ROUTE,
  PROFILE_ROUTE,
  SCHEDULE_ROUTE,
  TASKS_DETAILS_DIALOG_ROUTE,
  TECHNICIANDUTY_ROUTE,
  TIME_ROUTE
} from './utils/constants';
import CreateTaskView from './views/create-task-view/CreateTaskView';
import Shell from './views/shell/Shell';
import TimeRegView from './views/time-reg-view/TimeRegView';

import TimeRegDialog from './blocks/timereg-dialog/TimeRegDialog';
import routeInPathname from './utils/routing/route-in-pathname';
import PortalView from './views/portal-view/PortalView';
import ProfileView from './views/profile-view/ProfileView';
import TaskDetailsView from './views/task-view/task-details-view2/TaskDetailsView';
import TechnicianDutyShell from './views/technician-duty-view/TechnicianDutyShell';

import UserRoleRoutes from './UserRole-routes';
import MassCreationTaskView from './views/mass-creation-task-view/MassCreationTaskView';
import ScheduleView from './views/schedule-view/ScheduleView';
import Image from './views/image-view/image';

const App = () => {
  const path = useLocation().pathname;
  const includedRoute = routeInPathname(path);

  return (
    <Container>
      <Routes>
        <Route path={IMAGE_ROUTE} element={<Image />} />
        <Route path={TECHNICIANDUTY_ROUTE} element={<TechnicianDutyShell />} />
        <Route path="/*" element={<Shell />}>
          <Route path={TIME_ROUTE + '/*'} element={<TimeRegView />} />
          <Route path={SCHEDULE_ROUTE + '/*'} element={<ScheduleView />} />
          <Route path={PORTAL_ROUTE} element={<PortalView />} />

          {UserRoleRoutes()}
        </Route>
      </Routes>

      {includedRoute === ADD_TIME_ROUTE && <TimeRegDialog />}
      {includedRoute === EDIT_TASK_ROUTE && <CreateTaskView />}
      {includedRoute === TASKS_DETAILS_DIALOG_ROUTE && <TaskDetailsView />}
      {includedRoute === MASS_CREATION && <MassCreationTaskView />}
      {includedRoute === PROFILE_ROUTE && <ProfileView />}
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  height: 100%;
`;

export default App;
