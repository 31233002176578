import { AssetType2, StretchDTO } from '../../../../../api/api';
import Stretch from './Stretch';
import { AddIcon } from '../../../../../assets/icons/AddIcon';
import { SectionProps } from '../../../../../models/CommonProps/SectionProps';
import { useCallback, useEffect, useState } from 'react';
import { Row } from '../../../../../styling/FormStyling';
import Button from '../../../../../components/button/Button';
import { SectionContent } from '../../../../../styling/FormStyling';
import styled from 'styled-components';
import Typography from '../../../../../components/typography/Typography';
import Link from '../../../../../components/link/Link';

interface Props extends SectionProps {
  value?: StretchDTO[];
  onStretchChange?: (value: StretchDTO[]) => void;
  showErrors?: boolean;
  handleGoToAsset?: () => void;
  mode: 'create' | 'read' | 'write';
  isEditable: boolean;
}

const StretchesSection = (props: Props) => {
  const { value, readonlyMode, onStretchChange, showErrors, mode, isEditable, handleGoToAsset } = props;

  const [stretches, setStretches] = useState<StretchDTO[]>([]);

  useEffect(() => {
    if (value) {
      setStretches(value);
    }
  }, [value]);

  const updateStretches = useCallback(
    (stretches: StretchDTO[]) => {
      if (!onStretchChange) return;

      setStretches(stretches);
      onStretchChange(stretches);
    },
    [onStretchChange]
  );

  const handleUpdateStretch = useCallback(
    (index: number, stretch: StretchDTO) => {
      updateStretches(
        stretches.map((s, i) => {
          return i === index ? stretch : s;
        })
      );
    },
    [stretches, updateStretches]
  );

  const handleRemoveStretch = useCallback(
    (index: number) => {
      updateStretches(
        stretches.filter((s, i) => {
          return i !== index;
        })
      );
    },
    [stretches, updateStretches]
  );

  const handleAddStretch = () => {
    updateStretches([
      ...stretches,
      { from: '', assetTypeFrom: AssetType2.Netstation, to: '', assetTypeTo: AssetType2.Netstation }
    ]);
  };

  return (
    <Container>
      {stretches?.map((stretch, index) => {
        return (
          <>
            <Row key={index} data-testid="stretch">
              <Stretch
                stretch={stretch}
                handleUpdateStretch={handleUpdateStretch}
                index={index}
                handleRemoveStretch={handleRemoveStretch}
                readonlyMode={readonlyMode}
                data-testid="stretch"
                showErrors={showErrors}
                mode={mode}
                isEditable={isEditable}
              />
            </Row>
            {mode === 'create' && stretches.length === index + 1 && (
              <Typography color="warning">
                Husk at angive det stationsnummer med det laveste nummer, som asset på opgavekortet.{' '}
                {handleGoToAsset && <Link onClick={handleGoToAsset}>Gå til asset her</Link>}
              </Typography>
            )}
          </>
        );
      })}

      {stretches && (mode === 'create' || (mode === 'write' && isEditable)) && (
        <Row>
          <Button onClick={handleAddStretch} variant="secondary" data-testid="add-stretch">
            <AddIcon size="20px" />
            Tilføj strækning
          </Button>
        </Row>
      )}
    </Container>
  );
};

const Container = styled(SectionContent)`
  width: 100%;
`;

export const StretchContainer = styled(Row)`
  width: 100%;
`;

export default StretchesSection;
