import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const TechnicianIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
      <path d="M365.33-80v-535q-93.66-21.33-150.16-90.5T158.67-864h66.66q0 79.67 58.17 134.5t143.83 54.83h100q35.34 0 56 8 20.67 8 46.34 31l183 175-47.34 47.34-176-168.67v502h-66.66v-250H432v250h-66.67Zm112.06-649.33q-31.06 0-53.22-22.12Q402-773.56 402-804.61q0-31.06 22.11-53.22Q446.23-880 477.28-880t53.22 22.11q22.17 22.12 22.17 53.17t-22.12 53.22q-22.11 22.17-53.16 22.17Z" />
    </IconSvg>
  );
};
