import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import DialogView from '../../../components/dialog-view/DialogView';
import removeQueryParams from '../../../utils/routing/remove-query-params';

import TaskDetailsViewContent from './TaskDetailsViewContent';
import {
  CALENDAR_ROUTE,
  EDIT_TASK_ROUTE,
  TASKS_DETAILS_DIALOG_ROUTE,
  TASKS_ROUTE,
  WORKPLAN_ROUTE
} from '../../../utils/constants';
import NavigationHeader from './navigation-header/NavigationHeader';

import { isDevice } from '../../../utils/device-handling/deviceDetectionUtils';
import useWorkTaskLock from '../../../hooks/useWorkTaskLock';
import { QueryParams } from '../../../models/QueryParams';
import useQuery from '../../../hooks/useQuery';
import useChecklistLock from '../../../hooks/useChecklistLock';
import { useNavigate } from '../../../hooks/useNavigate';

const TaskDetailsView = () => {
  const [toolsOpen, setToolsOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useQuery<QueryParams>();

  const { releaseWorkTaskLock } = useWorkTaskLock();
  const { releaseChecklistLock } = useChecklistLock();

  const fullscreenTask = isDevice();
  const prevLocation = location?.state?.prevLocation?.pathname ?? undefined;
  const header = prevLocation?.includes(EDIT_TASK_ROUTE)
    ? 'Opret opgave'
    : prevLocation?.includes(WORKPLAN_ROUTE)
    ? 'Opgaver'
    : prevLocation?.includes(CALENDAR_ROUTE)
    ? 'Kalender'
    : prevLocation?.includes(TASKS_ROUTE)
    ? 'Opgaveliste'
    : '';

  const handleCloseDialog = useCallback(() => {
    releaseWorkTaskLock(parseInt(id ?? ''));
    releaseChecklistLock();

    if (prevLocation?.includes(EDIT_TASK_ROUTE)) {
      navigate(-1);
    } else {
      navigate(removeQueryParams(['id', 'type'], TASKS_DETAILS_DIALOG_ROUTE), {
        state: { prevLocation: location }
      });
    }
  }, [id, location, navigate, prevLocation, releaseChecklistLock, releaseWorkTaskLock]);

  const handleCloseTask = useCallback(() => {
    releaseWorkTaskLock(parseInt(id ?? ''));
    releaseChecklistLock();
    navigate(-1);
  }, [id, navigate, releaseChecklistLock, releaseWorkTaskLock]);

  return (
    <>
      {fullscreenTask ? (
        <>
          <NavigationHeader onClick={handleCloseTask} title={header} />
          <StyledContent onClick={() => toolsOpen && setToolsOpen(false)}>
            <TaskDetailsViewContent
              hideCloseButton
              toolsOpen={toolsOpen}
              setToolsOpen={(open) => setToolsOpen(open)}
              handleCloseTask={handleCloseTask}
            />
          </StyledContent>
        </>
      ) : (
        <DialogView handleClose={handleCloseDialog} onClick={() => toolsOpen && setToolsOpen(false)}>
          <StyledDialogContent>
            <TaskDetailsViewContent
              toolsOpen={toolsOpen}
              setToolsOpen={(open) => setToolsOpen(open)}
              handleCloseTask={handleCloseDialog}
            />
          </StyledDialogContent>
        </DialogView>
      )}
    </>
  );
};

const StyledDialogContent = styled.div`
  padding: 0;
  height: 90vh;
  width: 85vw;
  max-width: 1400px;
  overflow: hidden;

  @media (max-width: 1200px) {
    width: 95vw;
  }
`;
const StyledContent = styled.div`
  height: 100%;
  width: 100vw;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: start;
  overflow: hidden;
`;

export default TaskDetailsView;
