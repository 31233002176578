//Routes
export const TASKS_ROUTE = 'tasks';
export const TIME_ROUTE = 'time';
export const GOODS_ROUTE = 'goods';
export const ORDER_GOODS_ROUTE = 'goods-order';
export const MOVE_GOODS_ROUTE = 'goods-move';
export const RETURN_GOODS_ROUTE = 'return';
export const RECEIPT_ROUTE = 'receipt';
export const TECHNICIANDUTY_ROUTE = 'vagt';
export const TASKS_DETAILS = 'task-details';
export const WORKPLAN_ROUTE = 'workplan';
export const CALENDAR_ROUTE = 'calendar';
export const TECHNICIAN_TASKLIST_ROUTE = 'tasks-technician';
export const PORTAL_ROUTE = 'portal';
export const INTERNAL_TIME_OFFICE = 'internal-office';
export const ADMIN_NEWS = 'admin/news';
export const ADMIN_DEPARTMENTS = 'admin/departments';
export const ADMIN_TASKTYPES = 'admin/tasktypes';
export const ADMIN_REUSABLECOMPONENTS = 'admin/reusable-components';
export const ADMIN_PORTAL_LINKS = 'admin/portal-links';
export const ADMIN_REASONCODES = 'admin/reason-codes';
export const ADMIN_QUESTIONS = 'admin/questions';
export const MAP_TASK_LIST_ROUTE = 'map';
export const SCHEDULE_ROUTE = 'schedule';
export const COUNT_GOODS_ROUTE = 'count-goods';
export const IMAGE_ROUTE = 'image';
export const NEWS_ROUTE = 'news';

// Dialogs
export const ADD_TIME_ROUTE = '/add-timereg';
export const EDIT_TASK_ROUTE = '/edit-task';
export const TASKS_DETAILS_DIALOG_ROUTE = '/task-details-dialog';
export const MASS_CREATION = '/mass-creation';
export const PROFILE_ROUTE = '/profile';

//DepartmentId
export const PLANNING_DEPARTMENT_ID = 17;

//D365 related constants
export const D365_LEGALENTITYID_FOR_PRODUCTS = '110';

//Error codes
export enum ErrorCodes {
  ERP_CALL_ERROR = 'ERP_CALL_ERROR',
  PROJECT_VALIDATION_ERROR = 'PROJECT_VALIDATION_ERROR'
}
