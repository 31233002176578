import WorkClient from '../api/workClient';

const getMessages = (worktaskId: number) => {
  return WorkClient.getAllMessages(worktaskId);
};

const MessageService = {
  getMessages
};

export default MessageService;
