import { useLocation, useNavigate as useReactNavigate } from 'react-router-dom';

export const useNavigate = <T extends unknown>() => {
  const navigate = useReactNavigate();
  const location = useLocation();

  return (to: string | number, payload?: T) => {
    if (typeof to === 'string') {
      navigate(to, {
        state: {
          prevLocation: location,
          payload: payload
        }
      });
    } else if (to === -1) {
      const state = location.state as { prevLocation: string };
      state?.prevLocation
        ? navigate(state.prevLocation, {
            state: {
              prevLocation: location,
              payload: payload
            }
          })
        : navigate(-1);
    } else {
      navigate(to);
    }
  };
};
