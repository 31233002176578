import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { AssetResultDTO } from '../../../../api/api';
import TextField from '../../../../components/text-field/TextField';
import { BaseErrors } from '../../../../models/CreationErrors';
import AssetAutocomplete from '../../../../components/asset-autocomplete/AssetAutocomplete';

interface Props {
  handleLocationSelected: (locationResult: AssetResultDTO) => void;
  handleLocationsReceived?: (asset: AssetResultDTO) => void;
  locationResults?: AssetResultDTO[];
  selectedAsset?: AssetResultDTO;
  errors?: BaseErrors;
}

const LocationSearch = (props: Props) => {
  const { handleLocationSelected, handleLocationsReceived, locationResults, selectedAsset, errors } = props;

  const [asset, setAsset] = useState<AssetResultDTO | undefined>(selectedAsset ?? undefined);
  const [showSearchResult, setShowSearchResult] = useState(
    !!locationResults && locationResults.length > 0 && locationResults[0]?.assetId ? true : false
  );

  useEffect(() => {
    if (selectedAsset) {
      setAsset(selectedAsset);
      setShowSearchResult(true);
    } else {
      setShowSearchResult(false);
    }
  }, [selectedAsset]);

  const handleOnAssetSelected = useCallback(
    (_asset: AssetResultDTO) => {
      handleLocationSelected(_asset);
      handleLocationsReceived && handleLocationsReceived(_asset);
      setAsset(_asset);
      setShowSearchResult(true);
    },
    [handleLocationSelected, handleLocationsReceived]
  );

  return (
    <>
      <Container>
        <AssetAutocomplete
          id="searchTerm"
          label={'Fremsøg asset'}
          onSelect={(_asset) => handleOnAssetSelected(_asset)}
          required
          inputMode
          error={errors?.assetId}
          prevAsset={selectedAsset}
        />
      </Container>

      {showSearchResult && asset && asset.assetId && (
        <Row>
          <TextField
            disabled
            label="Adresse - asset"
            fullWidth
            value={`${asset.assetId}${!!asset.location?.city || !!asset.location?.streetName ? ' - ' : ''}${
              asset.location?.streetName ?? ''
            } ${asset.location?.houseNumber ?? ''} ${asset.location?.postalCode ?? ''} ${asset.location?.city ?? ''}`}
          />
        </Row>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 28px;
`;

const Row = styled.div`
  display: flex;
  column-gap: 24px;
`;

export default LocationSearch;
