import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';

import { store } from './stateManagement/store';

import n1LightTheme from './theme/light-theme';
import { loginRequest } from './utils/authConfig';
import { msalInstance } from './utils/authProvider/authProvider';

import * as dayjs from 'dayjs';
import 'dayjs/locale/da';
import GlobalStyles from './styling/GlobalStyles';
import EnviromentIndicator from './components/enviroment-indicator/EnviromentIndicator';
dayjs.locale('da');

declare global {
  interface Window {
    _env_: any;
  }
}

const muiTheme = createTheme({
  typography: {
    fontFamily: `'Overpass', sans-serif`
  }
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={n1LightTheme}>
      <React.StrictMode>
        <GlobalStyles />
        <MuiThemeProvider theme={muiTheme}>
          <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
              <BrowserRouter>
                <EnviromentIndicator />
                <App />
              </BrowserRouter>
            </MsalAuthenticationTemplate>
          </MsalProvider>
        </MuiThemeProvider>
      </React.StrictMode>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
