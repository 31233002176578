import { useSelector } from 'react-redux';
import { ProfileDTO2Role } from '../api/api';
import { selectUserProfile } from '../stateManagement/reducers/userProfileReducer';
import { useCallback } from 'react';

const useUserAccess = () => {
  const userProfileState = useSelector(selectUserProfile);
  const userRole = userProfileState.userProfile?.role;

  const technicianUserGroupAllowed = useCallback(() => {
    if (!userProfileState?.isLoaded) return false;
    return (
      !userRole ||
      userRole === ProfileDTO2Role.Technician ||
      userRole === ProfileDTO2Role.CombinedTechnicianAndOffice ||
      userRole === ProfileDTO2Role.Developer
    );
  }, [userProfileState, userRole]);

  const officeUserGroupAllowed = useCallback(() => {
    if (!userProfileState?.isLoaded) return false;
    return (
      userRole === ProfileDTO2Role.Office ||
      userRole === ProfileDTO2Role.CombinedTechnicianAndOffice ||
      userRole === ProfileDTO2Role.Admin ||
      userRole === ProfileDTO2Role.Developer
    );
  }, [userProfileState, userRole]);

  const adminUserGroupAllowed = useCallback(() => {
    if (!userProfileState?.isLoaded) return false;
    return userRole === ProfileDTO2Role.Admin || userRole === ProfileDTO2Role.Developer;
  }, [userProfileState, userRole]);

  return { technicianUserGroupAllowed, officeUserGroupAllowed, adminUserGroupAllowed };
};
export default useUserAccess;
