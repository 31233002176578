import { NewsDTONewsType } from '../../api/api';
import n1LightTheme from '../../theme/light-theme';

export const getTextColor = (newsType?: NewsDTONewsType) => {
  switch (newsType) {
    case NewsDTONewsType.ResolvedError:
      return n1LightTheme.palette.functions.success.primary;
    case NewsDTONewsType.OperationalError:
      return n1LightTheme.palette.functions.error.primary;
    case NewsDTONewsType.Standard:
      return n1LightTheme.palette.text.primary;
  }
};

export const getBackgroundColor = (newsType?: NewsDTONewsType) => {
  switch (newsType) {
    case NewsDTONewsType.ResolvedError:
      return n1LightTheme.palette.functions.success.tertiary;
    case NewsDTONewsType.OperationalError:
      return n1LightTheme.palette.functions.error.tertiary;
    case NewsDTONewsType.Standard:
      return n1LightTheme.palette.background.primary;
    default:
      return n1LightTheme.palette.background.primary;
  }
};
