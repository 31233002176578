import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../components/button/Button';
import useDepartmentsList from './useDepartmentsList';
import Typography from '../../../components/typography/Typography';
import { AddIcon } from '../../../assets/icons/AddIcon';
import AdminCreateDepartmentDialog from './AdminCreateDepartmentDialog';
import Table from '../../../blocks/table/TableClient';
import { Header } from '../../goods-view/GoodsView';
import { DepartmentDTO } from '../../../api/api';
import NotificationService from '../../../services/NotificationService';
import LoadingOverlay from '../../../components/loading-overlay/LoadingOverlay';
import { log } from '../../../utils/logging/log';
import DepartmentService from '../../../services/DepartmentService';

const AdminDepartmentsView = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [allDepartments, setAllDepartments] = useState<DepartmentDTO[]>([]);
  const [editingDepartment, setEditingDepartment] = useState<DepartmentDTO>();
  const [loading, setLoading] = useState(false);

  const createDepartmentObjectCallback = (department: DepartmentDTO) => {
    setAllDepartments((prev) => [...prev, department]);
    setShowDialog(false);
  };

  const editDepartmentCallback = (updatedDepartment: DepartmentDTO) => {
    setAllDepartments((prev) =>
      prev.map((department) =>
        department.departmentId === updatedDepartment.departmentId ? (updatedDepartment as DepartmentDTO) : department
      )
    );
  };

  const editDepartmentClickedHandler = async (department: DepartmentDTO) => {
    setEditingDepartment(department);
    setShowDialog(true);
  };

  useEffect(() => {
    setLoading(true);
    DepartmentService.GetDepartments()
      .then((response) => {
        setAllDepartments(response);
      })
      .catch((e) => {
        log(e);
        NotificationService.error('Kunne ikke hente afdelinger');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const { tableInstance } = useDepartmentsList(allDepartments, editDepartmentClickedHandler);
  return (
    <Container>
      <Header>
        <Typography variant="h3">Afdelinger</Typography>
        <Button onClick={() => setShowDialog(true)}>
          <AddIcon size="20px" />
          Opret ny afdeling
        </Button>
      </Header>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <>
          <Table loading={false} tableInstance={tableInstance} />
          <AdminCreateDepartmentDialog
            setShowDialog={() => setShowDialog(false)}
            showDialog={showDialog}
            createDepartmentObjectCallback={createDepartmentObjectCallback}
            editingDepartment={editingDepartment}
            editDepartmentObjectCallback={editDepartmentCallback}
            resetEditingDepartment={() => setEditingDepartment(undefined)}
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;

  & > *:nth-child(1) {
    padding: ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(7)} 0px
      ${(props) => props.theme.spacing(7)};
  }
`;

export default AdminDepartmentsView;
