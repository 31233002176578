import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ToastModel } from '../../models/Toast';
import Alert from '../alert/Alert';

interface Props {
  onClose?: () => void;
  children: React.ReactNode;
  toast: ToastModel;
}

const Toast = (props: Props) => {
  const { onClose, children } = props;
  const { severity, duration } = props.toast;
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (duration) {
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => onClose && onClose(), duration);
    }
  }, [duration, onClose]);

  return (
    <Container>
      <Alert severity={severity} height="100%" onClose={onClose}>
        {children}
      </Alert>
    </Container>
  );
};

const Container = styled.div`
  top: ${(props) => props.theme.spacing(6)};
  z-index: ${(props) => props.theme.zIndex.toast};
  left: ${(props) => props.theme.spacing(30)};
  height: 48px;
  position: fixed;
`;

export default Toast;
