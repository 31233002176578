import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ChevronUp = (props: IconProps) => {
  return (
    <IconSvg {...props} data-name="Layer 1" viewBox="35.771 38.329 15.17 8.368" xmlns="http://www.w3.org/2000/svg">
      <polyline
        fill="none"
        strokeWidth="1.5"
        points="36.219 39.11 43.372 46.263 50.524 39.11"
        transform="matrix(-1, 0, 0, -1, 86.743, 85.373001)"
      ></polyline>
    </IconSvg>
  );
};
