import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const AddDocumentIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} data-testid="add-icon" viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
      <path d="M440-240h80v-120h120v-80H520v-120h-80v120H320v80h120v120ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z" />
    </IconSvg>
  );
};
