import styled, { css } from 'styled-components';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'standard' | 'outlined' | 'primary';
  children?: React.ReactNode;
  padding?: string;
  size?: string;
  disabledBackground?: boolean;
}

const IconButton = (props: Props) => {
  const { variant, children, padding, size, ...rest } = props;

  return (
    <>
      <StyledButton variant={variant ?? 'standard'} padding={padding} size={size} {...rest}>
        {children}
      </StyledButton>
    </>
  );
};

const standardStyles = css`
  background: transparent;
  border: 1px solid transparent;
`;

const outlinedStyles = css`
  background: #ffffff;
  color: ${(props) => props.theme.palette.main.black.primary};
  border: ${(props) => `1px solid ${props.theme.palette.grey.black20}`};

  :hover {
    background: ${(props) => props.theme.palette.grey.black5};
  }

  :active {
    background: ${(props) => props.theme.palette.main.black.primary};
    border: 1px solid transparent;
    color: #ffffff;

    svg {
      fill: #ffffff;
      stroke: #ffffff;
    }
  }
`;

const primaryStyles = css`
  background: ${(props) => props.theme.palette.main.yellow.primary};
  color: ${(props) => props.theme.palette.main.black.primary};
  border: 1px solid transparent;

  :hover {
    background: #ffe357;
  }
`;

const disabledStyles = css<Props>`
  background: ${(props) => (!props.disabledBackground ? props.theme.palette.grey.black5 : 'transparent')};
  color: ${(props) => props.theme.palette.grey.black40};
  border: 1px solid transparent;

  svg {
    fill: ${(props) => props.theme.palette.grey.black40};
    stroke: ${(props) => props.theme.palette.grey.black40};
  }
`;

const StyledButton = styled.button<Props>`
  margin: 0;
  padding: ${(props) => props.padding ?? '12px'};
  width: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${({ size }) =>
    size &&
    css`
      width: ${size};
      height: ${size};
    `};

  :hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  ${(props) => props.variant === 'standard' && !props.disabled && standardStyles};
  ${(props) => props.variant === 'outlined' && !props.disabled && outlinedStyles};
  ${(props) => props.variant === 'primary' && !props.disabled && primaryStyles};
  ${(props) => props.disabled && disabledStyles}
`;

export default IconButton;
