import styled from 'styled-components';
import ToolTip from '../tool-tip/ToolTip';
import { InfoIcon } from '../../assets/icons/InfoIcon';
import Typography from '../typography/Typography';
interface Props {
  text: string;
  required?: boolean;
  helpText?: string;
  error?: boolean;
  readOnly?: boolean;
}

const QuestionHeader = (props: Props) => {
  const { text, required, helpText, error, readOnly = false } = props;

  return (
    <Header>
      <StyledTypography variant="h6" textAlign="left" error={error} readOnly={readOnly}>
        {text}
        {required ? '*' : ''}
      </StyledTypography>
      {helpText && (
        <ToolTipContainer>
          <ToolTip title={helpText}>
            <div>
              <InfoIcon size="16px" />
            </div>
          </ToolTip>
        </ToolTipContainer>
      )}
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
`;

const ToolTipContainer = styled.div`
  margin-left: 5px;
`;

const StyledTypography = styled(Typography)<{ error: boolean | undefined; readOnly?: boolean }>`
  font-size: ${(props) => (props.readOnly ? '14px' : '1rem')};
  color: ${(props) =>
    props.error
      ? props.theme.palette.functions.error.primary
      : props.readOnly
      ? props.theme.palette.grey.black40
      : props.theme.palette.text.primary};
  font-weight: 400;
`;

export default QuestionHeader;
