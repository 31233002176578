import styled from 'styled-components';
import Button from '../button/Button';
import { useState } from 'react';
import TextField from '../text-field/TextField';

export type Severity = 'error' | 'warning' | 'action' | 'success';

interface Props {
  handleSubmitCallback: (text: string) => void;
  handleCancelCallback?: () => void;
  isLoading?: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
}

const Form = (props: Props) => {
  const { handleSubmitCallback, handleCancelCallback, isLoading, submitButtonText, cancelButtonText } = props;

  const [text, setText] = useState('');

  const submitHandler = (event: any) => {
    event.preventDefault();
    handleSubmitCallback(text);
  };

  const cancel = () => {
    setText('');
    handleCancelCallback && handleCancelCallback();
  };

  return (
    <>
      <CommentField
        fullWidth
        label="Besked"
        multiline
        onChange={(e: any) => setText(e.target.value)}
        placeholder="Skriv besked her"
        rows={5}
        value={text}
      />
      <ButtonContainer>
        <Button variant="secondary" onClick={cancel}>
          {cancelButtonText ?? 'Ryd'}
        </Button>
        <Button disabled={!text} isLoading={isLoading} onClick={(e) => submitHandler(e)}>
          {submitButtonText ?? 'Send'}
        </Button>
      </ButtonContainer>
    </>
  );
};

const CommentField = styled(TextField)`
  width: 100%;
  margin-top: ${(props) => props.theme.spacing(8)} !important;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing(6)};
`;

export default Form;
