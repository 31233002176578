import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import {
  dateRangeFilterFn,
  Cell,
  multiSelectFilterFn,
  multiSelectStringArrayFilterFn,
  MultiSelectStringArrayFilterFn,
  SearchColumnFilter,
  SelectBooleanColumnFilter,
  SelectColumnFilter
} from '../../../blocks/table/TableFilterUtils';
import useTableInstance from '../../../hooks/useTableInstance';
import { TaskTypeDetailedDTO2 } from '../../../api/api';

import { getEnumDisplayValue, Voltage } from '../../../utils/enumUtils';
import ToolTip from '../../../components/tool-tip/ToolTip';
import { DateRangeColumnFilter } from '../../task-view/task-list-view/TaskListFilterUtils';
import { formatDate } from '../../../utils/dateHandling';

export interface TaskTypeData {
  id?: number;
  name?: string;
  normTimeMin?: number;
  slaDays?: number;
  projectNumber_OneERP?: string;
  projectNumber_North?: string;
  projectNumber_South?: string;
  defaultDepartment?: string;
  enableContractorAgreements?: string;
  subWorkTaskType?: string;
  emergencyOperationalFailure?: string;
  specificAssetType?: string;
  taskTypeCategories?: string[];
  voltageLevel?: string[];
  flsCallTypeId?: string;
  isActive?: string;
  taskTypeOwner?: string;
  taskTypeOwnerEmail?: string;
  priority?: string;
  description: string;
  enableStretches?: string;
  ignoreDrivingTimeWhenPlanned?: string;
  requiresPreparationTask?: string;
  defaultRelationalTaskTypeId?: number;
}

const getVoltageLevels = (task: TaskTypeDetailedDTO2) => {
  const levels = [];

  task.voltageLevelLow && levels.push(getEnumDisplayValue(Voltage.Low));
  task.voltageLevelMedium && levels.push(getEnumDisplayValue(Voltage.Medium));
  task.voltageLevelHigh && levels.push(getEnumDisplayValue(Voltage.High));

  return levels;
};

const useTaskTypeList = (taskTypes: TaskTypeDetailedDTO2[]) => {
  const data = useMemo(() => {
    if (!taskTypes.length) return [];

    return taskTypes.map((task) => {
      return {
        id: task.id,
        name: task.name,
        normTimeMin: task.normTimeMin,
        slaDays: task.slaDays,
        projectNumber_OneERP: task.projectNumber_OneERP,
        projectNumber_North: task.projectNumber_North,
        projectNumber_South: task.projectNumber_South,
        defaultDepartment: task?.defaultDepartment?.name ?? '',
        enableContractorAgreements: task.enableContractorAgreements ? 'Ja' : 'Nej',
        subWorkTaskType: task.subWorkTaskType ? getEnumDisplayValue(task.subWorkTaskType) : '',
        emergencyOperationalFailure: task.emergencyOperationalFailure ? 'Ja' : 'Nej',
        specificAssetType: task.specificAssetType,
        taskTypeCategories: task.taskTypeCategories?.map((obj) => obj.name),
        flsCallTypeId: task.flsCallTypeId,
        isActive: task.isDisabled ? 'Nej' : 'Ja',
        taskTypeOwner: task.taskTypeOwner,
        taskTypeOwnerEmail: task.taskTypeOwnerEmail,
        voltageLevel: getVoltageLevels(task),
        priority: task.priority ? getEnumDisplayValue(task.priority) : '',
        description: task.description,
        enableStretches: task.enableStretches ? 'Ja' : 'Nej',
        ignoreDrivingTimeWhenPlanned: task.ignoreDrivingTimeWhenPlanned ? 'Ja' : 'Nej',
        requiresPreparationTask: task.requiresPreparationTask ? 'Ja' : 'Nej',
        defaultRelationalTaskTypeId: task.defaultRelationalTaskTypeId,
        updated: task.updated
      } as TaskTypeData;
    });
  }, [taskTypes]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
        width: 90,
        minWidth: 90,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Aktiv',
        accessor: 'isActive',
        width: 100,
        minWidth: 100,
        disableSortBy: true,
        Filter: SelectBooleanColumnFilter,
        filter: multiSelectFilterFn
      },
      {
        Header: 'Navn',
        accessor: 'name',
        width: 200,
        minWidth: 200,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Definition',
        accessor: 'description',
        width: 200,
        minWidth: 200,
        Filter: SearchColumnFilter,
        filter: 'text',
        Cell: (cellProps: CellProps<TaskTypeData>) => {
          const { cell } = cellProps;
          const description = cell.value ?? ('' as string);
          const shortDescription = description.length > 50 ? description.substring(0, 50) + '...' : description;
          return (
            <Cell>
              <ToolTip title={cell.row.original.description ?? ''}>{shortDescription}</ToolTip>
            </Cell>
          );
        }
      },
      {
        Header: 'Normtid',
        accessor: 'normTimeMin',
        width: 120,
        minWidth: 120,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'SLA-dage',
        accessor: 'slaDays',
        width: 120,
        minWidth: 120,
        Filter: SearchColumnFilter,
        filter: 'text',
        disableSortBy: true
      },
      {
        Header: 'ProjektNr. OneERP',
        accessor: 'projectNumber_OneERP',
        width: 120,
        minWidth: 120,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Afdeling',
        accessor: 'defaultDepartment',
        width: 120,
        minWidth: 120,
        Filter: SelectColumnFilter,
        filter: multiSelectFilterFn
      },

      {
        Header: 'Kategori',
        accessor: 'taskTypeCategories',
        width: 150,
        minWidth: 150,
        disableSortBy: true,
        Filter: MultiSelectStringArrayFilterFn,
        filter: multiSelectStringArrayFilterFn,
        Cell: (cellProps: CellProps<TaskTypeData>) => {
          const { cell } = cellProps;
          return cell.row.original.taskTypeCategories?.join(', ');
        }
      },
      {
        Header: 'Assettype',
        accessor: 'specificAssetType',
        width: 120,
        minWidth: 120,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: multiSelectFilterFn
      },
      {
        Header: 'Spændingsniveau',
        accessor: 'voltageLevel',
        width: 200,
        minWidth: 200,
        Filter: MultiSelectStringArrayFilterFn,
        filter: multiSelectStringArrayFilterFn,
        Cell: (cellProps: CellProps<TaskTypeData>) => {
          const { cell } = cellProps;
          return cell.row.original.voltageLevel?.join(', ');
        }
      },
      {
        Header: 'Arbejdsinstruks',
        accessor: 'enableContractorAgreements',
        width: 120,
        minWidth: 120,
        Filter: SelectBooleanColumnFilter,
        filter: 'text'
      },
      {
        Header: '2-mandsopgave',
        accessor: 'subWorkTaskType',
        width: 120,
        minWidth: 120,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: multiSelectFilterFn
      },
      {
        Header: 'Prioritet',
        accessor: 'priority',
        width: 120,
        minWidth: 120,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: multiSelectFilterFn
      },
      {
        Header: 'Akut driftsfejl',
        accessor: 'emergencyOperationalFailure',
        width: 120,
        minWidth: 120,
        disableSortBy: true,
        Filter: SelectBooleanColumnFilter,
        filter: multiSelectFilterFn
      },
      {
        Header: 'FLS Id',
        accessor: 'flsCallTypeId',
        width: 120,
        minWidth: 120,
        disableSortBy: true,
        Filter: SearchColumnFilter,
        filter: multiSelectFilterFn
      },
      {
        Header: 'Ejer',
        accessor: 'taskTypeOwner',
        width: 220,
        minWidth: 220,
        disableSortBy: true,
        Filter: SearchColumnFilter,
        filter: multiSelectFilterFn
      },
      {
        Header: 'Strækninger',
        accessor: 'enableStretches',
        width: 120,
        minWidth: 120,
        Filter: SelectBooleanColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Ignorer kørsel',
        accessor: 'ignoreDrivingTimeWhenPlanned',
        width: 120,
        minWidth: 120,
        Filter: SelectBooleanColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Relationsopgave påkrævet',
        accessor: 'requiresPreparationTask',
        width: 120,
        minWidth: 120,
        Filter: SelectBooleanColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Relationsopgavetype',
        accessor: 'defaultRelationalTaskTypeId',
        width: 120,
        minWidth: 120,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Sidst opdateret',
        accessor: 'updated',
        width: 150,
        minWidth: 150,
        Filter: (tableInstance, x) => DateRangeColumnFilter(tableInstance, true),
        filter: dateRangeFilterFn,
        Cell: (cellProps: CellProps<TaskTypeData>) => {
          const { cell } = cellProps;
          return <div>{cell.value ? formatDate(new Date(cell.value)) ?? '-' : '-'}</div>;
        }
      }
    ] as Column<TaskTypeData>[];
  }, []);

  const tableInstance = useTableInstance<TaskTypeData>(data, columns);

  return { tableInstance };
};

export default useTaskTypeList;
