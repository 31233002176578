import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectConnection, selectLockedWorkTasks } from '../stateManagement/reducers/signalRReducer';
import { getInitialsFromEmail } from '../utils/initialsUtils';

const useWorkTaskLock = () => {
  const connection = useSelector(selectConnection);
  const lockedWorkTasks = useSelector(selectLockedWorkTasks);

  const addWorkTaskLock = useCallback(
    (workTaskId: number) => {
      connection?.send('AddWorkTaskLock', workTaskId);
    },
    [connection]
  );

  const releaseWorkTaskLock = useCallback(
    (workTaskId: number) => {
      connection?.send('ReleaseWorkTaskLock', workTaskId);
    },
    [connection]
  );

  const workTaskLockedBy = useCallback(
    (workTaskId: number) => {
      const emailList: string[] = (lockedWorkTasks ?? [])
        .filter((task) => task.workTaskId === workTaskId && task.email)
        .map((task) => getInitialsFromEmail(task.email ?? ''));
      return emailList;
    },
    [lockedWorkTasks]
  );

  return {
    addWorkTaskLock,
    releaseWorkTaskLock,
    workTaskLockedBy
  };
};

export default useWorkTaskLock;
