import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ChevronRight = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-right-icon">
      <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
    </IconSvg>
  );
};
