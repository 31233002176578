import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ColumnFilterIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <rect stroke="none" x="1.086" y="3.371" width="3.287" height="22.273" rx={1}></rect>
      <path
        d="M 23.442 8.586 L 26.67 14.166 L 20.213 14.166 L 23.442 8.586 Z"
        strokeLinejoin="round"
        transform="matrix(0, 1, -1, 0, 37.667179, -8.639961)"
      ></path>
      <rect stroke="none" x="9.071" y="3.371" width="3.287" height="22.273" rx={1}></rect>
      <rect stroke="none" x="16.787" y="3.371" width="3.287" height="22.273" rx={1}></rect>
    </IconSvg>
  );
};
