import { useCallback, useEffect, useState } from 'react';
import { FilterProps, Row, useAsyncDebounce } from 'react-table';

import { Select, MenuItem } from '../../components/select/Select';
import TextField from '../../components/text-field/TextField';
import DateRangePicker from '../../components/date-range-picker/DateRangePicker';
import { formatDate } from '../../utils/dateHandling';
import styled from 'styled-components';

export const SelectColumnFilter = (tableInstance: FilterProps<any>) => {
  const { filterValue = [], setFilter, preFilteredRows, id, Header } = tableInstance.column;
  const optionsSet = new Set<string>();
  if (preFilteredRows) {
    preFilteredRows.forEach((row) => {
      optionsSet.add(row.values[id]);
    });
  }
  const options = [...optionsSet.values()];

  const handleChange = (values: any) => {
    if (values.includes('clear')) {
      setFilter([]);
    } else {
      setFilter(values);
    }
  };

  return (
    <Select
      onChange={(e) => handleChange(e.target.value || undefined)}
      multiple
      label={Header}
      compact
      fullWidth
      inputProps={{ 'aria-label': 'Without label' }}
      value={filterValue ?? []}
    >
      <MenuItem value="clear">Alle</MenuItem>
      {options?.map((option, i) => {
        return (
          <MenuItem key={i} style={{ whiteSpace: 'pre-wrap' }} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export const SelectBooleanColumnFilter = (tableInstance: FilterProps<any>, hideAll?: boolean) => {
  const { filterValue = [], setFilter, preFilteredRows, id, Header } = tableInstance.column;
  const optionsSet = new Set<string>();
  if (preFilteredRows) {
    preFilteredRows.forEach((row) => {
      optionsSet.add(row.values[id]);
    });
  }
  const options = [...optionsSet.values()];

  const handleChange = (values: any) => {
    if (values.includes('clear')) {
      setFilter([]);
    } else {
      setFilter(values);
    }
  };

  return (
    <Select
      onChange={(e) => handleChange(e.target.value || undefined)}
      multiple
      label={Header}
      compact
      fullWidth
      inputProps={{ 'aria-label': 'Without label' }}
      value={filterValue ?? []}
    >
      {options?.map((option, i) => {
        return (
          <MenuItem key={i} style={{ whiteSpace: 'pre-wrap' }} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export const SearchColumnFilter = (tableInstance: FilterProps<any>) => {
  const { filterValue, setFilter, Header } = tableInstance.column;
  const [value, setValue] = useState(filterValue);

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue]);

  const onChange = useAsyncDebounce((value?: string) => {
    setFilter(value);
  }, 500);

  return (
    <TextField
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      label={Header}
      compact
      fullWidth
      value={value || ''}
    />
  );
};

export const DateRangeColumnFilter = (tableInstance: FilterProps<any>, alignRight?: boolean, hasDeadlineDate?: boolean) => {
  const { setFilter, Header, filterValue } = tableInstance.column;

  const handleDateChange = useCallback(
    (from?: Date, to?: Date) => {
      from && to ? setFilter([from?.toISOString(), to?.toISOString()]) : setFilter([]);
    },
    [setFilter]
  );

  return (
    <DateRangePicker
      compact
      right={alignRight ? '0' : undefined}
      label={Header as string}
      onDateChanged={handleDateChange}
      hasDeadlineDate={hasDeadlineDate}
      disabled={false}
      filterValue={filterValue}
    />
  );
};

export const dateRangeFilterFn = (rows: Row<any>[], id: any, filterValues: string[]) => {
  if (filterValues && filterValues.length === 2) {
    const from = new Date(filterValues[0]);
    from.setHours(0, 0, 0, 0);
    const to = new Date(filterValues[1]);
    to.setHours(0, 0, 0, 0);

    if (from.getTime() === to.getTime()) {
      return rows.filter((row) => formatDate(new Date(row.values[id])) === formatDate(from));
    } else {
      return rows.filter((row) => {
        const date = new Date(row.values[id]);
        date.setHours(0, 0, 0, 0);

        return date.getTime() >= from.getTime() && date.getTime() <= to.getTime();
      });
    }
  }
  return rows;
};

export const multiSelectFilterFn = (rows: Row<any>[], id: any, filterValues: string[]) => {
  if (!filterValues.length) return rows;
  return rows.filter((row) => filterValues.includes(row.values[id]));
};

export const multiSelectStringArrayFilterFn = (rows: Row<any>[], id: any, filterValues: string[]) => {
  if (!filterValues.length) return rows;
  return rows.filter((row) => filterValues.some((filterValue) => row.values[id].includes(filterValue)));
};

export const Cell = styled.div<{ width?: number }>`
  display: flex;
  align-items: center;
  inline-size: ${(props) => props.width ?? 0} - ${(props) => props.theme.spacing(6)};
  overflow-wrap: anywhere;
  justify-content: space-between;
  padding: 0 ${(props) => props.theme.spacing(3)};
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  align: left;
`;

export const MultiSelectStringArrayFilterFn = (tableInstance: FilterProps<any>) => {
  const { filterValue = [], setFilter, preFilteredRows, id, Header } = tableInstance.column;

  const options = Array.from(new Set(preFilteredRows.map((row) => row.values[id]).flat()));

  const handleChange = (values: any) => {
    if (values.includes('clear')) {
      setFilter([]);
    } else {
      setFilter(values);
    }
  };

  return (
    <Select
      onChange={(e) => handleChange(e.target.value || undefined)}
      multiple
      label={Header}
      compact
      fullWidth
      inputProps={{ 'aria-label': 'Without label' }}
      value={filterValue ?? []}
    >
      {options?.map((option, i) => {
        return (
          <MenuItem key={i} style={{ whiteSpace: 'pre-wrap' }} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );
};
