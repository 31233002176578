import styled from 'styled-components';
import DialogView from '../../../components/dialog-view/DialogView';
import TextField from '../../../components/text-field/TextField';
import { useEffect, useState } from 'react';
import { DialogContent } from '@mui/material';
import Button from '../../../components/button/Button';
import NavigationHeader from '../../goods-view/components/NavigationHeader';
import NotificationService from '../../../services/NotificationService';
import { DepartmentDTO } from '../../../api/api';
import { log } from '../../../utils/logging/log';
import DepartmentService from '../../../services/DepartmentService';

interface Props {
  showDialog: boolean;
  setShowDialog: (value: boolean) => void;
  createDepartmentObjectCallback: (departmentObject: DepartmentDTO) => void;
  editDepartmentObjectCallback: (departmentObject: DepartmentDTO) => void;
  editingDepartment?: DepartmentDTO;
  resetEditingDepartment: () => void;
}

const AdminCreateDepartmentDialog = (props: Props) => {
  const {
    showDialog,
    setShowDialog,
    createDepartmentObjectCallback,
    editingDepartment,
    editDepartmentObjectCallback,
    resetEditingDepartment
  } = props;
  const [name, setName] = useState('');

  useEffect(() => {
    editingDepartment?.name && setName(editingDepartment.name);
  }, [editingDepartment]);

  const createDepartment = () => {
    DepartmentService.createDepartment(name)
      .then((response) => {
        createDepartmentObjectCallback(response);
        onClose();
      })
      .catch((e) => {
        log(e);
        NotificationService.error('Kunne ikke oprette afdeling');
      });
  };

  const editDepartment = () => {
    if (!editingDepartment?.departmentId) return;

    const body = {
      departmentId: editingDepartment.departmentId,
      name,
      isActive: true
    } as DepartmentDTO;

    DepartmentService.updateDepartment(body)
      .then((response) => {
        NotificationService.success('Afdelingen blev opdateret');
        editDepartmentObjectCallback(response);
        onClose();
      })
      .catch((e) => {
        log(e);
        NotificationService.error('Kunne ikke opdatere afdeling');
      });
  };

  const onClose = () => {
    setName('');
    setShowDialog(false);
    resetEditingDepartment();
  };

  return (
    <DialogView open={showDialog}>
      <NavigationHeader headerTitle={editingDepartment ? 'Rediger afdeling' : 'Ny nyhed'} onClose={onClose} />
      <StyledDialogContent>
        <Row>
          <TextField fullWidth label="Afdeling" value={name} onChange={(e) => setName(e.target.value)} />
        </Row>
        <Row>
          <Button onClick={editingDepartment ? editDepartment : createDepartment}>
            {editingDepartment ? 'Rediger afdeling' : 'Opret afdeling'}
          </Button>
        </Row>
      </StyledDialogContent>
    </DialogView>
  );
};

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  width: 50vw;
  max-height: 70vh;
  overflow-y: scroll;
`;

const Row = styled.div`
  display: flex;
  margin: ${(props) => props.theme.spacing(4)} ${(props) => props.theme.spacing(5)};
`;

export default AdminCreateDepartmentDialog;
