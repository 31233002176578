import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { NearbyTaskItemDTO, WorkTaskDTO2 } from '../../api/api';
import CircularProgress from '../../components/circular-progress/CircularProgress';
import TaskService from '../../services/TaskService';
import { log } from '../../utils/logging/log';
import { Coordinates } from '../../models/Coordinates';
import useNearbyTasksTable from './useNearbyTasksTable';
import Table from '../table/TableClient';
import Typography from '../../components/typography/Typography';
import Alert from '../../components/alert/Alert';
import { useDispatch } from 'react-redux';
import { updateTask } from '../../stateManagement/reducers/taskCreationReducer';

interface Props {
  assetId: string;
  coordinates?: Coordinates;
  taskCreationId?: string;
  showAlerts?: boolean;
  differentAssets?: boolean;
  workTaskId?: string;
}

const taskComparator = (task1: WorkTaskDTO2, task2: WorkTaskDTO2, assetId: string) => {
  if (task1.assetId === assetId && task2.assetId !== assetId) {
    return -1;
  }
  if (task2.assetId === assetId && task1.assetId !== assetId) {
    return 1;
  }
  return taskDateComparator(task1, task2);
};

const taskDateComparator = (task1: WorkTaskDTO2, task2: WorkTaskDTO2) => {
  if (!!task1.createdAt && !!task2.createdAt) {
    const date1 = new Date(task1.createdAt);
    const date2 = new Date(task2.createdAt);
    if (date1 < date2) {
      return -1;
    }
    if (date1 > date2) {
      return 1;
    }
  }
  return 0;
};

const NearbyTasks = (props: Props) => {
  const { assetId, coordinates, taskCreationId, showAlerts, differentAssets, workTaskId } = props;

  const [openNearbyTasksOnSameAsset, setOpenNearbyTasksOnSameAsset] = useState<NearbyTaskItemDTO[]>([]);
  const [openNearbyTasksOnDifferentAsset, setOpenNearbyTasksOnDifferentAsset] = useState<NearbyTaskItemDTO[]>([]);
  const [processedNearbyTasksOnSameAsset, setProcessedNearbyTasksOnSameAsset] = useState<NearbyTaskItemDTO[]>([]);
  const [isSearchingNearbyTasks, setIsSearchingNearbyTasks] = useState<boolean>(false);
  const [firstTimeRender, setFirstTimeRender] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if ((firstTimeRender || differentAssets) && coordinates?.longitude && coordinates?.latitude) {
      setIsSearchingNearbyTasks(true);
      coordinates.latitude !== undefined && coordinates.longitude !== undefined && assetId && setFirstTimeRender(false);
      Promise.resolve(TaskService.getNearbyTask(coordinates.latitude, coordinates.longitude, assetId))
        .then((result) => {
          const openTasksOnSameAsset = !workTaskId
            ? result.openTasksOnSameAsset
            : result.openTasksOnSameAsset?.filter((t) => t.workTaskId !== workTaskId);
          setOpenNearbyTasksOnSameAsset(openTasksOnSameAsset ?? []);

          setOpenNearbyTasksOnDifferentAsset(
            result.openTasksOnDifferentAsset?.sort((a, b) => taskComparator(a, b, assetId)) ?? []
          );

          const processedTasksOnSameAsset = !workTaskId
            ? result.processedTasksOnSameAsset
            : result.processedTasksOnSameAsset?.filter((t) => t.workTaskId !== workTaskId);
          setProcessedNearbyTasksOnSameAsset(processedTasksOnSameAsset ?? []);

          setIsSearchingNearbyTasks(false);
          taskCreationId &&
            dispatch(updateTask({ id: taskCreationId ?? '', task: { nearbyTasks: result.openTasksOnSameAsset } }));
        })
        .catch((error) => {
          log(error);
          setIsSearchingNearbyTasks(false);
        });
    }
  }, [assetId, coordinates, differentAssets, dispatch, firstTimeRender, taskCreationId, workTaskId]);

  const { tableInstance: openNearbyTasksOnSameAssetInstance } = useNearbyTasksTable(openNearbyTasksOnSameAsset ?? []);
  const { tableInstance: openNearbyTasksOnDifferentAssetInstance } = useNearbyTasksTable(
    openNearbyTasksOnDifferentAsset ?? []
  );
  const { tableInstance: processedNearbyTasksOnSameAssetInstance } = useNearbyTasksTable(
    processedNearbyTasksOnSameAsset ?? []
  );

  if (isSearchingNearbyTasks) {
    return <CircularProgress />;
  }

  if (
    openNearbyTasksOnSameAsset?.length > 0 ||
    openNearbyTasksOnDifferentAsset?.length > 0 ||
    processedNearbyTasksOnSameAsset?.length > 0
  ) {
    return (
      <Container>
        <CreatedTasksOnSameAssetHeaderDiv>
          <Header>Oprettede opgaver på asset</Header>
          {showAlerts && openNearbyTasksOnSameAssetInstance.rows.length > 0 && (
            <Alert margin="0px 10px" severity="warning" height="32px">
              {`Opmærksom: Der ligger allerede følgende åbne opgaver på ${assetId}`}
            </Alert>
          )}
        </CreatedTasksOnSameAssetHeaderDiv>
        {openNearbyTasksOnSameAssetInstance.rows.length > 0 ? (
          <Table
            tableInstance={openNearbyTasksOnSameAssetInstance}
            noPadding
            loading={isSearchingNearbyTasks}
            renderFullHeight
            alwaysShowSort
          />
        ) : (
          <Typography fontStyle="italic">Fandt ingen åbne opgaver på samme asset</Typography>
        )}

        <Header>Tidligere opgaver på asset</Header>
        {processedNearbyTasksOnSameAssetInstance.rows.length > 0 ? (
          <Table
            tableInstance={processedNearbyTasksOnSameAssetInstance}
            noPadding
            loading={isSearchingNearbyTasks}
            renderFullHeight
            alwaysShowSort
          />
        ) : (
          <Typography fontStyle="italic">Fandt ingen afsluttede opgaver på samme asset</Typography>
        )}
        <Header>Opgaver i området (Inden for 500 meter)</Header>
        {openNearbyTasksOnDifferentAssetInstance.rows.length > 0 ? (
          <Table
            tableInstance={openNearbyTasksOnDifferentAssetInstance}
            noPadding
            loading={isSearchingNearbyTasks}
            renderFullHeight
            alwaysShowSort
          />
        ) : (
          <Typography fontStyle="italic">Fandt ingen åbne opgaver i nærheden</Typography>
        )}
      </Container>
    );
  } else {
    return (
      <StyledOutlinedDiv>
        <span>Der blev ikke fundet nogle åbne arbejdskort i en radius af 500 meter fra den pågældende asset.</span>
      </StyledOutlinedDiv>
    );
  }
};

const StyledOutlinedDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: ${(props) => props.theme.spacing(2)};
  border: ${(props) => '1.9px dashed ' + props.theme.palette.grey.black20};
  border-radius: 8px;
  color: ${(props) => props.theme.palette.grey.black60};
  line-height: 64px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(3)};
  margin-top: ${(props) => props.theme.spacing(3)};
`;

const Header = styled.h3`
  margin: ${(props) => props.theme.spacing(3)} 0;
`;

const CreatedTasksOnSameAssetHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export default NearbyTasks;
