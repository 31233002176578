import { LoaderOptions } from '@googlemaps/js-api-loader';
import { LocationDTO } from '../../api/api';

export const GoogleApiSettings: LoaderOptions = {
  apiKey: window._env_.MAPS_KEY,
  libraries: ['places'],
  id: 'My_ID'
};

export const getLocationString = (location: LocationDTO | undefined): string => {
  const streetName = location?.streetName ? location.streetName + ' ' : '';
  const houseNumber = location?.houseNumber ? location.houseNumber + ', ' : '';
  const postalCode = location?.postalCode ? location?.postalCode + ' ' : '';
  const city = location?.city ?? '';
  return streetName + houseNumber + postalCode + city;
};

export const getNearestAddressByLatLng = async (
  coordinates: {
    lat: number;
    lng: number;
  }[]
): Promise<LocationDTO[]> => {
  const promises = [];
  const result: LocationDTO[] = [];

  for (let index = 0; index < coordinates.length; index++) {
    const { lat, lng } = coordinates[index];

    promises.push(
      fetch(`https://api.dataforsyningen.dk/adgangsadresser/reverse?x=${lng}&y=${lat}`).then((res) => {
        return res.json().then((obj) => {
          result.push({
            city: obj.supplerendebynavn ?? obj.postnummer.navn,
            houseNumber: obj.husnr,
            latitude: lat,
            longitude: lng,
            postalCode: obj.postnummer.nr,
            streetName: obj.vejstykke.navn
          });
        });
      })
    );
  }

  return Promise.all(promises).then(() => {
    return result;
  });
};

export const locationsAreEqual = (a: LocationDTO, b: LocationDTO) => {
  return (
    a.streetName === b.streetName &&
    a.houseNumber === b.houseNumber &&
    a.postalCode === b.postalCode &&
    a.city === b.city &&
    a.latitude === b.latitude &&
    a.longitude === b.longitude
  );
};
