import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const UnreadDot = (props: IconProps) => {
  return (
    <IconSvg {...props} strokeWidth="0" viewBox="-10 14 35 20" xmlns="http://www.w3.org/2000/svg">
      <svg width="14" height="45" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="7" cy="7.5" r="7" fill="#2E5AAC" />
      </svg>
    </IconSvg>
  );
};
