import { useMemo } from 'react';
import { Column } from 'react-table';
import { ReasonCodeDTO } from '../../../api/api';
import { CheckboxColumn } from '../../../blocks/table/TableUtils';
import useTableInstance from '../../../hooks/useTableInstance';
import { getEnumDisplayValue } from '../../../utils/enumUtils';
import { SearchColumnFilter, SelectColumnFilter, multiSelectFilterFn } from '../../../blocks/table/TableFilterUtils';

export interface ReasonCodeData {
  id?: number;
  reasonText?: string;
  status?: string;
}

const useAdminReasonCodesList = (questions: ReasonCodeDTO[]) => {
  const data = useMemo(() => {
    return questions.map((q) => {
      return {
        ...q,
        status: q.status ? getEnumDisplayValue(q.status) : '',
        id: q.reasonCodeId
      } as ReasonCodeData;
    });
  }, [questions]);

  const columns = useMemo(() => {
    return [
      CheckboxColumn<ReasonCodeData>(),
      {
        Header: 'Id',
        accessor: 'id',
        width: 50,
        minWidth: 50,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Tekst',
        accessor: 'reasonText',
        width: 200,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 100,
        minWidth: 100,
        Filter: SelectColumnFilter,
        filter: multiSelectFilterFn
      }
    ] as Column<ReasonCodeData>[];
  }, []);

  const tableInstance = useTableInstance<ReasonCodeData>(data, columns);

  return { tableInstance };
};

export default useAdminReasonCodesList;
