import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';

import useTableInstance from '../../../hooks/useTableInstance';
import QuantityCell from '../components/QuantityCell';
import { SelectedGoods, SelectedGoodsData } from '../../../models/Goods';

export const useSelectedGoodsList = (
  selectedGoods: SelectedGoods[],
  setGoodsAmount: (value: number, goodsId?: string, goodsBatch?: string, goodsSerial?: string) => void,
  removeGoods?: (goodsId?: string, goodsBatch?: string, goodsSerial?: string) => void,
  showAvailable?: boolean
) => {
  const data = useMemo((): SelectedGoodsData[] => {
    if (!selectedGoods.length) return [];

    return selectedGoods.map((item) => {
      const idSuffix = item.batchNumber || item.serialNumber;
      return {
        goodsId: item.itemId ? item.itemId : '',
        goodsBatch: item.batchNumber,
        goodsSerial: item.serialNumber,
        goodsIdDisplayName: item.itemId + (idSuffix ? ` (${idSuffix})` : ''),
        goodsName: item.name ?? '-',
        amount: item.qty ?? '0',
        available: showAvailable ? item.available ?? '0' : undefined,
        showError: showAvailable && (item.available ?? 0) < (item.qty ?? 0)
      } as SelectedGoodsData;
    });
  }, [selectedGoods, showAvailable]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Varenummer',
        accessor: 'goodsIdDisplayName',
        disableFilters: true,
        width: 100
      },
      {
        Header: 'Varenavn',
        accessor: 'goodsName',
        disableFilters: true,
        width: 175
      },
      {
        Header: 'På lager',
        accessor: 'available',
        disableFilters: true
      },
      {
        Header: 'Antal',
        accessor: 'amount',
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<SelectedGoodsData>) => {
          return (
            <QuantityCell
              maxAvailableStock={showAvailable ? parseInt(cellProps.row.original.available ?? '0') : undefined}
              row={cellProps.row.original}
              qty={cellProps.value}
              removeGoods={removeGoods}
              setGoodsAmount={setGoodsAmount}
            />
          );
        }
      }
    ] as Column<SelectedGoodsData>[];
  }, [removeGoods, setGoodsAmount, showAvailable]);

  const tableInstance = useTableInstance<SelectedGoodsData>(data, columns, {
    initialState: {
      pageSize: 6000,
      pageIndex: 0,
      hiddenColumns: showAvailable ? [] : ['available']
    }
  });

  return { tableInstance };
};
