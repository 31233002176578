import RadioButtons from '../radio-buttons/RadioButtons';

interface Props {
  text: string;
  handleValueChange: (value: number) => void;
  value?: any;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
}

const YesNoRadioButtons = (props: Props) => {
  const { value, handleValueChange, text, required, error, disabled } = props;

  return (
    <RadioButtons
      value={value}
      handleValueChange={handleValueChange}
      label={text}
      options={[
        { label: 'Ja', value: 1 },
        { label: 'Nej', value: 0 }
      ]}
      required={required}
      error={error}
      disabled={disabled}
      labelPlacement="end"
      row
    />
  );
};

export default YesNoRadioButtons;
