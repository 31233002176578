import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { WeekDays } from '../../utils/dateHandling';

interface CalendarState {
  selectedWeek?: WeekDays;
}

const initialState: CalendarState = {
  selectedWeek: undefined
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState: initialState,
  reducers: {
    setSelectedWeek: (state: CalendarState, action: PayloadAction<WeekDays>) => {
      state.selectedWeek = action.payload;
    }
  }
});

export const { setSelectedWeek } = calendarSlice.actions;
export const selectedWeek = (state: RootState) => state.calendarReducer.selectedWeek;

export default calendarSlice.reducer;
